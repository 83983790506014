import styled from '@emotion/styled';

import { COLORS, mq } from '@clutter/clean';

export const Grid = styled.div`
  min-height: 100vh;
  grid-column-gap: 72px;

  ${mq({
    display: [null, null, 'grid'],
    gridTemplateColumns: [null, null, '7fr 392px', '7fr 476px'],
  })}
`;

export const Content = styled.div`
  position: relative;

  ${mq({
    margin: ['0 24px 40px', null, '0 auto'],
    padding: ['0', null, '0 0 0 64px'],
    maxWidth: '1600px',
  })}

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    background: ${COLORS.grayBackground};
    width: calc((100vw - 1600px) / 2);
    left: 100%;
    top: 0;
    height: 100%;

    ${mq({ display: ['none', null, 'block'] })}
  }
`;
