import { Operation } from '@apollo/client';

type OperationKind = 'query' | 'mutation' | 'subscription' | 'unknown';

export const getOperationKind = (operation: Operation): OperationKind => {
  const definition = operation.query.definitions[0];
  if (definition?.kind === 'OperationDefinition') {
    return definition.operation;
  } else {
    return 'unknown';
  }
};
