import * as qs from 'qs';

export const buildUrlWithQs = (
  baseURL: string,
  queryParams?: { [key: string]: string | undefined },
) => {
  const [url, queryString] = baseURL.split('?');
  const existingQueryParams = qs.parse(queryString);
  const combinedQueryParams = qs.stringify({
    ...existingQueryParams,
    ...(queryParams || {}),
  });
  return `${url}?${combinedQueryParams}`;
};
