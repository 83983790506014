import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';

import lockClosed from '@images/illustrations/lock_closed_small.svg';
import planSize from '@images/illustrations/plan_size_small.svg';

const Illustration = styled.img`
  margin-bottom: 8px;
`;

export const StoragePlanValueProps: React.FC<{}> = () => {
  return (
    <Box.Flex justifyContent="space-between">
      <Box.FlexItem textAlign="center" margin="0 20px">
        <Illustration src={planSize} alt="" />
        <Text.Caption color={COLORS.storm}>
          We’ll lower your monthly rate if you need less space.
        </Text.Caption>
      </Box.FlexItem>
      <Box.FlexItem textAlign="center" margin="0 20px">
        <Illustration src={lockClosed} alt="" />
        <Text.Caption color={COLORS.storm}>
          Temperature-controlled facility with 24/7 security.
        </Text.Caption>
      </Box.FlexItem>
    </Box.Flex>
  );
};
