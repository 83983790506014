import React from 'react';

import { FeaturesOverlay } from '../features_overlay';
import {
  HOURLY_RATE,
  LONG_DISTANCE_FEATURES,
  SMART_STORAGE_WAREHOUSE_FEE,
} from '../features_overlay/data';

export enum OverlayContentKind {
  SmartStorageHourlyRate = 'SMART_STORAGE_HOURLY_RATE',
  MovingHourlyRate = 'MOVING_HOURLY_RATE',
  WarehouseFee = 'WAREHOUSE_FEE',
}

export const Overlay: React.FC<{
  contentKind?: OverlayContentKind;
  onClose: () => void;
}> = ({ contentKind, onClose }) => {
  const modalContent = (() => {
    switch (contentKind) {
      case OverlayContentKind.SmartStorageHourlyRate:
        return HOURLY_RATE;
      case OverlayContentKind.MovingHourlyRate:
        return LONG_DISTANCE_FEATURES;
      case OverlayContentKind.WarehouseFee:
        return SMART_STORAGE_WAREHOUSE_FEE;
      default:
        return [];
    }
  })();
  return (
    <FeaturesOverlay
      isOpen={!!contentKind}
      onClose={onClose}
      content={modalContent}
    />
  );
};
