import { WT_VISITOR_TOKEN } from './wt';

declare global {
  interface Window {
    dataLayer?: {
      push(data: Record<string, string>): void;
    };
  }
}

if (typeof window !== 'undefined' && window.dataLayer) {
  window.dataLayer.push({
    visitor_token: WT_VISITOR_TOKEN,
  });
}
