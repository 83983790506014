import React from 'react';

import { Box, COLORS, FontWeight, Text, ZIndex } from '@clutter/clean';
import { useLeadQuery } from '@graphql/platform';
import { AGENT_BOOKING_LEAD_TOKEN } from '@root/components/checkout/helpers/agent_booking';

export const AgentBanner = () => {
  const { data, error } = useLeadQuery({
    variables: { token: AGENT_BOOKING_LEAD_TOKEN! },
    skip: !AGENT_BOOKING_LEAD_TOKEN,
  });

  if (!AGENT_BOOKING_LEAD_TOKEN) return null;

  return (
    <Box
      background={COLORS.tiger}
      position="sticky"
      top="0"
      padding="16px"
      minHeight="52px"
      textAlign="center"
      style={{ zIndex: ZIndex.MODAL + 1 }}
    >
      <Text.Callout weight={FontWeight.Medium}>
        {error
          ? 'Attention: You are booking on behalf of an unknown lead, please return to Zendesk to re-open the checkout.'
          : data
          ? `Attention: You are booking on behalf of ${data.lead.name}.`
          : 'Loading'}
      </Text.Callout>
    </Box>
  );
};
