import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';

import customerPacking from '@images/illustrations/mover_packing_boxes.svg';
import moverWaving from '@images/illustrations/mover_waving_small.svg';
import loadingTruck from '@images/illustrations/moving_truck_small.svg';

const ValuePropContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 32px 24px 0;
`;

const Illustration = styled.img`
  width: 40px;
  height: 33px;
  margin-bottom: 8px;
`;

const TruckIllustration = styled.img`
  height: 33px;
  margin-bottom: 8px;
`;

export const StorageMapValueProps: React.FC<{}> = () => {
  return (
    <ValuePropContainer>
      <Box.FlexItem textAlign="center">
        <Illustration src={customerPacking} alt="" />
        <Text.Caption color={COLORS.storm}>
          We bring free packing materials to your home.
        </Text.Caption>
      </Box.FlexItem>
      <Box.FlexItem textAlign="center">
        <TruckIllustration src={loadingTruck} alt="" />
        <Text.Caption color={COLORS.storm}>
          We provide the moving truck and transportation.
        </Text.Caption>
      </Box.FlexItem>
      <Box.FlexItem textAlign="center">
        <Illustration src={moverWaving} alt="" />
        <Text.Caption color={COLORS.storm}>
          We fully employ our professional Move & Pack Specialists.
        </Text.Caption>
      </Box.FlexItem>
    </ValuePropContainer>
  );
};
