import * as React from 'react';

import { useLocation } from 'react-router';

import { useLatestRef } from '@clutter/hooks';
import { WTEventParams } from '@clutter/wt';
import { trackPageview } from '@root/initializers/wt';

interface IWtPageviewProps {
  params?: WTEventParams;
}

/**
 * A component that fires a `pageview` event on path change
 *
 * NB: This won't fire a pageview if only the query string changes even if it remounts.
 */
export const WtPageview: React.FC<IWtPageviewProps> = ({ params }) => {
  const location = useLocation();
  const paramRef = useLatestRef(params);
  React.useEffect(() => {
    trackPageview({ ...paramRef.current, kind: 'pageview' });
  }, [location, paramRef]);
  return null;
};
