import { COMMITMENT_TO_LENGTH } from '@root/constants/pricing';
import { Commitment } from '@root/resources/types/commitment';
import { EventSchema } from '@root/resources/wt/types';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';

export const useStoragePriceCompletedFunnelEvent = () => {
  const trackFunnelEvent = useTrackFunnelEvents();
  return ({
    planSize,
    commitment,
    amount,
  }: {
    planSize: string;
    commitment: Commitment;
    amount: number;
  }) => {
    trackFunnelEvent({
      schema: EventSchema.WWW__StoragePriceCompleted,
      action: 'display',
      metadata: {
        plan_size: planSize,
        commitment_length: COMMITMENT_TO_LENGTH[commitment],
        monthly_price: amount,
      },
    });
  };
};
