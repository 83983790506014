import React from 'react';

import styled from '@emotion/styled';

import { Box, FontWeight, LARGE_TITLE_STYLES, Text } from '@clutter/clean';

import { PolicyModal } from './subcomponents/policy_modal';

const Body = styled(Text.Body)`
  padding-top: 16px;
  padding-bottom: 40px;
`;

export const StorageCancellationModal: React.FC<{
  isOpen: boolean;
  handleModalClose(): void;
}> = ({ isOpen, handleModalClose }) => {
  return (
    <PolicyModal isOpen={isOpen} handleModalClose={handleModalClose}>
      <Box margin="0 0 8px">
        <Text style={LARGE_TITLE_STYLES.SM}>Cancellation policy</Text>
      </Box>
      <Text.Headline weight={FontWeight.Medium}>
        When will I be charged?
      </Text.Headline>
      <Body>
        Don’t worry, your reservation is completely free! Your card will not be
        charged until your appointment is complete.
      </Body>
      <Text.Headline weight={FontWeight.Medium}>
        What if I need to reschedule?
      </Text.Headline>
      <Body>
        We ask that you reschedule or cancel at least 48hrs before your
        appointment. Otherwise, you will be charged a $100 charge. This charge
        is increased to $200 on the same day of the appointment. You can
        reschedule or cancel online in just a few clicks.
      </Body>
      <Text.Headline weight={FontWeight.Medium}>
        What if I cancel before my term is over?
      </Text.Headline>
      <Body>
        You may cancel your Subscription Services at any time once the minimum
        storage term has been met.
      </Body>
    </PolicyModal>
  );
};
