import React from 'react';

import styled from '@emotion/styled';

import { Text } from '@clutter/clean';

import { AccessItems } from './access_items';
import { FeatureGrid } from './feature_grid';
import { FeatureGridIllustration } from './feature_grid_Illustration';
import { FeatureGridItem } from './feature_grid_item';
import { ImageAndTextFeature } from './image_and_text_feature';
import { QualityServices } from './quality_services';
import { TraditionalStorageComparison } from './traditional_storage_comparison';

import flexibleScheduling from '@images_responsive/www/checkout/flexible_scheduling.jpg';
import rateGuarantee from '@images_responsive/www/checkout/rate_guarantee.jpg';
import reliability from '@images_responsive/www/checkout/reliability.jpg';
import saveTime from '@images_responsive/www/checkout/save_time.jpg';
import storageFacilityCropped from '@images_responsive/www/checkout/storage_facility_cropped.jpg';
import storageFacility from '@images_responsive/www/checkout/storage_facility.jpg';
import spaceYouUseCropped from '@images_responsive/www/checkout/storage_hourly_rate_cropped.jpg';
import spaceYouUse from '@images_responsive/www/checkout/storage_hourly_rate.jpg';
import storeAlmostAnything from '@images_responsive/www/checkout/store_almost_anything.jpg';
import clutterTruck from '@images/illustrations/clutter_truck.svg';
import forklift from '@images/illustrations/forklift.svg';
import moverWaving from '@images/illustrations/mover_waving.svg';
import moverWithRegrigerator from '@images/illustrations/mover_with_refrigerator.svg';
import onlinePhotoCatalog from '@images/illustrations/online_photo_catalog_small.svg';
import onlineQuote from '@images/illustrations/online_quote.svg';
import packingBox from '@images/illustrations/packing_box.svg';
import tape from '@images/illustrations/tape_multi_small.svg';

const List = styled.ul`
  list-style-type: disc;
  margin-left: 36px;
`;

export const SMART_STORAGE_FEATURES = [
  <ImageAndTextFeature
    key="0"
    title="Save time and money"
    content={
      <>
        Moving your items into traditional self-storage costs you a lot of time,
        stress, and money (on average more than $800). With Clutter Smart
        Storage, you save on all three.
        <br />
        <br />
        Every Smart Storage plan comes with free unlimited packing materials and
        discounted rates on moving. Finish your storage project on-budget with
        the help of our Move & Pack Specialists that are evaluated every day on
        safety and efficiency.
        <br />
        <br />
        And to save extra money on your project, you can opt to pack boxes
        yourself and we’ll just handle the heavy lifting.
      </>
    }
    image={saveTime}
  />,
  <ImageAndTextFeature
    key="1"
    title="Only pay for the space you use"
    content={
      <>
        With a traditional self-storage unit, you have to pay for the extra
        space that you’re not using.
        <br />
        <br />
        Clutter’s Move & Pack Specialists use an app that measures every item
        and you’ll only pay for the space your items take up. If you need less
        storage on the day of your pickup, we will automatically downgrade you
        to a smaller, cheaper plan.
        <br />
        <br />
        As you add or remove items from storage, you can flex your plan size and
        price based on the space you need.
      </>
    }
    image={spaceYouUse}
    croppedImage={spaceYouUseCropped}
  />,
  <QualityServices key="2" />,
  <AccessItems key="3" />,
  <ImageAndTextFeature
    key="4"
    title="Store almost anything"
    content={
      <>
        We’ve designed our Smart Storage facilities to safely store almost any
        type of item regardless of size or shape. Our special handling for
        categories like furniture, rugs, and bulky items allows us to eliminate
        common forms of damages.
        <br />
        <br />
        There are only a few things we can’t store: <br />
        <List>
          <li>Living items or food</li>
          <li>Vehicles or combustibles</li>
          <li>Grand pianos</li>
          <li>All-in-one home gyms</li>
        </List>
        <br />
        Anything else, send our way!
      </>
    }
    image={storeAlmostAnything}
  />,
  <TraditionalStorageComparison key="5" />,
];

export const LONG_DISTANCE_FEATURES = [
  <ImageAndTextFeature
    key="0"
    title="What’s included"
    content={
      <>
        At Clutter, our long-distance moving service is designed to provide a
        seamless and stress-free experience for our customers. Our white-glove
        approach sets us apart from other companies and offers several key
        benefits.
        <br />
        <br />
        <Text.Title size="extraSmall">Transparent Prices</Text.Title>
        <br />
        First, our prices are extremely transparent and adhere to the rates
        regulated by law. You won’t see any hidden fees for stairs, elevators,
        long-carry, or shuttle service at your destination, which will save you
        hundreds, if not thousands of dollars on your move.
        <br />
        <br />
        <Text.Title size="extraSmall">Everything In House</Text.Title>
        <br />
        Second, we do everything in-house, so you never have to worry about your
        items being handled by anyone other than a Clutter Move & Pack
        Specialist. Our professional movers are all W-2 employees who are
        background-checked, licensed, and insured so that you receive the
        highest quality of service.
        <br />
        <br />
        <Text.Title size="extraSmall">Dedicated Truck</Text.Title>
        <br />
        Finally, we provide a dedicated truck for each customer, so your
        belongings will never get transferred to another truck along the way.
        Our trucks are used the entire way, giving you peace of mind that your
        items will stay secure and arrive on time.
        <br />
        <br />
        <Text.Title size="extraSmall">Guaranteed Delivery Date</Text.Title>
        <br />
        Unlike competitors, we guarantee our delivery date within a 1-hour
        arrival window. You’ll be able to track your team’s ETA and progress
        throughout the appointment.
      </>
    }
    image={spaceYouUse}
    croppedImage={spaceYouUseCropped}
  />,
];

export const HOURLY_RATE = [
  <ImageAndTextFeature
    key="0"
    title="What’s included"
    content={
      <>
        We’ll pack boxes, disassemble furniture, wrap items for safe storage,
        and load everything onto the truck.
        <br />
        <br />
        You’ll be able to track your team’s ETA and progress throughout the
        appointment.
        <br />
        <br />
        After making your free reservation, let us know exactly what we’re
        picking up with Clutter’s Virtual Walkthrough tool so we send the right
        amount of help and materials for your pickup.
        <br />
        <br />
        <FeatureGrid>
          <FeatureGridItem>
            <FeatureGridIllustration src={moverWaving} />
            <div>Movers</div>
          </FeatureGridItem>
          <FeatureGridItem>
            <FeatureGridIllustration src={moverWithRegrigerator} />
            <div>Equipment</div>
          </FeatureGridItem>
          <FeatureGridItem>
            <FeatureGridIllustration src={tape} />
            <div>Supplies</div>
          </FeatureGridItem>
          <FeatureGridItem>
            <FeatureGridIllustration src={packingBox} />
            <div>Boxes</div>
          </FeatureGridItem>
          <FeatureGridItem>
            <FeatureGridIllustration src={onlineQuote} />
            <div>Tracking</div>
          </FeatureGridItem>
        </FeatureGrid>
      </>
    }
    image={spaceYouUse}
    croppedImage={spaceYouUseCropped}
  />,
];

export const SMART_STORAGE_WAREHOUSE_FEE = [
  <ImageAndTextFeature
    key="0"
    title="Warehouse Fee"
    content={
      <>
        Smart Storage is the safest and most convenient way to store thanks to
        the professional teams and equipment managing your items at our
        facilities.
        <br />
        <br />
        Your account comes with 24/7 online access via a photo inventory where
        you can tag, organize, and request delivery for one or all of your
        items.
        <br />
        <br />
        The warehouse fee covers all the packing materials, equipment, labor,
        and technology powering your Smart Storage experience.
        <br />
        <br />
        <FeatureGrid>
          <FeatureGridItem>
            <FeatureGridIllustration src={clutterTruck} />
            <div>Truck</div>
          </FeatureGridItem>
          <FeatureGridItem>
            <FeatureGridIllustration src={forklift} />
            <div>Unloading</div>
          </FeatureGridItem>
          <FeatureGridItem>
            <FeatureGridIllustration src={onlinePhotoCatalog} />
            <div>Inventory</div>
          </FeatureGridItem>
        </FeatureGrid>
      </>
    }
    image={storageFacility}
    croppedImage={storageFacilityCropped}
  />,
];

export const MOVING_LEARN_MORE = [
  <ImageAndTextFeature
    key="0"
    title="Reliability"
    content={
      <>
        With over 151,990+ moves completed across North America, Clutter is the
        most trusted and experienced local mover.
        <br />
        <br />
        Clutter offers an online account portal where you can manage your move
        when the time is right for you. Dedicated customer support teams are
        available to support you throughout your move.
      </>
    }
    image={reliability}
  />,
  <QualityServices key="1" />,
  <ImageAndTextFeature
    key="2"
    title="Transparency"
    content={
      <>
        Clutter provides you an instant rate quote in under 2 minutes. Just
        answer a couple of questions and calculate your rate. This rate will not
        change and there are no hidden fees.
        <br />
        <br />
        If you want a specific time estimate, then make a free reservation and
        complete the detailed virtual walkthrough from your account portal.
      </>
    }
    image={saveTime}
  />,
];

export const MOVING_RATE_GUARANTEE = [
  <ImageAndTextFeature
    key="0"
    title="We guarantee our rates"
    content={
      <>
        The rates calculated will not change and there are no hidden fees.
        <br />
        <br />
        Many movers will charge you extra fees for parking, long carry, or even
        using moving blankets.
        <br />
        <br />
        With Clutter, everything is included in one easy to understand rate. If
        you want a more specific time estimate, then make a free reservation and
        complete a Virtual Walkthrough whenever is most convenient for you.
      </>
    }
    image={rateGuarantee}
  />,
];

export const MOVING_FLEXIBLE_SCHEDULING = [
  <ImageAndTextFeature
    key="0"
    title="Move when it’s best for you"
    content={
      <>
        We understand that moving dates change. Easily reschedule or cancel for
        free from your online account any time up to 48 hours before your move.
        <br />
        <br />
        If you don’t see the time that works for you, make a reservation and
        contact our customer experience team. They will work to find you the
        date and time that works best for you.
      </>
    }
    image={flexibleScheduling}
  />,
];
