import React from 'react';

import { AddressFragment } from '@graphql/platform';

export const Address: React.FunctionComponent<{
  address: Omit<AddressFragment, 'id'>;
  className?: string;
}> = ({ address, className }) => (
  <div className={className}>
    <div>{address.street}</div>
    {address.aptsuite && <div>{address.aptsuite}</div>}
    <div>
      {address.city}, {address.state} {address.zip}
    </div>
  </div>
);
