import React from 'react';

import { ServiceSelection } from '@shared/service_selector/constants';
import { GeocodeStatus } from '@utils/geocode';

type Props = {
  type: GeocodeStatus;
  service?: ServiceSelection;
};

function buildError(props: Props) {
  const serviceCopy = (() => {
    switch (props.service) {
      case ServiceSelection.Storage:
        return 'offer Smart Storage';
      case ServiceSelection.Moving:
        return 'offer moving services';
      default:
        return 'operate';
    }
  })();

  switch (props.type) {
    case GeocodeStatus.Invalid:
      return 'Please provide a valid ZIP code';
    case GeocodeStatus.OSA:
      return `We’re sorry, but Clutter doesn’t ${serviceCopy} in your area yet.`;
  }
}

export const GeocodeError: React.FC<Props> = (props) => {
  return <>{buildError(props)}</>;
};
