import React from 'react';

import { Box, COLORS, FontWeight, SkeletonLoader, Text } from '@clutter/clean';
import { Currency } from '@shared/currency';
import { Fallback } from '@shared/fallback';
import { Pluralize } from '@shared/formatters/pluralize';

export const Price: React.FC<{
  amount: number;
  maxAmount?: number;
  isMobile: boolean;
  suffix?: string;
  estimatedMovers?: number;
  displayStartingAt?: boolean;
  startingAtText?: string;
  loading?: boolean;
  showBackground?: boolean;
}> = ({
  amount,
  maxAmount = 0,
  isMobile,
  suffix = '',
  estimatedMovers,
  displayStartingAt = false,
  startingAtText = 'Starting at',
  loading = false,
  showBackground = false,
}) => {
  const showDiscount = !!maxAmount && amount < maxAmount;
  const HeadingText = isMobile ? Text.Callout : Text.Body;
  return (
    <Fallback
      delayMs={0}
      loader={
        <SkeletonLoader
          height={['20px', null, null, '38px']}
          width="100px"
          margin={['initial', null, null, '0 0 0 12px']}
        />
      }
      value={!loading}
    >
      <div>
        <Box.FlexItem
          padding="2px 4px"
          borderRadius="4px"
          background={
            showDiscount || showBackground
              ? COLORS.tealBackground
              : 'transparent'
          }
          display="inline-block"
          order={[2, null, null, 'initial']}
        >
          <HeadingText color={COLORS.tealDark} weight={FontWeight.Medium}>
            {displayStartingAt && `${startingAtText} `}
            <Currency amount={amount} />
            {suffix}
          </HeadingText>
        </Box.FlexItem>
      </div>
      {showDiscount && (
        <Box.FlexItem
          order={[1, null, null, 'initial']}
          padding={['4px', null, null, 'initial']}
        >
          <HeadingText textDecoration="line-through" color={COLORS.hippo}>
            <Currency amount={maxAmount} />
            {suffix}
          </HeadingText>
        </Box.FlexItem>
      )}
      {!!estimatedMovers && (
        <Box.FlexItem order={[3, 'initial']}>
          <Text.Caption color={COLORS.panther}>
            <Pluralize
              count={estimatedMovers}
              singular="mover"
              plural="movers"
            />{' '}
            included
          </Text.Caption>
        </Box.FlexItem>
      )}
    </Fallback>
  );
};
