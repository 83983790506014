import React from 'react';

import styled from '@emotion/styled';

import { COLORS, toggleStyleValue } from '@clutter/clean';

type Direction = 'up' | 'down';

type DropdownArrowProps = {
  className?: string;
  direction?: Direction;
  fillColor?: string;
  animate?: boolean;
};

const Svg = styled.svg<{ direction: Direction; animate?: boolean }>`
  transform: scaleY(${({ direction }) => (direction === 'down' ? 1 : -1)});
  ${toggleStyleValue('animate', 'transition: transform 150ms ease', '')}
`;

export const DropdownArrow: React.FC<DropdownArrowProps> = ({
  className,
  direction = 'down',
  fillColor = COLORS.panther,
  animate = false,
}) => (
  <Svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    direction={direction}
    animate={animate}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.373699 1.57901C0.169596 1.38236 0.169596 1.05554 0.373699 0.858889L0.918195 0.334263C1.11185 0.147679 1.41839 0.147679 1.61204 0.334263L5.65308 4.22783C5.84673 4.41442 6.15327 4.41442 6.34692 4.22783L10.388 0.334264C10.5816 0.14768 10.8882 0.14768 11.0818 0.334264L11.6263 0.85889C11.8304 1.05555 11.8304 1.38236 11.6263 1.57901L6.34692 6.66574C6.15327 6.85232 5.84673 6.85232 5.65308 6.66574L0.373699 1.57901Z"
      fill={fillColor}
    />
  </Svg>
);
