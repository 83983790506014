export function getUTMParams() {
  if (typeof window === 'undefined' || !window.URLSearchParams) return {};
  const params = new URLSearchParams(window.location.search);
  const utmParams: Record<string, string> = {};

  params.forEach((value, param) => {
    if (param.indexOf('utm_') === 0) {
      const key = param.replace('utm_', '');
      utmParams[key] = value;
    }
  });

  return utmParams;
}
