import { LocatableBoundsInput } from '@graphql/platform';

type IntermediatePart = 'number' | 'zipCode' | 'street' | 'city' | 'state';

const COMPONENTS: Array<{ key: IntermediatePart; components: string[] }> = [
  { key: 'number', components: ['street_number'] },
  { key: 'zipCode', components: ['postal_code'] },
  { key: 'street', components: ['street_address', 'route'] },

  {
    key: 'city',
    components: [
      'neighborhood',
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
      'administrative_area_level_5',
      'administrative_area_level_4',
      'administrative_area_level_3',
    ],
  },
  {
    key: 'state',
    components: ['administrative_area_level_1'],
  },
];

export function extractAddressFromGeocoderResult(
  result: google.maps.GeocoderResult,
) {
  const { address_components: addressComponents } = result;

  const intermediateAddress = {
    number: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
  };

  addressComponents.forEach((component) => {
    COMPONENTS.map(({ key, components }) => {
      if (
        components.indexOf(component.types[0]) !== -1 ||
        components.indexOf(component.types[1]) !== -1 // Fall back to secondary type
      ) {
        intermediateAddress[key] =
          key === 'state' ? component.short_name : component.long_name;
      }
    });
  });

  const { number, street, city, state, zipCode } = intermediateAddress;

  return {
    street: number ? `${number} ${street}` : street,
    city,
    state,
    zipCode,
    latitude: result.geometry.location.lat(),
    longitude: result.geometry.location.lng(),
    googleAddressType: result.types[0],
  };
}

export function latLngBoundsToBoundsInput(
  bounds: google.maps.LatLngBounds,
): LocatableBoundsInput {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return {
    northEastPoint: {
      latitude: ne.lat(),
      longitude: ne.lng(),
    },
    southWestPoint: {
      latitude: sw.lat(),
      longitude: sw.lng(),
    },
  };
}
