import Cookies from 'js-cookie';
import * as qs from 'qs';

// We need to forward Impact's (a marketing channel; https://impact.com) click ID query param called 'irclickid' to
// reservation creations so that Impact can be tied to the order for attribution. The value is to be saved into a cookie
// because navigating away from the original URL the visitor landed on should still result in the order being attributed
// to Impact.

const COOKIE_EXPIRES = 1; // days

if (typeof window !== 'undefined') {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const impactClickId = queryParams.irclickid;

  if (impactClickId !== undefined) {
    // location.hostname houses the whole hostname, we need to set the domain of the cookie to exclude the subdomain;
    // e.g. 'www-jameson.clutter.com' and 'www.clutter.com' -> '.clutter.com', and 'www.clutter.test' -> '.clutter.test'
    const domain = location.hostname.match(/\.?\w+\.\w+$/)![0];
    Cookies.set('impactClickId', impactClickId, {
      expires: COOKIE_EXPIRES,
      domain,
    });
  }
}

export const IMPACT_CLICK_ID = Cookies.get('impactClickId');
