export const PHONE_MASK = (rawValue: string) => {
  const base = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  if (rawValue.startsWith('+1')) {
    base.unshift(/\+/, /\d/, ' ');
  } else if (rawValue.startsWith('+')) {
    base.unshift(/\+/, /\d/);
  }
  if (rawValue.startsWith('1')) {
    base.unshift(/\d/, ' ');
  }
  return base;
};

export const ZIP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const CURRENCY_MASK = (text: string) => {
  const digits = text.replace(/\D/g, '');
  const regex = Array.from({ length: digits.length }, () => /\d/);
  return ['$', ...regex];
};
