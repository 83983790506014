import * as FullStory from '@fullstory/browser';

import { WT_PAGE_UUID, WT_VISITOR_TOKEN } from './wt';

declare global {
  interface Window {
    FULL_STORY_ENABLED: boolean;
  }
}

const FULL_STORY_ORG_ID = 'CW3B2';

if (typeof window !== 'undefined' && window.FULL_STORY_ENABLED) {
  FullStory.init({ orgId: FULL_STORY_ORG_ID });

  FullStory.setUserVars({
    wtVisitorToken_str: WT_VISITOR_TOKEN,
    wtPageUuid_str: WT_PAGE_UUID,
  });
}
