import React, { useContext, useState } from 'react';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent, Token } from '@stripe/stripe-js';

import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { CheckoutVersion } from '@root/components/checkout/types';
import { StripeStatus } from '@root/resources/types/stripe_status';
import { EventSchema } from '@root/resources/wt/types';
import { PaymentMethodErrorKind } from '@shared/payment_method_error';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import {
  createThirdPartyConversionEvent,
  EventName,
  ProductType,
} from '@utils/third_party_conversion_events';

type State = {
  billingName: string;
  onBillingNameChange(name: string): void;
  billingNameError?: string;
  setBillingNameError(error: string | undefined): void;
  stripeToken?: Token;
  stripeStatus: StripeStatus;
  paymentMethodError?: PaymentMethodErrorKind;
  onPaymentMethodError(error: PaymentMethodErrorKind | undefined): void;
  onCardInputChange(event: StripeElementChangeEvent): void;
  onWalletToken(token?: Token): void;
  tokenize: () => Promise<Token | undefined>;
};
const PaymentContext = React.createContext<State | undefined>(undefined);

export const usePaymentContext = () => {
  const value = useContext(PaymentContext);
  if (!value) throw new Error('PaymentContextProvider not found');
  return value;
};

class TokenizerError extends Error {
  public message: PaymentMethodErrorKind;

  constructor(message: PaymentMethodErrorKind) {
    super(message);
    this.message = message;
  }
}

const useStripeTokenizer = () => {
  const elements = useElements();
  const stripe = useStripe();

  return async ({ name }: { name?: string }) => {
    const element = elements?.getElement('card');

    if (!stripe || !element) {
      throw new Error('Missing either "stripe" or "element".');
    }

    const { token } = await stripe.createToken(element, {
      name,
    });

    if (token && token.card!.funding === 'prepaid') {
      throw new TokenizerError(PaymentMethodErrorKind.PrepaidCard);
    }
    if (token) {
      return token;
    } else {
      throw new TokenizerError(PaymentMethodErrorKind.Unknown);
    }
  };
};

export const PaymentContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [billingName, onBillingNameChange] = useState<string>('');
  const [stripeStatus, setStripeStatus] = useState(StripeStatus.Initial);
  const [billingNameError, setBillingNameError] = useState<
    string | undefined
  >();
  const [stripeToken, setStripeToken] = useState<Token>();
  const [paymentMethodError, setPaymentError] =
    useState<PaymentMethodErrorKind>();

  const tokenize = useStripeTokenizer();
  const {
    flowState: {
      values: { zip },
    },
    flowVersion,
  } = useSharedCheckoutContext();

  const onAddCard = (token: Token) => {
    setStripeToken(token);
    createThirdPartyConversionEvent(EventName.ADD_PAYMENT_INFO, {
      zip_code: zip,
      product_type:
        flowVersion === CheckoutVersion.Moving
          ? ProductType.MOVING
          : ProductType.SMART_STORAGE,
    });
  };
  const trackFunnelEvent = useTrackFunnelEvents();

  const onCardInputChange = (e: StripeElementChangeEvent) => {
    setStripeToken(undefined);
    setPaymentError(undefined);
    setBillingNameError(undefined);
    setStripeStatus(
      e.complete
        ? StripeStatus.Complete
        : e.empty
        ? StripeStatus.Initial
        : StripeStatus.Pending,
    );
    if (e.complete) {
      trackFunnelEvent({
        schema: EventSchema.WWW__PaymentMethodCompleted,
        action: 'submit',
        metadata: { method: 'card' },
      });
    }
  };

  const paymentMethodComplete = stripeStatus === StripeStatus.Complete;

  return (
    <PaymentContext.Provider
      value={{
        billingName,
        billingNameError,
        setBillingNameError,
        stripeToken,
        stripeStatus,
        paymentMethodError,
        onPaymentMethodError: setPaymentError,
        onBillingNameChange(name) {
          setBillingNameError(undefined);
          onBillingNameChange(name);
        },
        onWalletToken: onAddCard,
        onCardInputChange,
        tokenize: async () => {
          if (!billingName)
            setBillingNameError('Please provide the cardholder’s name');
          if (!paymentMethodComplete)
            setPaymentError(PaymentMethodErrorKind.Invalid);
          if (!billingName || !paymentMethodComplete) return undefined;

          try {
            const token = await tokenize({ name: billingName });
            onAddCard(token);
            return token;
          } catch (e) {
            if (e instanceof TokenizerError) {
              setPaymentError(e.message);
              return undefined;
            }
            throw e;
          }
        },
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
