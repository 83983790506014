export enum ProtectionPlanEnum {
  PROTECTION_2500 = 'protection_2500',
  PROTECTION_1000 = 'protection_1000',
  MINIMAL_COVERAGE = 'minimal_coverage',
}

export const PROTECTION_PLANS: {
  [key in ProtectionPlanEnum]: {
    label: string;
    cost: number;
  };
} = {
  [ProtectionPlanEnum.PROTECTION_2500]: {
    label: 'Premium',
    cost: 25,
  },
  [ProtectionPlanEnum.PROTECTION_1000]: {
    label: 'Standard',
    cost: 15,
  },
  [ProtectionPlanEnum.MINIMAL_COVERAGE]: {
    label: '',
    cost: 0,
  },
};
