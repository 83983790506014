import { ApolloClient, gql } from '@apollo/client';

import { useOnce } from '@utils/hooks/use_once';

type PrefetchedQuery = {
  query: string;
  variables?: Record<string, any>;
  data: Record<string, any>;
};

declare global {
  interface Window {
    __APOLLO__?: Record<string, any>;
  }
}

function hydrateClient(client: ApolloClient<any>) {
  if (typeof window === 'undefined') {
    const prefetchedQueries = (global as any)
      .prefetchedQueries as PrefetchedQuery[];

    if (prefetchedQueries) {
      prefetchedQueries.forEach(({ query, data, variables }) => {
        if (data) {
          client.writeQuery({
            query: gql(query),
            variables,
            data,
          });
        }
      });
      (global as any).apolloCache = client.extract();
    }
  } else {
    if (window.__APOLLO__) {
      client.restore(window.__APOLLO__);
    }
  }
}

export function useHydrateApolloCache(client: ApolloClient<any>) {
  useOnce(() => {
    hydrateClient(client);
  });
}
