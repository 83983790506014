import React from 'react';

import { Box } from '@clutter/clean';
import { Footer } from '@shared/content/footer';
import { Header } from '@shared/header/header';

const Container = ({ children }: { children: React.ReactNode }) => (
  <>
    <Header sticky opaque />
    <Box minHeight="100vh" margin="0 auto">
      {children}
    </Box>
    <Footer />
  </>
);

const Section: React.FC<React.ComponentPropsWithoutRef<typeof Box>> = ({
  children,
  padding,
  ...props
}) => (
  <Box padding="0 24px" {...props}>
    <Box maxWidth="1230px" margin="0 auto" padding={padding}>
      {children}
    </Box>
  </Box>
);

export const Layout = Object.assign(Container, { Section });
