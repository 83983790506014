import * as React from 'react';

import { IServerData } from '@shared/server_data_context';

import { ClientRoutingConfig } from '../shared/client_routing_config';

import { App } from './app';

export const ClientLocation: React.FC<{
  serverData: IServerData;
  baseUrl: string;
}> = ({ serverData }) => (
  <ClientRoutingConfig>
    <App serverData={serverData} />
  </ClientRoutingConfig>
);
