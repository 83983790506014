import React from 'react';

import styled from '@emotion/styled';

import { Box, BREAKPOINTS, mq } from '@clutter/clean';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  ${mq({
    flexDirection: ['column', null, null, 'row'],
  })}
`;

const ImageContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box.FlexItem
      width={['100%', null, null, '210px']}
      height={['initial', null, null, '100%']}
      flexShrink={0}
      overflow="hidden"
      borderRadius="4px"
      textAlign="center"
    >
      {children}
    </Box.FlexItem>
  );
};

export const CardContainer = ({
  desktopImage,
  mobileImage,
  children,
}: {
  mobileImage: string;
  desktopImage: string;
  children: React.ReactNode;
}) => {
  return (
    <Container>
      <ImageContainer>
        <picture>
          <source
            media={`(min-width: ${BREAKPOINTS.LG})`}
            srcSet={desktopImage}
          />
          <img
            src={mobileImage}
            draggable={false}
            alt=""
            style={{ width: '100%' }}
          />
        </picture>
      </ImageContainer>
      <Box padding={['0', null, null, '0 0 0 16px']}>{children}</Box>
    </Container>
  );
};
