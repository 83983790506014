import { isPlainObject } from 'lodash-es';

const toSnakeCase = (s: string) =>
  s
    .replace(/[a-z][A-Z]/g, (m) => `${m[0]}_${m[1].toLowerCase()}`)
    .toLowerCase();

const mapKeys = (
  transform: (key: string) => string,
  target: any,
  deep = false,
) => {
  const transformed: any = {};

  Object.keys(target).forEach((key: string) => {
    const newKey = transform(key);
    if (deep && isPlainObject(target[key])) {
      transformed[newKey] = mapKeys(transform, target[key], deep);
    } else {
      transformed[newKey] = target[key];
    }
  });

  return transformed;
};

export const transformKeysToSnakeCase = (data: any, deep = false) => {
  return mapKeys(toSnakeCase, data, deep);
};
