import React from 'react';

import { Box, COLORS, FontWeight, Text, TextButton } from '@clutter/clean';
import { Currency } from '@shared/currency';
import { PricingSummary } from '@utils/hooks/pricing/use_pricing_summary';

import { CardContainer } from '../fees/card_container';
import { Price } from '../fees/price';

import { FreePickupSummary } from './free_pickup_summary';

import facilityImageMobile from '@images_responsive/www/checkout/storage_facility_cropped_wide.jpg';
import facilityImageDesktop from '@images_responsive/www/checkout/storage_facility.jpg';
import cashIllustration from '@images/illustrations/cash_small.svg';

export function PickupFeeCard({
  pricingSummary,
  showPrice,
  onModalLinkClick,
  planSizeName,
}: {
  pricingSummary?: PricingSummary;
  showPrice: boolean;
  onModalLinkClick: (e: React.MouseEvent<HTMLElement>) => void;
  planSizeName: string | undefined;
}) {
  return (
    <CardContainer
      desktopImage={facilityImageDesktop.src}
      mobileImage={facilityImageMobile.src}
    >
      <Box.Flex
        flexDirection="column"
        gap="8px"
        margin={['16px 0 0', null, null, '0']}
      >
        <Box.Flex justifyContent="space-between">
          <Text.Body weight={FontWeight.Medium}>Warehouse Fee</Text.Body>
          <Box.Flex textAlign="right">
            {pricingSummary && showPrice && (
              <>
                {pricingSummary.maxOnboardingFeeAmount >
                  pricingSummary.onboardingFeeAmount && (
                  <Price
                    amount={pricingSummary.maxOnboardingFeeAmount}
                    strikeThrough
                  />
                )}
                <Price amount={pricingSummary.onboardingFeeAmount} />
              </>
            )}
          </Box.Flex>
        </Box.Flex>
        <Text.Callout>
          Includes setting up your Smart Storage account and online photo
          inventory, delivering your items to our warehouse, and organizing and
          stowing them safely.{' '}
          <TextButton size="medium" onClick={onModalLinkClick}>
            <Text.Callout weight={FontWeight.Medium}>
              Why do I need to pay this?
            </Text.Callout>
          </TextButton>
        </Text.Callout>
        {planSizeName && (
          <Box.Flex>
            <Box.FlexItem alignSelf="start" margin="0 8px 0 0" flexShrink={0}>
              <img
                src={cashIllustration}
                width="16px"
                draggable={false}
                alt=""
              />
            </Box.FlexItem>
            <Text.Caption color={COLORS.hippo}>
              {pricingSummary?.anyCommitmentHasFreePickup ? (
                <FreePickupSummary pricingSummary={pricingSummary} />
              ) : (
                <>
                  {pricingSummary &&
                    pricingSummary.onboardingFeeSavingsAmount > 0 && (
                      <>
                        <b>
                          You’ve saved{' '}
                          <Currency
                            amount={pricingSummary.onboardingFeeSavingsAmount}
                          />
                        </b>{' '}
                        for selecting a {planSizeName} storage size on an{' '}
                        {pricingSummary.commitmentLength} month
                        {pricingSummary.commitmentLength > 4 && '+'} term.{' '}
                      </>
                    )}
                  Consider storing longer for more savings!
                </>
              )}
            </Text.Caption>
          </Box.Flex>
        )}
      </Box.Flex>
    </CardContainer>
  );
}
