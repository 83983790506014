import React from 'react';

import styled from '@emotion/styled';

import { Box, Text } from '@clutter/clean';

import bbb from '@images/reviews/bbb.svg';
import trustPilot from '@images/reviews/trust_pilot.svg';
import yelp from '@images/reviews/yelp.svg';

const Grid = styled.div`
  margin-top: 16px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto auto;
  grid-column-end: span 1;
`;

export const Ratings: React.FC = () => (
  <Grid>
    <Box.Flex
      flexDirection="column"
      alignItems="center"
      style={{ gridColumnEnd: 'span 2' }}
    >
      <Box margin="0 0 4px 0">
        <img
          src={trustPilot}
          width={104}
          draggable={false}
          alt="trust pilot"
          loading="lazy"
        />
      </Box>
      <Text style={{ fontSize: '8px' }}>
        TrustScore: <b>4.8 | 2,500+</b> reviews
      </Text>
    </Box.Flex>
    <Box textAlign="right">
      <img src={yelp} alt="yelp" width={72} draggable={false} loading="lazy" />
    </Box>
    <img src={bbb} alt="BBB" width={72} draggable={false} loading="lazy" />
  </Grid>
);
