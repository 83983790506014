export const IGNORED_ERRORS = [
  'UET is not defined',
  'The quota has been exceeded',
  'ceCurrentVideo.currentTime',
  'window.webkit', // We should never access this property in our own code
];

const shouldIgnoreError = (e: Error) =>
  IGNORED_ERRORS.some((ignoredError) =>
    Boolean(e.message?.includes(ignoredError)),
  );

const nr = (window as any).newrelic;
if (nr) {
  nr.setErrorHandler(shouldIgnoreError);
}
