import * as React from 'react';
import { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';

import {
  Box,
  Button,
  COLORS,
  FontWeight,
  Input,
  Text,
  UnstyledButton,
} from '@clutter/clean';
import {
  AvailabilityFragment,
  CouponDocument,
  CouponFragment,
  CouponQuery,
  CouponQueryVariables,
} from '@graphql/platform';
import { CouponSummary } from '@root/components/checkout/cart/coupon_summary';
import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { track } from '@root/initializers/wt';
import { useClientDataContext } from '@shared/client_data_context';
import { FormattedGQLError, parseGQLErrorUnion } from '@utils/gql_errors';
import { useOnMount } from '@utils/hooks/mount';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

const InputContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  input {
    flex-grow: 1;
  }
`;

const PromoCodeInput: React.FC<{
  coupon?: CouponFragment;
  service?: 'storage' | 'moving';
  disabled?: boolean;
  onChange(value: CouponFragment | undefined): void;
}> = ({ disabled, coupon, service, onChange }) => {
  const { isDesktop } = useBreakpoints();
  const {
    flowState: {
      values: { dateScheduled },
    },
  } = useSharedCheckoutContext();
  const {
    data: { urlPromoCode },
  } = useClientDataContext();
  const [inputValue, setInputValue] = useState(
    coupon?.promoCode ?? urlPromoCode ?? '',
  );
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<CouponQuery['coupon'] | undefined>();
  const client = useApolloClient();

  const orderBeforeCutoff = (
    scheduled: AvailabilityFragment | undefined,
    coupon: CouponFragment | undefined,
  ) => {
    if (!coupon?.scheduledCutoff || !scheduled) return true;

    const cutoffDate = DateTime.fromISO(coupon.scheduledCutoff).endOf('day');
    const scheduledDate = DateTime.fromISO(scheduled.fromTime);

    return scheduledDate < cutoffDate;
  };

  const validateCoupon = async (code: string, service?: string) => {
    try {
      setLoading(true);
      const { data } = await client.query<CouponQuery, CouponQueryVariables>({
        query: CouponDocument,
        variables: { code, service },
      });

      if (data) {
        setResult(data.coupon);
        if (
          data.coupon.__typename === 'Coupon' &&
          orderBeforeCutoff(dateScheduled, data.coupon)
        ) {
          onChange(data.coupon);
        } else {
          onChange(undefined);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInputValue(coupon?.promoCode ?? '');
  }, [coupon]);

  useOnMount(() => {
    if (urlPromoCode && !coupon) {
      validateCoupon(urlPromoCode, service);
    }
  });

  const [resultCoupon, couponError] = parseGQLErrorUnion(result);
  const showCutoffError =
    resultCoupon && !orderBeforeCutoff(dateScheduled, resultCoupon);

  return (
    <Box>
      <Text.Body weight={FontWeight.Medium}>Promo code</Text.Body>
      <InputContainer>
        <Input
          value={inputValue ?? ''}
          type="text"
          onChange={(event) => {
            setInputValue(event.target.value || '');
            setResult(undefined);
          }}
          maxLength={15}
          disabled={disabled}
          state={couponError ? 'error' : undefined}
        />
        <Button
          onClick={async () => {
            validateCoupon(inputValue || '', service);
            track({
              objectType: 'button',
              objectName: 'apply_promo',
              action: 'click',
              label: 'promo_code',
            });
          }}
          kind="secondary"
          size="medium"
          loading={loading}
          disabled={disabled || loading}
        >
          Apply
        </Button>
      </InputContainer>
      <Box padding="8px 0 12px">
        {couponError && (
          <Text.Caption color={COLORS.toucan} weight={FontWeight.Medium}>
            <FormattedGQLError error={couponError} />
          </Text.Caption>
        )}
        {showCutoffError && (
          <Text.Caption color={COLORS.toucan} weight={FontWeight.Medium}>
            This promo code is only eligible for appointments before{' '}
            {resultCoupon?.scheduledCutoff}
          </Text.Caption>
        )}
      </Box>
      {!isDesktop && coupon && (
        <Box.Flex justifyContent="space-between">
          <Box>
            <Text.Callout weight={FontWeight.Medium} color={COLORS.tealPrimary}>
              <CouponSummary coupon={coupon} />
            </Text.Callout>
          </Box>
          <UnstyledButton onClick={() => onChange(undefined)}>
            <Text.SmallCaps color={COLORS.hippo}>remove</Text.SmallCaps>
          </UnstyledButton>
        </Box.Flex>
      )}
    </Box>
  );
};

export { PromoCodeInput };
