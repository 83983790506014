import * as React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, mq, transparentize } from '@clutter/clean';
import { CustomLink } from '@shared/custom_link';

const FooterContainer = styled.div`
  background: ${COLORS.tealBackground};
  color: ${transparentize(COLORS.panther, 0.8)};

  ${mq({ padding: ['40px 24px', null, '40px'] })}
`;

const NavLink = styled(CustomLink)`
  color: ${COLORS.panther};
  font-size: 14px;
  font-weight: ${FontWeight.Medium};
`;

const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const CheckoutFooter: React.FC = () => {
  return (
    <FooterContainer aria-label="footer">
      <Box.Flex
        flexDirection={['row', null, 'column']}
        alignItems={['flex-start', null, 'center']}
        justifyContent={['space-evenly', null, 'center']}
      >
        <Box.Flex
          flexDirection={['column', null, 'row']}
          gap={['8px', null, '20px']}
          margin={[null, null, '0 0 16px']}
        >
          <NavLink to="/legal/tos">Legal</NavLink>
          <NavLink to="/legal/privacy_policy">Privacy Policy</NavLink>
          <NavLink to="/legal/california_privacy_rights">
            California Privacy Rights
          </NavLink>
          <NavLink to="/legal/california_privacy_rights#your-rights">
            Do Not Sell My Personal Information
          </NavLink>
        </Box.Flex>
        <CopyrightContainer>
          <p>Copyright {new Date().getFullYear()} Clutter, Inc.</p>
          <p>All rights reserved.</p>
          <p>DOT Number #2719785</p>
        </CopyrightContainer>
      </Box.Flex>
    </FooterContainer>
  );
};

export { CheckoutFooter };
