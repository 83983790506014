import { useDebouncedValue } from '@clutter/hooks';

export const useAnimatedEntry = (isOpen: boolean, duration: number) => {
  const renderChildren = useDebouncedValue(isOpen, duration) || isOpen;
  // This value is "delayed" slightly to allow the component to mount with any
  // initial CSS property that might transition once `isOpen` is true
  const debouncedIsOpen = useDebouncedValue(isOpen, 10);

  return { renderChildren, isOpen: debouncedIsOpen };
};
