import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  COLORS,
  toggleStyleValue,
  UnstyledButton,
  ZIndex,
} from '@clutter/clean';
import { XButton } from '@root/components/icons/x_button';
import { useAnimatedEntry } from '@utils/hooks/use_animated_entry';

const TRANSITION_MS = 300;

const overlayStyles = css`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  z-index: ${ZIndex.DROPDOWN};
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  top: 0;
  left: 0;
  position: fixed;
  background: ${toggleStyleValue('isOpen', 'rgba(0, 0, 0, 0.7)', 'none')};

  & > div {
    top: 100vh;
    top: 100dvh;
    left: 0;
    width: 100vw;
    background: ${COLORS.cloud};
    transition: all 0.3s ease-in-out;
    transform: translateY(${toggleStyleValue('isOpen', '-100%', 0)});
    position: absolute;
    border-radius: 12px 12px 0 0;
    z-index: ${ZIndex.MODAL};
    max-height: 100vh;
    max-height: 100dvh;
  }

  ${({ isOpen }) => isOpen && overlayStyles};
`;

const CloseButtonContainer = styled(UnstyledButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const CloseButton: React.FC<{
  onClick: React.MouseEventHandler;
}> = ({ onClick }) => (
  <CloseButtonContainer onClick={onClick}>
    <XButton />
  </CloseButtonContainer>
);

export const Drawer: React.FC<{
  isOpen: boolean;
  onClose(): void;
  children: React.ReactNode;
}> = ({ isOpen, onClose, children }) => {
  const { renderChildren, isOpen: debouncedIsOpen } = useAnimatedEntry(
    isOpen,
    TRANSITION_MS,
  );

  const onOverlayClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };

  if (!renderChildren) {
    return null;
  }

  return (
    <Overlay isOpen={debouncedIsOpen} onClick={onOverlayClick}>
      <div>
        <CloseButton onClick={onClose} />
        {children}
      </div>
    </Overlay>
  );
};
