import { useCallback, useEffect, useRef } from 'react';

/**
 * Wraps a function within a ref to allow passing as a dependency without
 * retriggering effects/callbacks/etc
 *
 * Example:
 *
 * ```ts
 * const onUnmount = useStabilizedFunction(() => { console.log(props); })
 * useEffect(() => {
 *   return () => onUnmount(); // Will log the last received props on cleanup, but without including all of props as a depencency
 * }, [onUnmount])
 * ```
 */
export const useStabilizedFunction = function <P extends any[], R>(
  fn: (...args: P) => R,
): (...args: P) => R {
  const fnRef = useRef(fn);
  useEffect(() => {
    fnRef.current = fn;
  });
  return useCallback((...args: P) => {
    return fnRef.current(...args);
  }, []);
};
