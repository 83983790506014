import React, { useRef, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { Token } from '@stripe/stripe-js';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import { Box, COLORS, Text, ZIndex } from '@clutter/clean';
import { Lead__ServiceNeeds } from '@graphql/platform';
import { PhoneInput } from '@root/components/checkout/cart/phone_input';
import {
  PaymentContextProvider,
  usePaymentContext,
} from '@root/components/checkout/payment_context';
import { setBookedFlag } from '@root/components/checkout/utilities/persistence';
import { DEFAULT_ELEMENTS_OPTIONS, getStripe } from '@root/initializers/stripe';
import { flushEvents, useTrack, WTProvider } from '@root/initializers/wt';
import { ServiceEnum } from '@root/resources/types/service';
import { phone as phoneValidator } from '@root/resources/validator';
import { EventSchema } from '@root/resources/wt/types';
import { WWW_ROUTES } from '@root/root/routes';
import { useClientDataContext } from '@shared/client_data_context';
import { CheckoutFooter } from '@shared/content/checkout_footer';
import { Header } from '@shared/header/header';
import { useCreateLead } from '@shared/lead_form';
import { MFAModal, MFAResult } from '@shared/mfa_modal';
import { PaymentMethodError } from '@shared/payment_method_error';
import { QualifiedContactWidget } from '@shared/qualified_contact_widget';
import { buildUrlWithQs } from '@utils/build_url_with_qs';
import {
  ErrorWithoutTypename,
  FormattedGQLError,
  GQLError,
} from '@utils/gql_errors';
import { useCartViewedFunnelEvent } from '@utils/hooks/funnel_events/use_cart_viewed_funnel_event';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { useOnMount } from '@utils/hooks/mount';
import { usePricingSetForZIP } from '@utils/hooks/pricing';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { getReferralCouponByService } from '@utils/referral';

import { DueToday } from './cart/due_today';
import { Content, Grid } from './cart/layout';
import { MissingInformationModal } from './cart/missing_information_modal';
import { PaymentMethod } from './cart/payment_method';
import { PromoCodeInput } from './cart/promo_code_input';
import { ReserveCTA } from './cart/reserve_cta';
import { SmartStorageBookingDetails } from './cart/smart_storage_booking_details';
import { StorageCancellationModal } from './cart/storage_cancellation_modal';
import { StorageCartValueProps } from './cart/storage_cart_value_props';
import { StorageServiceSummary } from './cart/storage_service_summary';
import { useReservationSummaryProps } from './cart/use_reservation_summary_props';
import { useReserveSmartStorage } from './cart/use_reserve_smart_storage';
import { PricingModal } from './product_pages/subcomponents/disposal/pricing_modal';
import { scrollToStep } from './utilities/scroll_animation';
import { useTrackBookingErrors } from './utilities/use_track_booking_errors';
import { useStorageCheckoutContext } from './context';
import { StorageCheckoutStep } from './types';

const SERVICE_NEEDS = [Lead__ServiceNeeds.SmartStorage];
const RESERVE_BUTTON_LABEL = 'Reserve Smart Storage';
const PAYMENT_METHOD_ID = 'payment-method';
const PHONE_ID = 'phone-section';

const CheckoutCartContent = () => {
  const {
    flowState: { completeFlow, onChange, goToStep, prev, values },
    navigationState: { dispatch: navigationDispatch },
    pricingSummary,
  } = useStorageCheckoutContext();
  const {
    address,
    bundleKind,
    checkoutType,
    customerToken,
    coupon,
    commitment,
    dateScheduled,
    planSize,
    protectionPlan,
    zip,
    name,
    phone,
    email,
    defaultSource,
    disposalSelected,
  } = values;

  const history = useHistory();
  const track = useTrack();
  const { isDesktop } = useBreakpoints();
  const leadData = {
    name: name ?? '',
    phone: phone ?? '',
    email: email ?? '',
  };
  useCartViewedFunnelEvent();

  const [showMFAModal, setShowMFAModal] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [gqlError, setGqlError] = useState<ErrorWithoutTypename | null>(null);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showDisposalPricingModal, setShowDisposalPricingModal] =
    useState(false);

  const editNavigationRef = useRef(false);

  const { tokenize, stripeToken, paymentMethodError, billingNameError } =
    usePaymentContext();

  const goToDetailPage = (step: StorageCheckoutStep) => {
    track({
      action: 'click',
      objectName: 'edit',
      value: step,
    });
    editNavigationRef.current = true;
    goToStep(step);
    navigationDispatch({ type: 'setTargetStep', payload: { step } });
    history.length > 1
      ? history.goBack()
      : history.replace(WWW_ROUTES.PRODUCT_PAGE_STORAGE);
  };

  useOnMount(() => {
    navigationDispatch({ type: 'viewCart' });
    const unsubscribe = history.listen((newLocation) => {
      // If a customer navigates back to the previous page via the back button, we want to ensure
      // that we track that action as a step transition (but not if they go to a
      // different, non-booking page which is treated as abandonment).
      if (
        WWW_ROUTES.PRODUCT_PAGE_STORAGE === newLocation.pathname &&
        !editNavigationRef.current
      ) {
        prev();
      }
      // This listener will only be called on navigation _after_ the page
      // unmounts, so we call this manually instead of returning as part of
      // effect cleanup
      unsubscribe();
    });
  });

  const {
    data: { referral },
  } = useClientDataContext();

  useOnMount(() => {
    if (!coupon) {
      onChange(
        'coupon',
        getReferralCouponByService(ServiceEnum.DoorToDoor, referral),
      );
    }
  });

  useTrackBookingErrors({
    phoneError,
    billingNameError,
    paymentMethodError,
    gqlError,
  });

  const pricingSet = usePricingSetForZIP(zip);

  const reservationSummaryProps = useReservationSummaryProps();

  const trackFunnelEvent = useTrackFunnelEvents();
  const [reserveSmartStorage, quoteLoading] = useReserveSmartStorage({
    address,
    bundleKind,
    commitment,
    coupon,
    checkoutType,
    dateScheduled,
    disposalSelected,
    lead: leadData,
    planSize,
    pricingSet,
    protectionPlan,
    zip,
  });
  const createLead = useCreateLead();

  const onMFAModalClose = ({ customerToken, leadToken }: MFAResult) => {
    setShowMFAModal(false);
    if (customerToken && leadToken) {
      track({ objectType: 'mfa_modal', action: 'submit' });
      onReserve({
        customerTokenOverride: customerToken,
        mfaLeadToken: leadToken,
      });
    } else {
      track({
        objectType: 'mfa_modal',
        action: 'click',
        label: 'close',
      });
    }
  };

  const onReserve = async ({
    customerTokenOverride = customerToken,
    mfaLeadToken,
    paymentRequired,
  }: {
    customerTokenOverride?: string;
    mfaLeadToken?: string;
    paymentRequired?: boolean;
  }) => {
    let hasBooked = false;
    setGqlError(null);
    setLoading(true);

    if (!mfaLeadToken) {
      track({
        action: 'click',
        objectName: 'reserve_button',
        label: paymentRequired ? RESERVE_BUTTON_LABEL : 'Save your reservation',
      });
    }

    try {
      let hasPhoneError = false;
      let hasCardError = false;
      if (phone && phoneValidator(phone)) {
        trackFunnelEvent({
          schema: EventSchema.WWW__PhoneCaptureCompleted,
          action: 'submit',
          metadata: { phone_number: phone },
        });
      } else {
        hasPhoneError = true;
        setPhoneError('Please enter a valid phone number');
      }

      let currentStripeToken: Token | undefined = stripeToken;
      if (!defaultSource) {
        currentStripeToken = await tokenize();
        if (!currentStripeToken) {
          hasCardError = true;
        }
      }

      if (hasPhoneError || hasCardError) {
        setTimeout(
          () =>
            scrollToStep(hasPhoneError ? PHONE_ID : PAYMENT_METHOD_ID, 'top'),
          10,
        );
        return;
      }

      let leadToken: string | undefined;
      if (!customerTokenOverride) {
        leadToken = (
          await createLead({
            serviceContext: ServiceEnum.DoorToDoor,
            leadAttributes: {
              name: leadData.name,
              phone: leadData.phone,
              email: leadData.email,
              serviceNeeds: SERVICE_NEEDS,
            },
          })
        ).token;
        onChange('leadToken', leadToken);
      } else {
        leadToken = mfaLeadToken;
      }

      const metadata = await reserveSmartStorage(
        customerTokenOverride,
        leadToken,
        currentStripeToken,
      );

      if (metadata) {
        hasBooked = true;
        await completeFlow('book', metadata);
        setBookedFlag();
        flushEvents();
        window.location.href = buildUrlWithQs(metadata.accountUrl!, {
          pb: 'true',
          service: 'storage',
        });
      }
    } catch (error) {
      if (error instanceof GQLError) {
        if (error.metadata.canResolveViaMFA) {
          setShowMFAModal(true);
          track({ action: 'display', objectName: 'mfa_modal' });
        } else {
          setGqlError(error.fullError);
        }
      }
    } finally {
      if (!hasBooked) {
        setLoading(false);
      }
    }
  };

  const ErrorText = isDesktop ? Text.Callout : Text.Caption;

  const bookingError = (gqlError || paymentMethodError) && (
    <Box textAlign="center" margin="8px 0 0">
      <ErrorText color={COLORS.toucan}>
        {gqlError && <FormattedGQLError error={gqlError} />}
        {paymentMethodError && (
          <PaymentMethodError error={paymentMethodError} />
        )}
      </ErrorText>
    </Box>
  );

  const cancelBy = (values.datePreferred || DateTime.local()).minus({
    days: 2,
  });

  const disableReserveButton =
    loading || quoteLoading || !reservationSummaryProps.monthlyTotalAmount;

  const promoCodeElement = (
    <PromoCodeInput
      coupon={coupon}
      onChange={(value) => {
        onChange('coupon', value);
      }}
      service="storage"
      disabled={!!coupon}
    />
  );

  return (
    <Box minHeight="100vh">
      {!isDesktop && (
        <>
          <StorageServiceSummary
            reservationSummary={reservationSummaryProps}
            openDisposalPricingModal={() => setShowDisposalPricingModal(true)}
            onEdit={goToDetailPage}
            collapsible
          />
          <Box margin="8px 8px 32px">
            <DueToday
              monthlyTotalAmount={reservationSummaryProps.monthlyTotalAmount}
              onboardingFeeAmount={pricingSummary?.onboardingFeeAmount}
              planSizeLabel={reservationSummaryProps.planSizeLabel}
            />
          </Box>
        </>
      )}
      <Content>
        <Grid>
          <Box padding={[null, null, '120px 0 0']}>
            <Box margin="0 0 32px">
              <SmartStorageBookingDetails
                values={values}
                onChange={onChange}
                onEdit={goToDetailPage}
                onCancellationLinkClick={() => setShowCancellationModal(true)}
              />
              <Box margin="24px 0 0" id={PHONE_ID}>
                <PhoneInput
                  onChange={(value) => {
                    setPhoneError(undefined);
                    onChange('phone', value);
                  }}
                  phoneError={phoneError}
                  value={phone ?? ''}
                />
              </Box>
            </Box>
            <Box id={PAYMENT_METHOD_ID} margin="0 0 16px">
              <PaymentMethod
                required={true}
                disabled={loading}
                defaultSource={defaultSource}
                onClearDefaultSource={() =>
                  onChange('defaultSource', undefined)
                }
              />
            </Box>
            {!isDesktop && <Box margin="0 0 32px">{promoCodeElement}</Box>}
            <Box margin="0 0 32px">
              <ReserveCTA
                bookingError={bookingError}
                onReserve={onReserve}
                buttonDisabled={disableReserveButton}
                buttonLoading={loading}
                cancelByDateTime={cancelBy}
                buttonLabel={RESERVE_BUTTON_LABEL}
                onCancellationLinkClick={() => setShowCancellationModal(true)}
                service={Lead__ServiceNeeds.SmartStorage}
              />
            </Box>
            <Box position="sticky" top="100vh" margin="auto" padding="32px 0">
              <StorageCartValueProps />
            </Box>
          </Box>
          {isDesktop && (
            <Box
              background={COLORS.grayBackground}
              height="100%"
              padding="120px 40px 0"
              position="relative"
              style={{ zIndex: ZIndex.TOOLBAR - 1 }}
            >
              <StorageServiceSummary
                openDisposalPricingModal={() =>
                  setShowDisposalPricingModal(true)
                }
                reservationSummary={reservationSummaryProps}
                onEdit={goToDetailPage}
              />
              <Box margin="32px 0">{promoCodeElement}</Box>
            </Box>
          )}
        </Grid>
      </Content>
      <MFAModal
        {...leadData}
        zip={zip ?? ''}
        serviceNeeds={SERVICE_NEEDS}
        isOpen={showMFAModal}
        onClose={onMFAModalClose}
      />
      <StorageCancellationModal
        isOpen={showCancellationModal}
        handleModalClose={() => setShowCancellationModal(false)}
      />
      <MissingInformationModal values={values} service="storage" />
      <PricingModal
        isOpen={showDisposalPricingModal}
        onClose={() => setShowDisposalPricingModal(false)}
      />
    </Box>
  );
};

export const SmartStorageCart = () => {
  const { isDesktop } = useBreakpoints();
  return (
    <WTProvider
      params={{
        container: StorageCheckoutStep.Cart,
      }}
    >
      <Elements options={DEFAULT_ELEMENTS_OPTIONS} stripe={getStripe()}>
        <PaymentContextProvider>
          <Header
            hideZipInput={true}
            sticky={false}
            opaque={!isDesktop}
            menuItems={[]}
          />
          <CheckoutCartContent />
          <QualifiedContactWidget bottomOffset={isDesktop ? 0 : 120} />
          <CheckoutFooter />
          {/* Added padding so the sticky button doesn't overlap footer */}
          <Box height={['140px', '120px', '0']} />
        </PaymentContextProvider>
      </Elements>
    </WTProvider>
  );
};
