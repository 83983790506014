import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Modal, mq } from '@clutter/clean';

import { PricingContent } from './pricing_modal/pricing_content';
import { ProhibitedContent } from './pricing_modal/prohibited_content';

const Container = styled.div`
  border-radius: 16px;
  padding: 0 24px;
  color ${COLORS.tealDark};
  position: relative;
  background: ${COLORS.cloud};
  max-height: calc(100vh - 80px); /* Fallback */
  max-height: 100dvh;
  max-width: 1400px;
  overflow: auto;

  ${mq({
    width: ['calc(100vw - 12px)', 'initial'],
    height: [null, 'initial'],
  })}
`;

export const PricingModal: React.FC<{ isOpen: boolean; onClose(): void }> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} handleModalClose={onClose} includeCloseButton>
      <Container>
        <Box padding="36px 0 24px">
          <Box margin="0 0 24px">
            <PricingContent />
          </Box>
          <ProhibitedContent />
        </Box>
      </Container>
    </Modal>
  );
};
