import { useMemo } from 'react';

import { DateTime, Interval } from 'luxon';

import {
  AvailabilityFragment,
  MultidayAvailabilityFragment,
} from '@graphql/platform';

/** Clamp a list of availability windows to the provided start and end date.
 * Dates are expanded to the start of the `from` date and the end of the `till`
 * date. */
export const clampAvailabilities = (
  availabilities: ReadonlyArray<
    AvailabilityFragment | MultidayAvailabilityFragment
  >,
  { from, till }: { from: DateTime; till: DateTime },
) => {
  const interval = Interval.fromDateTimes(
    from.startOf('day'),
    till.endOf('day'),
  );
  return availabilities.filter((a) =>
    interval.contains(DateTime.fromISO(a.fromTime)),
  );
};

export const useFilteredAvailabilities = (
  availabilities: ReadonlyArray<
    AvailabilityFragment | MultidayAvailabilityFragment
  >,
  datePreferred?: DateTime,
) => {
  return useMemo(
    () =>
      datePreferred &&
      availabilities
        .filter((availability) => {
          const availabilityDate = DateTime.fromISO(
            availability.fromTime,
          ).startOf('day');
          const selectedDate = datePreferred.startOf('day');
          return (
            availabilityDate.toMillis() >= DateTime.now().toMillis() &&
            availabilityDate.toMillis() === selectedDate.toMillis()
          );
        })
        .sort(
          (a, b) =>
            DateTime.fromISO(a.fromTime).toMillis() -
            DateTime.fromISO(b.fromTime).toMillis(),
        ),
    [availabilities, datePreferred],
  );
};
