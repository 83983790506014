import { Stripe } from '@stripe/stripe-js';
import loadjs from 'loadjs';

import { STRIPE_PUBLISHABLE_KEY } from '../config/constants';

const STRIPE_LOADJS_ID = 'stripe';
const STRIPE_JS_URL = 'https://js.stripe.com/v3/';

let stripePromise: Promise<Stripe>;

export const DEFAULT_ELEMENTS_OPTIONS = {
  fonts: [
    {
      family: 'Basier Square',
      src: 'url(https://www.clutter.com/fonts/basiersquare-regular.woff)',
    },
  ],
};

export const getStripe = () => {
  if (stripePromise) {
    return stripePromise;
  }

  if (process.env.NODE_ENV !== 'test') {
    loadjs(STRIPE_JS_URL, STRIPE_LOADJS_ID);
  }

  stripePromise = new Promise((resolve) => {
    const stripeWindow = window as any;
    if (stripeWindow.Stripe) {
      resolve(stripeWindow.Stripe(STRIPE_PUBLISHABLE_KEY));
    } else {
      loadjs.ready(STRIPE_LOADJS_ID, () =>
        resolve(stripeWindow.Stripe(STRIPE_PUBLISHABLE_KEY)),
      );
    }
  });

  return stripePromise;
};
