import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  Button,
  COLORS,
  mq,
  Text,
  toggleStyleValue,
  transparentize,
} from '@clutter/clean';
import { Lead__ServiceNeeds } from '@graphql/platform';
import { useTrack } from '@root/initializers/wt';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { DISABLED_STYLE } from '../layout';

const ReserveButtonContainer = styled.div<{
  sticky: boolean;
  disabled: boolean;
}>`
  opacity: ${toggleStyleValue('disabled', 0.2, 1)};
  pointer-events: ${toggleStyleValue('disabled', 'none', 'initial')};
  bottom: ${toggleStyleValue('sticky', '0', 'initial')};
  left: 0;
  width: 100vw;
  z-index: 10;
  position: ${toggleStyleValue('sticky', 'sticky', 'initial')};
  box-shadow: ${toggleStyleValue('sticky', '0 -6px 12px rgb(0 0 0 / 5%)', '')};

  ${mq({
    margin: ['24px 0 -20px -24px', null, '72px 0 0'],
    width: ['100vw', null, '100%'],
    boxShadow: [null, null, 'none'],
    background: [COLORS.cloud, null, 'transparent'],
    position: [null, null, 'static'],
    padding: ['16px', null, 0],
  })}

  button {
    width: 100%;

    ${mq({
      height: [null, null, '80px'],
    })}
  }
`;

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 60px;
  background: white;
  z-index: 1;
  box-shadow: 0 0 16px ${transparentize(COLORS.panther, 0.1)};
  text-align: right;
`;

const CTA_LABEL = 'Continue to Reservation';

type ReserveButtonProps = {
  sticky: boolean;
  disabled: boolean;
  service: Lead__ServiceNeeds.Moving | Lead__ServiceNeeds.SmartStorage;
  hasViewedCart: boolean;
  skipAddressSelected?: boolean;
  onClick: () => void;
};

export const ReserveButton: React.FC<ReserveButtonProps> = ({
  sticky,
  service,
  disabled,
  hasViewedCart,
  onClick,
}) => {
  const track = useTrack();
  const { isMobile } = useBreakpoints();

  const onReserve = (e: React.MouseEvent) => {
    // Consumers of this component are responsible for redirecting correctly to allow for extra validations
    e.preventDefault();
    if (disabled) {
      return;
    }
    track({ objectName: 'cart_cta', objectType: 'button', label: CTA_LABEL });
    onClick();
  };

  const subText = (() => {
    switch (service) {
      case Lead__ServiceNeeds.Moving:
        return isMobile
          ? 'No obligation reservation'
          : '$0 required for reservation. You can reschedule or cancel for free up to 2 days before your appointment.';
      default:
        return isMobile
          ? '$0 required for reservation.'
          : 'Reserve now for $0 to save your spot. You can change plans or reschedule anytime before your pickup appointment.';
    }
  })();

  return (
    <>
      {isMobile && hasViewedCart ? (
        <FooterContainer>
          <Button
            onClick={onReserve}
            style={disabled ? DISABLED_STYLE : undefined}
          >
            {CTA_LABEL}
          </Button>
        </FooterContainer>
      ) : (
        <>
          <ReserveButtonContainer sticky={sticky} disabled={disabled}>
            <Button onClick={onReserve} disabled={disabled}>
              {CTA_LABEL}
            </Button>
            <Box padding={['4px 4px 0', '12px 4px 0']} textAlign="center">
              <Text.Body color={COLORS.storm}>{subText}</Text.Body>
            </Box>
          </ReserveButtonContainer>
        </>
      )}
    </>
  );
};
