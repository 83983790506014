import { each, flatten, keys, uniq, values } from 'lodash-es';

interface IErrorsMessages {
  [index: string]: string[];
}

class Errors {
  public static build(errors: IErrorsMessages) {
    const errs = new Errors();
    errs.parse({ errors });
    return errs;
  }
  public messages: IErrorsMessages;

  constructor() {
    this.messages = {};
  }

  public add(field: string, message: string): void {
    if (!this.messages[field]) {
      this.messages[field] = [];
    }
    this.messages[field] = uniq(this.messages[field].concat([message]));
  }

  public added(field: string): boolean {
    return !this.messages[field] || !this.messages[field].length;
  }

  public reset(field?: string): void {
    if (field) {
      delete this.messages[field];
    } else {
      this.messages = {};
    }
  }

  public parse(data: any): void {
    if (data.errors) {
      each(data.errors, (message, field) => {
        if (Array.isArray(message)) {
          message.forEach((msg) => this.add(field, msg));
        } else {
          this.add(field, message);
        }
      });
    }
  }

  public anyForFields(fields: string[]) {
    return fields.some(
      (field) => this.messages[field] && this.messages[field].length > 0,
    );
  }

  public includes(substr: string) {
    return this.summaries.some((err) => err.includes(substr));
  }

  public get summaries(): string[] {
    return flatten(values(this.messages));
  }

  public get any(): boolean {
    return !this.none;
  }

  public get none(): boolean {
    return !this.count;
  }

  public get count(): number {
    return keys(this.messages).length;
  }
}

export { Errors };
