import * as React from 'react';

import styled from '@emotion/styled';

type ISSRSafeFormProps = {
  onSubmit(e: React.FormEvent<HTMLFormElement>): void;
} & Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit'>;

const Fieldset = styled.fieldset`
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

const SSRForm: React.FC<ISSRSafeFormProps> = ({
  children,
  onSubmit,
  ...rest
}) => {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <form onSubmit={onSubmit} {...rest}>
      <Fieldset disabled={!mounted}>{children}</Fieldset>
    </form>
  );
};

export { SSRForm };
