import { useMemo, useRef } from 'react';
import * as React from 'react';

interface ISafeIdContextValue {
  getId(): number;
}

let fallbackId = 0;

export const resetIDCounter = () => (fallbackId = 0);

export const SafeIDContext = React.createContext<ISafeIdContextValue>({
  getId: () => fallbackId++,
});

export const SafeIDProvider: React.FC<any> = ({ children }) => {
  const idRef = useRef(0);
  const contextValue = useMemo(() => {
    return {
      getId() {
        return idRef.current++;
      },
    };
  }, []);

  return (
    <SafeIDContext.Provider value={contextValue}>
      {children}
    </SafeIDContext.Provider>
  );
};
