import { useEffect, useRef, useState } from 'react';

export const useMountedRef = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);
  return isMounted;
};

export const useOnMount = (
  onMount: (
    mountedRef: React.MutableRefObject<boolean>,
  ) => void | (() => void) | Promise<any>,
) => {
  const mountedRef = useMountedRef();
  useEffect(() => {
    const result = onMount(mountedRef);
    if (result instanceof Promise) return undefined;
    return result;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

/** A hook to run a callback only on unmount (without it going stale as with plain `useEffect`) */
export function useOnUnmount(cb: () => void) {
  const cbRef = useRef(cb);

  // Avoid modifying ref during render for Concurrent mode
  useEffect(() => {
    cbRef.current = cb;
  });

  useEffect(() => {
    return () => {
      cbRef.current();
    };
  }, []);
}
