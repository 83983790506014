import React from 'react';

import { Box, BREAKPOINTS, COLORS, Text } from '@clutter/clean';
import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { PLANS } from '@root/constants/pricing';
import { Currency } from '@shared/currency';

import { Superscript } from '../../steps/smart_storage/bundles/superscript';

import hourlyRateImageDesktop from '@images_responsive/www/checkout/storage_hourly_rate_cropped_wide_desktop.jpg';
import hourlyRateImageMobile from '@images_responsive/www/checkout/storage_hourly_rate_cropped_wide.jpg';
import locationTruckTracking from '@images/illustrations/location_truck_tracking.svg';
import moversWithTV from '@images/illustrations/movers_with_tv_small.svg';
import cargoVan from '@images/illustrations/moving_shuttle_van.svg';
import onlinePhotoCatalog from '@images/illustrations/online_photo_catalog_small.svg';
import tape from '@images/illustrations/tape_multi_small.svg';
import boxes from '@images/illustrations/unlimited_materials_boxes.svg';

const VALUE_PROPS: Array<{ imgSrc: string; description: string }> = [
  { imgSrc: cargoVan, description: 'Truck, dollies, and wrapping materials' },
  { imgSrc: tape, description: 'Unlimited packing boxes and supplies' },
  {
    imgSrc: moversWithTV,
    description: 'Professionally trained Clutter movers',
  },
  { imgSrc: boxes, description: 'Help packing boxes' },
  { imgSrc: locationTruckTracking, description: 'Real-time GPS tracking' },
  {
    imgSrc: onlinePhotoCatalog,
    description: 'Online inventory for stored items',
  },
];

export const EducationCard: React.FC<{}> = ({}) => {
  const {
    pricingSummary,
    flowState: {
      values: { planSize },
    },
  } = useStorageCheckoutContext();

  const planSizeName = planSize ? PLANS[planSize].label : undefined;

  const { fullServiceLaborRateAmount, formattedFreeDuration } =
    pricingSummary || {};

  return (
    <Box>
      <Box margin="0 0 16px">
        <Text.Title size="small" color={COLORS.tealDark}>
          {planSizeName ? (
            <>
              What’s included in your{' '}
              <span style={{ whiteSpace: 'nowrap' }}>{planSizeName}</span>{' '}
              pickup
            </>
          ) : (
            'What’s included in your pickup'
          )}
        </Text.Title>
      </Box>
      <Box overflow="hidden" borderRadius="4px" margin="0 0 16px">
        <picture>
          <source
            media={`(min-width: ${BREAKPOINTS.LG})`}
            srcSet={hourlyRateImageDesktop.src}
          />
          <img
            src={hourlyRateImageMobile.src}
            draggable={false}
            alt=""
            style={{ width: '100%', borderRadius: '4px' }}
          />
        </picture>
      </Box>
      <Box>
        <Text.Callout color={COLORS.panther}>
          Our Move & Pack Specialists come ready to pack boxes, wrap furniture,
          and load your items onto our truck.¹
        </Text.Callout>
        <Box margin="16px 0">
          <Text.Callout color={COLORS.hippo}>Includes:</Text.Callout>
        </Box>
        <Box.Grid
          gridTemplateColumns={['1fr', '1fr 1fr']}
          gridGap={['16px', '0 16px']}
        >
          {VALUE_PROPS.map(({ imgSrc, description }, key) => (
            <Box.GridItem key={key}>
              <Box.Flex
                alignItems="center"
                gap="16px"
                height={['initial', '56px']}
              >
                <img draggable={false} src={imgSrc} width="40px" alt="" />
                <Text.Callout color={COLORS.hippo}>{description}</Text.Callout>
              </Box.Flex>
            </Box.GridItem>
          ))}
        </Box.Grid>
      </Box>
      {!!fullServiceLaborRateAmount && (
        <Box margin="12px 0 0">
          <Superscript>1</Superscript>{' '}
          <Text.Caption display="inline" color={COLORS.hippo}>
            First pickup free up to {formattedFreeDuration} and then billed
            hourly at{' '}
            <Currency amount={fullServiceLaborRateAmount} suffix="/hour" />.
          </Text.Caption>
        </Box>
      )}
    </Box>
  );
};
