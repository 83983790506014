import React from 'react';

import calendar from '@images/illustrations/calendar.svg';
import movingTruck from '@images/illustrations/mover_with_sixteen_foot_truck.svg';
import onlinePhoneCatalog from '@images/illustrations/online_photo_catalog_small.svg';
import instantQuote from '@images/illustrations/online_quote.svg';
import clutterFacility from '@images/illustrations/storage_facility.svg';

export const MOVING_VALUE_PROPS = [
  'Live day-of move tracking',
  'Fully online Virtual Walkthrough',
  'Straight forward pricing',
  'Fully employed movers',
];

export const MOVING_ROUTE_VALUE_PROPS = [
  'Track your team’s ETA in real time',
  'First month of storage free while your items are in transit',
  'Fully online Virtual Walkthrough',
  'Dedicated truck so that your items are never transferred',
];

export const STORAGE_VALUE_PROPS = [
  '24/7 digital access',
  'Full service pickups',
  'Secure, clean facilities',
  'Temperature-controlled',
];

type Perk = {
  description: React.ReactNode;
  icon: {
    src: string;
    width: number;
    height: number;
  };
};

export const MOVING_PERKS: Perk[] = [
  {
    description: (
      <>
        Get an instant quote in under 2<br />
        minutes.
      </>
    ),
    icon: {
      src: instantQuote,
      height: 130,
      width: 231,
    },
  },
  {
    description: (
      <>
        Make a free reservation to secure
        <br />
        your spot.
      </>
    ),
    icon: {
      src: calendar,
      width: 166,
      height: 160,
    },
  },
  {
    description: (
      <>
        Complete a Virtual Walkthrough
        <br />
        before your move.
      </>
    ),
    icon: {
      src: onlinePhoneCatalog,
      height: 78,
      width: 85,
    },
  },
];

export const STORAGE_PERKS: Perk[] = [
  {
    description: (
      <>
        We pick up and inventory your
        <br />
        items.
      </>
    ),
    icon: {
      src: movingTruck,
      width: 236,
      height: 108,
    },
  },
  {
    description: (
      <>
        Items are stored in our Smart
        <br />
        Storage facility.
      </>
    ),
    icon: {
      src: clutterFacility,
      height: 55,
      width: 83,
    },
  },
  {
    description: (
      <>
        Schedule deliveries from your
        <br />
        online storage inventory.
      </>
    ),
    icon: {
      src: onlinePhoneCatalog,
      height: 78,
      width: 85,
    },
  },
];
