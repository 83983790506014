// TODO: make this work with SSR (see hardcoded phone)

const parse = (name: string): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }
  const element = document.querySelector(`meta[name='${name}']`);
  if (element) {
    return element.getAttribute('content') || null;
  } else {
    return null;
  }
};

export const CSRF_TOKEN = parse('csrf-token');

export const STRIPE_PUBLISHABLE_KEY = parse('stripe-publishable-key');

export const CONTACT_PHONE = parse('contact-phone') || '1-800-971-3674';
export const IS_BOT = parse('is-bot');

export const WT_COOKIE_EXPIRES = 365 * 20; // i.e. 20 years
export const WT_COOKIE_DOMAIN = parse('wt-cookie-domain');
export const WT_TRACKER_URL = parse('wt-tracker-url');
export const WT_TRACKER_DOMAIN = parse('wt-tracker-domain');

export const API_BASE_URL = parse('api-base-url');

export const GOOGLE_PUBLIC_MAPS_API_KEY = parse('google-public-maps-api-key');

export const SENTRY_DSN = parse('sentry_dsn');

export const HEROKU_SLUG_COMMIT = parse('heroku-slug-commit') || undefined;

export const CLUTTER_CLIENT = 'landing_frontend';
