import React from 'react';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';

export const FeatureGridItem: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box.GridItem
    background={COLORS.tealBackground}
    textAlign="center"
    padding={['4px', '12px']}
    borderRadius="4px"
  >
    <Text.Callout weight={FontWeight.Medium}>
      <Box>{children}</Box>
    </Text.Callout>
  </Box.GridItem>
);
