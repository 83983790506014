import { RateGroupKindEnum } from '@graphql/platform';
import { Commitment } from '@root/resources/types/commitment';
import { PlanKey } from '@root/resources/types/plan_key';

export const SMALLEST_PLAN_SIZE = '5x5';
export const WASHINGTON_PRICING_REGIONS = ['Portland', 'Seattle'];

export const PLANS: {
  [key in PlanKey]: {
    label: string;
    description: string;
    cuft: number;
  };
} = {
  '5x5': {
    label: '5’ × 5’',
    description: 'Small closet size',
    cuft: 100,
  },
  '5x10': {
    label: '5’ × 10’',
    description: 'Walk-in closet size',
    cuft: 200,
  },
  '10x10': {
    label: '10’ × 10’',
    description: 'Garage size',
    cuft: 400,
  },
  '10x15': {
    label: '10’ × 15’',
    description: 'Studio apartment',
    cuft: 600,
  },
  '10x20': {
    label: '10’ × 20’',
    description: '1 bedroom home',
    cuft: 800,
  },
  '10x25': {
    label: '10’ × 25’',
    description: '2 bedroom home',
    cuft: 1000,
  },
  '10x30': {
    label: '10’ × 30’',
    description: '3 bedroom home',
    cuft: 1200,
  },
  '10x40': {
    label: '10’ × 40’',
    description: '4 bedroom home',
    cuft: 1600,
  },
  '10x50': {
    label: '10’ × 50’',
    description: '5 bedroom home',
    cuft: 2000,
  },
};

export const COMMITMENT_TO_LENGTH: Record<Commitment, number> = {
  [RateGroupKindEnum.Mover]: 1,
  [RateGroupKindEnum.Flexer]: 4,
  [RateGroupKindEnum.Saver]: 8,
};

export const RATE_GROUP_COMMITMENT: PartialRecord<
  RateGroupKindEnum,
  Commitment
> = {
  [RateGroupKindEnum.CurbsideMover]: RateGroupKindEnum.Mover,
  [RateGroupKindEnum.CurbsideFlexer]: RateGroupKindEnum.Flexer,
  [RateGroupKindEnum.CurbsideSaver]: RateGroupKindEnum.Saver,
  [RateGroupKindEnum.Mover]: RateGroupKindEnum.Mover,
  [RateGroupKindEnum.Flexer]: RateGroupKindEnum.Flexer,
  [RateGroupKindEnum.Saver]: RateGroupKindEnum.Saver,
};
