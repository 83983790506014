import styled from '@emotion/styled';

export const TogglerIcon = styled.div<{ isOpen: boolean }>`
  width: 24px;
  height: 12px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  display: inline-block;

  & img {
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;
