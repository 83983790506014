import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';
import stringify from 'fast-json-stable-stringify';

import { useLatestRef } from '@clutter/hooks';
import {
  MovingQuoteCreateMutation,
  useMovingQuoteCreateMutation,
} from '@graphql/platform';
import { buildQuoteInput } from '@utils/moving/moving_quote_helpers';

import { MovingCheckoutData } from '../data';

import { determineMoveSize } from './moving';

let lastQuoteInput = '';
let lastQuote: FetchResult<MovingQuoteCreateMutation> | undefined = undefined;

export type MovingQuoteCreator = () => Promise<
  FetchResult<MovingQuoteCreateMutation> | undefined
>;

export const useBoundCreateMovingQuote = (values: MovingCheckoutData) => {
  const latestInputRef = useLatestRef(values);

  const [createQuote] = useMovingQuoteCreateMutation();

  return useCallback(async () => {
    // Because this is intended to be called after making a change, we want to
    // make sure that the latest changes have been flushed. This currently works,
    // but may not be the best approach in concurrent mode.
    await new Promise((resolve) => setTimeout(resolve));

    const nextInput = stringify(latestInputRef.current);
    if (lastQuoteInput === nextInput) return lastQuote;

    lastQuoteInput = nextInput;

    const {
      adjustedMoverCount,
      dateScheduled,
      startAddress,
      endAddress,
      startAddressDetails,
      skipAddressSelected,
      packingHelp,
      packingMaterials,
      rooms,
      zip,
    } = latestInputRef.current;

    const hasAddress =
      (startAddress?.zip && endAddress?.zip) || skipAddressSelected;
    const packingHelpSelected = packingHelp !== undefined;

    if (!hasAddress || !packingHelpSelected) {
      lastQuote = undefined;
      return undefined;
    }

    const variables = buildQuoteInput({
      dateScheduled: dateScheduled?.fromTime,
      movingStartAddress: { ...startAddress, ...startAddressDetails },
      movingEndAddress: {
        ...endAddress,
        buildingType: startAddressDetails?.buildingType,
      },
      movingPackingHelp: packingHelp,
      movingPackingMaterials: packingMaterials,
      movingRooms: rooms,
      movingSpace: determineMoveSize(startAddressDetails),
      movingAddressSkipped: skipAddressSelected,
      moverOverride: adjustedMoverCount,
      zip,
    });

    lastQuote = await createQuote({ variables });
    return lastQuote;
  }, [latestInputRef, createQuote]);
};
