import React from 'react';

import basicMaterialsBox from '@images/illustrations/basic_materials_box.svg';
import standardMaterialsBoxes from '@images/illustrations/standard_materials_boxes.svg';
import unlimitedMaterialsBoxes from '@images/illustrations/unlimited_materials_boxes.svg';

export const MATERIAL_KIT_METADATA: Record<
  string,
  {
    title: string;
    lineItemName: string;
    description: React.ReactNode;
    icon: string;
  }
> = {
  'Basic Packing Supplies': {
    title: 'Basic - 20 boxes',
    lineItemName: 'Basic packing materials',
    description: '20 boxes, tape, and bubble wrap',
    icon: basicMaterialsBox,
  },
  'Standard Packing Supplies': {
    title: 'Standard - 30 boxes',
    lineItemName: 'Standard packing materials',
    description: '30 boxes, tape, and bubble wrap',
    icon: standardMaterialsBoxes,
  },
  'Unlimited Packing Supplies': {
    title: 'Unlimited',
    lineItemName: 'Unlimited packing materials',
    description: 'As many supplies as you need',
    icon: unlimitedMaterialsBoxes,
  },
};
