import { useMemo, useReducer } from 'react';

type ViewCart = {
  type: 'viewCart';
};

type SetTargetStep<StepEnum> = {
  type: 'setTargetStep';
  payload: { step: StepEnum | null };
};

type Action<StepEnum> = ViewCart | SetTargetStep<StepEnum>;

export type NavigationState<StepEnum> = {
  dispatch(action: Action<StepEnum>): void;
  hasViewedCart: boolean;
  targetStep: StepEnum | null;
};

type NavigationStateReducer<StepName> = (
  state: Omit<NavigationState<StepName>, 'dispatch'>,
  action: Action<StepName>,
) => Omit<NavigationState<StepName>, 'dispatch'>;

function stepNavigationReducer<StepEnum>(
  state: Omit<NavigationState<StepEnum>, 'dispatch'>,
  action: Action<StepEnum>,
): Omit<NavigationState<StepEnum>, 'dispatch'> {
  switch (action.type) {
    case 'viewCart': {
      return { ...state, hasViewedCart: true };
    }
    case 'setTargetStep': {
      return { ...state, targetStep: action.payload.step };
    }
  }
}

export function useNavigationState<StepEnum>() {
  const [state, dispatch] = useReducer<NavigationStateReducer<StepEnum>>(
    stepNavigationReducer,
    {
      hasViewedCart: false,
      targetStep: null,
    },
  );

  return useMemo(
    () => ({
      ...state,
      dispatch,
    }),
    [state, dispatch],
  );
}
