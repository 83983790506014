import { reportMessage } from './monitoring';

export function skipAnimation() {
  if (typeof window === 'undefined') return false;
  return (
    window.matchMedia &&
    window.matchMedia('(prefers-reduced-motion: reduce)').matches
  );
}

const fakeStorage: Storage = {
  getItem() {
    return null;
  },
  setItem() {
    return null;
  },
  clear() {
    return;
  },
  key() {
    return null;
  },
  removeItem(_) {
    return null;
  },
  length: 0,
};

const captureMessage = (error: any) => {
  reportMessage(error.message, { tags: { sessionStorageError: 'true' } });
};

function captureDOMException<T = any>(callback: () => T) {
  try {
    return callback();
  } catch (e) {
    if (e instanceof DOMException || e instanceof TypeError) captureMessage(e);
    else throw e;
  }
}

/**
 * Accessing the Storage APIs in Chrome and Safari with certain
 * privacy settings enabled results in an error being thrown. Because of this,
 * browser storage should not be relied on for business critical functions.
 */
let SafeSessionStorage = fakeStorage;
let SafeLocalStorage = fakeStorage;

try {
  SafeSessionStorage = {
    getItem: (key) =>
      captureDOMException(() => sessionStorage.getItem(key)) ?? null,
    setItem: (key, value) =>
      captureDOMException(() => sessionStorage.setItem(key, value)),
    clear: () => captureDOMException(() => sessionStorage.clear()),
    key: (idx) => captureDOMException(() => sessionStorage.key(idx)) ?? null,
    removeItem: (key) =>
      captureDOMException(() => sessionStorage.removeItem(key)),
    get length() {
      return captureDOMException(() => sessionStorage.length) ?? 0;
    },
  };
  SafeLocalStorage = {
    getItem: (key) =>
      captureDOMException(() => localStorage.getItem(key)) ?? null,
    setItem: (key, value) =>
      captureDOMException(() => localStorage.setItem(key, value)),
    clear: () => captureDOMException(() => localStorage.clear()),
    key: (idx) => captureDOMException(() => localStorage.key(idx)) ?? null,
    removeItem: (key) =>
      captureDOMException(() => localStorage.removeItem(key)),
    get length() {
      return captureDOMException(() => localStorage.length) ?? 0;
    },
  };
} catch (error) {
  captureMessage(error);
}

export { SafeSessionStorage, SafeLocalStorage };
