import * as React from 'react';

import { COLORS } from '@clutter/clean';

const CloseButton: React.FC<{
  color?: string;
  className?: string;
  onClick: () => void;
}> = ({ color = COLORS.panther, onClick, className }) => (
  <svg
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.445496 13.8309C0.610332 13.941 0.804147 13.9998 1.00246 14C1.26847 13.9998 1.52351 13.8941 1.71149 13.7061L7.02267 8.40321L12.3338 13.7061C12.5218 13.8941 12.7769 13.9998 13.0429 14C13.2412 13.9998 13.435 13.941 13.5998 13.8309C13.7647 13.7208 13.8931 13.5644 13.969 13.3815C14.0449 13.1985 14.0647 12.9972 14.0261 12.803C13.9875 12.6088 13.8921 12.4304 13.7519 12.2903L8.44073 6.98736L13.7516 1.68472C13.9288 1.49482 14.0253 1.24364 14.0207 0.984113C14.0162 0.724582 13.9109 0.47696 13.727 0.293416C13.5432 0.109872 13.2952 0.00473519 13.0353 0.000156075C12.7753 -0.00442304 12.5238 0.0919125 12.3336 0.268868L7.02267 5.5715L1.71179 0.268868C1.52159 0.0919125 1.27002 -0.00442304 1.01009 0.000156075C0.750155 0.00473519 0.502147 0.109872 0.318317 0.293416C0.134487 0.47696 0.0291875 0.724582 0.0246013 0.984113C0.0200151 1.24364 0.1165 1.49482 0.293731 1.68472L5.60461 6.98736L0.293432 12.2903C0.15329 12.4304 0.0578725 12.6088 0.0192312 12.803C-0.0194101 12.9972 0.000457969 13.1985 0.0763259 13.3815C0.152194 13.5644 0.280659 13.7208 0.445496 13.8309Z"
      fill={color}
    />
  </svg>
);

export { CloseButton };
