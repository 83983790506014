import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, mq, Text } from '@clutter/clean';
import {
  Moving__MaterialPackageSetEntryFragment,
  Moving__WhiteGloveBundleEnum,
  useMovingMaterialPackageSetEntriesFetchMutation,
} from '@graphql/platform';
import { useMovingCheckoutContext } from '@root/components/checkout/context';
import { unitDescriptor } from '@root/components/checkout/helpers/moving';
import { MovingCheckoutStep } from '@root/components/checkout/types';
import { movingScrollToStep } from '@root/components/checkout/utilities/scroll_animation';
import { useTrack } from '@root/initializers/wt';
import { EventSchema } from '@root/resources/wt/types';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { useMountedRef } from '@utils/hooks/mount';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { Price } from '../../../subcomponents/fees/highlighted_price';

import { Card } from './card';
import { ComparisonModal } from './comparison_modal';
import {
  basicBundleValueProps,
  fullServiceBundleValueProps,
  packingHelpBundleValueProps,
} from './value_props';

import cargoVan from '@images/illustrations/cargo_van_crop_small.svg';
import lightBulbIllustration from '@images/illustrations/light_bulb_rotated.svg';

const Icon = styled.img`
  ${mq({
    width: ['28px', '46px', null],
  })}
`;

export const Quote: React.FC = () => {
  const {
    flowState: {
      values: {
        rooms,
        startAddress,
        startAddressDetails,
        movingQuote,
        movingBundle,
        leadToken,
      },
      onChange,
    },
    createQuote,
  } = useMovingCheckoutContext();
  const [highlightedBundle, setHighlightedBundle] = useState(movingBundle);
  const [showModal, setShowModal] = useState(false);
  const [packageSetEntries, setPackageSetEntries] =
    useState<ReadonlyArray<Moving__MaterialPackageSetEntryFragment>>();

  const [fetchPackingMaterials] =
    useMovingMaterialPackageSetEntriesFetchMutation();

  const mountedRef = useMountedRef();
  const trackFunnelEvent = useTrackFunnelEvents();

  const track = useTrack({
    action: 'click',
  });

  useEffect(() => {
    if (!movingQuote) return;

    fetchPackingMaterials({
      variables: { signedQuoteID: movingQuote.signedID },
    }).then(({ data }) => {
      if (!mountedRef.current) return;
      if (data?.materialPackageSetEntriesFetch) {
        setPackageSetEntries(
          data?.materialPackageSetEntriesFetch?.materialPackageSetEntries,
        );
      } else {
        throw new Error('Failed to fetch material package set entries');
      }
    });
  }, [movingQuote, fetchPackingMaterials, mountedRef]);

  const { isMobile } = useBreakpoints();
  const onCompare = (bundle: Moving__WhiteGloveBundleEnum) => {
    track({
      container: `${bundle} card`,
      objectType: 'button',
      label: 'Compare',
    });

    setHighlightedBundle(bundle);
    setShowModal(true);
  };

  const onMatrixSelectBundle = (bundle: Moving__WhiteGloveBundleEnum) => {
    onSelectBundle(bundle, 'comparison_matrix');
    setShowModal(false);
  };

  const isLongDistance = movingQuote?.longDistanceTransportationRate;
  const hasEnteredLeadInformation = !!leadToken;
  const whiteGloveMoverCountOptions = movingQuote?.whiteGloveMoverCountOptions;
  const flatRateOptions = movingQuote?.flatRateOptions;
  const flatRateEligible = !!flatRateOptions?.length;
  const options = flatRateEligible
    ? flatRateOptions
    : whiteGloveMoverCountOptions;
  const recommendedMoverCount = movingQuote?.fullServiceMoverCount;
  const minimumHours = movingQuote?.movingConfiguration
    ? movingQuote?.movingConfiguration?.requiredLaborDuration / 3600
    : 0;

  if (!options?.length || options.length < 3) {
    return null;
  }

  const basicBundleOption = options[0];
  const packingHelpBundleOption = options[1];
  const fullServiceBundleOption = options[2];

  const basicBundleFlatRate = basicBundleOption.flatRateDetails?.totalAmount;
  const packingHelpBundleFlatRate =
    packingHelpBundleOption.flatRateDetails?.totalAmount;
  const fullServiceBundleFlatRate =
    fullServiceBundleOption.flatRateDetails?.totalAmount;

  const basicBundleLaborRate = basicBundleOption.laborRate?.amount || 0;

  const packingHelpBundleLaborRate =
    packingHelpBundleOption.laborRate?.amount || 0;

  const fullServiceBundleLaborRate =
    fullServiceBundleOption.laborRate?.amount || 0;

  const unlimitedPackingPackage = packageSetEntries?.find(
    (entry) => entry.package.name === 'Unlimited Packing Supplies',
  );

  const onSelectBundle = (
    bundle: Moving__WhiteGloveBundleEnum,
    container = 'card',
  ) => {
    let moverCount;

    if (bundle === Moving__WhiteGloveBundleEnum.Basic) {
      onChange('movingBundle', Moving__WhiteGloveBundleEnum.Basic);
      onChange('packingMaterials', false);
      moverCount = basicBundleOption.moverCount;
    } else if (bundle === Moving__WhiteGloveBundleEnum.PackingHelp) {
      onChange('movingBundle', Moving__WhiteGloveBundleEnum.PackingHelp);
      onChange('packingMaterials', unlimitedPackingPackage);
      moverCount = packingHelpBundleOption.moverCount;
    } else {
      onChange('movingBundle', Moving__WhiteGloveBundleEnum.FullService);
      onChange('packingMaterials', unlimitedPackingPackage);
      moverCount = fullServiceBundleOption.moverCount;
    }

    track({
      container: container,
      objectType: 'button',
      label: 'Select',
      value: bundle,
    });

    trackFunnelEvent({
      schema: EventSchema.WWW__MovePriceCompleted,
      action: 'click',
      metadata: {},
    });

    onChange('moverCountSelected', true);
    onChange(
      'adjustedMoverCount',
      moverCount === recommendedMoverCount ? undefined : moverCount,
      () => createQuote(),
    );

    movingScrollToStep(MovingCheckoutStep.Appointment);
  };

  return (
    <Box margin={['48px 0 0', null, '72px 0 0']}>
      <Box margin="0 0 16px">
        <Text.Title size="small" color={COLORS.tealDark}>
          Select your package
        </Text.Title>
        <Text.Body color={COLORS.storm}>
          Pack your own stuff, or have us do it all!
        </Text.Body>
      </Box>
      {highlightedBundle && (
        <ComparisonModal
          selectedBundle={highlightedBundle}
          isOpen={showModal}
          handleModalClose={() => setShowModal(false)}
          onSelectBundle={onMatrixSelectBundle}
        />
      )}
      <Box.Flex flexDirection={['column', null, null, 'row']} gap="12px">
        <Card
          testId="basicBundle"
          bannerValue="Lowest cost"
          title="Basic"
          subtitle="We move, you pack & unpack"
          selected={movingBundle === Moving__WhiteGloveBundleEnum.Basic}
          showPrice={hasEnteredLeadInformation}
          flatRate={basicBundleFlatRate}
          laborRate={basicBundleLaborRate}
          minimumHours={minimumHours}
          valueProps={basicBundleValueProps(isMobile)}
          onCompare={() => onCompare(Moving__WhiteGloveBundleEnum.Basic)}
          onSelect={() => onSelectBundle(Moving__WhiteGloveBundleEnum.Basic)}
        />
        <Card
          testId="packingHelpBundle"
          bannerValue="Best value"
          title="Packing Help"
          subtitle="We move & pack, you unpack"
          selected={movingBundle === Moving__WhiteGloveBundleEnum.PackingHelp}
          showPrice={hasEnteredLeadInformation}
          flatRate={packingHelpBundleFlatRate}
          laborRate={packingHelpBundleLaborRate}
          minimumHours={minimumHours}
          showBadge={true}
          valueProps={packingHelpBundleValueProps(
            isMobile,
            unlimitedPackingPackage?.fixedCost || 0,
          )}
          onCompare={() => onCompare(Moving__WhiteGloveBundleEnum.PackingHelp)}
          onSelect={() =>
            onSelectBundle(Moving__WhiteGloveBundleEnum.PackingHelp)
          }
        />
        <Card
          testId="fullServiceBundle"
          bannerValue="Most Luxurious"
          title="Full Service"
          subtitle="We do everything!"
          selected={movingBundle === Moving__WhiteGloveBundleEnum.FullService}
          showPrice={hasEnteredLeadInformation}
          flatRate={fullServiceBundleFlatRate}
          laborRate={fullServiceBundleLaborRate}
          minimumHours={minimumHours}
          showBadge={false}
          valueProps={fullServiceBundleValueProps(isMobile)}
          onCompare={() => onCompare(Moving__WhiteGloveBundleEnum.FullService)}
          onSelect={() =>
            onSelectBundle(Moving__WhiteGloveBundleEnum.FullService)
          }
        />
      </Box.Flex>
      {isLongDistance && !flatRateEligible && (
        <Box.Flex
          justifyContent="space-between"
          padding="24px"
          background={COLORS.cloud}
          border={`1px solid ${COLORS.grayBorder}`}
          alignItems="flex-start"
          gap="12px"
          margin="12px 0 0"
          borderRadius="4px"
        >
          <Icon src={cargoVan} alt="" />
          <Box.Flex flexDirection="column">
            <Box.Flex justifyContent="space-between" alignItems="center">
              <Text.Callout weight={FontWeight.Medium}>
                Transport Fee
              </Text.Callout>
              <Box.Flex
                alignItems="center"
                gap="8px"
                display={['flex', 'none', 'flex', 'none']}
              >
                <Text.Callout weight={FontWeight.Medium} color={COLORS.hippo}>
                  {movingQuote.driveDistanceInMiles?.toLocaleString()} miles
                </Text.Callout>
                <Price
                  isMobile={isMobile}
                  amount={movingQuote.longDistanceTransportFee || 0}
                  startingAtText=""
                  showBackground
                  displayStartingAt
                />
              </Box.Flex>
            </Box.Flex>
            <Text.Callout>
              A flat fee that covers the transportation of up to{' '}
              {movingQuote.minimumBilledWeight?.toLocaleString()}lbs of cargo.
            </Text.Callout>
            <Box.Flex margin="16px 0 0">
              <Box.FlexItem alignSelf="start" margin="0 8px 0 0" flexShrink={0}>
                <img
                  src={lightBulbIllustration}
                  width="16px"
                  draggable={false}
                  alt=""
                />
              </Box.FlexItem>
              <Text.Caption color={COLORS.hippo}>
                On average, a{' '}
                {unitDescriptor(
                  { ...startAddress, ...startAddressDetails },
                  rooms,
                )}{' '}
                weighs between{' '}
                {movingQuote.estimatedWeightLowerBound?.toLocaleString()} and{' '}
                {movingQuote.estimatedWeightUpperBound?.toLocaleString()}{' '}
                pounds.
              </Text.Caption>
            </Box.Flex>
          </Box.Flex>
          <Box.Flex
            alignItems="center"
            gap="8px"
            display={['none', 'flex', 'none', 'flex']}
            flexShrink={0}
          >
            <Text.Callout weight={FontWeight.Medium} color={COLORS.hippo}>
              {movingQuote.driveDistanceInMiles} miles
            </Text.Callout>
            <Price
              isMobile={isMobile}
              amount={movingQuote.longDistanceTransportFee || 0}
              startingAtText=""
              showBackground
              displayStartingAt
            />
          </Box.Flex>
        </Box.Flex>
      )}
    </Box>
  );
};
