import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { useTrack } from '@root/initializers/wt';
import { EventWithSchema } from '@root/resources/wt/types';
import { useStabilizedFunction } from '@utils/hooks/utility';

type DeepDefault<Base, DefaultShape> = Base extends Record<string, unknown>
  ? {
      // Map over all keys of T and check if that key exists in U
      [Key in keyof Base as Key extends keyof DefaultShape
        ? // If it does, and it's an object of some sort, continue to recurse
          DefaultShape[Key] extends Record<string, unknown>
          ? Key
          : // If it's not an object, `never` omits it from the type
            never
        : Key]: Key extends keyof DefaultShape
        ? DeepDefault<Base[Key], DefaultShape[Key]>
        : Base[Key];
    }
  : Base;

type FunnelEvent = Extract<EventWithSchema, { category: 'funnel_event' }>;

export const useTrackFunnelEvents = () => {
  const track = useTrack();
  const {
    flowVersion,
    flowState: {
      flowInstanceUuid,
      values: { checkoutType },
    },
  } = useSharedCheckoutContext();
  return useStabilizedFunction(
    (
      event: DeepDefault<
        FunnelEvent,
        {
          category: 'funnel_event';
          metadata: { checkout_type: string; flow_version: string };
        }
      >,
    ) => {
      const params = {
        ...event,
        category: 'funnel_event' as const,
        metadata: {
          ...event.metadata,
          flow_version: flowVersion,
          checkout_type: checkoutType,
          ...{ flow_instance_uuid: flowInstanceUuid },
        },
      } as FunnelEvent;
      track(params);
    },
  );
};
