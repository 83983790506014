import React, { useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { Box } from '@clutter/clean';
import {
  Lead__ServiceNeeds,
  useGeocodeQuery,
  useMovingRouteSummaryQuery,
} from '@graphql/platform';
import { MovingAddressModal } from '@root/components/checkout/product_pages/subcomponents/moving_address_modal';
import { ShareableHero } from '@root/components/checkout/product_pages/subcomponents/shareable_hero';
import { WTProvider } from '@root/initializers/wt';
import { ServiceEnum } from '@root/resources/types/service';
import { WWW_ROUTES } from '@root/root/routes';
import { useClientDataContext } from '@shared/client_data_context';
import { ReviewCarousel } from '@shared/page_builder/components/review_carousel';
import { MOVING_TESTIMONIALS } from '@shared/page_builder/components/review_carousel/data';
import { QualifiedContactWidget } from '@shared/qualified_contact_widget';
import { ServiceSelection } from '@shared/service_selector/constants';
import { parseGeocodeEligibility } from '@utils/geocode';
import { useFlowInitiatedFunnelEvents } from '@utils/hooks/funnel_events/use_flow_initiated_funnel_event';
import { useOnMount } from '@utils/hooks/mount';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { useElementRect } from '@utils/hooks/use_element_rect';
import {
  createThirdPartyConversionEvent,
  EventName,
  ProductType,
} from '@utils/third_party_conversion_events';

import { FAQ } from '../../shared/faq/faq';
import { buildMovingFAQContentList } from '../../shared/faq/utils';
import { useMovingCheckoutContext } from '../context';
import { determineMoveSize, sanitizeUnitTypesInput } from '../helpers/moving';
import { CheckoutType, MovingCheckoutStep } from '../types';
import { resolveEnabledSteps } from '../utilities/enabled_steps';
import {
  createMovingBoundScrollToStep,
  movingScrollToStep,
} from '../utilities/scroll_animation';

import { Modal as CouponModal } from './subcomponents/coupon/modal';
import { FeaturesOverlay } from './subcomponents/features_overlay';
import { MOVING_LEARN_MORE } from './subcomponents/features_overlay/data';
import { sanitizeAddressInput } from './subcomponents/moving_address_fields';
import { MovingHero } from './subcomponents/moving_hero';
import { MovingMap } from './subcomponents/moving_map';
import { ReserveButton } from './subcomponents/reserve_button';
import { Layout } from './layout';

const MAP_CONTAINER_HEIGHT = 514;

const MapContainer = styled.div`
  width: 100%;
`;

let cachedShowMobileReserveButton = false;

// Note: this is extracted to a separate component to prevent re-rendering the
// entire checkout on container size changes
const StickyContent = () => {
  const [mapContainerRef, rect] = useElementRect();
  return (
    <Layout.StickyContainer
      containerHeight={rect?.height ?? MAP_CONTAINER_HEIGHT}
    >
      <MapContainer>
        <MovingMap mapContainerRef={mapContainerRef} />
      </MapContainer>
    </Layout.StickyContainer>
  );
};

const Steps: React.FC = () => {
  const history = useHistory();
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const [showMobileReserveButton, setShowMobileReserveButton] = useState(
    cachedShowMobileReserveButton,
  );
  const {
    data: {
      lead: { token },
    },
  } = useClientDataContext();

  const {
    resolvedSteps,
    disposalEligible,
    flowState: { steps, getStepProps, onChange, goToStep, values },
    navigationState: { hasViewedCart, targetStep, dispatch },
  } = useMovingCheckoutContext();

  useOnMount(() => {
    if (hasViewedCart && targetStep) {
      // If a target step is present, that means we are coming from the edit
      // page via a "back" navigation, but we also might want to focus a
      // particular step.
      setTimeout(() => {
        dispatch({ type: 'setTargetStep', payload: { step: null } });
        movingScrollToStep(targetStep);
      });
    }
  });

  const { data, loading: loadingGeocode } = useGeocodeQuery({
    variables: { zip: values.zip! },
    skip: !values.zip,
  });
  const movingEligible = parseGeocodeEligibility(data?.geocode).hasMoving;
  const { isDesktop } = useBreakpoints();

  const originAddress = sanitizeAddressInput(values.startAddress);
  const destinationAddress = sanitizeAddressInput(values.endAddress);

  const unitTypes =
    values.startAddress &&
    sanitizeUnitTypesInput(
      { ...values.startAddress, ...values.startAddressDetails },
      values.rooms,
    );
  const moveSize = determineMoveSize(values.startAddressDetails);

  const { data: moveRouteData } = useMovingRouteSummaryQuery({
    variables: {
      destinationAddress: destinationAddress!,
      originAddress: {
        ...originAddress!,
        details: {
          buildingType: values.startAddressDetails?.buildingType,
        },
      },
      unitTypes: unitTypes,
      moveSize: moveSize,
    },
    skip: !originAddress || !destinationAddress,
  });

  const initiateFlowTriggeredRef = useRef<boolean>(false);
  useEffect(() => {
    if (movingEligible && !initiateFlowTriggeredRef.current) {
      initiateFlowTriggeredRef.current = true;
      createThirdPartyConversionEvent(EventName.INITIATE_FLOW, {
        zip_code: values.zip,
        checkout_version: 'two_page_moving',
        product_type: ProductType.MOVING,
      });
    }
  }, [movingEligible]);

  const [completionAggregate, enabledSteps] = resolveEnabledSteps(
    resolvedSteps,
    {
      values,
      leadToken: token,
      validMoveRoute: moveRouteData?.movingRouteSummary?.validMoveRoute,
      disposalEligible,
    },
  );

  useEffect(() => {
    if (completionAggregate) {
      cachedShowMobileReserveButton = true;
      setShowMobileReserveButton(true);
    }
  }, [completionAggregate]);

  const reserveButtonDisabled =
    !completionAggregate || loadingGeocode || !movingEligible;

  const goToCart = () => {
    history.push(WWW_ROUTES.MOVING_CART);
    goToStep(MovingCheckoutStep.Cart);
  };

  const handleReserveButtonClick = () => {
    if (values.skipAddressSelected) {
      setShowAddressModal(true);
    } else {
      goToCart();
    }
  };

  // When a customer skips adding an address, we should redirect them to the appointment
  // step to re-select availability if their move is long distance
  const closeModalForRedirect = () => {
    setShowAddressModal(false);
  };

  return (
    <Layout.StepsOuter>
      <Layout.StepsLeft ref={leftContainerRef}>
        <StickyContent />
      </Layout.StepsLeft>
      <Layout.StepsRight>
        {steps.map((Step, i) => {
          const { name: stepName } = resolvedSteps[i];
          const enabled = enabledSteps[i];

          return (
            <React.Fragment key={stepName}>
              <Layout.StepContainer id={stepName} enabled={enabled}>
                <WTProvider
                  key={stepName}
                  params={{
                    container: stepName,
                    position: i,
                  }}
                >
                  <Step
                    {...getStepProps()}
                    enabled={enabled}
                    scrollToStep={createMovingBoundScrollToStep(
                      i,
                      resolvedSteps,
                    )}
                    onChange={
                      ((key, value, cb) => {
                        goToStep(i);
                        onChange(key, value, cb);
                      }) as typeof onChange
                    }
                  />
                </WTProvider>
              </Layout.StepContainer>
              {stepName === MovingCheckoutStep.MoverCount && !isDesktop && (
                <Box margin="24px 0 0">
                  <Layout.StepContainer id="mobile_map" enabled={!!token}>
                    <MovingMap />
                  </Layout.StepContainer>
                </Box>
              )}
            </React.Fragment>
          );
        })}
        <QualifiedContactWidget
          bottomOffset={
            !isDesktop && (showMobileReserveButton || hasViewedCart) ? 104 : 0
          }
        />
        <ReserveButton
          sticky={showMobileReserveButton}
          disabled={reserveButtonDisabled}
          service={Lead__ServiceNeeds.Moving}
          hasViewedCart={hasViewedCart}
          onClick={handleReserveButtonClick}
        />
        <MovingAddressModal
          isOpen={showAddressModal}
          onClose={() => setShowAddressModal(false)}
          onComplete={(closeModal?: boolean) =>
            closeModal ? closeModalForRedirect() : goToCart()
          }
        />
      </Layout.StepsRight>
    </Layout.StepsOuter>
  );
};

export const MovingProductPage = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const {
    flowState: {
      values: { checkoutType, zip, name, customerToken },
    },
  } = useMovingCheckoutContext();

  const geocode =
    useGeocodeQuery({
      variables: { zip: zip! },
      skip: !zip,
    }).data?.geocode ?? undefined;

  const faqContentList = buildMovingFAQContentList(
    geocode?.region?.name ?? undefined,
  );

  useFlowInitiatedFunnelEvents();

  return (
    <Layout isLoggedIn={!!customerToken}>
      {checkoutType === CheckoutType.Shareable ? (
        <ShareableHero name={name} service={ServiceEnum.Moving} />
      ) : (
        <MovingHero
          showOverlay={() => setShowOverlay(true)}
          cityName={geocode?.cityName ?? undefined}
        />
      )}
      <Steps />
      <ReviewCarousel reviews={MOVING_TESTIMONIALS} />
      <FAQ title="Moving FAQ" contentList={faqContentList} />
      <FeaturesOverlay
        isOpen={showOverlay}
        onClose={() => setShowOverlay(false)}
        content={MOVING_LEARN_MORE}
      />
      <CouponModal serviceSelection={ServiceSelection.Moving} />
    </Layout>
  );
};
