import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';

import calendar from '@images/illustrations/calendar.svg';
import onlineQuote from '@images/illustrations/online_quote.svg';

const Illustration = styled.img`
  height: 33px;
  margin-right: 16px;
  filter: grayscale(1);
`;

export const MovingCartValueProps: React.FC = () => (
  <Box.Flex gap="32px" flexDirection={['column', 'row']}>
    <Box.Flex>
      <Box.FlexItem flexShrink={0}>
        <Illustration src={onlineQuote} alt="" />
      </Box.FlexItem>
      <Text.Caption color={COLORS.storm}>
        Complete a virtual walkthrough after booking for the most accurate
        quote.
      </Text.Caption>
    </Box.Flex>
    <Box.Flex>
      <Box.FlexItem width="74px" textAlign={['center', 'right']} flexShrink={0}>
        <Illustration src={calendar} alt="" />
      </Box.FlexItem>
      <Text.Caption color={COLORS.storm}>
        No risk booking: You can cancel your free reservation online.
      </Text.Caption>
    </Box.Flex>
  </Box.Flex>
);
