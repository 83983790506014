import React from 'react';

import { Box, ExternalContent } from '@clutter/clean';
import { Location__Content, Location__Service } from '@graphql/platform';
import { ButtonCTA } from '@shared/button_cta';
import { ServiceSelection } from '@shared/service_selector/constants';

export const Content: React.FC<{
  content: Pick<Location__Content, 'blurb' | 'service'>;
}> = ({ content }) => (
  <>
    <ExternalContent dangerouslySetInnerHTML={{ __html: content.blurb }} />
    <Box textAlign="center" margin="48px 0 0 0">
      <ButtonCTA
        container="content"
        entryPoint="location_page_cta"
        label="Get a Quote"
        service={
          content.service === Location__Service.SmartStorage
            ? ServiceSelection.Storage
            : ServiceSelection.Moving
        }
      />
    </Box>
  </>
);
