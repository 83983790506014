import styled from '@emotion/styled';

import { getPropValue } from '@clutter/clean';

export const TransparentButton = styled.button<{ tapTargetExtension?: string }>`
  border: 0;
  appearance: none;
  cursor: pointer;
  background: transparent;

  &:focus:not(.focus-visible) {
    outline: none;
  }

  padding: ${getPropValue('tapTargetExtension', 0)};
  margin: -${getPropValue('tapTargetExtension', 0)};
`;
