import { useEffect } from 'react';

import { EventSchema } from '@root/resources/wt/types';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';

export const useLeadCaptureViewedFunnelEvent = (enabled: boolean) => {
  const track = useTrackFunnelEvents();

  useEffect(() => {
    if (enabled) {
      track({
        schema: EventSchema.WWW__LeadCaptureViewed,
        action: 'display',
        metadata: {},
      });
    }
  }, [track, enabled]);
};
