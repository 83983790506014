import React, { useEffect } from 'react';

import { Accordion, Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { useTrack } from '@root/initializers/wt';
import { useHasIntersected } from '@utils/hooks';

import { FAQContent } from './utils';

export const FAQ: React.FC<{
  title: string;
  contentList: FAQContent[];
}> = ({ title, contentList }) => {
  const { targetRef, hasIntersected } = useHasIntersected({ threshold: 0.5 });

  const track = useTrack({
    container: 'FAQ',
  });

  useEffect(() => {
    if (hasIntersected) {
      track({ action: 'view' });
    }
  }, [track, hasIntersected]);

  return (
    <Box
      ref={targetRef}
      maxWidth="980px"
      margin={['64px 24px 24px', null, '72px auto']}
    >
      <Box textAlign="center">
        <Text.Title size="medium">{title}</Text.Title>
      </Box>
      <Accordion.Group>
        {contentList.map((content, index) => (
          <Accordion.Item
            key={index}
            name={content.question}
            label={
              <Text.Body color={COLORS.tealDark} weight={FontWeight.Medium}>
                {content.question}
              </Text.Body>
            }
            onToggle={() => {
              track({
                objectName: 'faq_toggle',
                label: content.question,
                action: 'click',
                objectType: 'button',
              });
            }}
          >
            <Box padding="24px">{content.answer}</Box>
          </Accordion.Item>
        ))}
      </Accordion.Group>
    </Box>
  );
};
