import React from 'react';

import { PricingSummary } from '@utils/hooks/pricing/use_pricing_summary';

// i.e. "an" 8-month vs "a" 4-month
const Article: React.FC<{ commitment: number }> = ({ commitment }) =>
  commitment === 8 ? <>an</> : <>a</>;

const Commitments: React.FC<{
  commitments: number[];
}> = ({ commitments }) => {
  if (commitments.length === 0) return null;

  const terms = commitments
    .map((duration) => `${duration} month+`)
    .join(' or ');

  return (
    <>
      <Article commitment={commitments[0]} /> {terms} term
    </>
  );
};

export const FreePickupSummary: React.FC<{
  pricingSummary: PricingSummary;
}> = ({
  pricingSummary: {
    commitmentLength,
    freePickupCommitments,
    selectedCommitmentHasFreePickup,
  },
}) => (
  <>
    {selectedCommitmentHasFreePickup ? (
      <>
        You are getting a <b>FREE</b> pickup by choosing{' '}
        <Article commitment={commitmentLength} /> {commitmentLength} month+
        term.
      </>
    ) : (
      <>
        Get a <b>FREE</b> pickup if you change to{' '}
        <Commitments commitments={freePickupCommitments} />.
      </>
    )}
  </>
);
