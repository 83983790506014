import * as React from 'react';

import { useDeferredImage } from '@utils/hooks';

const EMPTY_IMAGE_URI =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const DeferredImage: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = (props) => {
  const { resolvedImage, targetRef } = useDeferredImage(
    props.src!,
    props.srcSet,
  );
  return (
    <img
      {...props}
      src={resolvedImage?.src ?? EMPTY_IMAGE_URI}
      srcSet={resolvedImage?.srcSet}
      ref={targetRef}
      alt={props.alt ?? ''}
    />
  );
};
