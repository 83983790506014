import React from 'react';

import { capitalize } from 'lodash-es';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { Box, COLORS } from '@clutter/clean';
import { Location__Service, useRouteQuery } from '@graphql/platform';
import { BreadcrumbItem } from '@shared/breadcrumb_item';
import { BreadcrumbList } from '@shared/breadcrumb_list';
import { ButtonCTA } from '@shared/button_cta';
import { NoWrap } from '@shared/formatters/no_wrap';
import { ServiceSelection } from '@shared/service_selector/constants';

import { CityMap } from './shared/city_map';
import { Content } from './shared/content';
import { Hero } from './shared/hero';
import { Layout } from './shared/layout';
import { NeighborhoodLinks } from './shared/neighborhood_links';
import { Perks } from './shared/perks';
import { Reviews } from './shared/reviews';
import { SERVICE_TO_ENUM } from './shared/service';
import { ServiceOverview } from './shared/service_overview';
import {
  buildRouteBreadcrumbData,
  buildRouteMetaData,
  parseRouteQuery,
} from './shared/structured_data';
import { locationsURL } from './routes';

type RouteParams = {
  state: string;
  city?: string;
  route: string;
  service: 'storage' | 'moving';
};

const formattedLocation = (location: any) => {
  if (location.__typename === 'Location__State') {
    return location.name;
  } else if (location.__typename === 'Location__City') {
    return `${location.name}, ${location.state.name}`;
  } else if (location.__typename === 'Location__Neighborhood') {
    return `${location.name}, ${location.city.state.name}`;
  }
};

export const Route = () => {
  const params = useParams<RouteParams>();

  const { data } = useRouteQuery({
    variables: {
      route: params.route,
      service: SERVICE_TO_ENUM[params.service],
    },
  });

  const route = data?.route;

  if (!route) return null;

  const { content, origin, destination } = route;

  const service =
    params.service === 'storage'
      ? Location__Service.SmartStorage
      : Location__Service.Moving;

  // We can add reviews to a route but we'll be using reviews
  // from the origin city/neighborhood
  const originReviews =
    origin.__typename === 'Location__City' ||
    origin.__typename === 'Location__Neighborhood'
      ? origin.content?.reviews
      : [];

  const { state, city } = parseRouteQuery(route);

  const metadata = buildRouteMetaData(route, params.service);
  const title = metadata.title;
  const description = metadata.description;

  return (
    <>
      <HelmetProvider context={global.helmetContext}>
        <Helmet>
          <title>{title}</title>
          {description && <meta name="description" content={description} />}
          <script type="application/ld+json">
            {buildRouteBreadcrumbData(route, params.service)}
          </script>
        </Helmet>
      </HelmetProvider>
      <Layout>
        <Box maxWidth="1600px" margin="0 auto 80px" padding="0 24px">
          <Hero
            headline={content?.headline}
            heroAlt={content?.heroAlt}
            heroImage={content?.heroImage}
            locationName={origin.name}
            destinationLocationName={destination.name}
            service={service}
          />
          <Box margin="32px 0 0">
            <BreadcrumbList>
              <BreadcrumbItem href="/">Clutter</BreadcrumbItem>
              <BreadcrumbItem href={locationsURL()}>Locations</BreadcrumbItem>
              <BreadcrumbItem>
                {capitalize(params.service)} from {formattedLocation(origin)} to{' '}
                {formattedLocation(destination)}
              </BreadcrumbItem>
            </BreadcrumbList>
          </Box>
        </Box>
        {content && (
          <Layout.Section padding="0 0 80px">
            <Content content={content} />
          </Layout.Section>
        )}
        <Layout.Section
          textAlign="center"
          margin="0 0 80px"
          padding="80px"
          background={COLORS.grayBackground}
        >
          <Perks service={service} />
          <ButtonCTA
            container="perks"
            label="Get a Quote"
            entryPoint="location_page_cta"
            service={
              params.service === 'moving'
                ? ServiceSelection.Moving
                : ServiceSelection.Storage
            }
          />
        </Layout.Section>
        <Layout.Section margin="0 0 80px">
          <ServiceOverview
            title={
              <>
                {origin.name} {params.service} you <NoWrap>can trust</NoWrap>
              </>
            }
            subtitle="Get a transparent quote in 2 minutes."
            service={service}
            isRoute={true}
          />
        </Layout.Section>
        {originReviews?.length && (
          <Layout.Section background={COLORS.grayBackground} padding="88px 0">
            <Reviews reviews={originReviews} locationName={origin.name} />
          </Layout.Section>
        )}
        {origin.__typename !== 'Location__State' && !!state && !!city && (
          <Layout.Section padding="80px 0">
            <CityMap state={state} city={city} />
            <Box margin="80px 0 0">
              <NeighborhoodLinks
                state={state}
                city={city}
                neighborhoods={city.neighborhoods}
                service={params.service}
              />
            </Box>
          </Layout.Section>
        )}
      </Layout>
    </>
  );
};
