import React, { useState } from 'react';

import styled from '@emotion/styled';
import { DateTime } from 'luxon';

import { Box, COLORS, Text, UnstyledButton } from '@clutter/clean';
import { AddressFragment } from '@graphql/platform';
import { StorageCheckoutData } from '@root/components/checkout/data';
import {
  CheckoutType,
  StorageCheckoutStep,
  StorageCheckoutStepProps,
} from '@root/components/checkout/types';
import { Address } from '@shared/formatters/address';

import { BookingDetailsGrid } from './booking_details_grid';
import { BookingDetailsItem } from './booking_details_item';
import { EditContactModal } from './edit_contact_modal';

const LinkText = styled(Text.Callout)`
  text-decoration: underline;
`;

export const SmartStorageBookingDetails = ({
  values,
  onEdit,
  onChange,
  onCancellationLinkClick,
}: {
  values: StorageCheckoutData;
  onEdit(step: StorageCheckoutStep): void;
  onChange: StorageCheckoutStepProps['onChange'];
  onCancellationLinkClick(): void;
}) => {
  const appointmentDateTime = values.dateScheduled
    ? DateTime.fromISO(values.dateScheduled.fromTime)
    : // This branch is an unlikely error state so incorrect data _might_ be rendered,
      // but it will be behind a modal conveying the error.
      values.datePreferred || DateTime.local();
  const cancelByDate = appointmentDateTime.minus({ days: 2 }).toFormat('MMM d');
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Box margin="0 0 16px">
        <Text.Title size="extraSmall">Review booking details</Text.Title>
        <Text.Callout color={COLORS.storm}>
          Free cancellation before {cancelByDate}.{' '}
          <UnstyledButton onClick={() => onCancellationLinkClick()}>
            <LinkText>Cancellation policy</LinkText>
          </UnstyledButton>
        </Text.Callout>
      </Box>
      <BookingDetailsGrid columns={3}>
        <BookingDetailsItem
          label="Contact"
          editable={values.checkoutType !== CheckoutType.Reonboarding}
          onEdit={() => setModalOpen(true)}
        >
          {values.name}
          <br />
          {values.email}
          <br />
          {values.phone}
        </BookingDetailsItem>
        <BookingDetailsItem
          label="Pickup Address"
          onEdit={() => onEdit(StorageCheckoutStep.AddressAndLeadInfo)}
        >
          <Address address={(values.address as AddressFragment) ?? {}} />
        </BookingDetailsItem>
        <BookingDetailsItem
          label="Date & arrival time"
          onEdit={() => onEdit(StorageCheckoutStep.Appointment)}
        >
          {values.datePreferred?.toLocaleString({
            day: 'numeric',
            weekday: 'short',
            month: 'long',
          })}
          <br />
          {values.dateScheduled?.formatted}
        </BookingDetailsItem>
      </BookingDetailsGrid>
      <EditContactModal
        isOpen={modalOpen}
        onChange={onChange}
        values={{ name: values.name, email: values.email, phone: values.phone }}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};
