import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Modal, mq, UnstyledButton } from '@clutter/clean';
import { XButton } from '@root/components/icons/x_button';

const Container = styled.div`
  position: relative;
  max-height: min(600px, 80vh);
  overflow-y: auto;
  padding: 64px 24px 48px;
  ${mq({
    width: ['calc(100vw - 32px)', 'initial'],
    minWidth: [null, '580px'],
  })}
`;

export const PolicyModal: React.FC<{
  isOpen: boolean;
  children: React.ReactNode;
  handleModalClose(): void;
}> = ({ isOpen, handleModalClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleModalClose={() => handleModalClose()}
      backgroundColor={COLORS.tealBackground}
      color={COLORS.tealDark}
    >
      <Container>
        <Box textAlign="center">
          <Box position="absolute" top="24px" right="24px">
            <UnstyledButton onClick={handleModalClose}>
              <XButton />
            </UnstyledButton>
          </Box>
          {children}
        </Box>
      </Container>
    </Modal>
  );
};
