import { useMemo } from 'react';

import { DateTime } from 'luxon';

import { useLatestRef } from '@clutter/hooks';

export const useAvailabilitySummary = ({
  from,
  till,
  currentDay,
  availableDays,
  isUnavailable,
}: {
  from: DateTime;
  till: DateTime;
  currentDay: DateTime;
  availableDays?: Set<string>;
  isUnavailable: (
    value: DateTime,
    availableDays: Set<string> | undefined,
  ) => boolean;
}) => {
  const timesRef = useLatestRef({ from, till, currentDay });

  return useMemo<boolean[]>(() => {
    const { from, till, currentDay } = timesRef.current;
    const startDay = DateTime.max(from, currentDay.plus({ days: 1 }));
    const summary = [];

    for (
      let date: DateTime = startDay;
      date < till;
      date = date.plus({ days: 1 })
    ) {
      summary.push(!isUnavailable(date, availableDays));
    }
    return summary;
  }, [isUnavailable, availableDays, timesRef]);
};
