import * as React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, COLORS, mq, ResponsiveContainer } from '@clutter/clean';
import { useTrack } from '@root/initializers/wt';
import { ContactPhoneLink } from '@shared/contact_phone_link';
import { DesktopOnly, MobileOnly } from '@shared/screen_size_filters';

import { IMenuItem, Menu } from './menu';

import hamburgerMenu from '../../../images/icons/hamburger_menu.svg';
import phoneIcon from '../../../images/www/contact_phone_dark_teal.svg';
import { Logo } from '@images/svg/logo';

interface IHeaderProps {
  template?: string;
  themeName?: 'teal' | 'darkTeal';
  hidePhone?: boolean;
  onClick?(e: React.SyntheticEvent<HTMLAnchorElement>): void;
  menuItems?: IMenuItem[];
}

const HeaderBackground = styled.div`
  height: 90px;

  ${mq({
    background: ['transparent', 'white'],
  })}
`;

const HeaderContainer = styled(ResponsiveContainer)`
  height: 90px;
  width: 100%;
  display: flex;

  z-index: 100;

  & img {
    display: block;
  }

  ${mq({
    padding: ['28px 24px 12px', '0'],
  })}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
`;

const CallLink = styled.span`
  border-radius: 4px;
  border: solid 1px ${COLORS.tealDark};
  padding: 10px 20px;

  ${mq({
    position: [null, 'absolute'],
    right: [null, '16px'],
  })}

  & > a {
    font-weight: bold;
    font-size: 16px;
    color: ${COLORS.tealDark};
    text-decoration: none;

    @media (min-width: ${BREAKPOINTS.SM}) {
      border: none;
      padding: none;
    }
  }
`;

const LinksContainer = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: ${COLORS.tealDark};
  margin-right: 264px;
  ${mq({ display: ['none', 'inline'] })};
`;

const MenuLink = styled.a`
  color: ${COLORS.tealDark};
  margin-right: 24px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${COLORS.tealDark};
  }
`;

const HamburgerMenuContainer = styled.div`
  margin: 16px 0px 0px 24px;
  ${mq({ display: ['inline', 'none'] })};
`;

const ClutterLogo = styled(Logo)`
  ${mq({
    width: ['84px', '128px'],
  })}
`;

const MenuLinks: React.FC<{ menuItems: IMenuItem[] }> = ({ menuItems }) => {
  return (
    <LinksContainer>
      {menuItems.map((item, index) => {
        return (
          <MenuLink key={index} href={item.href}>
            {item.label}
          </MenuLink>
        );
      })}
    </LinksContainer>
  );
};

const CallNow: React.FC<{ template?: string }> = ({ template }) => {
  const track = useTrack();
  return (
    <CallLink>
      <ContactPhoneLink
        onClick={(e) =>
          track({
            label: e.currentTarget.innerText,
            value: e.currentTarget.href,
            container: 'header_mobile',
            objectType: 'link',
            objectName: 'call_us_button',
            action: 'click',
            metadata: { template },
          })
        }
      >
        {(num) => (
          <>
            <DesktopOnly>Call Now: {'1 ' + num}</DesktopOnly>
            <MobileOnly>
              <img src={phoneIcon} height="16" width="16" alt="Contact Phone" />
            </MobileOnly>
          </>
        )}
      </ContactPhoneLink>
    </CallLink>
  );
};

const Header: React.FC<IHeaderProps> = ({
  template,
  themeName,
  hidePhone = false,
  onClick,
  menuItems,
}) => {
  const [fullScreenMenu, setFullScreenMenu] = React.useState(false);

  if (fullScreenMenu && menuItems) {
    return (
      <Menu
        onCloseClick={() => setFullScreenMenu(false)}
        menuItems={menuItems}
      />
    );
  }

  return (
    <HeaderBackground>
      <HeaderContainer>
        <Content>
          <span>
            <a href="/" onClick={onClick}>
              <ClutterLogo
                fillColor={
                  themeName === 'teal' ? COLORS.tealPrimary : COLORS.tealDark
                }
              />
            </a>
          </span>
          {menuItems && <MenuLinks menuItems={menuItems} />}
          {!hidePhone && <CallNow template={template} />}
        </Content>
        {menuItems && (
          <HamburgerMenuContainer>
            <img
              src={hamburgerMenu}
              onClick={() => setFullScreenMenu(true)}
              alt="Menu"
            />
          </HamburgerMenuContainer>
        )}
      </HeaderContainer>
    </HeaderBackground>
  );
};

export { Header };
