import React from 'react';

import { Box } from '@clutter/clean';

export const FeatureGrid: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box.Grid
    gridGap={12}
    gridTemplateColumns={['1fr 1fr', 'repeat(auto-fit, minmax(80px, 1fr))']}
  >
    {children}
  </Box.Grid>
);
