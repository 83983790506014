import { usePricingSetQuery } from '@graphql/platform';
import { WT_VISITOR_TOKEN } from '@root/initializers/wt';

export const usePricingSetForZIP = (zip: string | undefined) =>
  usePricingSetQuery({
    variables: {
      zip: zip!,
      visitorToken: WT_VISITOR_TOKEN,
    },
    skip: !zip || zip.length < 5,
    ssr: false,
  }).data?.pricingSet;
