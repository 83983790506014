import React from 'react';

import { Box, COLORS, FontWeight, Text, UnstyledButton } from '@clutter/clean';

export const BookingDetailsItem: React.FC<{
  children: React.ReactNode;
  label: React.ReactNode;
  editable?: boolean;
  onEdit(): void;
}> = ({ children, label, editable = true, onEdit }) => {
  return (
    <Box padding="12px 16px">
      <Box.Flex justifyContent="space-between" margin="0 0 2px">
        <Text.Callout weight={FontWeight.Medium}>{label}</Text.Callout>
        {editable && (
          <UnstyledButton
            onClick={(e) => {
              e.preventDefault();
              onEdit();
            }}
          >
            <Text.Callout weight={FontWeight.Medium} color={COLORS.tealPrimary}>
              Edit
            </Text.Callout>
          </UnstyledButton>
        )}
      </Box.Flex>
      <Text.Callout>{children}</Text.Callout>
    </Box>
  );
};
