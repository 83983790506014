import React from 'react';

import { Box, SkeletonLoader } from '@clutter/clean';

export const SummaryItemLoader = () => (
  <Box.Flex height="66px">
    <SkeletonLoader width="48px" height="48px" />
    <Box.Flex flexGrow={1} margin="0 0 0 8px" flexDirection="column">
      <SkeletonLoader height="50%" margin="0 0 8px" />
      <SkeletonLoader height="50%" />
    </Box.Flex>
  </Box.Flex>
);
