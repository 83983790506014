import { ApolloClient, FieldPolicy, from, InMemoryCache } from '@apollo/client';
import stringify from 'fast-json-stable-stringify';

import { API_BASE_URL } from '@root/config/constants';

import { buildErrorLink } from './build_error_link';
import { buildHttpLink } from './build_http_link';
import { dedupeBy } from './dedupe_by';

const AVAILABILITY_FIELD_POLICY: FieldPolicy = {
  keyArgs: (variables) => {
    return stringify(variables!.input.order);
  },
  merge(existing, incoming) {
    if (!existing) return incoming;
    return dedupeBy([...existing, ...incoming], 'fromTime');
  },
};

export const buildPlatformApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            availabilities: AVAILABILITY_FIELD_POLICY,
            longDistanceAvailabilities: AVAILABILITY_FIELD_POLICY,
          },
        },
      },
    }),
    link: from([buildErrorLink(), buildHttpLink(`${API_BASE_URL}/graphql`)]),
  });
