import { debounce } from 'lodash-es';

declare const window: {
  clutter: {
    systemSpecStubs: Record<string, any>;
  };
};

interface AutocompleteService {
  geocodeSuggestion: (
    _: google.maps.places.AutocompletePrediction,
    callback: (result: google.maps.GeocoderResult | null) => void,
  ) => void;

  getSuggestions: (
    _: string,
    callback: (
      suggestions: google.maps.places.AutocompletePrediction[] | null,
    ) => void,
    options: {
      componentRestrictions: google.maps.places.AutocompleteOptions['componentRestrictions'];
    },
  ) => void;
}

interface AutocompleteServiceConstructor {
  new (): AutocompleteService;
}

class StubbedAddressAutocompleteService implements AutocompleteService {
  public geocodeSuggestion = (
    _: google.maps.places.AutocompletePrediction,
    callback: (result: google.maps.GeocoderResult | null) => void,
  ) => {
    const result = window.clutter.systemSpecStubs.googleGeocode[0];
    const { lat, lng } = result.geometry.location;

    result.geometry.location = {
      ...result.geometry.location,
      lat: () => lat,
      lng: () => lng,
    };

    setTimeout(() => callback(result));
  };

  public getSuggestions = (
    _: string,
    callback: (
      suggestions: google.maps.places.AutocompletePrediction[] | null,
    ) => void,
  ) => {
    callback(window.clutter.systemSpecStubs.googleAutocomplete || []);
  };
}

class GoogleAddressAutocompleteService implements AutocompleteService {
  private autocompleteService: google.maps.places.AutocompleteService;
  private geocoder: google.maps.Geocoder;

  constructor() {
    this.geocoder = new google.maps.Geocoder();
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  public geocodeSuggestion = (
    prediction: google.maps.places.AutocompletePrediction,
    callback: (result: google.maps.GeocoderResult | null) => void,
  ) => {
    this.geocoder.geocode({ placeId: prediction.place_id }, (result) =>
      callback(result ? result[0] : null),
    );
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getSuggestions = debounce(
    (
      input: string,
      callback: (
        suggestions: google.maps.places.AutocompletePrediction[] | null,
      ) => void,
      options: {
        componentRestrictions: google.maps.places.AutocompleteOptions['componentRestrictions'];
      },
    ) => {
      this.autocompleteService.getPlacePredictions(
        {
          input,
          types: ['address'],
          componentRestrictions: options.componentRestrictions,
        },
        callback,
      );
    },
  );
}

export const AddressAutocompleteService: AutocompleteServiceConstructor =
  process.env.NODE_ENV === 'test'
    ? StubbedAddressAutocompleteService
    : GoogleAddressAutocompleteService;
