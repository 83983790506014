import React, { createContext, useContext } from 'react';

import { AbeetContextValue } from '@clutter/abeet';

import { ServiceEnum } from '../../resources/types/service';

interface IServerData {
  // LP Specific
  isHomepage?: boolean;

  // Shared
  tests: AbeetContextValue['tests'];
  potentialServices: ServiceEnum[];
  gmbUrl?: string;
  yelpUrl?: string;
  device: 'desktop' | 'mobile';
  inferredZipCode?: string;
  inferredRegion?: string;
}

const ServerDataContext = createContext<IServerData>({
  tests: {},
  potentialServices: [ServiceEnum.DoorToDoor],
  device: 'desktop',
});

export const ServerDataProvider: React.FC<{
  value: IServerData;
  children: React.ReactNode;
}> = ({ value, children }) => (
  <ServerDataContext.Provider value={value}>
    {children}
  </ServerDataContext.Provider>
);

export const useServerDataContext = () => useContext(ServerDataContext);

export { ServerDataContext, IServerData };
