import React from 'react';

export const Currency: React.FC<{
  amount: number | string;
  precision?: 0 | 2 | 'automatic';
  suffix?: React.ReactNode;
}> = ({ amount, precision = 'automatic', suffix }) => {
  const number = Number(amount);
  const digits =
    precision === 'automatic'
      ? Math.trunc(number) === amount
        ? 0
        : 2
      : precision;
  const formatted = Math.abs(number)
    .toFixed(digits)
    .replace(/\d(?=(\d{3})+(?:\.|$))/g, '$&,'); // Add commas via dark arts
  return (
    <>
      {number < 0 && '-'}${formatted}
      {suffix}
    </>
  );
};
