import React, { useState } from 'react';

import { Box, COLORS, Text } from '@clutter/clean';
import { RateGroupKindEnum, useGeocodeQuery } from '@graphql/platform';
import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { HourlyRateCard } from '@root/components/checkout/product_pages/subcomponents/onboarding_fees/hourly_rate_card';
import { HourlyRateCardChallenger } from '@root/components/checkout/product_pages/subcomponents/onboarding_fees/hourly_rate_card_challenger';
import { PLANS } from '@root/constants/pricing';
import { useTrack } from '@root/initializers/wt';
import { PRICING_TEST_INELIGIBLE_REGIONS } from '@utils/hooks/ab_testing';

import { Overlay, OverlayContentKind } from '../fees/overlay';

import { EducationCard } from './education_card';
import { PickupFeeCard } from './pickup_fee_card';

export const OnboardingFees: React.FC<{
  showPrice: boolean;
}> = ({ showPrice }) => {
  const [overlayContentKind, setOverlayContentKind] = useState<
    OverlayContentKind | undefined
  >();
  const {
    pricingSummary,
    flowState: {
      values: { zip, planSize, commitment },
    },
  } = useStorageCheckoutContext();
  const track = useTrack({
    action: 'click',
  });

  const planSizeName = planSize ? PLANS[planSize].label : undefined;

  const onContentKindChange = (
    event?: React.MouseEvent<HTMLElement>,
    contentKind?: OverlayContentKind,
  ) => {
    setOverlayContentKind(contentKind);

    if (contentKind) {
      const label = event!.currentTarget.innerText;
      track({
        objectName: 'learn_more_button',
        objectType: 'button',
        container: 'onboarding_fee_card',
        label,
      });
    }
  };

  const noPickupFee = pricingSummary?.onboardingFeeAmount === 0;
  const HourlyRateComponent = noPickupFee
    ? HourlyRateCardChallenger
    : HourlyRateCard;

  const { data } = useGeocodeQuery({
    variables: {
      zip: zip || '',
    },
    skip: !zip,
  });

  const regionName = data?.geocode?.region?.name;

  if (
    noPickupFee &&
    commitment !== RateGroupKindEnum.Mover &&
    !!regionName &&
    !PRICING_TEST_INELIGIBLE_REGIONS.includes(regionName)
  ) {
    return <EducationCard />;
  }

  return (
    <>
      <Box>
        <Box margin="0 0 16px">
          <Text.Title size="small" color={COLORS.tealDark}>
            {planSizeName
              ? `Your pickup rate for ${planSizeName} storage`
              : 'Your pickup rate'}
          </Text.Title>
        </Box>
        <Box.Flex flexDirection="column">
          <HourlyRateComponent
            pricingSummary={pricingSummary}
            showPrice={showPrice}
            onModalLinkClick={(e) =>
              onContentKindChange(e, OverlayContentKind.SmartStorageHourlyRate)
            }
            planSizeName={planSizeName}
          />
          <Box
            border={`1px ${COLORS.grayBorder}`}
            borderStyle="solid none none"
            margin={['16px 0', '20px 0']}
          />
          {noPickupFee ? null : (
            <PickupFeeCard
              pricingSummary={pricingSummary}
              showPrice={showPrice}
              onModalLinkClick={(e) =>
                onContentKindChange(e, OverlayContentKind.WarehouseFee)
              }
              planSizeName={planSizeName}
            />
          )}
        </Box.Flex>
      </Box>
      <Overlay
        contentKind={overlayContentKind}
        onClose={() => onContentKindChange()}
      />
    </>
  );
};
