import React from 'react';

import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import {
  Box,
  COLORS,
  FontWeight,
  ProgressBar,
  Text,
  UnstyledButton,
} from '@clutter/clean';
import {
  DraftFragment,
  Lead__ServiceNeeds,
  Maybe,
  useGeocodeQuery,
} from '@graphql/platform';
import { useTrack } from '@root/initializers/wt';
import { generateLeadSource } from '@root/resources/lead';
import { WWW_ROUTES } from '@root/root/routes';
import { Action, useClientDataContext } from '@shared/client_data_context';
import { CustomLink } from '@shared/custom_link';
import { getPageName } from '@utils/get_pagename_utilities';

import { Map } from './map';

import arrowUp from '@images/icons/arrow_up.svg';

function progressPercentage(draft: DraftFragment) {
  let steps: any[];
  if (draft.latestService === Lead__ServiceNeeds.SmartStorage) {
    steps = [
      draft.plan,
      draft.address?.street,
      draft.preferredTime,
      draft.protectionPlanSlug,
    ];
  } else if (draft.latestService === Lead__ServiceNeeds.Moving) {
    steps = [
      draft.movingOriginAttributes?.buildingType,
      draft.movingOriginAttributes?.street,
      draft.movingDestinationAttributes?.street,
      draft.movingQuote?.packing,
      draft.preferredTime,
    ];
  } else {
    return 0;
  }

  const currentStep = steps
    .map((val) => (val ?? null) !== null)
    .lastIndexOf(true);

  return (
    Math.max(currentStep, 0) / steps.length +
    // Offset completion percentage by half a "step" as we don't want to show a
    // completely empty/full progress bar
    0.5 / steps.length
  );
}

const ServiceHeader: React.FC<{ service?: Maybe<Lead__ServiceNeeds> }> = ({
  service,
}) => {
  return (
    <Box.Flex width="100%" justifyContent="space-between">
      <Box.FlexItem>
        <Text.SmallCaps>
          {service === Lead__ServiceNeeds.Moving ? 'Moving' : 'Storage'}
        </Text.SmallCaps>
      </Box.FlexItem>
      <Box.FlexItem>
        <UnstyledButton>
          <Box.Flex gap="8px" textAlign="right">
            <Text.Callout color={COLORS.tealPrimary} weight={FontWeight.Medium}>
              Continue
            </Text.Callout>
            <img
              src={arrowUp}
              width="16"
              height="8"
              alt=""
              style={{ transform: 'rotate(90deg)' }}
            />
          </Box.Flex>
        </UnstyledButton>
      </Box.FlexItem>
    </Box.Flex>
  );
};

const StartedContent: React.FC<{ draft: DraftFragment; timezone?: string }> = ({
  draft,
  timezone,
}) => {
  const draftStartedAt =
    draft.latestService === Lead__ServiceNeeds.Moving
      ? draft.movingQuoteStartedAt
      : draft.storageQuoteStartedAt;
  return (
    <Box padding={['16px 8px', '16px 24px']} width="100%">
      <ServiceHeader service={draft.latestService} />
      <Text.Body weight={FontWeight.Medium}>
        Reservation started <br />
        {draftStartedAt && (
          <>
            on{' '}
            {DateTime.fromISO(draftStartedAt || DateTime.now().toISO())
              .setZone(timezone)
              .toLocaleString({
                day: 'numeric',
                weekday: 'long',
                month: 'long',
              })}
          </>
        )}
      </Text.Body>
      <Box width="100%" margin="12px 0 0">
        <ProgressBar percentage={progressPercentage(draft)} />
      </Box>
    </Box>
  );
};

const InProgressContent: React.FC<{
  draft: DraftFragment;
  timezone?: string;
}> = ({ draft, timezone }) => {
  const cityName =
    draft.latestService === Lead__ServiceNeeds.Moving
      ? draft.movingOriginAttributes?.city
      : draft.address?.city;

  const stateName =
    draft.latestService === Lead__ServiceNeeds.Moving
      ? draft.movingOriginAttributes?.state
      : draft.address?.state;
  return (
    <Box.Flex>
      <Box.FlexItem>
        <Map draft={draft} />
      </Box.FlexItem>
      <Box.FlexItem padding={['16px 8px', '16px 24px']} width="100%">
        <ServiceHeader service={draft.latestService} />
        <Text.Body weight={FontWeight.Medium}>
          {DateTime.fromISO(draft?.preferredTime || DateTime.now().toISO())
            .setZone(timezone)
            .toLocaleString({
              day: 'numeric',
              weekday: 'long',
              month: 'long',
            })}
        </Text.Body>
        <Text.Callout>
          {DateTime.fromISO(draft?.preferredTime || DateTime.now().toISO())
            .setZone(timezone)
            .toLocaleString({
              minute: '2-digit',
              hour: 'numeric',
            })}
        </Text.Callout>
        <Text.Callout>
          {cityName}, {stateName}
        </Text.Callout>
        <Box width="100%">
          <ProgressBar percentage={progressPercentage(draft)} />
        </Box>
      </Box.FlexItem>
    </Box.Flex>
  );
};

export const DraftContent: React.FC<{
  draft: DraftFragment;
}> = ({ draft }) => {
  const { data } = useGeocodeQuery({
    variables: {
      zip: draft.address?.zip ?? draft.movingOriginAttributes?.zip ?? '',
    },
    skip: !draft.address?.zip && !draft.movingOriginAttributes?.zip,
  });
  const timezone = data?.geocode?.region?.tz ?? undefined;
  const nearComplete =
    draft.preferredTime && (draft.address || draft.movingOriginAttributes);

  const path =
    draft.latestService === Lead__ServiceNeeds.Moving
      ? WWW_ROUTES.PRODUCT_PAGE_MOVING
      : WWW_ROUTES.PRODUCT_PAGE_STORAGE;

  const { dispatch } = useClientDataContext();
  const history = useHistory();
  const track = useTrack({
    action: 'click',
    container: 'cart_preview',
    objectName: 'cart_draft_card',
    objectType: 'link',
    value: path,
  });

  const zip =
    (draft.latestService === Lead__ServiceNeeds.Moving
      ? draft.movingOriginAttributes?.zip
      : draft.address?.zip) ?? undefined;

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    track({
      metadata: { draft_id: draft.id },
    });
    dispatch({
      type: Action.SetupCheckout,
      payload: {
        leadSource: generateLeadSource({
          domain: 'www',
          pageName: getPageName(),
          entryPoint: 'cart_preview',
        }),
        zip,
        draft: draft,
      },
    });
    history.push(path);
  };

  return (
    <Box
      color={COLORS.panther}
      border={`1px solid ${COLORS.grayBorder}`}
      borderRadius="4px"
      width={['100%', null, '424px']}
    >
      <CustomLink to={path} onClick={onClick}>
        {nearComplete ? (
          <InProgressContent draft={draft} timezone={timezone} />
        ) : (
          <StartedContent draft={draft} timezone={timezone} />
        )}
      </CustomLink>
    </Box>
  );
};
