import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';

const TitleContainer = styled.div`
  text-align: center;
  padding: 28px 0 18px;
  border-bottom: 1.5px solid ${COLORS.grayBorder};
`;

const Table = styled.table<{ borderColor: string }>`
  width: 100%;
  border-collapse: collapse;

  tr {
    border-right: 1.5px solid ${COLORS.cloud};
    border-bottom: 1.5px solid ${({ borderColor }) => borderColor};
    tbody &:last-of-type {
      border-bottom: none;
    }
  }

  th,
  td {
    padding: 12px 0;
    text-align: center;
  }
`;

const Row: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <tr>
    <td>
      <Text.Caption>{children}</Text.Caption>
    </td>
  </tr>
);

const SmartStorageFeatures = () => (
  <Table borderColor={COLORS.cloud}>
    <thead>
      <tr>
        <th>
          <Text.Body>
            <b>Clutter Smart Storage</b>
            <br />
            Starting at $50/mo (in select cities)
          </Text.Body>
        </th>
      </tr>
    </thead>
    <tbody>
      <Row>
        Pickups & deliveries
        <br />
        <b>Starting at $69</b>
      </Row>
      <Row>
        We handle the heavy lifting
        <br />
        <b>Included!</b>
      </Row>
      <Row>
        We provide bins, bags, and blankets
        <br />
        <b>$0</b>
      </Row>
      <Row>
        Great low rates
        <br />
        <b>Starting at $50/mo</b>
      </Row>
      <Row>
        Never leaving your home to visit a dusty storage facility
        <br />
        <b>Priceless!</b>
      </Row>
    </tbody>
  </Table>
);

const TraditionalStorageFeatures = () => (
  <Table borderColor={COLORS.grayBorder}>
    <thead>
      <tr>
        <th>
          <Text.Body>
            <b>Traditional Storage</b>
            <br />
            $120/mo $800+ in extras
          </Text.Body>
        </th>
      </tr>
    </thead>
    <tbody>
      <Row>
        Truck rental + gas
        <br />
        <b>$200</b>
      </Row>
      <Row>
        Hiring movers
        <br />
        <b>$400</b>
      </Row>
      <Row>
        Packing supplies
        <br />
        <b>$200</b>
      </Row>
      <Row>
        High storage rates
        <br />
        <b>Approx $120+</b>
      </Row>
      <Row>
        Spending hours + energy
        <br />
        <b>$$$$</b>
      </Row>
    </tbody>
  </Table>
);

export const TraditionalStorageComparison: React.FC = () => (
  <Box height={[null, '480px']}>
    <TitleContainer>
      <Text.Title size="small">Traditional storage Vs Smart storage</Text.Title>
    </TitleContainer>
    <Box.Flex
      borderRadius="10px"
      flexDirection={['column-reverse', 'row']}
      height="100%"
      color={COLORS.panther}
      textAlign="center"
    >
      <Box.FlexItem
        background={COLORS.grayBackground}
        flexShrink={0}
        flexBasis={['100%', '50%']}
      >
        <TraditionalStorageFeatures />
      </Box.FlexItem>
      <Box.FlexItem
        background={COLORS.tealBackground}
        flexShrink={0}
        flexBasis={['100%', '50%']}
      >
        <SmartStorageFeatures />
      </Box.FlexItem>
    </Box.Flex>
  </Box>
);
