import { HttpLink } from '@apollo/client';
import fetch from 'unfetch';

export const buildHttpLink = (uri: string) =>
  new HttpLink({
    uri: ({ operationName }) =>
      ` ${uri}${
        process.env.NODE_ENV === 'development' ? '?op=' + operationName : ''
      }`,
    fetch,
    credentials: 'include',
  });
