import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Box, Button, COLORS, Modal, Text } from '@clutter/clean';
import {
  MovingCheckoutData,
  StorageCheckoutData,
} from '@root/components/checkout/data';
import { fetchBookedFlag } from '@root/components/checkout/utilities/persistence';
import { WWW_ROUTES } from '@root/root/routes';
import { reportMessage } from '@utils/monitoring';

const REQUIRED_STORAGE_FIELDS: Array<[keyof StorageCheckoutData, string]> = [
  ['name', 'Full name'],
  ['email', 'Email address'],
  ['address', 'Pickup address'],
  ['dateScheduled', 'Pickup date & time'],
];

const REQUIRED_MOVING_FIELDS: Array<[keyof MovingCheckoutData, string]> = [
  ['name', 'Full name'],
  ['email', 'Email address'],
  ['startAddress', 'Start address'],
  ['endAddress', 'End address'],
  ['dateScheduled', 'Appointment date & time'],
];

export const resolveMissingFields = (
  values: Record<string, any>,
  fields: Array<[string, string]>,
) => {
  return fields
    .map(([key, name]) => (values[key] === undefined ? name : null))
    .filter((v): v is string => v !== null);
};

export const MissingInformationModal = ({
  service,
  values,
}: {
  service: 'moving' | 'storage';
  values: MovingCheckoutData | StorageCheckoutData;
}) => {
  const [showBookedModal] = useState(fetchBookedFlag);
  const missingFields = resolveMissingFields(
    values,
    service === 'moving' ? REQUIRED_MOVING_FIELDS : REQUIRED_STORAGE_FIELDS,
  );
  const history = useHistory();

  const showModal = !showBookedModal && missingFields.length > 0;

  useEffect(() => {
    if (showModal) {
      reportMessage(
        `Cart viewed with missing information. Fields: ${missingFields.join(
          ', ',
        )}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const goBack = () =>
    history.length > 0
      ? history.goBack()
      : history.replace(
          service === 'moving'
            ? WWW_ROUTES.PRODUCT_PAGE_MOVING
            : WWW_ROUTES.PRODUCT_PAGE_STORAGE,
        );

  return (
    <Modal isOpen={showModal} includeCloseButton handleModalClose={goBack}>
      <Box padding="24px" width={['calc(100vw - 32px)', 'initial']}>
        <Box padding="0 24px 0 0">
          <Text.Title size="extraSmall">
            We need a little bit more information
          </Text.Title>
        </Box>
        <Box margin="16px 0 0">
          <Text color={COLORS.storm}>
            Please go back and add the following information
          </Text>
          <Box.Flex gap="16px" margin="32px">
            {missingFields.map((field) => (
              <b key={field}>{field}</b>
            ))}
          </Box.Flex>
          <Box textAlign="right">
            <Button onClick={goBack}>Back</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
