import React from 'react';

import { Box, COLORS, Text } from '@clutter/clean';
import { CustomLink } from '@shared/custom_link';

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box.Grid
      gridTemplateColumns={[
        '1fr',
        '1fr 1fr',
        'repeat(3, 1fr)',
        'repeat(4, 1fr)',
      ]}
      gridGap="24px 32px"
    >
      {children}
    </Box.Grid>
  );
};

const Link: React.FC<{ to: string; children: React.ReactNode }> = ({
  to,
  children,
}) => {
  return (
    <Text.Button color={COLORS.tealPrimary} textDecoration="underline">
      <CustomLink to={to}>{children}</CustomLink>
    </Text.Button>
  );
};

export const LocationLinksLayout = Object.assign(Container, { Link });
