import React, { useState } from 'react';

import { ApolloProvider } from '@apollo/client';

import { buildPlatformApolloClient } from '@root/initializers/apollo/build_platform_apollo_client';
import { WTProvider } from '@root/initializers/wt';
import { LandingAbeetProvider } from '@shared/abeet';
import { ClientDataProvider } from '@shared/client_data_context';
import { IServerData, ServerDataProvider } from '@shared/server_data_context';
import { Layout } from '@utils/clean';
import { useHydrateApolloCache } from '@utils/hooks/use_hydrate_apollo_cache';

import { GlobalStyles } from './global_styles';

export const App: React.FC<{
  serverData: IServerData;
  children: React.ReactNode;
}> = ({ serverData, children }) => {
  const [client] = useState(buildPlatformApolloClient);
  useHydrateApolloCache(client);

  return (
    <ApolloProvider client={client}>
      <LandingAbeetProvider initialTestResults={serverData.tests}>
        <ServerDataProvider value={serverData}>
          <ClientDataProvider>
            <WTProvider params={{}}>
              <Layout>
                <GlobalStyles />
                {children}
              </Layout>
            </WTProvider>
          </ClientDataProvider>
        </ServerDataProvider>
      </LandingAbeetProvider>
    </ApolloProvider>
  );
};
