import { EventSchema } from '@root/resources/wt/types';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';

export const useZipValidatedFunnelEvent = () => {
  const track = useTrackFunnelEvents();

  return ({ zip, service }: { zip: string; service: 'storage' | 'moving' }) =>
    track({
      schema: EventSchema.WWW__ZipValidated,
      action: 'click',
      metadata: {
        service,
        zip,
      },
    });
};
