import { useGeocodeQuery } from '@graphql/platform';
import { useSharedCheckoutContext } from '@root/components/checkout/context';

import { useInferredRegion } from './use_inferred_region';

// Geocode the region based on the ZIP in the shared checkout context w/ a fallback.
// i.e. "Seattle" / "Los Angeles" / "New York" / etc.
export const useSharedCheckoutContextRegion = (): string | undefined => {
  const {
    flowState: {
      values: { zip },
    },
  } = useSharedCheckoutContext();

  const fallback = useInferredRegion();
  const { data } = useGeocodeQuery({
    variables: { zip: zip ?? '' },
    skip: !zip,
  });

  const region = data?.geocode?.region;
  return region ? region.name : fallback;
};
