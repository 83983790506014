import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, HEADLINE_STYLES, Text } from '@clutter/clean';

import moverWaving from '@images/illustrations/mover_waving_small.svg';
import moverWithRefridgerator from '@images/illustrations/mover_with_refrigerator.svg';
import truck from '@images/illustrations/moving_shuttle_van.svg';
import tablet from '@images/illustrations/online_quote_check.svg';
import protectionBadge from '@images/illustrations/protection_plan_standard_small.svg';
import tape from '@images/illustrations/tape_multi_small.svg';

export const Content = styled.div`
  padding: 16px;
  border: 1px solid ${COLORS.grayBorder};
  border-top: none;
  border-radius: 4px;

  h4 {
    ${HEADLINE_STYLES.SM};
    font-weight: ${FontWeight.Medium};
    margin-bottom: 8px;
  }

  p {
    margin-top: 16px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const LaborGridItem = styled.div`
  align-items: center;
  background: rgba(255 255 255 / 0.6);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 80px;
  padding: 8px;
  text-align: center;

  img {
    display: block;
    height: 32px;
    margin-bottom: 4px;
  }
`;

export const LaborContent: React.FC<{ flatRate: boolean }> = ({ flatRate }) => (
  <Content>
    <h4>
      <b>This is what your {flatRate ? 'flat' : 'hourly'} rate pays for:</b>
    </h4>
    <Box.Grid
      gridTemplateColumns={['repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
      gridGap="16px"
      overflow="auto"
    >
      <Box.GridItem gridColumn={['auto', null, 'auto / span 2']}>
        <LaborGridItem>
          <img src={moverWaving} alt="" />
          <Text.Callout weight={FontWeight.Medium}>Movers</Text.Callout>
          <Text.Caption color={COLORS.hippo}>
            Your movers are fully employed and trained by Clutter.
          </Text.Caption>
        </LaborGridItem>
      </Box.GridItem>
      <Box.GridItem gridColumn={['auto', null, 'auto / span 2']}>
        <LaborGridItem>
          <img src={truck} alt="" />
          <Text.Callout weight={FontWeight.Medium}>Truck</Text.Callout>
          <Text.Caption color={COLORS.hippo}>Gas is included.</Text.Caption>
        </LaborGridItem>
      </Box.GridItem>
      <LaborGridItem>
        <img src={moverWithRefridgerator} alt="" />
        <Text.Callout weight={FontWeight.Medium}>Equipment</Text.Callout>
        <Text.Caption color={COLORS.hippo}>
          Free dollies, straps, and tools.
        </Text.Caption>
      </LaborGridItem>
      <LaborGridItem>
        <img src={tape} alt="" />
        <Text.Callout weight={FontWeight.Medium}>Supplies</Text.Callout>
        <Text.Caption color={COLORS.hippo}>
          Free blankets, tape, and shrink wrap.
        </Text.Caption>
      </LaborGridItem>
      <LaborGridItem>
        <img src={protectionBadge} alt="" />
        <Text.Callout weight={FontWeight.Medium}>Basic Insurance</Text.Callout>
        <Text.Caption color={COLORS.hippo}>
          Upgrades are available.
        </Text.Caption>
      </LaborGridItem>
      <LaborGridItem>
        <img src={tablet} alt="" />
        <Text.Callout weight={FontWeight.Medium}>Technology</Text.Callout>
        <Text.Caption color={COLORS.hippo}>
          Manage, reschedule, and track your move online.
        </Text.Caption>
      </LaborGridItem>
    </Box.Grid>
  </Content>
);

export const MoversContent = () => (
  <Content>
    <h4>
      <b>Your Clutter move team.</b>
    </h4>
    <Text.Body>
      <p>
        Your Clutter move team is composed of a driver (who also helps move) and
        professional movers. Your moving professionals are fully employed and
        trained by Clutter. Each mover completes an extensive interview and
        background check process.
      </p>
      <p>
        We respect your time and budget which is why our movers are held to high
        quality standards to ensure a safe and fast move of your home.
      </p>
    </Text.Body>
  </Content>
);

export const TrucksContent = () => (
  <Content>
    <h4>Your truck and equipment details.</h4>
    <Text.Body>
      <p>This is the number of trucks needed to service a move of your size.</p>
      <p>
        We supply a modern, fully serviced and fueled moving truck, an insured
        professional driver, and basic in-truck protection plan. AI enabled
        cameras in the cab monitor for safe driving conditions when moving your
        items.
      </p>
      <p>
        All trucks have loading ramps and come fully loaded with dollies, tools,
        packing paper, bubblewrap, moving blankets & edge protectors.
      </p>
    </Text.Body>
  </Content>
);

export const TodayContent = () => (
  <Content>
    <h4>Technology to make your move easier.</h4>
    <Text.Body>
      <p>
        Before your move: Upload pictures and videos during a virtual
        walkthrough so we can make sure your move goes smooth and fast.
      </p>
      <p>
        During your move: Use the Today App to track our team. Know exactly when
        they’ll arive at your home, and where they are when moving between
        homes.
      </p>
    </Text.Body>
  </Content>
);
