import { useEffect } from 'react';

import { GMAP_LOADJS_ID, loadMapsApi } from '@root/initializers/google_maps';

import { useLoadJS } from './use_load_js';

export const useGoogleMapJS = () => {
  useEffect(() => {
    loadMapsApi();
  }, []);
  return useLoadJS(GMAP_LOADJS_ID);
};
