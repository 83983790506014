import React, { useCallback } from 'react';

// Wraps a native event handler to call a provided onChange with `onChange(name, value)`
export const useNamedOnChange = <
  T extends HTMLInputElement | HTMLSelectElement,
>(
  onChange?: (name: string, value: string) => void,
  transform?: (value: string) => string,
) =>
  useCallback(
    (e: React.ChangeEvent<T>) => {
      const { name, value } = e.currentTarget;
      const transformedValue =
        transform && value !== undefined ? transform(value) : value;
      if (onChange) {
        onChange(name, transformedValue);
      }
    },
    [onChange, transform],
  );
