import * as React from 'react';

import { App as SharedApp } from '@root/root/shared/app';
import { SafeIDProvider } from '@shared/safe_id_context';
import { IServerData } from '@shared/server_data_context';
import { WtPageview } from '@shared/wt_pageview';

import { Routes } from './routes';

const App: React.FC<{ serverData: IServerData }> = ({ serverData }) => {
  return (
    <SharedApp serverData={serverData}>
      <SafeIDProvider>
        <WtPageview />
        <Routes />
      </SafeIDProvider>
    </SharedApp>
  );
};

export { App };
