import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  Text,
  TextButton,
  UnstyledButton,
} from '@clutter/clean';
import { Estimation__ItemCategory } from '@graphql/platform';

import { Minus } from '@images/svg/minus';
import { Plus } from '@images/svg/plus';

const CounterContainer = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  border: 1px solid ${COLORS.grayBorder};
  overflow: hidden;
  padding: 0 8px;
`;

const IconButton = styled(UnstyledButton)`
  svg {
    vertical-align: middle;
  }
`;

export const CounterCard: React.FC<{
  item: Pick<Estimation__ItemCategory, 'name'>;
  count: number;
  onChange(newCount: number | null): void;
}> = ({ item, count, onChange }) => {
  const itemName = item.name;

  return (
    <CounterContainer>
      <Box.Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        padding="4px 0"
      >
        <Text.Callout>
          <b>{itemName}</b>
        </Text.Callout>
        <Box.Flex alignItems="center" margin="4px 0" gap="4px">
          {count === 0 && (
            <Box margin="0 8px 0 0">
              <TextButton onClick={() => onChange(null)} kind="destructive">
                Remove
              </TextButton>
            </Box>
          )}
          <IconButton onClick={() => onChange(count > 0 ? count - 1 : null)}>
            <Minus
              height={24}
              width={24}
              fillColor={count === 0 ? COLORS.hippo : COLORS.tealPrimary}
              strokeColor={COLORS.cloud}
            />
          </IconButton>
          <Box minWidth="32px" textAlign="center">
            <Text.Body weight={FontWeight.Medium}>{count}</Text.Body>
          </Box>
          <IconButton onClick={() => onChange(count + 1)}>
            <Plus
              height={24}
              width={24}
              fillColor={COLORS.tealPrimary}
              strokeColor={COLORS.cloud}
            />
          </IconButton>
        </Box.Flex>
      </Box.Flex>
    </CounterContainer>
  );
};
