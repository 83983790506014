import React from 'react';

export type ReviewData = {
  title: React.ReactNode;
  quote: React.ReactNode;
  reviewer: string;
};

export const SMART_STORAGE_TESTIMONIALS: ReviewData[] = [
  {
    title: 'Has to store items in between moving homes.',
    quote: (
      <>
        Love Clutter!!!!!! From scheduling to pick up and drop offs, best
        service ever. Both appointments were on time, they are extremely kind
        and considerate and professional. I highly recommend them.
      </>
    ),
    reviewer: 'Lauren H. Los Angeles, CA',
  },
  {
    title: 'Wants to save space in their apartment.',
    quote: (
      <>
        Great fast experience with Clutter. Nicolas was fantastic. Had them drop
        off some things from storage and pick up new items. Seamless.
      </>
    ),
    reviewer: 'Isabel M. Seattle, WA',
  },
  {
    title: 'Needs inventory storage and management for their small business.',
    quote: (
      <>
        I used clutter for work storage. Clutter delivered my items neatly,
        intact and in a timely fashion.
      </>
    ),
    reviewer: 'John C. Brooklyn, NY',
  },
  {
    title: 'Had a bad experience with a traditional self-storage provider.',
    quote: (
      <>
        The Move & Pack Specialists carried and wrapped all my furniture
        carefully and quickly and finished in no time at all. Their rates were
        pretty fair for the service they provided.
      </>
    ),
    reviewer: 'Robert H. Chicago, IL',
  },
  {
    title: 'Doesn’t have time to visit a storage unit.',
    quote: (
      <>
        Love the tracking of the delivery and updates. Delivery was fast and
        easy.
      </>
    ),
    reviewer: 'Allison R. Philadelphia, PA',
  },
  {
    title: 'Wants a safe, clean, and quality storage solution.',
    quote: (
      <>
        I am very pleased with my first Clutter experience. Would definitely
        recommend the service. Easy process and gave me peace of mind.
      </>
    ),
    reviewer: 'Chris L. Walnut Creek, CA',
  },
];

export const MOVING_TESTIMONIALS: ReviewData[] = [
  {
    title: 'First time using a moving company.',
    quote: (
      <>
        They were kind, thoughtful and helpful with the entire moving process.
        They were careful with the furniture and our home and always were
        checking to make sure things were perfect.
      </>
    ),
    reviewer: 'Janice J. Chicago, IL',
  },
  {
    title: 'Had a large home and was in a time crunch.',
    quote: (
      <>
        They arrived on time, packed and moved everything so quickly that I
        didn’t know a move could be completed in such a short period of time.
        200% recommend this great team!!!
      </>
    ),
    reviewer: 'David S. Los Angeles, CA',
  },
  {
    title: 'Needed custom furniture moved.',
    quote: (
      <>
        They were amazing, we had some pieces that were quite clunky and they
        managed to get everything out without disassembling. Perfect!
      </>
    ),
    reviewer: 'Mary R. New York, NY',
  },
  {
    title: 'Had a poor experience with a prior moving company. ',
    quote: (
      <>
        Don’t hesitate to work with them, they were in and out, and were very
        careful with all of our items as well. Very pleased with their service.
      </>
    ),
    reviewer: 'Sherry T. Oakland, CA',
  },
];

export const STORE_AND_MOVE_TESTIMONIALS: ReviewData[] = [
  ...MOVING_TESTIMONIALS,
  ...SMART_STORAGE_TESTIMONIALS,
];
