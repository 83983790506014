import React from 'react';

import { css, Global } from '@emotion/react';

const styles = css`
  body.locked_position {
    overflow: hidden;
    width: 100%;
    top: var(--offset-top);
  }
  body.fixed {
    position: fixed;
  }
`;

export const GlobalStyles = () => <Global styles={styles} />;
