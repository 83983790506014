import { ApolloClient } from '@apollo/client';

import {
  RateGroupKindEnum,
  ReonboardingCustomerDocument,
  ReonboardingCustomerQuery,
  ReonboardingCustomerQueryVariables,
} from '@graphql/platform';
import { CheckoutType } from '@root/components/checkout/types';
import { formatPhoneNumber } from '@utils/formatters';

import { InitialValues } from './types';

export type ReonboardingOptions = {
  customerToken: string;
  client: ApolloClient<unknown>;
};

export async function getReonboardingInitialValues({
  customerToken,
  client,
}: ReonboardingOptions): Promise<InitialValues | undefined> {
  const customer = (
    await client.query<
      ReonboardingCustomerQuery,
      ReonboardingCustomerQueryVariables
    >({ query: ReonboardingCustomerDocument, variables: { customerToken } })
  ).data?.reonboardingCustomer;

  if (!customer) return;

  const { name, email, defaultSource, currentStorageCustomer } = customer;
  const data = {
    checkoutType: CheckoutType.Reonboarding,
    name,
    email,
    phone: customer.phone ? formatPhoneNumber(customer.phone) : undefined,
    zip: customer.address?.zip ?? undefined,
    commitment: RateGroupKindEnum.Saver as const,
    customerToken,
    defaultSource: defaultSource ?? undefined,
    currentStorageCustomer,
  };

  return {
    movingData: data,
    storageData: data,
  };
}
