import React from 'react';

import { COLORS } from '@clutter/clean';

import { ISharedIconProps } from './shared_icon_props';

export const LocationMarker: React.FC<ISharedIconProps> = ({
  width = '18',
  height = '20',
  className,
  fillColor = COLORS.panther,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 0C3.925 0 0 3.55169 0 7.91672C0 12.7076 6.08333 18.1551 7.95 19.7101C8.41362 20.0966 9.08721 20.0966 9.55083 19.7101C11.4167 18.1534 17.5 12.7051 17.5 7.91672C17.5 3.55169 13.575 0 8.75 0Z"
      fill={fillColor}
    />
  </svg>
);
