import { booleanResultResolver, useAbeetResult } from '@clutter/abeet';
import { useGeocodeQuery } from '@graphql/platform';
import { useAbeetVisitorTest } from '@shared/abeet';

export const PRICING_TEST_INELIGIBLE_REGIONS = ['Portland', 'Seattle'];

export function useCouponDefaultTest() {
  const result = useAbeetResult('web_visitor_coupon_default');
  return result
    ? {
        promoCode: result.promo_code,
        expirationDate: result.expiration_date,
      }
    : {};
}

export function useBundleEligibilityTest(zip?: string) {
  const { data } = useGeocodeQuery({
    variables: { zip: zip ?? '' },
    skip: !zip,
  });
  const regionName = data?.geocode?.region?.name;
  return (
    useAbeetVisitorTest('web_visitor_labor', {
      resolver: booleanResultResolver('flexer_saver_bundles'),
      skip: !regionName || PRICING_TEST_INELIGIBLE_REGIONS.includes(regionName),
    })[0] ||
    (!!regionName && PRICING_TEST_INELIGIBLE_REGIONS.includes(regionName))
  );
}
