import { DateTime } from 'luxon';

export const isBeforeNextDayCutoff = () =>
  DateTime.local() <
  DateTime.local().set({
    hour: 16,
    minute: 30,
    second: 0,
    millisecond: 0,
  });

export const isNextDay = (value: DateTime) =>
  value.hasSame(DateTime.local().startOf('day').plus({ days: 1 }), 'day');

export const isOutsideNextDayCutoff = (value: DateTime) =>
  !isNextDay(value) || isBeforeNextDayCutoff();
