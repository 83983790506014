import React from 'react';

import { Box, COLORS, Text, UnstyledButton } from '@clutter/clean';
import { DeferredImage } from '@shared/deferred_image';
import { useSlide } from '@utils/hooks';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { TogglerIcon } from './toggler_icon';

import arrowUp from '@images/icons/arrow_up.svg';

export const SummaryItem: React.FC<{
  illustration: string;
  item: React.ReactNode;
  amount: React.ReactNode;
  discountedAmount?: React.ReactNode;
  description: React.ReactNode;
  expandableContent?: React.ReactNode;
}> = ({
  item,
  amount,
  discountedAmount,
  description,
  expandableContent,
  illustration,
}) => {
  const { isMobile } = useBreakpoints();
  const [ref, maxHeight, isOpen, toggleIsOpen] =
    useSlide<HTMLDivElement>(isMobile);

  return (
    <Box>
      <Box.Flex>
        <img src={illustration} width="48" alt="" />
        <Box.FlexItem flexGrow={1} margin="0 0 0 8px">
          <Text.Body>
            <b>{item}</b>
          </Text.Body>
          <Text.Callout color={COLORS.hippo}>{description}</Text.Callout>
          {expandableContent && (
            <UnstyledButton onClick={() => toggleIsOpen()}>
              <Text.Callout color={COLORS.tealPrimary}>
                <Box.Flex
                  style={{ gap: '8px' }}
                  margin="4px 0 0"
                  alignItems="center"
                >
                  <b>{isOpen ? 'Hide details' : 'Show details'}</b>
                  <TogglerIcon isOpen={isOpen}>
                    <DeferredImage src={arrowUp} />
                  </TogglerIcon>
                </Box.Flex>
              </Text.Callout>
            </UnstyledButton>
          )}
        </Box.FlexItem>
        <Box.FlexItem flexShrink={0} textAlign="right">
          <Text.Body>
            <b>{amount}</b>
          </Text.Body>
          {discountedAmount && (
            <Text.Callout color={COLORS.hippo} textDecoration="line-through">
              {discountedAmount}
            </Text.Callout>
          )}
        </Box.FlexItem>
      </Box.Flex>
      {expandableContent && (
        <>
          <Box
            maxHeight={maxHeight}
            overflow="hidden"
            transition="max-height 300ms ease"
            aria-hidden={!isOpen}
          >
            <Box.Flex
              ref={ref}
              flexDirection="column"
              style={{ gap: '16px' }}
              padding="8px 0 0"
            >
              {expandableContent}
            </Box.Flex>
          </Box>
        </>
      )}
    </Box>
  );
};
