import React from 'react';

import { Text } from '@clutter/clean';
import { CustomLink } from '@shared/custom_link';

export const BreadcrumbItem: React.FC<{
  href?: string;
  children: React.ReactNode;
}> = ({ children, href }) => {
  return href ? (
    <CustomLink to={href}>
      <Text.Button display="inline">{children}</Text.Button>
    </CustomLink>
  ) : (
    <Text.Button display="inline">{children}</Text.Button>
  );
};
