import { AddressFragment, Moving__BuildingTypeEnum } from '@graphql/platform';

/** An address created on the client (e.g. via Google autocomplete) */
export type ClientAddress = Omit<AddressFragment, 'id'> & {
  googleAddressType?: string;
};

export enum MovingAccessKind {
  NOT_APPLICABLE = 'Not Applicable',
  STAIRS = 'Stairs',
  ELEVATOR = 'Elevator',
  SERVICE_ELEVATOR = 'Service Elevator',
}

export type MovingAddressDetails = {
  buildingType?: Moving__BuildingTypeEnum;
  floor?: number;
  access?: string;
  stories?: number;
  unitSize?: string;
  squareFootage?: number;
  longCarry?: boolean;
};

export type StrictMovingAddressDetails =
  | {
      buildingType?: Moving__BuildingTypeEnum.Other;
    }
  | {
      buildingType?: Moving__BuildingTypeEnum.DormOrCampusApartment;
    }
  | {
      buildingType?: Moving__BuildingTypeEnum.House;
    }
  | {
      buildingType?: Moving__BuildingTypeEnum.Apartment;
    }
  | {
      buildingType?: Moving__BuildingTypeEnum.StorageFacilityOrWarehouse;
      unitSize?: string;
    }
  | {
      buildingType?: Moving__BuildingTypeEnum.Commercial;
      squareFootage?: number;
    };

export type StrictMovingBuildingType = Exclude<
  StrictMovingAddressDetails['buildingType'],
  undefined
>;

export type MovingAddress = AddressFragment & MovingAddressDetails;

export enum ADDRESS_VALIDATION {
  VALID = 'We’ve located this address',
  NOT_IN_SERVICE_AREA = 'We’re sorry, this address is not in our service area',
  NOT_FOUND = 'We couldn’t find this address. Please search again',
  INCOMPLETE = 'Oops! This address is not complete. Please search again',
  INVALID_ZIP = 'Oops! This address does not match your ZIP',
  SELECTION_REQUIRED = 'Please search again and select from the list',
}
