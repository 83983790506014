import React from 'react';

import { Box, COLORS, Text } from '@clutter/clean';

import locationPin from '@images/illustrations/location_truck_tracking.svg';

export const OSACard = () => (
  <Box.Flex
    alignItems="flex-start"
    background={COLORS.grayBorder}
    borderRadius="4px"
    padding="24px"
  >
    <img src={locationPin} width="45" alt="" />
    <Box margin="0 0 0 16px">
      <Text.Callout>
        <b>We don’t currently service this area!</b>
        <Box margin="4px 0 0">
          Clutter is expanding every day, check back soon!
        </Box>
      </Text.Callout>
    </Box>
  </Box.Flex>
);
