import { IValidation } from '../types/validation';
import { length, presence, zip } from '../validator';

const addressValidations: { [key: string]: IValidation[] } = {
  street: [{ validator: presence, message: 'Street is required.' }],
  city: [{ validator: presence, message: 'Phone is required.' }],
  zip: [
    {
      validator: zip,
      message: 'Please enter a valid ZIP code.',
      conditions: presence,
    },
  ],
  state: [
    { validator: presence, message: 'State is required' },
    { validator: length(2), message: 'Please enter a valid state' },
  ],
};

export { addressValidations };
