import React, { useEffect, useState } from 'react';

import { Box } from '@clutter/clean';
import {
  LatLngFragment,
  Location__City,
  Location__State,
} from '@graphql/platform';
import {
  DEFAULT_MAP_STYLE,
  MapProvider,
  useMapContext,
} from '@shared/map/context';
import { Marker } from '@shared/map/marker';

const LA_LAT_LNG = { lat: 34, lng: -118 };

const MAP_OPTIONS = {
  styles: DEFAULT_MAP_STYLE,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
};

const ServiceAreaMap: React.FC<{
  states: ReadonlyArray<
    Pick<Location__State, 'id' | 'slug'> & {
      cities: ReadonlyArray<
        Pick<Location__City, 'id' | 'name'> & { center: LatLngFragment }
      >;
    }
  >;
  height: string | Array<string | null>;
}> = ({ states, height }) => {
  const [coords, setCoords] = useState<LatLngFragment[]>([]);
  const { fitToBounds, map } = useMapContext();

  useEffect(() => {
    if (!map || !states.length) return;
    const newCoords = states.reduce<LatLngFragment[]>((currCoords, state) => {
      const cityCoords = state.cities.map((city) => city.center);
      return currCoords.concat(cityCoords);
    }, []);
    fitToBounds(newCoords);
    setCoords(newCoords);
  }, [map, states, fitToBounds]);

  return (
    <Box>
      <Box height={height} width="100%" maxWidth="1200px" margin="40px auto 0">
        <MapProvider.Map
          options={MAP_OPTIONS}
          defaultCenter={LA_LAT_LNG}
          draggable={false}
        >
          {coords.map((point, index) => (
            <Marker key={index} {...point} />
          ))}
        </MapProvider.Map>
      </Box>
    </Box>
  );
};

const WithProvider: React.FC<React.ComponentProps<typeof ServiceAreaMap>> = (
  props,
) => (
  <MapProvider includeMapComponent={false}>
    <ServiceAreaMap {...props} />
  </MapProvider>
);

export { WithProvider as ServiceAreaMap };
