/// <reference types="@types/google.maps" />
import * as loadjs from 'loadjs';
import * as qs from 'qs';

const GMAP_LOADJS_ID = 'googleMapsAPI';
const CALLBACK_NAME = 'clutterMapsCB';

let promise: Promise<typeof google.maps>;

const loadMapsApi = (key = 'AIzaSyB_eA0GuGrWtNKq5Yt52uLYn0T_Ezmqgl8') => {
  if (promise) return promise;

  const queryString = qs.stringify(
    {
      key,
      libraries: 'places',
      region: 'US',
      language: 'en',
      v: 'quarterly',
      callback: CALLBACK_NAME,
    },
    { addQueryPrefix: true },
  );

  loadjs(
    `https://maps.googleapis.com/maps/api/js${queryString}`,
    GMAP_LOADJS_ID,
  );

  promise = new Promise((resolve) => {
    (window as any)[CALLBACK_NAME] = () => {
      resolve(google.maps);
    };
  });

  return promise;
};

export { GMAP_LOADJS_ID, loadMapsApi };
