import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';
import { WWW_ROUTES } from '@root/root/routes';
import { CustomLink } from '@shared/custom_link';

import MOVING_ICON from '@images/illustrations/home.svg';
import STORAGE_ICON from '@images/illustrations/online_quote_check.svg';

type ServiceItem = {
  name: string;
  description: React.ReactNode;
  icon: {
    src: string;
    width: number;
    height: number;
  };
};

const ServiceLink = styled(CustomLink)`
  color: ${COLORS.tealPrimary};
  text-decoration: underline;
`;

const SERVICE_ITEMS: ServiceItem[] = [
  {
    name: 'Smart Storage',
    description: (
      <>
        Store items without ever leaving your home.{' '}
        <ServiceLink to={WWW_ROUTES.SERVICE_WAREHOUSE_STORAGE}>
          Learn more.
        </ServiceLink>
      </>
    ),
    icon: {
      src: STORAGE_ICON,
      width: 321,
      height: 130,
    },
  },
  {
    name: 'Local Moving',
    description: (
      <>
        Moving with same-day and different day delivery.{' '}
        <ServiceLink to={WWW_ROUTES.SERVICE_MOVING}>Learn more.</ServiceLink>
      </>
    ),
    icon: {
      src: MOVING_ICON,
      width: 198,
      height: 111,
    },
  },
];

export const Services: React.FC = () => {
  return (
    <Box color={COLORS.tealDark} textAlign="center">
      <Text.Title size="large" tag="h2">
        Your one-stop shop
      </Text.Title>
      <Box margin={['16px 0 64px', '24px 0 80px']}>
        <Text.Headline>
          Clutter is a one-stop shop, here to help you with all your moving and
          storage needs, providing the most affordable, flexible, & reliable
          service across North America.
        </Text.Headline>
      </Box>
      <Box.Flex
        gap="64px"
        justifyContent="space-evenly"
        flexDirection={['column', 'row']}
      >
        {SERVICE_ITEMS.map((service, index) => (
          <Box.FlexItem key={index}>
            <Box maxWidth="256px" margin="0 auto">
              <img
                loading="lazy"
                src={service.icon.src}
                width={service.icon.width}
                height={service.icon.height}
                alt=""
                style={{ height: '64px' }}
              />
              <Box margin="12px 0 8px">
                <Text.Title size="extraSmall">{service.name}</Text.Title>
              </Box>
              <Text.Body>{service.description}</Text.Body>
            </Box>
          </Box.FlexItem>
        ))}
      </Box.Flex>
    </Box>
  );
};
