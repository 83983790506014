import React from 'react';

import {
  Box,
  Button,
  COLORS,
  FontWeight,
  InfoModal,
  Text,
} from '@clutter/clean';
import { FreeStorageContent } from '@root/components/checkout/product_pages/subcomponents/moving_free_storage_modal';

import { useMovingCheckoutContext } from '../context';

export const MovingFreeStorageModal: React.FC<{
  isOpen: boolean;
  planAdded: boolean;
  onClose(): void;
}> = ({ isOpen, planAdded, onClose }) => {
  const {
    flowState: {
      values: { coupon, freeStorageSelected },
      onChange,
    },
  } = useMovingCheckoutContext();

  return (
    <InfoModal isOpen={isOpen} handleModalClose={onClose}>
      <FreeStorageContent planAdded={planAdded} />
      <Button
        disabled={!!freeStorageSelected || !!coupon}
        fullWidth
        onClick={() => {
          onChange('freeStorageSelected', true);
          onClose();
        }}
      >
        Add promo
      </Button>
      {(!!coupon || !!freeStorageSelected) && (
        <Box margin="8px 0 0" textAlign="center">
          <Text.Caption color={COLORS.storm} weight={FontWeight.Medium}>
            {!!coupon &&
              'Unable to add promo as there is one already applied. One per reservation.'}
            {!!freeStorageSelected && 'Free storage promo already applied.'}
          </Text.Caption>
        </Box>
      )}
    </InfoModal>
  );
};
