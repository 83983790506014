import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS } from '@clutter/clean';

const Diamond = styled(Box)`
  box-sizing: border-box;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: -9px;
  background-color: ${COLORS.tealPrimary};
  transform: scaleX(1.5) rotate(45deg);
  top: 0;
`;

export const DiscountBanner: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box
      color={COLORS.cloud}
      background={COLORS.tealPrimary}
      padding="16px"
      borderRadius="4px"
      position="relative"
      margin="24px 0 0"
    >
      <Diamond left={['50%', null, '67%']} />
      {children}
    </Box>
  );
};
