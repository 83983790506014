import * as React from 'react';

import { ContactPhoneLink } from '@shared/contact_phone_link';

type ErrorMessage = React.ReactNode;

export enum PaymentMethodErrorKind {
  PrepaidCard = 'prepaid',
  Declined = 'declined',
  Unknown = 'unknown',
  Invalid = 'invalid',
}

export const PAYMENT_ERROR_TO_MESSAGE: {
  [key in PaymentMethodErrorKind]: ErrorMessage;
} = {
  [PaymentMethodErrorKind.PrepaidCard]: (
    <>
      Sorry, we don’t accept prepaid cards at this time. Please call{' '}
      <ContactPhoneLink /> to put a different card on file.
    </>
  ),

  [PaymentMethodErrorKind.Declined]: (
    <>
      Sorry, your card was declined. Please call <ContactPhoneLink /> to put a
      different card on file.
    </>
  ),

  [PaymentMethodErrorKind.Unknown]:
    'It looks like there was a problem with that card.',
  [PaymentMethodErrorKind.Invalid]: 'Please provide a valid payment method.',
};

export const PaymentMethodError: React.FC<{
  error: PaymentMethodErrorKind;
}> = ({ error }) => {
  return <>{PAYMENT_ERROR_TO_MESSAGE[error]}</>;
};
