import React from 'react';

import { MovingCheckoutStep, MovingStepDescriptor } from '../../../types';
import { Address, addressCompleted } from '../moving/address';
import { Appointment } from '../moving/appointment';
import { Building } from '../moving/building';
import { LeadInfo } from '../moving/lead_info';
import { MoveSize, moveSizeCompleted } from '../moving/move_size';
import { MoverCount } from '../moving/mover_count';
import { Zip } from '../moving/zip';
import { Disposal } from '../shared/disposal';
import { Commitment } from '../smart_storage/commitment';
import { PlanSize } from '../smart_storage/plan_size';
import { ProtectionPlan } from '../smart_storage/protection_plan';

export const Moving: MovingStepDescriptor[] = [
  {
    name: MovingCheckoutStep.Zip,
    step: Zip,
    resolveCompleted: ({ values: { zipValidated } }) => !!zipValidated,
  },
  {
    name: MovingCheckoutStep.Building,
    step: Building,
    resolveCompleted: ({ values }) =>
      !!values.startAddressDetails?.buildingType,
  },
  {
    name: MovingCheckoutStep.MoveSize,
    step: MoveSize,
    resolveCompleted: moveSizeCompleted,
  },
  {
    name: MovingCheckoutStep.Address,
    step: Address,
    resolveCompleted: addressCompleted,
  },
  {
    name: MovingCheckoutStep.StoragePlanSize,
    step: (props) => (
      <PlanSize
        {...props}
        movingCheckoutFlow={true}
        endAddress={props.values.endAddress}
        movingExtendedDestinationServiceArea={
          props.values.extendedDestinationServiceArea
        }
      />
    ),
    resolveCompleted: ({ values }) =>
      values.extendedDestinationServiceArea ||
      values.currentStorageCustomer ||
      !!values.skipStoragePlanSizeSelected ||
      !!values.planSize,
  },
  {
    name: MovingCheckoutStep.StorageCommitment,
    step: (props) => <Commitment {...props} movingCheckoutFlow={true} />,
    resolveCompleted: ({ values }) =>
      !!values.skipStoragePlanSizeSelected || !!values.commitment,
  },
  {
    name: MovingCheckoutStep.StorageProtectionPlan,
    step: (props) => <ProtectionPlan {...props} movingCheckoutFlow={true} />,
    resolveCompleted: ({ values }) =>
      !!values.skipStoragePlanSizeSelected || !!values.protectionPlan,
  },
  {
    name: MovingCheckoutStep.LeadInfo,
    step: LeadInfo,
    resolveCompleted: ({ leadToken }) => !!leadToken,
  },
  {
    name: MovingCheckoutStep.MoverCount,
    step: MoverCount,
    resolveCompleted: ({ values }) => values.moverCountSelected !== undefined,
  },
  {
    name: MovingCheckoutStep.Appointment,
    step: Appointment,
    resolveCompleted: ({ values }) =>
      values.dateScheduled !== undefined &&
      (!values.longDistance || values.unpackingScheduled !== undefined),
  },
  {
    name: MovingCheckoutStep.Disposal,
    step: Disposal,
    resolveCompleted: ({ disposalEligible, values: { disposalSelected } }) =>
      disposalEligible ? disposalSelected !== undefined : true,
  },
  // The actual cart is rendered on a separate page, but stubbed here to support step
  // transitions.
  {
    name: MovingCheckoutStep.Cart,
    step: () => null,
    resolveCompleted: () => true,
  },
];
