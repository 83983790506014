import React from 'react';

import styled from '@emotion/styled';

import { Box, mq, Text } from '@clutter/clean';
import { ResponsiveImage } from '@shared/responsive_image';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { IResponsiveImage } from '@utils/images';

const Image = styled(ResponsiveImage)`
  ${mq({
    width: [null, '324px'],
    height: ['300px', 'auto'],
    objectFit: ['cover', 'initial'],
  })}
`;

export const ImageAndTextFeature: React.FC<{
  title: string;
  content: React.ReactNode;
  image: IResponsiveImage;
  croppedImage?: IResponsiveImage;
}> = ({ title, content, image, croppedImage }) => {
  const { isMobile } = useBreakpoints();

  const resolvedImage = isMobile ? image : croppedImage ?? image;
  // const src =
  //   typeof resolvedImage === 'string' ? resolvedImage : resolvedImage.src;
  // const srcSet =
  // typeof resolvedImage === 'string' ? undefined : resolvedImage.srcSet;
  return (
    <Box.Flex
      height={[null, '480px']}
      borderRadius="10px"
      flexDirection={['column', 'row']}
    >
      <Image draggable={false} image={resolvedImage} alt="" />
      <Box.Flex flexDirection="column" padding="20px" overflow="auto">
        <Box margin="0 0 12px">
          <Text.Title size="small">{title}</Text.Title>
        </Box>
        <Text.Body>{content}</Text.Body>
      </Box.Flex>
    </Box.Flex>
  );
};
