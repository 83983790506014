import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  mq,
  SERIF_FONT_FAMILY,
  Text,
} from '@clutter/clean';
import { Ratings } from '@shared/ratings';

import moverWaving from '@images/illustrations/mover_waving.svg';
import onlineQuote from '@images/illustrations/online_quote_small.svg';
import phoneNotification from '@images/illustrations/phone_notification_small.svg';
import protectionStandard from '@images/illustrations/protection_plan_standard.svg';
import yelpReview from '@images/reviews/yelp_review.png';

const Grid = styled.div`
  display: grid;
  ${mq({
    marginTop: ['32px', '48px'],
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: ['48px', '24px'],
  })}
`;

const SerifCaption = styled(Text.Caption)`
  font-family: ${SERIF_FONT_FAMILY};
  font-weight: ${FontWeight.Semibold};
  margin-bottom: 12px;
`;

const QualityServicesGridItem: React.FC<{
  title: string;
  content: React.ReactNode;
  icon: string;
}> = ({ title, content, icon }) => (
  <Box.Flex>
    <Box.FlexItem margin="0 12px 12px 0" flexShrink={0}>
      <img src={icon} width="36px" height="36px" draggable={false} alt="" />
    </Box.FlexItem>
    <Box.FlexItem>
      <SerifCaption>{title}</SerifCaption>
      <Text.Caption color={COLORS.hippo}>{content}</Text.Caption>
    </Box.FlexItem>
  </Box.Flex>
);

export const QualityServices: React.FC = () => (
  <Box.Flex
    height={[null, '480px']}
    borderRadius="10px"
    flexDirection={['column', 'row']}
  >
    <Box.FlexItem
      background={COLORS.tealBackground}
      width={['initial', '324px']}
      padding="48px"
      flexShrink={0}
    >
      <Box.Flex
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        height="100%"
        padding={['0', '0 0 24px 0']}
      >
        <img src={yelpReview} draggable={false} alt="" />
        <Ratings />
      </Box.Flex>
    </Box.FlexItem>
    <Box.Flex flexDirection="column" padding="20px" overflow="auto">
      <Box margin="0 0 12px">
        <Text.Title size="small">Quality services</Text.Title>
      </Box>
      <Box maxWidth="346px">
        <Text.Body>
          We’ve helped customers across the country move and store over 10
          million items. You can depend on our policies:
        </Text.Body>
      </Box>
      <Grid>
        <QualityServicesGridItem
          title="Employee Training"
          content={
            <>
              Every Move & Pack Specialist in your home must pass a background
              check and Clutter’s training bootcamp.
            </>
          }
          icon={moverWaving}
        />
        <QualityServicesGridItem
          title="High Tech Safety"
          content={
            <>
              Our virtual move auditors scan images from inside our trucks to
              ensure safe packing before we leave your home.
            </>
          }
          icon={onlineQuote}
        />
        <QualityServicesGridItem
          title="Incident Response Promise"
          content={
            <>
              In case of any damaged items, we guarantee claims are processed
              within 48 hours.
            </>
          }
          icon={protectionStandard}
        />
        <QualityServicesGridItem
          title="Help Always Available"
          content={
            <>
              Our customers Care team is available via phone, chat, email and
              throughout your project.
            </>
          }
          icon={phoneNotification}
        />
      </Grid>
    </Box.Flex>
  </Box.Flex>
);
