import { useEffect, useLayoutEffect, useState } from 'react';

import { BREAKPOINTS } from '@clutter/clean';
import { useServerDataContext } from '@shared/server_data_context';
import { considerateThrow } from '@utils/monitoring';

enum BreakpointClassifier {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

const isSSR = typeof window === 'undefined';
const useIsomorphicLayoutEffect = isSSR ? useEffect : useLayoutEffect;

const resolveClassifier = (width: number) => {
  if (width < BREAKPOINTS.SM_INT) {
    return BreakpointClassifier.Mobile;
  } else if (width >= BREAKPOINTS.SM_INT && width < BREAKPOINTS.MD_INT) {
    return BreakpointClassifier.Tablet;
  } else {
    return BreakpointClassifier.Desktop;
  }
};

const useBreakpointsInner = (defaultSize: BreakpointClassifier) => {
  const [classifier, setClassifier] = useState(defaultSize);

  useIsomorphicLayoutEffect(() => {
    const onResize = () => {
      setClassifier(resolveClassifier(window.innerWidth));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    isMobile: classifier === BreakpointClassifier.Mobile,
    isTablet: classifier === BreakpointClassifier.Tablet,
    isDesktop: classifier === BreakpointClassifier.Desktop,
  };
};

/**
 * Exposes the breakpoint "classifier" for current viewport with a server
 * rendered default. Before mounting, the component will always render as on the
 * server. This means that if the default does not match the curent viewport
 * there will be a flicker of "incorrect" content (when hydrating after server
 * rendering) or a needless double render (when mounting for the first time on
 * the client).
 *
 * This hook should not be used to change content rendered to the DOM unless you
 * have a very good reason.
 *
 * If a component is guaranteed to not be server rendered, prefer
 * `useBreakpoints`
 * */
export const useIsomorphicBreakpoints = () => {
  const { device } = useServerDataContext();
  return useBreakpointsInner(
    device === 'desktop'
      ? BreakpointClassifier.Desktop
      : BreakpointClassifier.Mobile,
  );
};

export function useBreakpoints() {
  if (typeof window === 'undefined') {
    considerateThrow(
      new Error('Use `useIsomorphicBreakpoints` when server rendering.'),
    );
  }

  const defaultSize = useState(() =>
    resolveClassifier(typeof window === 'undefined' ? 0 : window.innerWidth),
  )[0];

  return useBreakpointsInner(defaultSize);
}
