import React from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Box, COLORS } from '@clutter/clean';
import { useLocationLinksQuery } from '@graphql/platform';
import { locationsURL } from '@root/components/locations/routes';
import {
  BASE_BREADCRUMBS,
  jsonBreadcrumbList,
} from '@root/components/locations/shared/structured_data';
import { BreadcrumbItem } from '@shared/breadcrumb_item';
import { BreadcrumbList } from '@shared/breadcrumb_list';

import { CityLinks } from './shared/city_links';
import { Hero } from './shared/hero';
import { Layout } from './shared/layout';
import { ServiceAreaMap } from './shared/service_area_map';
import { Services } from './shared/services';

export const Locations = () => {
  const { data } = useLocationLinksQuery();

  const states = data?.states ?? [];

  const descriptionContent =
    'Clutter offers storage and moving services across the US. ' +
    'Find a storage location near you to get affordable rates, secure storage, and convenient services.';

  return (
    <>
      <HelmetProvider context={global.helmetContext}>
        <Helmet>
          <title>
            Find Storage Units | Pickup & Delivery Storage | Clutter
          </title>
          <meta name="description" content={descriptionContent}></meta>
          <script type="application/ld+json">
            {jsonBreadcrumbList(BASE_BREADCRUMBS)}
          </script>
        </Helmet>
      </HelmetProvider>
      <Layout>
        <Box maxWidth="1600px" margin="0 auto 80px" padding="0 24px">
          <Hero />
          <Box margin="32px 0 0">
            <BreadcrumbList>
              <BreadcrumbItem href="/">Clutter</BreadcrumbItem>
              <BreadcrumbItem href={locationsURL()}>Locations</BreadcrumbItem>
            </BreadcrumbList>
          </Box>
        </Box>
        <Layout.Section padding="72px 0 96px">
          <Services />
        </Layout.Section>
        <Layout.Section
          background={COLORS.grayBackground}
          padding="72px 0 96px"
        >
          <ServiceAreaMap states={states} height={['300px', null, '600px']} />
        </Layout.Section>
        <Layout.Section padding="72px 0 96px">
          <CityLinks states={states} service="storage" />
        </Layout.Section>
        <Layout.Section
          background={COLORS.grayBackground}
          padding="72px 0 96px"
        >
          <CityLinks states={states} service="moving" />
        </Layout.Section>
      </Layout>
    </>
  );
};
