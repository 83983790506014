import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import {
  Box,
  BREAKPOINTS,
  COLORS,
  mq,
  Text,
  toggleStyleValue,
} from '@clutter/clean';
import { useDebouncedValue } from '@clutter/hooks';
import { WTEventParams } from '@clutter/wt';
import { useDraftQuery } from '@graphql/platform';
import { CustomLink } from '@shared/custom_link';
import { TransparentButton } from '@shared/transparent_button';
import { useIntersectionObserver } from '@utils/hooks';
import { useIsMounted, useOnMount } from '@utils/hooks/mount';
import { useIsomorphicBreakpoints } from '@utils/hooks/use_breakpoints';

import { track } from '../../../initializers/wt';
import { WWW_ROUTES } from '../../../root/routes';
import { ServiceSelection } from '../service_selector/constants';
import { PinnedServiceSelector } from '../service_selector/pinned_service_selector';

import { Cart } from './cart';
import { MenuLink } from './menu_link';

import { HamburgerToggle } from '@images/svg/hamburger';
import { Logo } from '@images/svg/logo';

export const DESKTOP_HEADER_HEIGHT = '120px';
export const MOBILE_HEADER_HEIGHT = '84px';
export const DESKTOP_HEADER_HEIGHT_INT = 120;
export const MOBILE_HEADER_HEIGHT_INT = 84;
export const ZIP_INPUT_TRIGGER_ID = 'hero_zip_input_container';

const FILL_COLOR = COLORS.tealPrimary;
const LOGIN_LINK = 'https://account.clutter.com';
const MOVEMENT_EASE = 'cubic-bezier(0.52, 0, 0.43, 0.96)';
const TRANSITION_DURATION = '0.4s';
const FADE_DURATION = '0.2s';
const STAGGER_DURATION = '0.6s';
const STAGGER_DURATION_MS = 600;
const DEFAULT_MENU_LINK_PARAMS = {
  objectType: 'button',
  action: 'click',
  container: 'header',
};
const LOGO_PARAMS = {
  objectName: 'logo',
  objectType: 'image',
  action: 'click',
  label: 'Clutter',
  value: '/',
};

export const SERVICE_LINK_PARAMS = {
  objectName: 'service_link',
};

const defaultMenuLinks = (isLoggedIn?: boolean): MenuLink[] => [
  {
    href: LOGIN_LINK,
    label: isLoggedIn ? 'My Account' : 'Log in',
    wtParams: {
      objectName: isLoggedIn ? 'my_account' : 'log_in',
    },
  },
  {
    href: '/help',
    label: 'Help Center',
    wtParams: {
      objectName: 'help_center',
    },
  },
];

type MenuLink = {
  href: string;
  label: React.ReactNode;
  isFeatured?: boolean;
  wtParams: WTEventParams;
};

const PositionContainer = styled.div<{ opaque: boolean; sticky: boolean }>`
  position: ${toggleStyleValue('sticky', 'sticky', 'relative')};
  top: 0;
  height: 0;
  width: 100%;
  overflow: visible;
  z-index: 100;
  ${toggleStyleValue(
    'opaque',
    mq({ height: [MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT] }),
    '',
  )}
`;

const Container = styled.header<{
  atTop: boolean;
  menuOpen: boolean;
}>`
  width: 100%;
  transition: box-shadow 0.2s;
  color: ${FILL_COLOR};
  box-shadow: 0px 2px 20px
    rgba(194, 194, 194, ${toggleStyleValue('atTop', 0, 0.25)});

  ${mq({
    height: [MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT],
  })}
`;

const Content = styled.div<{ opaque: boolean }>`
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: ${({ opaque }) => `rgba(255,255,255,${opaque ? 1 : 0})`};
  transition: background 0.2s linear;

  ${mq({
    borderBottom: [`1px solid ${COLORS.grayBorder}`, null, 'none'],
    padding: ['0 24px 16px', null, '0 60px'],
    alignItems: ['flex-end', null, 'center'],
  })};
`;

const Left = styled.div`
  position: relative;
`;

const Right = styled.div<{ showOverflow: boolean }>`
  overflow: ${toggleStyleValue('showOverflow', 'visible', 'hidden')};
  height: 100%;
  ${mq({ display: ['none', null, 'block'] })}
  flex-grow: 1;
`;

/* Animated Containers */

const DesktopToggle = styled(TransparentButton)<{
  showMenu: boolean;
}>`
  position: absolute;
  left: 0px;
  top: 12px;
  opacity: ${toggleStyleValue('showMenu', 1, 0)};
  transition: opacity ${FADE_DURATION} linear;
  transition-delay: ${toggleStyleValue('showMenu', STAGGER_DURATION, '0s')};

  ${mq({
    display: ['none', null, 'block'],
  })}
`;

const MobileToggle = styled(TransparentButton)`
  float: right;

  & > div {
    width: 24px;
    height: 18px;
  }

  ${mq({
    display: ['block', null, 'none'],
  })}
`;

const LogoContainer = styled.div<{ showMenu: boolean }>`
  @media (min-width: ${BREAKPOINTS.SM}) {
    transition: transform ${TRANSITION_DURATION} ${MOVEMENT_EASE};
    transform: translateX(${toggleStyleValue('showMenu', '70px', '0px')});
    transition-delay: ${FADE_DURATION};
  }
`;

const LinkContainer = styled.div<{ showLinks: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  opacity: ${toggleStyleValue('showLinks', 1, 0)};
  transition: opacity ${FADE_DURATION} linear;
  transition-delay: ${toggleStyleValue('showLinks', STAGGER_DURATION, '0s')};
  justify-content: flex-end;
`;

const ZipContainer = styled.div<{ showCTA: boolean }>`
  height: 100%;
  align-items: center;
  transform: translateY(${toggleStyleValue('showCTA', '-100%', '0%')});
  opacity: ${toggleStyleValue('showCTA', 1, 0)};
  pointer-events: ${toggleStyleValue('showCTA', 'initial', 'none')};
  transition: transform ${TRANSITION_DURATION} ${MOVEMENT_EASE},
    opacity ${TRANSITION_DURATION} linear;
  transition-delay: ${FADE_DURATION};
  justify-content: flex-end;
  margin: 0 0 0 auto;

  ${mq({
    display: ['none', null, 'flex'],
    width: [null, '360px', 'initial'],
  })}
`;

const ExpandedMenuLinkContainer = styled.div`
  text-align: center;
  padding: 32px 0;

  ${mq({
    margin: [0, null, '0 12px', '0 40px'],
    width: ['100%', null, 'initial'],
    borderBottom: [`1px solid ${COLORS.grayBorder}`, null, 'none'],
  })}
`;

const ExpandedMenu = styled.div<{ expanded: boolean }>`
  display: ${toggleStyleValue('expanded', 'flex', 'none')};
  align-items: center;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.1);

  ${mq({
    background: COLORS.cloud,
    flexDirection: ['column', null, 'row'],
    justifyContent: ['flex-start', null, 'center'],
    border: [null, null, `1px solid ${COLORS.grayBorder}`],
  })}
`;

const ClutterLogo = styled(Logo)`
  height: unset;

  ${mq({
    width: ['84px', null, '160px'],
  })}
`;

export const trackLinkClick = (
  params: WTEventParams,
  event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  container = 'header',
) =>
  track({
    ...DEFAULT_MENU_LINK_PARAMS,
    label: event?.currentTarget.innerText,
    value: 'href' in event.currentTarget ? event.currentTarget.href : undefined,
    container,
    ...params,
  });

const Header: React.FC<{
  className?: string;
  opaque?: boolean;
  sticky?: boolean;
  hideZipInput?: boolean;
  menuItems?: MenuLink[];
  service?: ServiceSelection;
  isLoggedIn?: boolean;
}> = ({
  className,
  opaque = false,
  sticky = true,
  hideZipInput,
  menuItems,
  service,
  isLoggedIn,
}) => {
  const resolvedMenuItems = menuItems || defaultMenuLinks(isLoggedIn);
  const [menuOpen, setMenuOpen] = useState(false);
  const { isTablet, isMobile } = useIsomorphicBreakpoints();
  const [setAtTopNode, atTopEntry] = useIntersectionObserver();
  const [setZipInputContainerElement, zipInputContainerEntry] =
    useIntersectionObserver();
  const [showOverflow, setShowOverflow] = useState(true);
  const isMounted = useIsMounted();

  useOnMount(() => {
    const zipContainer = document.querySelector<HTMLElement>(
      '#' + ZIP_INPUT_TRIGGER_ID,
    );

    if (zipContainer) setZipInputContainerElement(zipContainer);
  });

  const useMobileMenu = isTablet || isMobile;
  const atTop = !atTopEntry || atTopEntry.isIntersecting || !sticky;

  const { data, loading: draftLoading } = useDraftQuery({});

  const showZipInput =
    !!zipInputContainerEntry &&
    !zipInputContainerEntry.isIntersecting &&
    !hideZipInput &&
    !data?.draft?.latestService;

  const { pathname } = useLocation();
  const onFunnelPage = /\/book\/(moving|storage)/.test(pathname);
  const showCartButton = !showZipInput && !onFunnelPage;

  const renderZipInput =
    useDebouncedValue(showZipInput, STAGGER_DURATION_MS) || showZipInput;

  useEffect(() => {
    if (!showZipInput && !useMobileMenu) {
      setMenuOpen(false);
    }
    setShowOverflow(false);

    const timeout = setTimeout(() => {
      setShowOverflow(true);
    }, STAGGER_DURATION_MS);

    return () => clearTimeout(timeout);
  }, [showZipInput, useMobileMenu]);

  return (
    <>
      <div ref={setAtTopNode}>{/* Trigger for scrolling */}</div>
      <PositionContainer opaque={opaque} sticky={sticky}>
        <Container className={className} atTop={atTop} menuOpen={menuOpen}>
          <Content opaque={opaque || menuOpen || !atTop}>
            <Left>
              <DesktopToggle
                aria-label="Toggle Navigation"
                showMenu={showZipInput}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <HamburgerToggle fillColor={FILL_COLOR} open={menuOpen} />
              </DesktopToggle>
              <LogoContainer showMenu={showZipInput && !useMobileMenu}>
                <CustomLink
                  to="/"
                  onClick={(e) => trackLinkClick(LOGO_PARAMS, e)}
                >
                  <ClutterLogo fillColor={FILL_COLOR} />
                </CustomLink>
              </LogoContainer>
            </Left>
            {!!resolvedMenuItems.length && (
              <>
                <Right showOverflow={showOverflow}>
                  <LinkContainer showLinks={!showZipInput}>
                    <MenuLink
                      to={WWW_ROUTES.SERVICE_WAREHOUSE_STORAGE}
                      isFeatured
                      onClick={(e) => trackLinkClick(SERVICE_LINK_PARAMS, e)}
                    >
                      <Text.Button>Storage</Text.Button>
                    </MenuLink>
                    <MenuLink
                      to={WWW_ROUTES.SERVICE_MOVING}
                      isFeatured
                      onClick={(e) => trackLinkClick(SERVICE_LINK_PARAMS, e)}
                    >
                      <Text.Button>Moving</Text.Button>
                    </MenuLink>
                    {resolvedMenuItems.map(({ href, label, wtParams }) => (
                      <MenuLink
                        key={href}
                        to={href}
                        onClick={(e) => trackLinkClick(wtParams, e)}
                      >
                        <Text.Button>{label}</Text.Button>
                      </MenuLink>
                    ))}
                  </LinkContainer>
                  <ZipContainer showCTA={showZipInput}>
                    {renderZipInput && (
                      // Since the service selector can't know whether it's
                      // visible, this ensures that any onMount hooks only fire
                      // when visible
                      <PinnedServiceSelector
                        id="header_service_selector"
                        position="header"
                        container="header"
                        initialService={service}
                      />
                    )}
                  </ZipContainer>
                </Right>
                <Box.Flex gap={['16px', '24px']}>
                  {showCartButton && (
                    <Cart draft={data?.draft} loading={draftLoading} />
                  )}
                  <MobileToggle
                    aria-label="Toggle Navigation"
                    tapTargetExtension="16px"
                    onClick={() => setMenuOpen(!menuOpen)}
                  >
                    <HamburgerToggle fillColor={FILL_COLOR} open={menuOpen} />
                  </MobileToggle>
                </Box.Flex>
              </>
            )}
          </Content>
          {isMounted && (
            <ExpandedMenu expanded={menuOpen} tabIndex={menuOpen ? 0 : -1}>
              <ExpandedMenuLinkContainer>
                <MenuLink
                  to={WWW_ROUTES.SERVICE_WAREHOUSE_STORAGE}
                  isFeatured
                  onClick={(e) => trackLinkClick(SERVICE_LINK_PARAMS, e)}
                >
                  <Text.Button>Storage</Text.Button>
                </MenuLink>
              </ExpandedMenuLinkContainer>
              <ExpandedMenuLinkContainer>
                <MenuLink
                  to={WWW_ROUTES.SERVICE_MOVING}
                  isFeatured
                  onClick={(e) => trackLinkClick(SERVICE_LINK_PARAMS, e)}
                >
                  <Text.Button>Moving</Text.Button>
                </MenuLink>
              </ExpandedMenuLinkContainer>
              {resolvedMenuItems.map(({ href, label, wtParams }) => (
                <ExpandedMenuLinkContainer key={href}>
                  <MenuLink
                    to={href}
                    onClick={(e) => trackLinkClick(wtParams, e)}
                  >
                    <Text.Button>{label}</Text.Button>
                  </MenuLink>
                </ExpandedMenuLinkContainer>
              ))}
            </ExpandedMenu>
          )}
        </Container>
      </PositionContainer>
    </>
  );
};

export { Header };
