import React from 'react';

import { DateTime, Duration } from 'luxon';

import { CallbackWindow } from '@graphql/platform';

import { formatCallbackWindow } from './callback_windows';

export const CallbackWindowSummary: React.FC<{
  callbackWindow: CallbackWindow;
}> = ({ callbackWindow }) => (
  <>
    A Clutter agent will call you back{' '}
    {callbackWindow.duration ===
    Duration.fromObject({ minutes: 10 }).toISO() ? (
      <>in the next 10 minutes</>
    ) : (
      <>
        {DateTime.fromISO(callbackWindow.time).toLocaleString(
          DateTime.DATE_MED,
        )}{' '}
        between {formatCallbackWindow(callbackWindow)}
      </>
    )}
    .
  </>
);
