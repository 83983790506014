import styled from '@emotion/styled';

import { COLORS, mq } from '@clutter/clean';

const BigText = styled.h1`
  font-family: 'Recoleta';
  letter-spacing: -0.26px;

  ${mq({ fontSize: ['32px', '56px'] })}
`;

const SmallHeader = styled.h4`
  font-size: 17px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: ${COLORS.tealBrand};
`;

export { BigText, SmallHeader };
