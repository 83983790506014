import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, mq } from '@clutter/clean';

const DESKTOP_ALIGNMENTS = ['16%', '50%', '84%'];
const MOBILE_ALIGNMENTS = ['25%', '75%'];

const Diamond = styled(Box)`
  box-sizing: border-box;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: -9px;
  background-color: ${COLORS.tealPrimary};
  transform: scaleX(1.5) rotate(45deg);
  ${mq({ bottom: [4, 0] })}
`;

export const GridTooltip: React.FC<{
  children: React.ReactNode;
  columnIndex: number;
}> = ({ columnIndex, children }) => {
  return (
    <Box
      color={COLORS.cloud}
      background={COLORS.tealPrimary}
      padding="16px"
      borderRadius="4px"
      position="relative"
    >
      {children}
      <Diamond
        left={[MOBILE_ALIGNMENTS[columnIndex], DESKTOP_ALIGNMENTS[columnIndex]]}
      />
    </Box>
  );
};
