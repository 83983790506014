import React, { type ComponentProps, type FC } from 'react';

import styled from '@emotion/styled';

import { Button } from '@clutter/clean';

import { useFunnelEntryPointContext } from './use_funnel_entry_point_context';

const StyledSubmitButton = styled(Button)`
  white-space: nowrap;
`;

export const FunnelEntryPointSubmitButton: FC<ComponentProps<typeof Button>> = (
  props,
) => {
  const { loading } = useFunnelEntryPointContext();
  return (
    <StyledSubmitButton
      type="submit"
      kind="primary"
      size="medium"
      {...props}
      loading={props.loading === undefined ? loading : props.loading}
      disabled={props.disabled === undefined ? loading : props.disabled}
    >
      {props.children ?? 'Get Pricing'}
    </StyledSubmitButton>
  );
};
