import { onError } from '@apollo/client/link/error';

import { reportError } from '@utils/monitoring';

import { getOperationKind } from './get_operation_kind';

export const buildErrorLink = () =>
  onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((err) => {
        reportError(new Error(err.message), {
          tags: { graphql: getOperationKind(operation) },
          metadata: {
            operationName: operation.operationName,
            variables: operation.variables,
          },
        });
      });
    }
  });
