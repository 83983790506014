import React from 'react';

import { COLORS } from '@clutter/clean';

export const Logo: React.FC<
  { fillColor?: string } & React.HTMLAttributes<SVGElement>
> = ({ fillColor = COLORS.tealBrand, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="216"
    viewBox="0 0 216 52"
    className={className}
    style={style}
    role="img"
    aria-labelledby="logo-title logo-desc"
  >
    <title id="logo-title">Clutter</title>
    <desc id="logo-desc">Moving & Storage</desc>
    <path
      fill={fillColor}
      fillRule="nonzero"
      d="M171.7 43.89a14.71 14.71 0 0 0 11.6-4.8l3.6 5.9c-3.3 3.4-8.4 6.2-16.4 6.2-9.3 0-16.9-5.8-16.9-18.4 0-11.8 6.9-18.6 17.8-18.6 11.2 0 18.23 6.79 15.9 20.6h-23.5c.6 5.9 3.2 9.1 7.9 9.1zm0-22.8c-4.6 0-7.1 3-7.7 8.4h14.8c.1-5.6-2.4-8.39-7.1-8.39v-.01zM48.3 50.4V2.5L58.1 1v49.4h-9.8zM88.1 39V14.8h9.7v35.6h-9.7v-4.3c-3.2 2.2-8.2 5-13.9 5-7.7 0-10.6-4.6-10.6-11.2V14.8h9.8v22.9c0 3.3.9 5.8 4.6 5.8 3.7 0 7.9-2.5 10.1-4.5zm56.6 3.7a18 18 0 0 0 5.5-1.6l3.5 7.2a29 29 0 0 1-11.4 2.8c-6.8 0-10.3-3.7-10.3-12V22h-15v17.3c0 3 1.5 3.4 3 3.4 1.7 0 5.4-1.5 6.3-1.9l3.5 7.2c-2.2 1-7.5 3.1-12.2 3.1-6.8 0-10.3-3.7-10.3-12V22h-4.9v-7.2h4.9V6l9.7-1.4v10.2h15V6l9.7-1.4v10.2h11.9c.5 0 .8.2.5.9-.3.7-3 6.3-3 6.3h-9.4v17.3c0 3 1.5 3.4 3 3.4zm58-20.4a12.72 12.72 0 0 1 13.3-7.8v9.1c-11.9 0-13.3 10.8-13.3 11.4v15.4h-9.8V16.3l9.8-1.5v7.5zM25.9 41.8c7.4 0 13.4-6.6 13.4-6.5l4.4 6.8A24.6 24.6 0 0 1 0 26.6C0 17.4 6.71 2.1 24.7 2.1 37.21 2.1 42 8.89 42 12.2a6.49 6.49 0 0 1-6.6 6.6c-5.53-.11-7.2-5.19-6.4-7.4a22.37 22.37 0 0 0-3.2-.2 15.23 15.23 0 0 0-15.5 15.4 15.38 15.38 0 0 0 15.6 15.2z"
    />
  </svg>
);
