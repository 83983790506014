import React from 'react';

import styled from '@emotion/styled';

import { Box, FontWeight, SANS_SERIF_FONT_FAMILY, Text } from '@clutter/clean';
import { ServiceEnum } from '@root/resources/types/service';

const Greeting = styled.div`
  text-transform: uppercase;
  font-family: ${SANS_SERIF_FONT_FAMILY};
  font-size: 16px;
  font-weight: ${FontWeight.Semibold};
`;

export const ShareableHero: React.FC<{
  name?: string;
  service: ServiceEnum.Moving | ServiceEnum.DoorToDoor;
}> = ({ name, service }) => {
  return (
    <div>
      <Box
        margin={['40px auto', '80px auto 64px']}
        maxWidth="1720px"
        padding={['0 24px', '0 60px']}
      >
        <Greeting>
          {name ? name + '’s ' : 'Your'}{' '}
          {service === ServiceEnum.Moving ? 'Move' : 'Storage'}
        </Greeting>
        <Box margin="12px 0 0">
          <Text.Title size="large">
            Complete your{' '}
            {service === ServiceEnum.DoorToDoor ? 'Smart Storage' : 'Moving'}{' '}
            reservation
          </Text.Title>
        </Box>
        <Box margin="8px 0 0">
          <Text.Body>
            Book today to lock in your{' '}
            {service === ServiceEnum.DoorToDoor ? 'monthly' : 'hourly'} rate
          </Text.Body>
        </Box>
      </Box>
    </div>
  );
};
