import React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, mq, toggleStyleValue } from '@clutter/clean';
import { useAnimatedEntry } from '@utils/hooks/use_animated_entry';

const TRANSITION_MS = 600;

const Container = styled.div<{
  show: boolean;
  hideMobile?: boolean;
}>`
  background-color: white;
  position: fixed;
  bottom: 0%;
  left: 0;
  width: 100%;
  padding: 16px 24px;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));

  visibility: visible;

  z-index: 10;
  box-shadow: 0 0 16px -2px rgba(0, 0, 0, 0.1);

  form {
    float: right;
  }

  b {
    margin: 22px 0;
    font-size: 16px;

    ${mq({
      fontSize: ['16px', null, '24px'],
    })}
  }

  @media (min-width: ${({ hideMobile }) =>
      hideMobile ? '0px' : BREAKPOINTS.SM}) {
    bottom: ${toggleStyleValue('show', '0', '-86px')};
    opacity: ${toggleStyleValue('show', '1', '0')};
    transition: bottom ${TRANSITION_MS}ms, opacity ${TRANSITION_MS}ms;
  }
`;

const PinnedFooterContainer = React.forwardRef<
  HTMLDivElement,
  {
    show: boolean;
    hideMobile?: boolean;
    children: React.ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>
>(({ show, hideMobile, children, ...rest }, ref) => {
  const { renderChildren, isOpen: debouncedShow } = useAnimatedEntry(
    show,
    TRANSITION_MS,
  );
  return (
    <Container ref={ref} show={debouncedShow} hideMobile={hideMobile} {...rest}>
      {renderChildren && children}
    </Container>
  );
});

export { PinnedFooterContainer };
