import * as React from 'react';

import { COLORS, Text } from '@clutter/clean';
import { PricingSummary } from '@utils/hooks/pricing/use_pricing_summary';
import { pluralize } from '@utils/pluralize';

export const PerMoverHourDisclaimer: React.FC<{
  pricingSummary?: PricingSummary;
}> = ({ pricingSummary }) => {
  if (!pricingSummary) return null;
  const baseLaborRateAmount = pricingSummary.baseLaborRateAmount;
  const estimatedMovers = pricingSummary.estimatedMovers;

  return (
    <Text.Caption color={COLORS.hippo}>
      * ${baseLaborRateAmount}/hr per Move & Pack specialist. Your plan size
      should take {pluralize(estimatedMovers, 'specialist', 'specialists')}.
    </Text.Caption>
  );
};
