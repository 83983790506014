import { ApolloClient } from '@apollo/client';

import { LeadDocument, LeadQuery, LeadQueryVariables } from '@graphql/platform';
import { TEAL_USER_ID } from '@root/components/checkout/helpers/agent_booking';
import { CheckoutType } from '@root/components/checkout/types';
import { parseDraftToValues } from '@root/components/checkout/utilities/drafts';
import { InitialValues } from '@root/components/checkout/utilities/initial_values/types';
import { formatPhoneNumber } from '@utils/formatters';

export type ShareableOptions = {
  client: ApolloClient<unknown>;
  leadToken: string;
};

export async function getShareableInitialValues({
  client,
  leadToken,
}: ShareableOptions): Promise<InitialValues | undefined> {
  const lead = (
    await client.query<LeadQuery, LeadQueryVariables>({
      query: LeadDocument,
      variables: { token: leadToken },
    })
  )?.data?.lead;

  if (!lead) return;

  const { name, phone, email, draft } = lead;

  const { storageData, movingData } = draft
    ? await parseDraftToValues(client, draft)
    : ({} as Awaited<ReturnType<typeof parseDraftToValues>>);

  const sharedProperties = {
    checkoutType: TEAL_USER_ID ? CheckoutType.Agent : CheckoutType.Shareable,
    name: name ?? undefined,
    phone: phone ? formatPhoneNumber(phone) : undefined,
    email: email ?? undefined,
    leadToken,
  };

  return {
    movingData: { ...movingData, ...sharedProperties },
    storageData: { ...storageData, ...sharedProperties },
  };
}
