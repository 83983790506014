import { DateTime } from 'luxon';

const DATE_TIME_RESET = { hour: 0, minute: 0, second: 0, millisecond: 0 };
const SUNDAY = { weekday: 7 };
const LOOKBACK = { weeks: 1 };
export const FROM_ISO_OPTIONS = { setZone: true };

export const normalizeDate = (date: DateTime) =>
  date.set(DATE_TIME_RESET).minus(LOOKBACK).set(SUNDAY);

export const hashDateTime = (datetime: DateTime) =>
  `${datetime.year}-${datetime.month}-${datetime.day}`;
