import React, { useState } from 'react';

import { COLORS } from '@clutter/clean';

export const InfoTooltipIcon: React.FC = () => {
  const [hovering, setHovering] = useState(false);
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <circle
        cx="7.5"
        cy="7.5"
        r="6.9"
        fill={hovering ? COLORS.tealPrimary : 'transparent'}
        stroke={COLORS.tealPrimary}
        strokeWidth="1.2"
      />
      <path
        d="M6.57227 8.63184V8.27051C6.57227 7.95801 6.639 7.6862 6.77246 7.45508C6.90592 7.22396 7.15007 6.97819 7.50488 6.71777C7.84668 6.47363 8.07129 6.27507 8.17871 6.12207C8.28939 5.96908 8.34473 5.79818 8.34473 5.60938C8.34473 5.39779 8.2666 5.23665 8.11035 5.12598C7.9541 5.0153 7.736 4.95996 7.45605 4.95996C6.96777 4.95996 6.41113 5.11947 5.78613 5.43848L5.25391 4.36914C5.97982 3.96224 6.74967 3.75879 7.56348 3.75879C8.23405 3.75879 8.76628 3.91992 9.16016 4.24219C9.55729 4.56445 9.75586 4.99414 9.75586 5.53125C9.75586 5.88932 9.67448 6.19857 9.51172 6.45898C9.34896 6.7194 9.03971 7.01237 8.58398 7.33789C8.27148 7.56901 8.07292 7.74479 7.98828 7.86523C7.9069 7.98568 7.86621 8.14355 7.86621 8.33887V8.63184H6.57227ZM6.41602 10.3018C6.41602 10.0283 6.48926 9.82161 6.63574 9.68164C6.78223 9.54167 6.99544 9.47168 7.27539 9.47168C7.54557 9.47168 7.75391 9.54329 7.90039 9.68652C8.05013 9.82975 8.125 10.0348 8.125 10.3018C8.125 10.5589 8.05013 10.7624 7.90039 10.9121C7.75065 11.0586 7.54232 11.1318 7.27539 11.1318C7.00195 11.1318 6.79036 11.0602 6.64062 10.917C6.49089 10.7705 6.41602 10.5654 6.41602 10.3018Z"
        fill={hovering ? COLORS.cloud : COLORS.tealPrimary}
      />
    </svg>
  );
};
