import ReactRailsUJS from 'react_ujs';

import { ready } from './ready';

// This function also mounts non-SSR components, but the majority of our pages
// are server rendered.
export function hydrate() {
  ready(() => {
    ReactRailsUJS.mountComponents();
  });
}
