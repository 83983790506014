import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { COLORS } from '@clutter/clean';

const spin = keyframes`
    0% {
        transform: rotate(-180deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
`;

const SpinLoader = styled.span<{ color?: string }>`
  border: 2px solid ${({ color }) => color || COLORS.tealBrand};
  border-top-color: transparent;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s ease infinite;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
`;

export { SpinLoader };
