import React, { useState } from 'react';

import styled from '@emotion/styled';

import { COLORS, mq, toggleStyleValue } from '@clutter/clean';

const BORDER_HOVER = '#c4c4c4';

const Outer = styled.label<{ selected: boolean; focusVisible?: boolean }>`
  border: 1px solid var(--border);
  background: var(--background);
  border-radius: 4px;
  cursor: pointer;
  display: block;
  position: relative;
  transition: background 150ms ease;

  ${toggleStyleValue(
    'focusVisible',
    `box-shadow: 0 0 0 2px var(--outline), 0 0 0 4px ${COLORS.cloud}, 0 0 0 7px ${COLORS.tealBrand};`,
    'box-shadow: 0 0 0 2px var(--outline)',
  )};

  ${({ selected }) =>
    selected
      ? {
          '--border': COLORS.tealPrimary,
          '--outline': COLORS.tealPrimary,
          '--background': COLORS.tealBackground,
        }
      : {
          '--border': COLORS.grayBorder,
          '--outline': 'transparent',
          '--background': COLORS.cloud,
        }}

  &:hover {
    ${({ selected }) =>
      selected
        ? ''
        : {
            '--border': BORDER_HOVER,
            '--outline': BORDER_HOVER,
          }}
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
`;

const MainContent = styled.div<{ flush?: boolean }>`
  margin: 0;

  ${({ flush }) =>
    mq({
      padding: flush ? 0 : ['12px', null, '16px 12px'],
    })}
`;

export const RadioCard: React.FC<
  {
    selected: boolean;
    bannerText?: string;
    flush?: boolean;
  } & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  selected,
  bannerText,
  flush,
  children,
  style,
  className,
  ...inputProps
}) => {
  const [focusVisible, setFocusVisible] = useState(false);

  return (
    <Outer
      selected={selected}
      focusVisible={focusVisible}
      style={style}
      className={className}
      onBlur={() => setFocusVisible(false)}
      onFocus={(e) => {
        setFocusVisible(e.target.classList.contains('focus-visible'));
      }}
    >
      <MainContent flush={flush}>{children}</MainContent>
      <Input type="radio" checked={selected} {...inputProps} />
    </Outer>
  );
};
