import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, mq, Text } from '@clutter/clean';
import { RateGroupKindEnum } from '@graphql/platform';
import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { Commitment as CommitmentType } from '@root/resources/types/commitment';
import { useStoragePriceCompletedFunnelEvent } from '@utils/hooks/funnel_events/use_storage_price_completed_funnel_event';
import {
  PricingSummary,
  roundedDiscountPercentage,
} from '@utils/hooks/pricing/use_pricing_summary';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { useFreePickupReturnEligible } from '@utils/hooks/use_free_pickup_return_eligible';
import { useLastDefined } from '@utils/hooks/use_last_defined';
import { extractStoragePrice } from '@utils/pricing/pricing_set';

import { RadioCard } from '../../subcomponents/radio_card';
import { StoragePlanValueProps } from '../../subcomponents/storage_plan_value_props';

import { DiscountBanner } from './commitment/discount_banner';
import { SavingsSummary } from './commitment/savings_summary';

const CommitmentCards = styled.div`
  display: grid;
  ${mq({
    gridTemplateColumns: ['1fr', null, '1fr 1fr 1fr'],
    gridGap: ['10px', null, '16px'],
  })}
`;

const GroupedCommitmentContainer = styled.div`
  display: flex;
  gap: 12px;

  & > label {
    ${mq({
      margin: ['initial', null, '12px 0'],
      flexBasis: [1, null, '33%'],
    })}
  }
  & > div {
    display: flex;
    gap: 12px;
    padding: 12px;
    border: 3px dashed ${COLORS.tealPrimary};
    border-radius: 8px;

    & > label {
      flex-basis: 50%;
    }

    ${mq({
      flexDirection: ['column', null, 'row'],
      flexBasis: [1, null, '67%'],
    })}
  }
  ${mq({ flexDirection: ['column', null, 'row'] })}
`;

const CommitmentCardContents: React.FC<{
  commitment: RateGroupKindEnum;
  summary?: PricingSummary;
  defaultPercent?: number;
  titleText: React.ReactNode;
}> = ({ commitment, summary, defaultPercent, titleText }) => {
  const discountedPrice =
    commitment === RateGroupKindEnum.Saver
      ? summary?.saverMonthlyAmount
      : commitment === RateGroupKindEnum.Flexer
      ? summary?.flexerMonthlyAmount
      : undefined;

  const discountText =
    commitment === RateGroupKindEnum.Mover
      ? 'Most flexible'
      : `${
          summary && discountedPrice
            ? roundedDiscountPercentage(
                summary.moverMonthlyAmount,
                discountedPrice,
              )
            : defaultPercent
        }% discount`;

  return (
    <Box.Flex
      textAlign="center"
      flexDirection="column"
      justifyContent="center"
      height="100%"
      padding="0 4px"
    >
      <Text.Title size="extraSmall">{titleText}</Text.Title>
      <Box
        padding="2px 4px"
        margin="0 auto"
        background={
          commitment === RateGroupKindEnum.Mover ? 'transparent' : COLORS.dust
        }
        borderRadius="4px"
      >
        <Text.Caption weight={FontWeight.Medium} color={COLORS.moss}>
          {discountText}
        </Text.Caption>
      </Box>
    </Box.Flex>
  );
};

type CommitmentStepProps = StorageCheckoutStepProps & {
  movingCheckoutFlow: boolean;
};

export const Commitment: React.FC<CommitmentStepProps> = ({
  onChange,
  scrollToStep,
  values: { commitment, planSize },
  movingCheckoutFlow,
}) => {
  const { isMobile } = useBreakpoints();
  const {
    pricingSummary: summary,
    defaultPricingSummary,
    pricingSet,
  } = useSharedCheckoutContext();

  const freePickupReturnEligible = useFreePickupReturnEligible();
  const pricingSummary = useLastDefined(summary);
  const trackStoragePriceCompleted = useStoragePriceCompletedFunnelEvent();
  const visible = !movingCheckoutFlow || (movingCheckoutFlow && planSize);

  const handleChange = (term: CommitmentType) => {
    if (planSize && pricingSet) {
      // This can't be read from the pricing summary as fees are currently
      // loaded asynchronously.
      const amount = extractStoragePrice(term, planSize, pricingSet);
      trackStoragePriceCompleted({
        planSize,
        commitment: term,
        amount: amount!,
      });
    }

    onChange('commitment', term);
    !isMobile && scrollToStep();
  };

  if (!visible) {
    return null;
  }

  const flexerDefaultPercent = defaultPricingSummary
    ? roundedDiscountPercentage(
        defaultPricingSummary.moverMonthlyAmount,
        defaultPricingSummary.flexerMonthlyAmount,
      )
    : 20;

  const saverDefaultPercent = defaultPricingSummary
    ? roundedDiscountPercentage(
        defaultPricingSummary.moverMonthlyAmount,
        defaultPricingSummary.saverMonthlyAmount,
      )
    : 40;

  const moverCard = (
    <RadioCard
      selected={commitment === RateGroupKindEnum.Mover}
      onChange={() => handleChange(RateGroupKindEnum.Mover)}
      name="commitment"
      value="mover"
    >
      <CommitmentCardContents
        commitment={RateGroupKindEnum.Mover}
        summary={pricingSummary}
        titleText="Month to month"
      />
    </RadioCard>
  );

  const flexerCard = (
    <RadioCard
      selected={commitment === RateGroupKindEnum.Flexer}
      onChange={() => handleChange(RateGroupKindEnum.Flexer)}
      name="commitment"
      value="flexer"
    >
      <CommitmentCardContents
        commitment={RateGroupKindEnum.Flexer}
        summary={pricingSummary}
        defaultPercent={flexerDefaultPercent}
        titleText={
          <>
            4 <span style={{ whiteSpace: 'nowrap' }}>months +</span>
          </>
        }
      />
    </RadioCard>
  );

  const saverCard = (
    <RadioCard
      selected={commitment === RateGroupKindEnum.Saver}
      onChange={() => handleChange(RateGroupKindEnum.Saver)}
      name="commitment"
      value="saver"
    >
      <CommitmentCardContents
        commitment={RateGroupKindEnum.Saver}
        summary={pricingSummary}
        defaultPercent={saverDefaultPercent}
        titleText={
          <>
            8 <span style={{ whiteSpace: 'nowrap' }}>months +</span>
          </>
        }
      />
    </RadioCard>
  );

  return (
    <Box margin="48px 0 0">
      <Text.Title size="small" color={COLORS.tealDark}>
        Select term length
      </Text.Title>
      <Box margin="4px 0 0">
        <Text.Body color={COLORS.storm}>
          The longer you store, the more you save.
        </Text.Body>
      </Box>
      <Box>
        <Box margin="16px 0 0" position="relative">
          {freePickupReturnEligible && !movingCheckoutFlow ? (
            <>
              <GroupedCommitmentContainer>
                {moverCard}
                <Box>
                  {flexerCard}
                  {saverCard}
                </Box>
              </GroupedCommitmentContainer>{' '}
              <DiscountBanner>
                <Text.Title size="extraSmall" color={COLORS.cloud}>
                  Free pickup and free delivery
                </Text.Title>
                <Text.Body color={COLORS.cloud}>
                  Free first pickup and final delivery when you store for at
                  least 4 months! You’ll never visit a self-storage unit again.
                  It’s that simple.
                </Text.Body>
              </DiscountBanner>
            </>
          ) : (
            <CommitmentCards>
              {moverCard}
              {flexerCard}
              {saverCard}
            </CommitmentCards>
          )}
        </Box>
        <Box margin="24px 0 0">
          <SavingsSummary />
        </Box>
      </Box>
      <Box margin="32px 0 0" display={['block', null, 'none']}>
        <StoragePlanValueProps />
      </Box>
    </Box>
  );
};
