import React, { useEffect } from 'react';

import { Box, COLORS, Text } from '@clutter/clean';
import {
  LatLngFragment,
  Location__City,
  Location__State,
  useSeoRegionPolygonQuery,
} from '@graphql/platform';
import {
  DEFAULT_MAP_STYLE,
  MapProvider,
  useMapContext,
} from '@shared/map/context';
import { Polygon } from '@shared/map/polygon';

const MAP_OPTIONS = {
  styles: DEFAULT_MAP_STYLE,
};

const POLYGON_OPTIONS: google.maps.PolygonOptions = {
  fillColor: COLORS.tealPrimary,
  strokeColor: COLORS.tealBrand,
};

const CityMap: React.FC<{
  state: Pick<Location__State, 'slug'>;
  city: Pick<Location__City, 'name' | 'slug'> & { center: LatLngFragment };
}> = ({ state, city }) => {
  const { data } = useSeoRegionPolygonQuery({
    variables: { city: city.slug, state: state.slug },
  });

  const { fitToBounds } = useMapContext();

  useEffect(() => {
    const polygons = data?.state?.city?.polygons;
    if (polygons) {
      fitToBounds(polygons.map((p) => p.paths).flat(2));
    }
  }, [fitToBounds, data]);

  return (
    <Box textAlign="center">
      <Text.Title size="large" tag="h2">
        We pick up and deliver all over {city.name}
      </Text.Title>
      <Box
        height={['300px', '600px']}
        width="100%"
        maxWidth="1200px"
        margin="40px auto 0"
      >
        <MapProvider.Map
          center={city.center}
          options={MAP_OPTIONS}
          defaultZoom={9}
        >
          {data?.state?.city?.polygons.map((p, i) => (
            <Polygon key={i} polygon={p.paths} options={POLYGON_OPTIONS} />
          ))}
        </MapProvider.Map>
      </Box>
    </Box>
  );
};

const WithProvider: React.FC<React.ComponentProps<typeof CityMap>> = (
  props,
) => (
  <MapProvider includeMapComponent={false}>
    <CityMap {...props} />
  </MapProvider>
);

export { WithProvider as CityMap };
