import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { BREAKPOINTS } from '@clutter/clean';
import { considerateThrow } from '@utils/monitoring';

const isSSR = typeof window === 'undefined';

export const useBreakpoint = (minWidth: number) => {
  if (isSSR) {
    considerateThrow(new Error('Do not use this when server rendering!'));
  }

  const [breakpointMet, setBreakpointMet] = useState(
    (isSSR ? 0 : window.innerWidth) > minWidth,
  );

  useEffect(() => {
    const onResize = () => {
      setBreakpointMet(window.innerWidth > minWidth);
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [minWidth]);

  return breakpointMet;
};

const AUTOFILLABLE_INPUTS = {
  text: true,
  tel: true,
  email: true,
  number: true,
};

export const useAutofillOnChangeHack = (
  form: HTMLFormElement | null,
  onChange: (fieldName: string, value: any, ...rest: any[]) => void,
) => {
  // Locally track changes to inputs
  const changeRef = useRef<Record<string, any>>({ field: '', value: '' });

  // Event listenener to update locally tracked changes
  const wrappedOnChange = React.useCallback(
    (field: string, value: any, ...rest: any[]) => {
      changeRef.current = { field, value };
      onChange(field, value, ...rest);
    },
    [form, onChange],
  );

  const autofillOnChange = React.useCallback(
    // `any` used because of gap between `React.InputEvent` and native `Event`
    (e: any) => {
      // If we don't do the following check, `onChange` will be called twice with the same field and value,
      // first when the user changes the input value (via the React `onChange`), and then again (here in this
      // listener) once the element loses focus.
      if (
        changeRef.current.field !== e.currentTarget.name ||
        changeRef.current.value !== e.currentTarget.value
      ) {
        wrappedOnChange(e.currentTarget.name, e.currentTarget.value);
      }
    },
    [],
  );

  React.useEffect(() => {
    // Bail early if no form yet or using a non-broken browser
    if (!form || !navigator.userAgent.includes('CriOS')) {
      return;
    }

    const inputs = form ? Array.from(form.getElementsByTagName('input')) : [];
    inputs.forEach((input) => {
      if (!(input.type in AUTOFILLABLE_INPUTS)) {
        return;
      }
      input.addEventListener('change', autofillOnChange);
    });

    return () =>
      inputs.forEach((input) =>
        input.removeEventListener('change', autofillOnChange),
      );
  }, [form]);

  return wrappedOnChange;
};

// If this is used by a pack, ensure that a polyfill is imported before any other modules
export const useIntersectionObserver = ({
  root,
  rootMargin,
  threshold,
}: IntersectionObserverInit = {}) => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | null>(null);
  const [element, setElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') return;
    if (!element) return;

    const observer = new IntersectionObserver(
      ([currentEntry]) => updateEntry(currentEntry),
      { root, rootMargin, threshold },
    );
    observer.observe(element);

    return () => observer.disconnect();
  }, [element, root, rootMargin, threshold]);

  return [setElement, entry] as const;
};

export const useHasIntersected = (config?: IntersectionObserverInit) => {
  const [targetRef, entry] = useIntersectionObserver(config);
  const [hasIntersected, setHasIntersected] = React.useState(false);

  React.useEffect(() => {
    if (!hasIntersected && entry?.isIntersecting) {
      setHasIntersected(true);
    }
  }, [entry?.isIntersecting]);

  return {
    targetRef,
    hasIntersected,
  };
};

export const useDeferredImage = (src: string, srcSet?: string) => {
  const { targetRef, hasIntersected } = useHasIntersected({
    rootMargin: '50px',
  });
  return {
    targetRef,
    resolvedImage: hasIntersected ? { src, srcSet } : undefined,
  };
};

/**
 * A hook to disable body scrolling (e.g. when a modal is open). Relies on
 * styles from <SharedApp />
 *
 * @param lock Whether scroll should be locked. Will update on changes.
 */
export const useLockScroll = (lock: boolean) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (lock) {
      const windowYOffset = window.pageYOffset;
      setScrollPosition(windowYOffset);
      document.body.style.setProperty('--offset-top', `-${windowYOffset}px`);
      document.body.classList.add('locked_position');
      // iOS Safari requires position fixed, but desktop safari has a weird flicker when it's present
      if (window.innerWidth < BREAKPOINTS.SM_INT) {
        document.body.classList.add('fixed');
      }
    } else {
      if (!hasRunRef.current) {
        // Ensure that hook doesn't cause scroll side effects on initial mount
        hasRunRef.current = true;
        return;
      }
      document.body.classList.remove('locked_position', 'fixed');
      window.scrollTo(0, scrollPosition);
    }
  }, [lock]);
};
