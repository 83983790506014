import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../initializers/full_story';
import '../initializers/gtm';
import '../initializers/impact';
import '../initializers/new_relic';
import '../initializers/sentry';
import '../styles/location';
import ReactRailsUJS from 'react_ujs';

import { ClientLocation as ClientLocation } from '@root/root/location/client';
import { hydrate } from '@utils/hydrate';

import { trackPageview } from '../initializers/wt';

trackPageview();

ReactRailsUJS.getConstructor = (name: string) => {
  switch (name) {
    case 'Location':
      return ClientLocation;
  }
};

hydrate();
