import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import {
  ErrorCodeEnum,
  ErrorFragment,
  Lead__ServiceNeeds,
  LeadCreateDocument,
  LeadCreateMutation,
  LeadInput,
} from '@graphql/platform';
import { Errors } from '@root/resources/errors';
import { PHONE_MASK } from '@root/resources/masks';
import { IMapping } from '@root/resources/types/mapping';
import { ServiceEnum } from '@root/resources/types/service';
import { Action, useClientDataContext } from '@shared/client_data_context';
import { FieldsList } from '@shared/fields_list';
import { GQLError } from '@utils/gql_errors';
import { titleize } from '@utils/text';
import {
  createThirdPartyConversionEvent,
  EventName,
} from '@utils/third_party_conversion_events';

type LeadAttributes = {
  name?: string;
  phone?: string;
  email?: string;
  zip?: string;
  customerToken?: string;
  source?: string;
  serviceNeeds?: Lead__ServiceNeeds[];
};

const SERVICE_CONTEXT_TO_SERVICE_NEEDS = {
  [ServiceEnum.DoorToDoor]: Lead__ServiceNeeds.SmartStorage,
  [ServiceEnum.Moving]: Lead__ServiceNeeds.Moving,
  [ServiceEnum.SelfStorage]: Lead__ServiceNeeds.SelfStorage,
};

export const useCreateLead = () => {
  const {
    data: {
      lead: { source },
    },
    dispatch,
  } = useClientDataContext();
  const client = useApolloClient();

  return async function createLead({
    leadAttributes,
    validateCustomerStatus,
    serviceContext,
  }: {
    leadAttributes: LeadAttributes;
    validateCustomerStatus?: boolean;
    serviceContext: ServiceEnum;
  }) {
    if (!leadAttributes.email && !leadAttributes.phone) {
      throw new Error(
        'LeadForm cannot create lead: email and phone are missing.',
      );
    }

    const input: LeadInput = {
      state: 'open',
      url: window.location.href,
      source,
      ...leadAttributes,
      serviceNeeds: leadAttributes.serviceNeeds || [
        SERVICE_CONTEXT_TO_SERVICE_NEEDS[serviceContext],
      ],
    };

    const { data, errors: mutationErrors } =
      await client.mutate<LeadCreateMutation>({
        mutation: LeadCreateDocument,
        variables: {
          input,
          validateCustomerStatus,
        },
      });

    if (mutationErrors) {
      throw new Error(mutationErrors.map((e) => e.message).join(', '));
    } else if (data?.result?.error) {
      const { errorCode } = data.result.error;

      const notBookingWarehouseStorage =
        serviceContext === ServiceEnum.Moving ||
        serviceContext === ServiceEnum.SelfStorage;
      const pendingOrActiveWarehouseStorage =
        errorCode === ErrorCodeEnum.PendingStorageOnboarding ||
        errorCode === ErrorCodeEnum.ActiveStorageAccount;
      const isExistingCustomer = errorCode === ErrorCodeEnum.ExistingCustomer;

      const canMFA =
        isExistingCustomer ||
        (pendingOrActiveWarehouseStorage && notBookingWarehouseStorage);

      throw new GQLError(data?.result?.error, { canResolveViaMFA: canMFA });
    } else if (!data?.result?.lead?.token) {
      throw new Error('Token not present');
    }

    const { token, id, currentStorageCustomer } = data.result.lead;

    if (leadAttributes.email) {
      dispatch({
        type: Action.SetLead,
        payload: { token, email: leadAttributes.email },
      });
    }

    createThirdPartyConversionEvent(EventName.LEAD, {
      name: leadAttributes.name,
      phone: leadAttributes.phone,
      email: leadAttributes.email,
    });
    return { token, id, currentStorageCustomer };
  };
};

const MAPPING: IMapping = {
  email: {
    type: 'text',
    placeholder: 'Email',
    label: 'Email',
    transformation: (value) => value.trim(),
  },
  phone: {
    label: 'Phone',
    placeholder: 'Phone',
    type: 'text',
    mask: PHONE_MASK,
  },
  name: {
    type: 'text',
    placeholder: 'Name',
    label: 'First & Last',
    transformation: titleize,
  },
};

const DISABLED_FIELDS = {
  name: true,
  email: true,
  phone: true,
};

export const DEFAULT_LEAD_FIELDS: LeadField[] = ['name', 'phone', 'email'];

export type LeadField = 'name' | 'phone' | 'email';

export type LeadFormRef = {
  createLead(): Promise<{
    id?: string;
    token?: string;
    error?: ErrorFragment;
    canMFA?: boolean;
    currentStorageCustomer?: boolean;
  }>;
};

/** LeadForm exposes the ability (via ref) to create a lead from a parent component
 * while still handling validation and network requests internally.
 * Example usage:
 *
 * ```tsx
 * const ParentComponent = () => {
 *   const ref = useRef<LeadFormRef>(null);
 *
 *   const handleSubmit = async () => {
 *     const { token } = await ref.current!.createLead();
 *     if (!token) { return; } // indicates a validation error
 *     useToken(token)
 *   }
 *
 *   return (
 *    <Container onSubmit={handleSubmit}>
 *      <LeadForm ref={ref} leadAttributes={leadAttributes} onChange={onChange} />
 *      <OtherComponents />
 *    </Container>
 *   );
 * }
 * ```
 *  */
export const LeadForm = forwardRef<
  LeadFormRef,
  {
    errors?: Errors;
    disabled?: boolean;
    validateCustomerStatus?: boolean;
    leadAttributes: LeadAttributes;
    fields?: LeadField[];
    mapping?: IMapping;
    serviceContext: ServiceEnum;
    onChange(key: LeadField, value: string): void;
  }
>(
  (
    {
      leadAttributes,
      disabled,
      validateCustomerStatus,
      errors,
      fields = DEFAULT_LEAD_FIELDS,
      mapping = MAPPING,
      serviceContext,
      onChange,
    },
    ref,
  ) => {
    const [showErrors, setShowErrors] = useState(false);
    const createLead = useCreateLead();

    useImperativeHandle(ref, () => ({
      createLead: async () => {
        if (errors?.anyForFields(fields)) {
          setShowErrors(true);
          return {};
        }

        try {
          const result = await createLead({
            leadAttributes,
            validateCustomerStatus,
            serviceContext,
          });
          return result;
        } catch (err) {
          setShowErrors(true);
          throw err;
        }
      },
    }));

    return (
      <FieldsList
        resource={{
          name: leadAttributes.name,
          phone: leadAttributes.phone,
          email: leadAttributes.email,
        }}
        disabled={disabled ? DISABLED_FIELDS : undefined}
        errors={showErrors ? errors?.messages : undefined}
        fields={fields}
        onChange={(key, value) => {
          setShowErrors(false);
          onChange(key as any, value as string);
        }}
        mapping={mapping}
      />
    );
  },
);
