import React, { useEffect } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, mq, Text } from '@clutter/clean';
import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { EventSchema } from '@root/resources/wt/types';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { ProtectionPlanEnum } from '@utils/protection_plan';

import { CardContents } from '../../subcomponents/card_contents';
import { RadioCard } from '../../subcomponents/radio_card';
import { SkipButton } from '../../subcomponents/skip_button';
import { StorageMapValueProps } from '../../subcomponents/storage_map_value_props';

import protection1000 from '@images/illustrations/protection_plan_basic_small.svg';
import protection2500 from '@images/illustrations/protection_plan_premium_small.svg';

const PlanCards = styled.div`
  display: grid;

  ${mq({
    marginTop: ['24px', '32px'],
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: ['12px', '16px'],
  })}
`;

type ProtectionPlanStepProps = StorageCheckoutStepProps & {
  movingCheckoutFlow: boolean;
};

export const ProtectionPlan: React.FC<ProtectionPlanStepProps> = ({
  enabled,
  onChange,
  scrollToStep,
  values: { protectionPlan, planSize },
  movingCheckoutFlow,
}) => {
  const { disposalEligible } = useSharedCheckoutContext();
  const { isMobile } = useBreakpoints();
  const trackFunnelEvent = useTrackFunnelEvents();
  const visible = !movingCheckoutFlow || (movingCheckoutFlow && planSize);
  const disposalStepVisible = disposalEligible;

  useEffect(() => {
    if (enabled && visible)
      trackFunnelEvent({
        schema: EventSchema.WWW__ProtectionPlanViewed,
        action: 'display',
        metadata: {},
      });
  }, [enabled, trackFunnelEvent, visible]);

  const handleChange = (plan: ProtectionPlanEnum) => {
    trackFunnelEvent({
      schema: EventSchema.WWW__ProtectionPlanCompleted,
      action: 'submit',
      metadata: {
        protection_plan_slug:
          plan === ProtectionPlanEnum.MINIMAL_COVERAGE ? undefined : plan,
      },
    });
    onChange('protectionPlan', plan);
    if (movingCheckoutFlow || disposalStepVisible) {
      scrollToStep();
    } else {
      isMobile && scrollToStep('current');
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <Box margin={['48px 0 0', null, '72px 0 0']}>
      <Text.Headline weight={FontWeight.Medium} color={COLORS.tealDark}>
        Add a protection plan?
      </Text.Headline>
      <Box margin="4px 0 0">
        <Text.Body color={COLORS.storm}>
          Choose how much coverage you need.
        </Text.Body>
      </Box>
      <PlanCards>
        <RadioCard
          name="protectionPlan"
          value="Standard"
          selected={protectionPlan === ProtectionPlanEnum.PROTECTION_1000}
          onChange={() => handleChange(ProtectionPlanEnum.PROTECTION_1000)}
        >
          <CardContents
            label="Standard"
            description="Protects items up to $1,000."
            price={15}
            imgSrc={protection1000}
            suffix="/mo"
          />
        </RadioCard>
        <RadioCard
          name="protectionPlan"
          value="Premium"
          selected={protectionPlan === ProtectionPlanEnum.PROTECTION_2500}
          onChange={() => handleChange(ProtectionPlanEnum.PROTECTION_2500)}
        >
          <CardContents
            label="Premium"
            description="Protects items up to $2,500."
            price={25}
            imgSrc={protection2500}
            suffix="/mo"
          />
        </RadioCard>
      </PlanCards>
      <SkipButton
        buttonText="Skip, I don’t need a protection plan"
        selected={protectionPlan === ProtectionPlanEnum.MINIMAL_COVERAGE}
        trackingObjectName="skip_protection_plan"
        name="protectionPlan"
        value="minimal_coverage"
        onSkip={() => {
          handleChange(ProtectionPlanEnum.MINIMAL_COVERAGE);
        }}
      />
      <Box textAlign="center" display={['block', null, 'none']}>
        <StorageMapValueProps />
      </Box>
    </Box>
  );
};
