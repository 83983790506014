import * as React from 'react';

import { formatPhoneLink, formatPhoneNumber } from '@utils/formatters';

import { CONTACT_PHONE } from '../../config/constants';

interface IPhoneLinkProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode | ((phone: string) => React.ReactNode);
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
}

export const ContactPhoneLink: React.FC<IPhoneLinkProps> = (props) => {
  const formattedNumber = formatPhoneNumber(CONTACT_PHONE);
  const { children } = props;

  return (
    <a href={formatPhoneLink(CONTACT_PHONE)} {...props}>
      {typeof children === 'function' ? (
        children(formattedNumber)
      ) : children ? (
        <>
          {children} {formattedNumber}
        </>
      ) : (
        formattedNumber
      )}
    </a>
  );
};
