import { PricingSetFragment, RateGroupKindEnum } from '@graphql/platform';
import { PlanKey } from '@root/resources/types/plan_key';

const extractLaborEntries = (pricingSet?: PricingSetFragment) =>
  pricingSet?.laborPricingGroupEntries;
const extractPricingGroupEntry = (
  rateGroupName?: RateGroupKindEnum,
  planSize?: PlanKey,
  pricingSet?: PricingSetFragment,
) =>
  pricingSet?.storagePricingGroupEntries.find(
    (e) =>
      e.rateGroup?.name === rateGroupName && e.pricing.plan.name === planSize,
  );

export const extractRateGroup = (
  rateGroupName?: string,
  pricingSet?: PricingSetFragment,
) =>
  extractLaborEntries(pricingSet)?.find(
    (entry) => entry.rateGroup?.name === rateGroupName,
  )?.rateGroup ?? undefined;

export const extractLaborPolicy = (
  rateGroupName: string,
  pricingSet?: PricingSetFragment,
) =>
  extractLaborEntries(pricingSet)?.find(
    (entry) => entry.rateGroup?.name === rateGroupName,
  )?.laborPolicy;

export const extractPlan = (
  rateGroupName?: RateGroupKindEnum,
  planSize?: PlanKey,
  pricingSet?: PricingSetFragment,
) =>
  extractPricingGroupEntry(rateGroupName, planSize, pricingSet)?.pricing?.plan;

export const extractStoragePrice = (
  rateGroupName?: RateGroupKindEnum,
  planSize?: PlanKey,
  pricingSet?: PricingSetFragment,
) =>
  extractPricingGroupEntry(rateGroupName, planSize, pricingSet)?.pricing.amount;
