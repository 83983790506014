import React from 'react';

import { Box, COLORS, Dropdown, FontWeight, Text } from '@clutter/clean';
import { Moving__BuildingTypeEnum } from '@graphql/platform';
import { useMovingCheckoutContext } from '@root/components/checkout/context';
import { MovingCheckoutData } from '@root/components/checkout/data';
import {
  MovingCheckoutStepProps,
  MovingStepDescriptor,
} from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';
import { StrictMovingBuildingType } from '@root/resources/types/address';

type MoveSizeOption = { label: string; unitType?: string; value: any };

export const SHORT_MOVE_ROOMS = ['1_item', '10_items', 'dorm_room'];

export const MOVE_SIZE_MAPPINGS: Record<
  string,
  { label: string; options: MoveSizeOption[] }
> = {
  [Moving__BuildingTypeEnum.Apartment]: {
    label: 'Select apartment size',
    options: [
      {
        label: 'Studio',
        value: 0,
      },
      {
        label: '1 bedroom',
        value: 1,
      },
      {
        label: '2 bedrooms',
        value: 2,
      },
      {
        label: '3 bedrooms',
        value: 3,
      },
      {
        label: '4+ bedrooms',
        value: 4,
      },
    ],
  },
  [Moving__BuildingTypeEnum.House]: {
    label: 'Select home size',
    options: [
      {
        label: '1 bedroom',
        value: 1,
      },
      {
        label: '2 bedrooms',
        value: 2,
      },
      {
        label: '3 bedrooms',
        value: 3,
      },
      {
        label: '4+ bedrooms',
        value: 4,
      },
    ],
  },
  [Moving__BuildingTypeEnum.StorageFacilityOrWarehouse]: {
    label: 'Select storage unit size',
    options: [
      { label: 'Locker', value: 'locker' },
      { label: '5’ × 5’', value: '5x5' },
      { label: '5’ × 10’', value: '5x10' },
      { label: '10’ × 10’', value: '10x10' },
      { label: '10’ × 15’', value: '10x15' },
      { label: '10’ × 20’', value: '10x20' },
      { label: '10’ × 25’', value: '10x25' },
      { label: '10’ × 30’', value: '10x30' },
    ],
  },
  [Moving__BuildingTypeEnum.Commercial]: {
    label: 'Select move size',
    options: [
      { label: 'Under 800 sqft', value: 600 },
      { label: '801 - 1000 sqft', value: 800 },
      { label: '1001 - 1500 sqft', value: 1000 },
      { label: '1501 - 2000 sqft', value: 1500 },
      { label: '2001 - 3000 sqft', value: 2000 },
      { label: '3001+ sqft', value: 3000 },
    ],
  },
};

const FALLBACK_OPTIONS = {
  label: 'Select move size',
  options: [] as MoveSizeOption[],
};

const isShortMove = (rooms: Record<string, number | boolean>) =>
  Object.keys(rooms).some((key) => SHORT_MOVE_ROOMS.includes(key));

const getValueForBuildingType = ({
  startAddressDetails,
  rooms,
}: MovingCheckoutData) => {
  if (!startAddressDetails?.buildingType) return;

  if (
    startAddressDetails?.buildingType ===
    Moving__BuildingTypeEnum.StorageFacilityOrWarehouse
  ) {
    return startAddressDetails?.unitSize;
  }

  if (
    startAddressDetails?.buildingType === Moving__BuildingTypeEnum.Commercial
  ) {
    return startAddressDetails.squareFootage;
  }

  if (rooms && isShortMove(rooms)) {
    return Object.keys(rooms)[0];
  }

  return rooms?.bedrooms;
};

const mappings = ({
  resolvedBuildingType,
}: {
  resolvedBuildingType?: StrictMovingBuildingType;
}) => {
  if (resolvedBuildingType) {
    return MOVE_SIZE_MAPPINGS[resolvedBuildingType];
  } else {
    return FALLBACK_OPTIONS;
  }
};

export const moveSizeCompleted: MovingStepDescriptor['resolveCompleted'] = ({
  values,
}) => {
  return getValueForBuildingType(values) !== undefined;
};

export const MoveSize: React.FC<MovingCheckoutStepProps> = ({
  onChange,
  values: { startAddressDetails, rooms },
  values,
  scrollToStep,
}) => {
  const { createQuote } = useMovingCheckoutContext();
  const track = useTrack();
  const resolvedBuildingType = startAddressDetails?.buildingType
    ? (startAddressDetails.buildingType as StrictMovingBuildingType)
    : undefined;

  const value = getValueForBuildingType(values);

  const { label, options } = mappings({
    resolvedBuildingType,
  });

  const onChangeMoveSize = (newValue?: any) => {
    const option = options.find((o) => o.value === newValue);
    if (option) {
      track({
        action: 'click',
        objectName: 'move_size_selector',
        objectType: 'input:select',
        value: option.label,
        label,
        metadata: { internalValue: option.value },
      });
    }

    switch (startAddressDetails?.buildingType) {
      case Moving__BuildingTypeEnum.Commercial: {
        onChange('startAddressDetails', {
          ...startAddressDetails,
          squareFootage: newValue,
        });
        break;
      }
      case Moving__BuildingTypeEnum.StorageFacilityOrWarehouse: {
        onChange('startAddressDetails', {
          ...startAddressDetails,
          unitSize: newValue,
        });
        break;
      }
      default: {
        if (option?.unitType) {
          onChange('rooms', { [option.unitType]: 1 });
        } else {
          onChange('rooms', { bedrooms: newValue });
        }
      }
    }
    scrollToStep();
    onChange('moverCountSelected', undefined);
    onChange('movingBundle', undefined);
    onChange('adjustedMoverCount', undefined, () => createQuote());
  };

  if (rooms && isShortMove(rooms)) {
    return null;
  }

  return (
    <Box margin={['64px 0 0', '72px 0 0']}>
      <Text.Title size="small" color={COLORS.tealDark}>
        What size is your move?
      </Text.Title>
      <Text.Body color={COLORS.storm}>
        Help us determine the number of movers, truck size, and duration of your
        move.
      </Text.Body>
      <Box margin="16px 0 0">
        <Text.Body color={COLORS.panther} weight={FontWeight.Medium}>
          {label}
        </Text.Body>
        <Dropdown
          value={value}
          options={options}
          placeholder="Select size"
          onChange={onChangeMoveSize}
        />
      </Box>
    </Box>
  );
};
