import React, {
  type FC,
  type ReactElement,
  type ReactNode,
  cloneElement,
} from 'react';

import { useFunnelEntryPointContext } from './use_funnel_entry_point_context';

/** Conditionally renders an error message if present */
export const FunnelEntryPointErrorMessage: FC<{
  render?: (errorMessage: ReactNode) => ReactNode;
  children?: ReactElement<{ children?: ReactNode }>;
}> = ({ render, children }) => {
  const { errorMessage } = useFunnelEntryPointContext();
  if (!errorMessage) return null;
  if (render) return <>{render(errorMessage)}</>;
  if (children) return cloneElement(children, {}, errorMessage);
  return <>{errorMessage}</>;
};
