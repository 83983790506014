import React from 'react';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { Currency } from '@shared/currency';

export const Price: React.FC<{
  amount: number;
  suffix?: string;
  strikeThrough?: boolean;
}> = ({ amount, suffix, strikeThrough = false }) => {
  const textProps = strikeThrough
    ? { color: COLORS.hippo, textDecoration: 'line-through' }
    : { color: COLORS.tealPrimary };
  return (
    <Box as="span" margin="0 0 0 8px">
      <Text.Body {...textProps} weight={FontWeight.Medium}>
        {!!amount ? <Currency amount={amount} suffix={suffix} /> : 'Free'}
      </Text.Body>
    </Box>
  );
};
