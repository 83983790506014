import React, { useState } from 'react';

import { Box, COLORS, Modal, Text, TextButton } from '@clutter/clean';
import { useMovingCheckoutContext } from '@root/components/checkout/context';
import { clearStoragePlan } from '@root/components/checkout/helpers/moving';
import {
  MovingCheckoutStep,
  MovingCheckoutStepProps,
} from '@root/components/checkout/types';
import { movingScrollToStep } from '@root/components/checkout/utilities/scroll_animation';
import { useTrack } from '@root/initializers/wt';
import { ServiceEnum } from '@root/resources/types/service';
import { useLeadCaptureViewedFunnelEvent } from '@utils/hooks/funnel_events/use_lead_capture_viewed_funnel_event';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { Banner as CouponBanner } from '../../subcomponents/coupon/banner';
import { FeaturesOverlay } from '../../subcomponents/features_overlay';
import { MOVING_RATE_GUARANTEE } from '../../subcomponents/features_overlay/data';
import { LeadFields } from '../shared/lead_fields';

export const LeadInfo: React.FC<MovingCheckoutStepProps> = (props) => {
  const track = useTrack({ container: 'lead' });
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [showStoragePlanModal, setShowStoragePlanModal] =
    useState<boolean>(false);
  const { isDesktop } = useBreakpoints();
  const { createQuote } = useMovingCheckoutContext();

  const {
    enabled,
    onChange,
    values: { name, skipStoragePlanSizeSelected },
  } = props;

  const scroll = () => {
    if (isDesktop) {
      props.scrollToStep();
    } else {
      movingScrollToStep(MovingCheckoutStep.MoverCount);
    }
  };

  useLeadCaptureViewedFunnelEvent(enabled);

  const handleLeadCreate = (
    wasCreated: boolean,
    newStorageCustomer?: boolean,
  ) => {
    if (wasCreated) {
      createQuote();
      if (newStorageCustomer) {
        if (!skipStoragePlanSizeSelected) {
          setShowStoragePlanModal(true);
          track({
            action: 'display',
            objectName: 'storage_removed_modal',
          });
        }
        clearStoragePlan(onChange);
      } else {
        scroll();
      }
    }
  };

  const handleLearnMoreClick = () => {
    track({
      action: 'click',
      objectName: 'features',
      objectType: 'button',
      label: 'We guarantee our rates',
    });
    setShowOverlay(true);
  };

  return (
    <>
      <Box margin={['48px 0 0', null, '72px 0 0']}>
        <Box margin="0 0 24px">
          <Box.Grid
            gridTemplateAreas={[
              "'title' 'description' 'link'",
              "'title link' 'description description'",
            ]}
          >
            <Box>
              <Text.Title size="small" color={COLORS.tealDark}>
                Calculate your rate
              </Text.Title>
            </Box>
            <Box.GridItem margin="4px 0 0" gridArea="description">
              <Text.Body color={COLORS.storm}>
                Get your hourly rate and view your move details. We’ll also send
                you a link to this quote.
              </Text.Body>
              <Box margin="16px 0 0">
                <CouponBanner />
              </Box>
            </Box.GridItem>
            <Box.GridItem
              margin={['16px 0 0', 0]}
              textAlign={['left', 'right']}
              gridArea="link"
              style={{ alignSelf: 'center' }}
            >
              <TextButton onClick={handleLearnMoreClick}>
                We guarantee our rates
              </TextButton>
            </Box.GridItem>
          </Box.Grid>
        </Box>
        <Box>
          <LeadFields
            {...props}
            onLeadCreate={handleLeadCreate}
            serviceContext={ServiceEnum.Moving}
            buttonLabel="Calculate my rate"
          />
        </Box>
      </Box>
      <FeaturesOverlay
        isOpen={showOverlay}
        onClose={() => {
          setShowOverlay(false);
          scroll();
        }}
        content={MOVING_RATE_GUARANTEE}
      />
      <Modal
        handleModalClose={() => setShowStoragePlanModal(false)}
        includeCloseButton
        isOpen={showStoragePlanModal}
      >
        <Box padding="24px">
          <Box.Flex
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Text.Title size="extraSmall">
              Hi {name ? name.split(' ')[0] : 'there'}!
            </Text.Title>
            <Box margin="16px 0 0">
              <Text.Body>
                It looks like you have an active Smart Storage account with us.
                To put more items into storage, please book a separate pickup
                appointment from your online account portal. In the meantime, go
                ahead and finish booking your move!
              </Text.Body>
            </Box>
          </Box.Flex>
        </Box>
      </Modal>
    </>
  );
};
