import { PLANS } from '@root/constants/pricing';
import { PlanKey } from '@root/resources/types/plan_key';

/**
 * Converts a plan name (e.g. "15.5x15.5" into a pretty name "15.5' × 15.5'").
 */
export const formatPlanName = (name: string) => {
  return name
    .replace(/\d+(\.\d?)?/g, (value) => `${value}'`)
    .replace('x', ' × ');
};

export const planForCuft = (cuft: number) => {
  return (Object.keys(PLANS).find((key) => PLANS[key as PlanKey].cuft > cuft) ??
    '10x50') as PlanKey;
};
