import * as React from 'react';

import { BrowserRouter } from 'react-router-dom';

import { ScrollToTop } from '@shared/scroll_to_top';

export const ClientRoutingConfig: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <BrowserRouter>
    <ScrollToTop>{children}</ScrollToTop>
  </BrowserRouter>
);
