import React from 'react';

import { CouponFragment } from '@graphql/platform';
import { InfoTooltip } from '@shared/info_tooltip';

export const CouponSummary: React.FC<{ coupon: CouponFragment }> = ({
  coupon,
}) => (
  <>
    {coupon.discountDescription} ({coupon.promoCode}){' '}
    {coupon.discountDisclaimer && (
      <InfoTooltip contents={coupon.discountDisclaimer} />
    )}
  </>
);
