import { type ApolloClient } from '@apollo/client';

import { PricingSetFragment, RateGroupKindEnum } from '@graphql/platform';
import { CheckoutType } from '@root/components/checkout/types';
import { SafeLocalStorage, SafeSessionStorage } from '@utils/browser';
import { DefaultSerializable, Serializer } from '@utils/serializable';

import { MovingCheckoutData, StorageCheckoutData } from '../data';

import { parseCheckoutQueryString } from './checkout_url';
import { updateMovingDraft, updateStorageDraft } from './drafts';

const STORAGE_KEY = '__clutter:checkout_data:storage';
const MOVING_KEY = '__clutter:checkout_data:moving';
const BOOKED_KEY = '__clutter:checkout_data:booked';

const MAX_DURATION = 1000 * 60 * 60; // one hour in milliseconds
const BOOKED_FLAG_DURATION = 1000 * 60 * 15; // 15 minutes in milliseconds

type PersistedData<T> = {
  data: T;
  /** Created/updated timestamp in epoch milliseconds */
  ts: number;
};

type StorageSessionPersistedCheckoutData = PersistedData<StorageCheckoutData>;
type MovingSessionPersistedCheckoutData = PersistedData<MovingCheckoutData>;

const serializer = new Serializer();

export const setBookedFlag = () =>
  SafeLocalStorage.setItem(BOOKED_KEY, new Date().toISOString());

export const fetchBookedFlag = () => {
  const value = SafeLocalStorage.getItem(BOOKED_KEY);
  if (value) return Date.parse(value) > Date.now() - BOOKED_FLAG_DURATION;
  return false;
};

export const clearBookedFlag = () => SafeLocalStorage.removeItem(BOOKED_KEY);

export const clearSessionCheckoutData = () => {
  SafeSessionStorage.removeItem(STORAGE_KEY);
  SafeSessionStorage.removeItem(MOVING_KEY);
};

export const persistStorageSessionCheckoutData = (
  client: ApolloClient<unknown>,
  data: StorageCheckoutData,
  leadToken?: string,
  pricingSet?: PricingSetFragment,
) => {
  const serialized = serializer.serialize({
    data,
    ts: Date.now(),
  });
  updateStorageDraft(client, data, leadToken, pricingSet);
  SafeSessionStorage.setItem(STORAGE_KEY, serialized);
};

export const persistMovingSessionCheckoutData = async (
  client: ApolloClient<unknown>,
  data: MovingCheckoutData,
  leadToken?: string,
  pricingSet?: PricingSetFragment,
) => {
  const serialized = serializer.serialize({
    data,
    ts: Date.now(),
  });
  updateMovingDraft(client, data, leadToken, pricingSet);
  SafeSessionStorage.setItem(MOVING_KEY, serialized);
};

const getSessionStorageData = <D extends DefaultSerializable>(key: string) => {
  const { data, ts = 0 } = serializer.deserialize<PersistedData<D>>(
    SafeSessionStorage.getItem(key) || '{}',
  );

  if (ts + MAX_DURATION > Date.now()) return data;
};

export const restoreStorageSessionCheckoutData = (): Omit<
  StorageSessionPersistedCheckoutData,
  'ts'
> => {
  const data = getSessionStorageData<StorageCheckoutData>(STORAGE_KEY);

  if (data) return { data };

  const { zip, zipValidated } = parseCheckoutQueryString(
    window.location.search,
  );

  return {
    data: {
      checkoutType: CheckoutType.Standard,
      zip,
      zipValidated,
      commitment: RateGroupKindEnum.Saver,
    },
  };
};

export const restoreMovingSessionCheckoutData = (): Omit<
  MovingSessionPersistedCheckoutData,
  'ts'
> => {
  const data = getSessionStorageData<MovingCheckoutData>(MOVING_KEY);

  if (data) return { data };

  const { zip, zipValidated } = parseCheckoutQueryString(
    window.location.search,
  );

  return {
    data: {
      checkoutType: CheckoutType.Standard,
      zip,
      zipValidated,
    },
  };
};
