import { useSalesTaxQuery } from '@graphql/platform';
import { PLANS } from '@root/constants/pricing';
import { PROTECTION_PLANS } from '@utils/protection_plan';

import { useSharedCheckoutContext } from '../context';

export type ReservationSummaryProps = {
  summaryLoaded: boolean;
  monthlyTaxAmount: number;
  monthlyTotalAmount: number;
  protectionPlanLabel: string;
  protectionPlanAmount: number;
  planSizeLabel: string;
};

const DEFAULT_RESERVATION_SUMMARY_PROPS: ReservationSummaryProps = {
  summaryLoaded: false,
  monthlyTaxAmount: 0,
  monthlyTotalAmount: 0,
  planSizeLabel: '',
  protectionPlanAmount: PROTECTION_PLANS['minimal_coverage'].cost,
  protectionPlanLabel: PROTECTION_PLANS['minimal_coverage'].label,
};

export const useReservationSummaryProps = (): ReservationSummaryProps => {
  const {
    flowState: { values },
    pricingSummary,
  } = useSharedCheckoutContext();
  const { planSize, protectionPlan } = values;

  const { data } = useSalesTaxQuery({
    variables: { zipCode: values.zip! },
    skip: !values.zip,
  });

  const taxRate = data?.salesTax?.percent ?? 0;

  if (!pricingSummary) {
    return DEFAULT_RESERVATION_SUMMARY_PROPS;
  }

  const { monthlyAmount } = pricingSummary;

  const monthlyTaxAmount = (Number(monthlyAmount) * taxRate) / 100.0;

  const protectionPlanDetail =
    PROTECTION_PLANS[protectionPlan || 'minimal_coverage'];

  const monthlyTotalAmount = monthlyAmount + protectionPlanDetail.cost;

  return {
    summaryLoaded: true,
    planSizeLabel: planSize ? PLANS[planSize].label : '',
    protectionPlanAmount: protectionPlanDetail.cost,
    protectionPlanLabel: protectionPlanDetail.label,
    monthlyTotalAmount,
    monthlyTaxAmount,
  };
};
