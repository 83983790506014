import React from 'react';

import styled from '@emotion/styled';

import {
  COLORS,
  SkeletonLoader,
  Text,
  toggleStyleValue,
  UnstyledButton,
} from '@clutter/clean';
import { Estimation__ItemCategory } from '@graphql/platform';

export const RowContainer = styled.div<{ selected: boolean }>`
  padding: 12px 16px;
  background: ${toggleStyleValue(
    'selected',
    COLORS.tealBackground,
    COLORS.cloud,
  )};
  cursor: pointer;

  &:hover {
    background: ${toggleStyleValue(
      'selected',
      COLORS.tealBackground,
      COLORS.grayBackground,
    )};
  }
`;

const Loader = () => (
  <RowContainer selected={false}>
    <SkeletonLoader height="19.5px" width="200px" />
  </RowContainer>
);

const SearchResult: React.FC<{
  selected?: boolean;
  value: string | Estimation__ItemCategory;
  onClick(): void;
}> = ({ value, selected, onClick }) => {
  const isCategory = typeof value !== 'string';

  return (
    <RowContainer selected={!!selected} onClick={() => onClick()}>
      <UnstyledButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
      >
        {isCategory && (
          <>
            <Text color={COLORS.tealPrimary}>+</Text>{' '}
          </>
        )}
        <Text>{isCategory ? value.name : value}</Text>
      </UnstyledButton>
    </RowContainer>
  );
};

const Combined = Object.assign(SearchResult, { Loader });

export { Combined as SearchResult };
