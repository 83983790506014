import styled from '@emotion/styled';

import { mq } from '@clutter/clean';

interface IResponsiveBreakProps {
  mode: 'only' | 'not';
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}

/**
 * A component for conditionally rendered breakpoints. The prop `mode` indicates
 * whether breakpoint-named props will be rendered or excluded:
 *
 * ```tsx
 * // Will only show on the smallest breakpoint
 * <ResponsiveBreak mode="only" sm />
 *
 * // Will show on everything that is _not_ the smallest breakpoint
 * <ResponsiveBreak mode="not" sm />
 * ```
 */
export const ResponsiveBreak = styled.br<IResponsiveBreakProps>`
  ${({ mode, sm, md, lg, xl }) =>
    mq({
      display: [sm, md, lg, xl]
        .map((val) => (mode === 'only' ? val : !val))
        .map((val) => (val ? 'initial' : 'none')),
    })}
`;
