import { MovingCheckoutData, StorageCheckoutData } from '../data';

type Key = keyof MovingCheckoutData | keyof StorageCheckoutData;
const TRANSIENT_PROPERTY_KEYS: Key[] = [
  'leadToken',
  'zipValidated',
  'checkoutType',
  'customerToken',
];

// Sometimes we store a property for client side persistence but don't want to persist on the server (e.g. tokens, complex objects)
function removeTransientProperties(data: PartialRecord<Key, any>) {
  for (const key of TRANSIENT_PROPERTY_KEYS) {
    if (key in data) delete data[key];
  }
  return data;
}

export const transformMovingStepTransitionData = (data: MovingCheckoutData) => {
  const transformed: Record<string, any> & Partial<MovingCheckoutData> = {
    ...data,
  };

  if (transformed.movingQuote) {
    transformed.movingQuoteID = transformed.movingQuote.id;
    delete transformed.movingQuote;
  }

  removeTransientProperties(transformed);

  return transformed;
};

export const transformStorageStepTransitionData = (
  data: StorageCheckoutData,
) => {
  const transformed: Record<string, any> & Partial<StorageCheckoutData> = {
    ...data,
  };

  removeTransientProperties(transformed);

  return transformed;
};
