export const dedupeBy = <Item extends Record<string, any>, Key extends string>(
  results: Item[],
  key: Key,
) => {
  const seen: Set<string> = new Set();
  return results.filter((item) => {
    const ref = item[key];
    const existing = seen.has(ref);
    seen.add(ref);
    return !existing;
  });
};
