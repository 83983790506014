import React from 'react';

import styled from '@emotion/styled';

import { LARGE_TITLE_STYLES, Text } from '@clutter/clean';
import { LaborBillingFormatEnum } from '@graphql/platform';
import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { PLANS } from '@root/constants/pricing';
import { Currency } from '@shared/currency';

import { PolicyModal } from './subcomponents/policy_modal';

const Body = styled(Text.Body)`
  padding-top: 16px;
  padding-bottom: 40px;
`;

const LaborRate: React.FC<{
  amount: number;
  laborBillingFormat?: LaborBillingFormatEnum;
}> = ({
  amount,
  laborBillingFormat = LaborBillingFormatEnum.PerAppointmentHour,
}) => {
  return (
    <>
      <Currency amount={amount} />
      /hour
      {laborBillingFormat === LaborBillingFormatEnum.PerMoverHour &&
        ' per mover'}
    </>
  );
};

export const SmartStorageFinalReturnBillingModal: React.FC<{
  isOpen: boolean;
  handleModalClose(): void;
}> = ({ isOpen, handleModalClose }) => {
  const {
    pricingSummary,
    flowState: {
      values: { planSize },
    },
  } = useStorageCheckoutContext();

  const planSizeName = planSize ? PLANS[planSize].label : undefined;

  if (!pricingSummary) return null;

  const {
    finalReturnFeeAmount,
    formattedFinalReturnFreeDuration,
    fullServiceLaborRateAmount,
    laborBillingFormat,
  } = pricingSummary;

  return (
    <PolicyModal isOpen={isOpen} handleModalClose={() => handleModalClose()}>
      <Text style={LARGE_TITLE_STYLES.SM}>
        What does the final delivery cost?
      </Text>
      <Body>
        Your
        {planSizeName && ` ${planSizeName}`} final delivery is{' '}
        {formattedFinalReturnFreeDuration ? (
          <>
            free up to {formattedFinalReturnFreeDuration} and then billed hourly
            at{' '}
            <LaborRate
              amount={fullServiceLaborRateAmount}
              laborBillingFormat={laborBillingFormat}
            />
            {!!finalReturnFeeAmount && (
              <>
                {' '}
                plus a <Currency amount={finalReturnFeeAmount} /> service fee
              </>
            )}
          </>
        ) : (
          <>
            {!!finalReturnFeeAmount && !!fullServiceLaborRateAmount ? (
              <>
                <Currency amount={finalReturnFeeAmount} /> plus{' '}
                <LaborRate
                  amount={fullServiceLaborRateAmount}
                  laborBillingFormat={laborBillingFormat}
                />
              </>
            ) : !!finalReturnFeeAmount ? (
              <Currency amount={finalReturnFeeAmount} />
            ) : !!fullServiceLaborRateAmount ? (
              <LaborRate
                amount={fullServiceLaborRateAmount}
                laborBillingFormat={laborBillingFormat}
              />
            ) : (
              'free'
            )}
          </>
        )}
        .
        <br />
        <br />
        Other delivery types and pricing options are available. On select
        eligible items, you can choose to have FedEx ship your items in storage
        anywhere nationwide or you can pick up your items yourself at one of our
        facilities for a flat rate fee, based on the size of the order.
      </Body>
    </PolicyModal>
  );
};
