import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, Text, TextButton } from '@clutter/clean';
import { LaborBillingFormatEnum } from '@graphql/platform';
import { Currency } from '@shared/currency';
import { PricingSummary } from '@utils/hooks/pricing/use_pricing_summary';

import { CardContainer } from '../fees/card_container';
import { Price } from '../fees/price';

import { FreePickupSummary } from './free_pickup_summary';
import { PerMoverHourDisclaimer } from './per_mover_hour_disclaimer';

import hourlyRateImageMobile from '@images_responsive/www/checkout/storage_hourly_rate_cropped_wide.jpg';
import hourlyRateImageDesktop from '@images_responsive/www/checkout/storage_hourly_rate.jpg';
import cashIllustration from '@images/illustrations/cash_small.svg';
import lightBulbIllustration from '@images/illustrations/light_bulb_rotated.svg';

const BulletPoints = styled.ul`
  list-style: disc;
  margin: 16px;
`;

const FreeDuration: React.FC<{ formattedFreeDuration: string | undefined }> = ({
  formattedFreeDuration,
}) => (
  <Box textAlign="center" margin="0 0 0 8px" borderRadius="4px">
    <Box background={COLORS.tealBackground} padding="2px">
      <Text.Headline weight={FontWeight.Medium}>FREE</Text.Headline>
    </Box>
    <Text.Caption color={COLORS.hippo}>
      Up to {formattedFreeDuration}
    </Text.Caption>
  </Box>
);

const ValueProps: React.FC<{
  pricingSummary: PricingSummary;
  formattedFreeDuration: string | undefined;
}> = ({ pricingSummary, formattedFreeDuration }) => {
  const {
    anyCommitmentHasFreePickup,
    commitmentLength,
    laborSavingsAmount,
    selectedCommitmentHasFreePickup,
  } = pricingSummary;

  if (formattedFreeDuration) {
    return (
      <Box.Flex>
        <Box.FlexItem alignSelf="start" margin="0 8px 0 0" flexShrink={0}>
          <img
            src={lightBulbIllustration}
            width="16px"
            draggable={false}
            alt=""
          />
        </Box.FlexItem>
        <Text.Caption color={COLORS.hippo}>
          Ensure your pickup is under {formattedFreeDuration} by packing ahead
          of time!
        </Text.Caption>
      </Box.Flex>
    );
  }

  return (
    <>
      {!selectedCommitmentHasFreePickup && (
        <Box.Flex>
          <Box.FlexItem alignSelf="start" margin="0 8px 0 0" flexShrink={0}>
            <img
              src={lightBulbIllustration}
              width="16px"
              draggable={false}
              alt=""
            />
          </Box.FlexItem>
          <Text.Caption color={COLORS.hippo}>
            Customers who pack boxes and disassemble items ahead of time save
            25-30% off their final bill.
          </Text.Caption>
        </Box.Flex>
      )}
      <Box.Flex>
        <Box.FlexItem alignSelf="start" margin="0 8px 0 0" flexShrink={0}>
          <img src={cashIllustration} width="16px" draggable={false} alt="" />
        </Box.FlexItem>
        <Text.Caption color={COLORS.hippo}>
          {anyCommitmentHasFreePickup ? (
            <FreePickupSummary pricingSummary={pricingSummary} />
          ) : (
            <>
              {laborSavingsAmount > 0 && (
                <>
                  <b>
                    You’re saving{' '}
                    <Currency
                      amount={pricingSummary.laborSavingsAmount}
                      suffix="/hr"
                    />
                  </b>{' '}
                  by selecting a {commitmentLength} month
                  {commitmentLength > 4 && '+'} term.{' '}
                </>
              )}
              Consider storing longer for more savings!
            </>
          )}
        </Text.Caption>
      </Box.Flex>
    </>
  );
};

export function HourlyRateCardChallenger({
  pricingSummary,
  showPrice,
  onModalLinkClick,
  planSizeName,
}: {
  pricingSummary: PricingSummary | undefined;
  showPrice: boolean;
  onModalLinkClick: (e: React.MouseEvent<HTMLElement>) => void;
  planSizeName: string | undefined;
}) {
  const { freeDuration, formattedFreeDuration } = pricingSummary || {};

  const perMoverHour =
    pricingSummary?.laborBillingFormat === LaborBillingFormatEnum.PerMoverHour;

  return (
    <CardContainer
      mobileImage={hourlyRateImageMobile.src}
      desktopImage={hourlyRateImageDesktop.src}
    >
      <Box.Flex flexDirection="column" gap="4px">
        <Box.Flex justifyContent="space-between">
          <Text.Body weight={FontWeight.Medium}>
            Hourly Move & Pack Rate
          </Text.Body>
          <Box.Flex textAlign="right" alignItems="center">
            {pricingSummary && showPrice && (
              <>
                {pricingSummary.maxLaborRateAmount >
                  pricingSummary.fullServiceLaborRateAmount && (
                  <Price
                    amount={
                      freeDuration
                        ? pricingSummary.fullServiceLaborRateAmount
                        : pricingSummary.maxLaborRateAmount
                    }
                    suffix={perMoverHour ? '/hr *' : '/hr'}
                    strikeThrough
                  />
                )}
                {freeDuration ? (
                  <FreeDuration formattedFreeDuration={formattedFreeDuration} />
                ) : (
                  <>
                    <Price
                      amount={pricingSummary.fullServiceLaborRateAmount}
                      suffix={perMoverHour ? '/hr *' : '/hr'}
                    />
                  </>
                )}
              </>
            )}
          </Box.Flex>
        </Box.Flex>

        {perMoverHour && (
          <PerMoverHourDisclaimer pricingSummary={pricingSummary} />
        )}

        <Text.Callout>
          Our Move & Pack Specialists will come pack your boxes, disassemble
          your furniture and bring all your things to storage.{' '}
          <TextButton size="medium" onClick={onModalLinkClick}>
            <Text.Callout weight={FontWeight.Medium}>Learn more.</Text.Callout>
          </TextButton>
          {pricingSummary && formattedFreeDuration && (
            <>
              <br />
              <br />
              As long as your pickup takes less than {formattedFreeDuration},
              it’s <b>100% free.</b> After {formattedFreeDuration} it’s{' '}
              <Currency amount={pricingSummary.fullServiceLaborRateAmount} />{' '}
              per hour.
            </>
          )}
        </Text.Callout>

        <Text.Callout>
          <BulletPoints>
            <li>
              <b>1 moving truck</b> fully insured and up to{' '}
              <b>3 Move & Pack Specialists</b>
            </li>
            <li>
              <b>Free delivery</b> to our warehouse plus <b>unlimited boxes</b>{' '}
              and materials
            </li>
            <li>
              Live appointment and <b>item inventory tracking</b>
            </li>
          </BulletPoints>
        </Text.Callout>

        {planSizeName && pricingSummary && showPrice && (
          <ValueProps
            pricingSummary={pricingSummary}
            formattedFreeDuration={formattedFreeDuration}
          />
        )}
      </Box.Flex>
    </CardContainer>
  );
}
