import * as Sentry from '@sentry/browser';

import { HEROKU_SLUG_COMMIT, SENTRY_DSN } from '@root/config/constants';

import { IGNORED_ERRORS } from './new_relic';
import { WT_VISITOR_TOKEN } from './wt';

const isClutterOwned = (url: string) => url.match(/\.clutter\.com/);

// Copied from Sentry implementation used for allowUrls/denyUrls: https://github.com/getsentry/sentry-javascript/blob/334b09750a4bc7b697c259b08c55e05f5fcbb0d1/packages/core/src/integrations/inboundfilters.ts#L178
function getLastValidUrl(frames: Sentry.StackFrame[] = []): string | null {
  for (let i = frames.length - 1; i >= 0; i--) {
    const frame = frames[i];

    if (
      frame &&
      frame.filename !== '<anonymous>' &&
      frame.filename !== '[native code]'
    ) {
      return frame.filename || null;
    }
  }

  return null;
}

if (SENTRY_DSN && process.env.RAILS_ENV !== 'test') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NODE_ENV,
    ignoreErrors: IGNORED_ERRORS,
    denyUrls: [
      /bing\.com/i,
      /static\.traversedlp.com/i, // AddShoppers
      /d3rr3d0n31t48m\.cloudfront.net/i, // AddShoppers
    ],
    release: HEROKU_SLUG_COMMIT,
    beforeSend(e) {
      const lastUrl = getLastValidUrl(
        e.exception?.values?.[0]?.stacktrace?.frames,
      );
      e.tags = {
        ...e.tags,
        unknownOrigin: !lastUrl || !isClutterOwned(lastUrl),
      };
      return e;
    },
  });

  Sentry.setUser({ id: WT_VISITOR_TOKEN });
}
