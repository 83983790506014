import React, { type ComponentProps, type FC } from 'react';

import { SSRForm } from '@shared/ssr_form';

import { useFunnelEntryPointContext } from './use_funnel_entry_point_context';

export const FunnelEntryPointForm: FC<
  Omit<ComponentProps<typeof SSRForm>, 'onSubmit'>
> = (props) => {
  const { onSubmit } = useFunnelEntryPointContext();
  return <SSRForm {...props} onSubmit={onSubmit} />;
};
