/** THIS FILE IS AUTOGENERATED. EDITS MUST BE MADE WITHIN PLATFORM IN INDIVIDUAL EVENT FILES */

export enum EventSchema {
  Pageview = 'pageview@1',
  ScrollDepth = 'scroll_depth@1',
  WWW__AppointmentDateCompleted = 'www/appointment_date_completed@1.1',
  WWW__AppointmentDateViewed = 'www/appointment_date_viewed@1.2',
  WWW__ArrivalWindowCompleted = 'www/arrival_window_completed@1.1',
  WWW__ArrivalWindowViewed = 'www/arrival_window_viewed@1.1',
  WWW__CartViewed = 'www/cart_viewed@1.1',
  WWW__FlowInitiated = 'www/flow_initiated@1.1',
  WWW__FunnelEntryPointViewed = 'www/funnel_entry_point_viewed@1.1',
  WWW__LeadCaptureCompleted = 'www/lead_capture_completed@1.1',
  WWW__LeadCaptureViewed = 'www/lead_capture_viewed@1.1',
  WWW__MovePriceCompleted = 'www/move_price_completed@1.1',
  WWW__MovePriceViewed = 'www/move_price_viewed@1.1',
  WWW__PaymentMethodCompleted = 'www/payment_method_completed@1.1',
  WWW__PaymentMethodViewed = 'www/payment_method_viewed@1.1',
  WWW__PhoneCaptureCompleted = 'www/phone_capture_completed@1.1',
  WWW__PhoneCaptureViewed = 'www/phone_capture_viewed@1.1',
  WWW__PickupPriceCompleted = 'www/pickup_price_completed@1.1',
  WWW__PickupPriceViewed = 'www/pickup_price_viewed@1.1',
  WWW__ProtectionPlanCompleted = 'www/protection_plan_completed@1.1',
  WWW__ProtectionPlanViewed = 'www/protection_plan_viewed@1.1',
  WWW__ReservationCompleted = 'www/reservation_completed@1.1',
  WWW__StoragePriceCompleted = 'www/storage_price_completed@1.1',
  WWW__StoragePriceViewed = 'www/storage_price_viewed@1.1',
  WWW__UnpackingDateCompleted = 'www/unpacking_date_completed@1',
  WWW__UnpackingDateViewed = 'www/unpacking_date_viewed@1',
  WWW__ZipValidated = 'www/zip_validated@1.1',
}

type Service = 'storage' | 'moving' | 'self_storage';

export type FunnelEventMetadata = {
  checkout_type: 'standard' | 'shareable' | 'reonboarding' | 'agent';
  flow_version: string;
};

/** An event fired from the browser when a page is initially loaded or navigated to */
export type Pageview = {
  schema: EventSchema.Pageview;
  kind: 'pageview';
  metadata: {
    customer_id?: string;
    today_app_token?: string;
    user_id?: string;
  };
};

/** An event fired when the browser reaches a certain percentage of the total
 * height of the page. Percentage is calculated as
 *
 * scroll_offset / (page_height - viewport_height) * 100 */
export type ScrollDepth = {
  schema: EventSchema.ScrollDepth;
  kind: 'event';
  metadata: {
    scroll_percent: number;
    page_height: number;
    viewport_height: number;
  };
};

/** A visitor selected a date for their appointment prior to selecting a time */
export type WWW__AppointmentDateCompleted = {
  schema: EventSchema.WWW__AppointmentDateCompleted;
  category: 'funnel_event';
  action: 'click';
  metadata: {
    date: string;
  } & FunnelEventMetadata;
};

/** A visitor was shown the calendar with available/unavailable dates for an appointment*/
export type WWW__AppointmentDateViewed = {
  schema: EventSchema.WWW__AppointmentDateViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: {
    from: string;
    originZip: string;
    destinationZip?: string;
    totalDays: number;
    unavailableDays: number;
  } & FunnelEventMetadata;
};

/** A visitor selected a time for their appointment */
export type WWW__ArrivalWindowCompleted = {
  schema: EventSchema.WWW__ArrivalWindowCompleted;
  category: 'funnel_event';
  action: 'click';
  metadata: {
    arrival_window: string;
  } & FunnelEventMetadata;
};

/** A visitor saw arrival windows for their selected date (or a message if none were available) */
export type WWW__ArrivalWindowViewed = {
  schema: EventSchema.WWW__ArrivalWindowViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: {
    window_count: number;
  } & FunnelEventMetadata;
};

/** A visitor saw the cart page of the checkout (where a reservation can be made) */
export type WWW__CartViewed = {
  schema: EventSchema.WWW__CartViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata;
};

/** A visitor has entered the checkout */
export type WWW__FlowInitiated = {
  schema: EventSchema.WWW__FlowInitiated;
  category: 'funnel_event';
  action: 'display';
  metadata: {
    zip?: string;
  } & FunnelEventMetadata;
};

/** A visitor saw a a page containing a funnel entry point. If the entrypoint on
 * that page was for a particular service, it will be present in metadata. This
 * does not 100% guarantee that the user saw the entrypoint within their
 * viewport, but there was at least an accessible entry point on the page the
 * event is fired from. */
export type WWW__FunnelEntryPointViewed = {
  schema: EventSchema.WWW__FunnelEntryPointViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: {
    service?: Service;
  };
};

/** A visitor provided lead information */
export type WWW__LeadCaptureCompleted = {
  schema: EventSchema.WWW__LeadCaptureCompleted;
  category: 'funnel_event';
  action: 'submit';
  metadata: {
    name?: string;
    email?: string;
    lead_id: string;
  } & FunnelEventMetadata;
};

/** A visitor saw the option to provide lead information (e.g. phone or email) */
export type WWW__LeadCaptureViewed = {
  schema: EventSchema.WWW__LeadCaptureViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata;
};

/** A visitor either interacted with a step after seeing the move price, or interacted with a move price after it was displayed */
export type WWW__MovePriceCompleted = {
  schema: EventSchema.WWW__MovePriceCompleted;
  category: 'funnel_event';
  action: 'click';
  metadata: FunnelEventMetadata;
};

/** A complete price for the move was displayed  */
export type WWW__MovePriceViewed = {
  schema: EventSchema.WWW__MovePriceViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata;
};

/** The user added a payment method prior to making a reservation */
export type WWW__PaymentMethodCompleted = {
  schema: EventSchema.WWW__PaymentMethodCompleted;
  category: 'funnel_event';
  action: 'submit';
  metadata: FunnelEventMetadata & {
    method: 'card' | 'apple_pay' | 'google_pay';
  };
};

/** A visitor saw the (enabled) option to add a payment method */
export type WWW__PaymentMethodViewed = {
  schema: EventSchema.WWW__PaymentMethodViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata;
};

/** A visitor provided their phone number */
export type WWW__PhoneCaptureCompleted = {
  schema: EventSchema.WWW__PhoneCaptureCompleted;
  category: 'funnel_event';
  action: 'submit';
  metadata: FunnelEventMetadata & { phone_number: string };
};

/** A visitor was shown the option to add a phone number */
export type WWW__PhoneCaptureViewed = {
  schema: EventSchema.WWW__PhoneCaptureViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata;
};

/** A visitor either interacted with the pickup price or completed a subsequent step after seeing the pickup price */
export type WWW__PickupPriceCompleted = {
  schema: EventSchema.WWW__PickupPriceCompleted;
  category: 'funnel_event';
  action: 'click';
  metadata: FunnelEventMetadata;
};

/** A visitor saw the final cost for having items picked up for an onboarding (e.g. labor or fixed fees) */
export type WWW__PickupPriceViewed = {
  schema: EventSchema.WWW__PickupPriceViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata;
};

/** A visitor completed the protection plan step */
export type WWW__ProtectionPlanCompleted = {
  schema: EventSchema.WWW__ProtectionPlanCompleted;
  category: 'funnel_event';
  action: 'submit';
  metadata: FunnelEventMetadata & { protection_plan_slug?: string };
};

/** A visitor saw the price for available protection plans */
export type WWW__ProtectionPlanViewed = {
  schema: EventSchema.WWW__ProtectionPlanViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata;
};

/** A visitor successfully made a reservation for a particular service in the funnel */
export type WWW__ReservationCompleted = {
  schema: EventSchema.WWW__ReservationCompleted;
  category: 'funnel_event';
  action: 'submit';
  metadata: FunnelEventMetadata & { order_id: string; service: Service };
};

/** A visitor selected a complete storage price (e.g. if term impacts storage price, both must be selected) */
export type WWW__StoragePriceCompleted = {
  schema: EventSchema.WWW__StoragePriceCompleted;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata & {
    plan_size: string;
    commitment_length: number;
    monthly_price: number;
  };
};

/** A visitor has entered the checkout */
export type WWW__StoragePriceViewed = {
  schema: EventSchema.WWW__StoragePriceViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: FunnelEventMetadata;
};

/** A visitor selected a date for their long-distance moving unpacking date */
export type WWW__UnpackingDateCompleted = {
  schema: EventSchema.WWW__UnpackingDateCompleted;
  category: 'funnel_event';
  action: 'click';
  metadata: {
    date: string;
  } & FunnelEventMetadata;
};

/** A visitor was shown the calendar with available/unavailable dates for a long-distance moving unpacking appointment*/
export type WWW__UnpackingDateViewed = {
  schema: EventSchema.WWW__UnpackingDateViewed;
  category: 'funnel_event';
  action: 'display';
  metadata: {
    packingDate: string;
    packingDays: number;
    originZip: string;
    destinationZip: string;
    availableDays: number;
  } & FunnelEventMetadata;
};

/** A user provided a zip that Clutter services (for the desired service if included in metadata) */
export type WWW__ZipValidated = {
  schema: EventSchema.WWW__ZipValidated;
  category: 'funnel_event';
  action: 'click';
  metadata: {
    zip: string;
    service: Service;
  } & FunnelEventMetadata;
};
export type EventWithSchema =
  | Pageview
  | ScrollDepth
  | WWW__AppointmentDateCompleted
  | WWW__AppointmentDateViewed
  | WWW__ArrivalWindowCompleted
  | WWW__ArrivalWindowViewed
  | WWW__CartViewed
  | WWW__FlowInitiated
  | WWW__FunnelEntryPointViewed
  | WWW__LeadCaptureCompleted
  | WWW__LeadCaptureViewed
  | WWW__MovePriceCompleted
  | WWW__MovePriceViewed
  | WWW__PaymentMethodCompleted
  | WWW__PaymentMethodViewed
  | WWW__PhoneCaptureCompleted
  | WWW__PhoneCaptureViewed
  | WWW__PickupPriceCompleted
  | WWW__PickupPriceViewed
  | WWW__ProtectionPlanCompleted
  | WWW__ProtectionPlanViewed
  | WWW__ReservationCompleted
  | WWW__StoragePriceCompleted
  | WWW__StoragePriceViewed
  | WWW__UnpackingDateCompleted
  | WWW__UnpackingDateViewed
  | WWW__ZipValidated;
