import React from 'react';

import { Box, Text, TextButton } from '@clutter/clean';
import {
  MoverCountOptionFragment,
  Moving__PackingEnum,
  Moving__WhiteGloveBundleEnum,
} from '@graphql/platform';
import { useMovingCheckoutContext } from '@root/components/checkout/context';
import { MATERIAL_KIT_METADATA } from '@root/components/checkout/helpers/material_kits';
import { flatRateOption } from '@root/components/checkout/helpers/moving';
import { MovingCheckoutStep } from '@root/components/checkout/types';
import { Currency } from '@shared/currency';
import { Pluralize } from '@shared/formatters/pluralize';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { CollapsibleSummaryContainer } from './service_summary/collapsible_summary_container';
import { ExpandableItem } from './service_summary/expandable_item';
import { SummaryItem } from './service_summary/summary_item';
import { SummaryItemLoader } from './service_summary/summary_item_loader';
import { MovingDueToday } from './moving_due_today';
import { MovingSavingsSummary } from './moving_savings_summary';
import { StoragePlanSummaryItem } from './storage_service_summary';
import { useReservationSummaryProps } from './use_reservation_summary_props';

import cargoVanCrop from '@images/illustrations/cargo_van_crop_small.svg';
import clock from '@images/illustrations/clock_large.svg';
import moverWithCouch from '@images/illustrations/mover_with_couch_small.svg';
import stackedBoxes from '@images/illustrations/stacked_boxes.svg';

const SECONDS_PER_HOUR = 60 * 60;

const ExpandedLaborContent = ({
  packingHelp,
  minimumDuration,
  bundleName,
  materialsKit,
}: {
  packingHelp?: Moving__PackingEnum;
  minimumDuration: number;
  bundleName?: Moving__WhiteGloveBundleEnum;
  materialsKit?: {
    lineItemName: string;
    fixedCost: number;
  };
}) => (
  <>
    <ExpandableItem>
      <span>Minimum Duration</span>
      <span>
        <Pluralize count={minimumDuration} singular="hour" plural="hours" />
      </span>
    </ExpandableItem>
    {packingHelp && (
      <ExpandableItem>
        <span>Packing help</span>
        <span>
          {packingHelp === Moving__PackingEnum.EverythingIsPacked ||
          bundleName === Moving__WhiteGloveBundleEnum.Basic
            ? 'Declined'
            : 'Included'}
        </span>
      </ExpandableItem>
    )}
    {materialsKit && (
      <ExpandableItem>
        <span>{materialsKit.lineItemName}</span>
        <span>
          <Currency amount={materialsKit.fixedCost} />
        </span>
      </ExpandableItem>
    )}
  </>
);

const ExpandedFlatRateContent = ({
  flatRateDetails,
  distanceInMiles,
}: {
  flatRateDetails: NonNullable<MoverCountOptionFragment['flatRateDetails']>;
  distanceInMiles: number;
}) => (
  <>
    <ExpandableItem>
      <span>Labor</span>
      <span>
        <Currency amount={flatRateDetails.laborAmount} />
      </span>
    </ExpandableItem>
    <ExpandableItem>
      <span>Transportation - {distanceInMiles} miles</span>
      <span>
        <Currency amount={flatRateDetails.transportFeeAmount} />
      </span>
    </ExpandableItem>
    {flatRateDetails.materialsAmount > 0 && (
      <ExpandableItem>
        <span>Packing Materials</span>
        <span>
          <Currency amount={flatRateDetails.materialsAmount} />
        </span>
      </ExpandableItem>
    )}
  </>
);

const ExpandedTransportationContent = ({
  minimumBilledWeight,
}: {
  minimumBilledWeight: number;
}) => (
  <ExpandableItem>
    <span>Minimum Weight</span>
    <span>{minimumBilledWeight.toLocaleString()} lbs</span>
  </ExpandableItem>
);

const Summary: React.FC<{
  onEdit: (step: MovingCheckoutStep) => void;
}> = ({ onEdit }) => {
  const { isDesktop } = useBreakpoints();

  const {
    flowState: {
      values: {
        movingQuote,
        packingMaterials,
        packingHelp,
        planSize,
        movingBundle,
        disposalSelected,
        dateScheduled,
      },
    },
    pricingSummary,
  } = useMovingCheckoutContext();

  const storageReservationSummaryProps = useReservationSummaryProps();

  const flatRate = flatRateOption({ movingQuote, movingBundle });
  const laborRate = movingQuote?.laborRate;
  const minimumDuration =
    movingQuote?.movingConfiguration?.requiredLaborDuration !== undefined
      ? movingQuote?.movingConfiguration?.requiredLaborDuration /
        SECONDS_PER_HOUR
      : undefined;

  const materialKit = packingMaterials
    ? MATERIAL_KIT_METADATA[packingMaterials.package.name]
    : undefined;

  const storeAndMove = !!planSize;
  const isLongDistance =
    movingQuote?.longDistanceTransportFee &&
    movingQuote?.longDistanceTransportationRate;

  const transportFee = movingQuote?.longDistanceTransportFee;
  const minimumBilledWeight = movingQuote?.minimumBilledWeight;

  const resolveWhiteGloveBundleName = () => {
    switch (movingBundle) {
      case Moving__WhiteGloveBundleEnum.Basic:
        return 'Basic Package';
      case Moving__WhiteGloveBundleEnum.PackingHelp:
        return 'Packing Help Package';
      case Moving__WhiteGloveBundleEnum.FullService:
        return 'Full Service Package';
    }
  };

  return (
    <Box margin="0 0 24px">
      <Box.Flex justifyContent="space-between" margin="0 0 12px">
        <Text.Title size="extraSmall">
          {storeAndMove ? 'Reservation Summary' : 'Moving Summary'}
        </Text.Title>
        <TextButton
          size="medium"
          onClick={() => onEdit(MovingCheckoutStep.MoveSize)}
        >
          <Text.Callout>
            <b>Edit</b>
          </Text.Callout>
        </TextButton>
      </Box.Flex>
      <Box.Flex flexDirection="column" gap="24px">
        {flatRate?.flatRateDetails && movingQuote?.driveDistanceInMiles && (
          <>
            <SummaryItem
              illustration={cargoVanCrop}
              item={resolveWhiteGloveBundleName()}
              amount={
                <Currency amount={flatRate.flatRateDetails.totalAmount} />
              }
              description="All-inclusive flat rate"
              expandableContent={
                <ExpandedFlatRateContent
                  flatRateDetails={flatRate.flatRateDetails}
                  distanceInMiles={movingQuote.driveDistanceInMiles}
                />
              }
            />
            {storeAndMove && (
              <StoragePlanSummaryItem
                {...storageReservationSummaryProps}
                moverMonthlyAmount={pricingSummary?.moverMonthlyAmount}
                commitmentLength={pricingSummary?.commitmentLength}
                monthlySavingsAmount={pricingSummary?.monthlySavingsAmount}
              />
            )}
          </>
        )}
        {!flatRate && laborRate && minimumDuration && (
          <>
            <SummaryItem
              illustration={stackedBoxes}
              item={resolveWhiteGloveBundleName()}
              amount={<Currency amount={laborRate.amount} suffix="/hr" />}
              description={<>{minimumDuration} hour minimum</>}
              expandableContent={
                <ExpandedLaborContent
                  packingHelp={packingHelp}
                  minimumDuration={minimumDuration}
                  bundleName={movingBundle}
                />
              }
            />
            {isLongDistance && transportFee && minimumBilledWeight && (
              <SummaryItem
                illustration={cargoVanCrop}
                item="Transport Fee"
                amount={<Currency amount={transportFee} />}
                description={<>{movingQuote.driveDistanceInMiles ?? 0} miles</>}
                expandableContent={
                  <ExpandedTransportationContent
                    minimumBilledWeight={minimumBilledWeight}
                  />
                }
              />
            )}
            {storeAndMove && (
              <StoragePlanSummaryItem
                {...storageReservationSummaryProps}
                moverMonthlyAmount={pricingSummary?.moverMonthlyAmount}
                commitmentLength={pricingSummary?.commitmentLength}
                monthlySavingsAmount={pricingSummary?.monthlySavingsAmount}
              />
            )}
            {packingMaterials && materialKit && (
              <SummaryItem
                illustration={materialKit?.icon}
                item={materialKit.lineItemName}
                amount={<Currency amount={packingMaterials.fixedCost} />}
                description={materialKit.description}
              />
            )}
            {disposalSelected && (
              <SummaryItem
                illustration={moverWithCouch}
                item="Disposal Charge"
                description="See pricing and restrictions"
                amount={<Currency amount={50} suffix="+" />}
              />
            )}
            {dateScheduled?.feeAmount && dateScheduled.feeAmount.value > 0 && (
              <SummaryItem
                illustration={clock}
                item="Scheduled Arrival Fee"
                description=""
                amount={<Currency amount={dateScheduled.feeAmount.value} />}
              />
            )}
          </>
        )}
        {!laborRate && !flatRate && (
          <>
            <SummaryItemLoader />
            <SummaryItemLoader />
          </>
        )}
      </Box.Flex>
      {isDesktop && (
        <Box margin="24px 0 0">
          <MovingDueToday />
        </Box>
      )}
      <Box margin="24px 0 0">
        <MovingSavingsSummary />
      </Box>
    </Box>
  );
};

export const MovingServiceSummary: React.FC<{
  collapsible?: boolean;
  onEdit: (step: MovingCheckoutStep) => void;
}> = ({ collapsible, ...props }) => (
  <>
    {collapsible ? (
      <CollapsibleSummaryContainer>
        {<Summary {...props} />}
      </CollapsibleSummaryContainer>
    ) : (
      <Summary {...props} />
    )}
  </>
);
