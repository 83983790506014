import { GeocodeFragment } from '@graphql/platform';
import { WWW_ROUTES } from '@root/root/routes';
import { ServiceSelection } from '@shared/service_selector/constants';

const REFERRER_KEY = 'ref';
const ZIP_KEY = 'zip';
const ZIP_VALIDATED_KEY = 'zv';
const ZIP_VALIDATED_VALUE = 't';
const SKIP_ADDRESS_KEY = 'sa';
const SKIP_ADDRESS_VALUE = 't';

const buildCheckoutQueryString = ({
  zip,
  zipValidated,
  skipAddressEligible,
}: {
  /** The zip provided to enter the checkout. If it is not provided, the user
   * can enter one on the checkout page. */
  zip?: string;
  /** Whether the zip is valid and serviceable for the specific checkout type. */
  zipValidated?: boolean;
  /** Whether to show the skip address option in moving funnel (OSA origins are not eligible
   * because moving service eligibility is contingent on having an ISA destination) */
  skipAddressEligible?: boolean;
}) => {
  const params = new URLSearchParams();

  if (zip) params.append(ZIP_KEY, zip);
  if (zipValidated === true)
    params.append(ZIP_VALIDATED_KEY, ZIP_VALIDATED_VALUE);
  if (skipAddressEligible) params.append(SKIP_ADDRESS_KEY, SKIP_ADDRESS_VALUE);

  return params.toString();
};

export const parseCheckoutQueryString = (query: string) => {
  const params = new URLSearchParams(query);
  return {
    zip: params.get(ZIP_KEY) ?? undefined,
    zipValidated: params.get(ZIP_VALIDATED_KEY) == ZIP_VALIDATED_VALUE,
    referrer: params.get(REFERRER_KEY) ?? undefined,
    skipAddressEligible: params.get(SKIP_ADDRESS_KEY) == SKIP_ADDRESS_VALUE,
  };
};

export function useResolveCheckoutURL() {
  return async (
    serviceSelection = ServiceSelection.Storage,
    geocode?: GeocodeFragment,
  ) => {
    const queryString = buildCheckoutQueryString({
      zip: geocode?.id,
      zipValidated: !!geocode?.id,
      skipAddressEligible:
        serviceSelection === ServiceSelection.Moving
          ? !geocode?.extendedMovingServiceArea
          : undefined,
    });

    return serviceSelection === ServiceSelection.Storage
      ? `${WWW_ROUTES.PRODUCT_PAGE_STORAGE}?${queryString}`
      : `${WWW_ROUTES.PRODUCT_PAGE_MOVING}?${queryString}`;
  };
}
