import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';

import calendar from '@images/illustrations/calendar.svg';
import planSize from '@images/illustrations/plan_size_small.svg';

const Illustration = styled.img`
  height: 33px;
  margin-right: 16px;
  filter: grayscale(1);
`;

export const StorageCartValueProps: React.FC = () => (
  <Box.Flex gap="32px" flexDirection={['column', 'row']}>
    <Box.Flex>
      <Illustration src={planSize} alt="" />
      <Text.Caption color={COLORS.storm}>
        We’ll lower your monthly rate if you need less space.
      </Text.Caption>
    </Box.Flex>
    <Box.Flex>
      <Illustration src={calendar} alt="" />
      <Text.Caption color={COLORS.storm}>
        No-risk booking: you can cancel your free reservation online.
      </Text.Caption>
    </Box.Flex>
  </Box.Flex>
);
