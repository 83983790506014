import { capitalize } from 'lodash-es';

import {
  CityQuery,
  Location__Content,
  NeighborhoodQuery,
  RouteQuery,
} from '@graphql/platform';
import {
  cityURL,
  locationsURL,
  neighborhoodURL,
} from '@root/components/locations/routes';

type JSONBreadcrumb = {
  '@type': 'ListItem';
  name: string;
  position: number;
  item: string | undefined;
};

const breadcrumb = (
  name: string,
  position: number,
  item?: string,
): JSONBreadcrumb => ({
  '@type': 'ListItem',
  name,
  position,
  item,
});

const ROOT_URL = 'https://www.clutter.com';

export const BASE_BREADCRUMBS: JSONBreadcrumb[] = [
  breadcrumb('Clutter', 1, ROOT_URL),
  breadcrumb('Locations', 2, ROOT_URL + locationsURL()),
];

export const jsonBreadcrumbList = (breadcrumb: JSONBreadcrumb[]): string => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumb,
  });
};

const parseQuery = (query: CityQuery | NeighborhoodQuery) => {
  const state = query.state;
  const city = query.state?.city;

  if (!city || !state) return {} as const;
  if ('neighborhood' in city)
    return {
      state,
      city,
      neighborhood: city.neighborhood!,
      content: city.neighborhood?.content,
    } as const;
  return {
    city,
    state,
    content: (city as any).content as Partial<Location__Content> | undefined,
  } as const;
};

export const parseRouteQuery = (route: RouteQuery['route']) => {
  if (!route) return {};

  if (route.origin.__typename === 'Location__Neighborhood') {
    return {
      state: route.origin.city.state,
      city: route.origin.city,
      neighborhood: route.origin,
      content: route.content,
    };
  } else if (route.origin.__typename === 'Location__City') {
    return {
      state: route.origin.state,
      city: route.origin,
      content: route.content,
    };
  } else if (route.origin.__typename === 'Location__State') {
    return {
      state: route.origin,
      content: route.content,
    };
  }
  return {};
};

export const buildMetaData = (
  query: CityQuery | NeighborhoodQuery,
  service: 'storage' | 'moving',
) => {
  const { city, state, neighborhood, content } = parseQuery(query);
  if (!city || !state) return undefined;

  const title = content?.title;
  const description = content?.description;

  const serviceCopy =
    service === 'storage' ? 'Storage + Moving' : 'Moving + Storage';

  if (neighborhood)
    return {
      title:
        title ??
        `The Best ${serviceCopy} Near ${neighborhood.name} | ${city.name}, ${state.code} |  Clutter`,
      description,
    };
  else
    return {
      title:
        content?.title ??
        `The Best ${serviceCopy} Near ${city.name}, ${state.code} |  Clutter`,
      description,
    };
};

export const buildRouteMetaData = (
  route: RouteQuery['route'],
  service: 'storage' | 'moving',
) => {
  const { state, city, neighborhood, content } = parseRouteQuery(route);
  if (!state) {
    throw new Error('Cannot resolve JSON+LD without state');
  }

  const title = content?.title;
  const description = content?.description;

  const serviceCopy =
    service === 'storage' ? 'Storage + Moving' : 'Moving + Storage';

  if (neighborhood)
    return {
      title:
        title ??
        `The Best ${serviceCopy} Near ${neighborhood.name} | ${city.name}, ${state.code} |  Clutter`,
      description,
    };
  else if (city)
    return {
      title:
        title ??
        `The Best ${serviceCopy} Near ${city.name}, ${state.code} |  Clutter`,
      description,
    };
  else
    return {
      title:
        content?.title ??
        `The Best ${serviceCopy} Near ${state.code} |  Clutter`,
      description,
    };
};

export const buildBreadcrumbData = (
  query: CityQuery | NeighborhoodQuery,
  service: 'storage' | 'moving',
): string | null => {
  const { city, state, neighborhood } = parseQuery(query);
  if (!city || !state) return null;

  const base = [...BASE_BREADCRUMBS];

  if (neighborhood) {
    base.push(
      breadcrumb(
        city.name,
        3,
        ROOT_URL + cityURL(state.slug, city.slug, service),
      ),
      breadcrumb(
        capitalize(service) + ' in ' + neighborhood.name,
        4,
        ROOT_URL +
          neighborhoodURL(state.slug, city.slug, neighborhood.slug, service),
      ),
    );
  } else {
    base.push(
      breadcrumb(
        capitalize(service) + ' in ' + city.name + ', ' + state.code,
        3,
        ROOT_URL + neighborhoodURL(state.slug, city.slug, service),
      ),
    );
  }

  return jsonBreadcrumbList(base);
};

export const buildRouteBreadcrumbData = (
  route: RouteQuery['route'],
  service: 'storage' | 'moving',
) => {
  const { city, state, neighborhood } = parseRouteQuery(route);
  if (!state) {
    throw new Error('Cannot resolve JSON+LD without state');
  }

  const base = [...BASE_BREADCRUMBS];

  if (neighborhood) {
    base.push(
      breadcrumb(
        city.name,
        3,
        ROOT_URL + cityURL(state.slug, city.slug, service),
      ),
      breadcrumb(
        capitalize(service) + ' in ' + neighborhood.name,
        4,
        ROOT_URL +
          neighborhoodURL(state.slug, city.slug, neighborhood.slug, service),
      ),
    );
  } else if (city) {
    base.push(
      breadcrumb(
        capitalize(service) + ' in ' + city.name,
        3,
        ROOT_URL + cityURL(state.slug, city.slug, service),
      ),
    );
  }
  return jsonBreadcrumbList(base);
};
