import React from 'react';

import styled from '@emotion/styled';

import { IResponsiveImage } from '@utils/images';

const SIZES = [128, 256, 384, 512, 640, 750, 828, 1080, 1200, 1920, 2048, 3840];

export type ImageDescriptor = IResponsiveImage & {
  fit?:
    | 'clamp'
    | 'clip'
    | 'crop'
    | 'facearea'
    | 'fill'
    | 'fillmax'
    | 'max'
    | 'min'
    | 'scale';
  crop?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'faces'
    | 'focalpoint'
    | 'edges'
    | 'entropy';
  fpY?: number;
  fpZ?: number;
};

type Resize = {
  w: number;
  h: number;
};

export const buildSrc = (image: ImageDescriptor, resize: Resize) => {
  const qs = new URLSearchParams({
    auto: 'compress,format',
    w: resize.w.toString(),
    h: resize.h.toString(),
    fit: image.fit ?? 'min',
  });

  if (image.crop) qs.append('crop', image.crop);
  if (image.fpY) qs.append('fp-y', image.fpY.toString());
  if (image.fpZ) qs.append('fp-y', image.fpZ.toString());
  return `${image.src}?${qs.toString()}`;
};

export const buildSrcSet = (image: ImageDescriptor, sizes = SIZES) => {
  const ratio = image.height / image.width;
  return sizes
    .map((w) => {
      const h = Math.round(w * ratio);
      const src = buildSrc(image, { w, h });
      return `${src} ${w}w`;
    })
    .join(', ');
};

export const buildSrcAttributes = (
  image: ImageDescriptor,
  { sizes = SIZES } = {},
) => {
  return {
    src: buildSrc(image, {
      w: image.width,
      h: image.height,
    }),
    srcSet: buildSrcSet(image, sizes),
  };
};

const Img = styled.img`
  width: 100%;
  height: auto;
`;

export const ResponsiveImage = ({
  image,
  alt,
  sizes,
  baseImageSizes = SIZES,
  loading,
  ...rest
}: {
  image: ImageDescriptor;
  alt: string;
  loading?: 'lazy' | 'eager';
  baseImageSizes?: number[];
} & Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'loading' | 'src' | 'srcSet'
>) => (
  <Img
    width={image.width}
    height={image.height}
    {...buildSrcAttributes(image, { sizes: baseImageSizes })}
    alt={alt}
    sizes={sizes}
    loading={loading ?? 'lazy'}
    {...rest}
  />
);
