import {
  Moving__BuildingTypeEnum as BuildingTypes,
  Moving__MaterialPackageSetEntryFragment,
  Moving__MoveSizeEnum,
  Moving__PackingEnum,
  Moving__QuoteInput,
} from '@graphql/platform';
import { sanitizeUnitTypesInput } from '@root/components/checkout/helpers/moving';
import { WT_VISITOR_TOKEN } from '@root/initializers/wt';
import { MovingAddress } from '@root/resources/types/address';

export const buildQuoteInput = ({
  movingStartAddress,
  movingEndAddress,
  movingRooms,
  movingPackingHelp,
  movingPackingMaterials,
  movingSpace,
  dateScheduled,
  movingAddressSkipped,
  moverOverride,
  zip,
}: {
  movingStartAddress?: Partial<MovingAddress>;
  movingEndAddress?: Partial<MovingAddress>;
  movingRooms?: Record<string, number | boolean>;
  movingPackingHelp?: Moving__PackingEnum;
  movingPackingMaterials?: Moving__MaterialPackageSetEntryFragment | boolean;
  movingSpace?: Moving__MoveSizeEnum;
  dateScheduled?: string;
  movingAddressSkipped?: boolean;
  moverOverride?: number;
  zip?: string;
}): { input: Moving__QuoteInput } => {
  const unitTypes = movingStartAddress
    ? sanitizeUnitTypesInput(movingStartAddress, movingRooms)
    : {};

  const materialPackageSetEntrySignedId =
    typeof movingPackingMaterials !== 'boolean'
      ? movingPackingMaterials?.signedID
      : undefined;

  return {
    input: {
      visitorToken: WT_VISITOR_TOKEN,
      originAddress: movingAddressSkipped
        ? undefined
        : {
            aptsuite: movingStartAddress?.aptsuite,
            street: movingStartAddress?.street || '',
            city: movingStartAddress?.city || '',
            state: movingStartAddress?.state || '',
            zip: movingStartAddress?.zip || '',
            details: {
              buildingType:
                movingStartAddress?.buildingType || BuildingTypes.Apartment,
              floor: movingStartAddress?.floor || 1,
              stories: movingStartAddress?.stories || 1,
            },
          },
      destinationAddress: movingAddressSkipped
        ? undefined
        : {
            aptsuite: movingEndAddress?.aptsuite,
            street: movingEndAddress?.street || '',
            city: movingEndAddress?.city || '',
            state: movingEndAddress?.state || '',
            zip: movingEndAddress?.zip || '',
            details: {
              buildingType:
                movingEndAddress?.buildingType || BuildingTypes.Apartment,
              floor: movingEndAddress?.floor || 1,
              stories: movingEndAddress?.stories || 1,
            },
          },
      moveSize: movingSpace || null,
      packing: movingPackingHelp!,
      packingSuppliesRequired: !!movingPackingMaterials,
      materialPackageSetEntrySignedId: materialPackageSetEntrySignedId,
      moverOverride,
      unitTypes,
      requestedDate: dateScheduled,
      skippedAddressZip: movingAddressSkipped ? zip! : undefined,
    },
  };
};
