import React, { useEffect } from 'react';

import { LatLngFragment } from '@graphql/platform';

import { useMapContext } from './context';

export const Polygon: React.FC<{
  polygon: ReadonlyArray<ReadonlyArray<LatLngFragment>>;
  options?: google.maps.PolygonOptions;
  fitToBounds?: boolean;
}> = ({ polygon, options }) => {
  const { maps, map } = useMapContext();

  if (!maps || !map) throw new Error('No map found');

  useEffect(() => {
    const p = new maps.Polygon({ paths: polygon as any, ...options });
    p.setMap(map);
    return () => p.setMap(null);
  }, [polygon, options, map, maps]);

  return null;
};
