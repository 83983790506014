export const resolveEnabledSteps = <ResolveCompletedArg>(
  steps: Array<{ resolveCompleted(arg: ResolveCompletedArg): boolean }>,
  arg: ResolveCompletedArg,
) => {
  let completionAggregate = true;

  const enabledSteps = steps.map(({ resolveCompleted }) => {
    const completed = resolveCompleted(arg);
    const previousStepCompleted = completionAggregate;
    completionAggregate &&= completed;
    return previousStepCompleted;
  });

  return [completionAggregate, enabledSteps] as const;
};
