import React, { useEffect } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  Button,
  Carousel,
  COLORS,
  FontWeight,
  SERIF_FONT_FAMILY,
  Text,
  useCarousel,
} from '@clutter/clean';
import { useTrack } from '@root/initializers/wt';
import { Ratings } from '@shared/ratings';
import { useHasIntersected } from '@utils/hooks';

import { ReviewData } from './review_carousel/data';

import { Arrow } from '@images/svg/arrow';

const LeftQuote = styled.div`
  font-family: ${SERIF_FONT_FAMILY};
  transform: translate(-8px, -8px);
  font-size: 40px;
`;

const RightQuote = styled.div`
  font-family: ${SERIF_FONT_FAMILY};
  font-size: 40px;
  line-height: 0;
  transform: translate(8px, 8px);
`;

const NavButton = styled(Button)`
  width: 56px;
  height: 56px;
  padding: 16px;
  margin-top: 28px;
  min-width: 0;
`;

export const ReviewCarousel: React.FC<{
  background?: string;
  reviews: ReviewData[];
}> = ({ background = COLORS.tealBackground, reviews }) => {
  const { targetRef, hasIntersected } = useHasIntersected({ threshold: 0.5 });
  const track = useTrack({
    container: 'testimonials',
  });
  const props = useCarousel({ draggable: false });
  const { next, prev } = props;

  useEffect(() => {
    if (hasIntersected) {
      track({ action: 'view' });
    }
  }, [track, hasIntersected]);

  const handlePrev = () => {
    track({ objectName: 'previous', action: 'click', objectType: 'button' });
    prev();
  };

  const handleNext = () => {
    track({ objectName: 'next', action: 'click', objectType: 'button' });
    next();
  };

  return (
    <Box
      ref={targetRef}
      background={background}
      color={COLORS.tealDark}
      padding={['46px 24px', null, '54px']}
      textAlign="center"
    >
      <Box.Flex>
        <NavButton onClick={handlePrev} aria-label="previous slide">
          <Arrow fillColor={COLORS.cloud} />
        </NavButton>
        <Carousel {...props} slidesToShow={1}>
          {reviews.map((testimonial, i) => (
            <Box
              key={i}
              width="100%"
              maxWidth="480px"
              margin="0 auto"
              padding="0 8px"
              textAlign="center"
            >
              <Text.Title size="small">{testimonial.title}</Text.Title>
              <Box.Flex position="relative" padding="24px 0 0">
                <LeftQuote>“</LeftQuote>
                <Text.Body>{testimonial.quote}</Text.Body>
                <Box.FlexItem alignSelf="flex-end">
                  <RightQuote>”</RightQuote>
                </Box.FlexItem>
              </Box.Flex>
              <Text.Body weight={FontWeight.Medium}>
                {testimonial.reviewer}
              </Text.Body>
            </Box>
          ))}
        </Carousel>
        <NavButton onClick={handleNext} aria-label="next slide">
          <Arrow fillColor={COLORS.cloud} direction="right" />
        </NavButton>
      </Box.Flex>
      <Ratings />
    </Box>
  );
};
