import React, { useEffect, useRef, useState } from 'react';

/** Provides a way to gracefully hide content while it loads */
export const Fallback: React.FC<{
  children: React.ReactNode;
  /** Displayed after `delayMs` have elapsed if children are not present */
  loader: React.ReactElement;
  /** Displayed before `delayMs` have elapsed if children are not present, defaults to null  */
  placeholder?: React.ReactElement;
  /** Milliseconds to wait before showing a loader while children are not defined */
  delayMs?: number;
  /** Minimum milliseconds the loader will show for once it is mounted to minimize flickering */
  minimumLoadingMs?: number;
  /** Alternative to checking for presence of `children` if a complex element must be provided */
  value?: any;
}> = (props) => {
  const {
    loader,
    placeholder = null,
    children,
    delayMs = 500, // ms
    minimumLoadingMs = 1000, // ms
    value,
  } = props;

  const [showLoader, setShowLoader] = useState(false);
  const [forceLoader, setForceLoader] = useState(false);

  const hasValue = 'value' in props ? !!value : !!children;
  const hasValueRef = useRef(hasValue);
  useEffect(() => {
    hasValueRef.current = hasValue;
  });

  useEffect(() => {
    if (!hasValue) {
      setTimeout(() => {
        // Double check that children haven't been added during the timeout
        if (hasValueRef.current) return;
        setShowLoader(true);
        if (minimumLoadingMs) {
          setForceLoader(true);
          setTimeout(() => {
            setForceLoader(false);
          }, minimumLoadingMs);
        }
      }, delayMs);
    } else {
      setShowLoader(false);
    }
  }, [hasValue, delayMs, minimumLoadingMs]);

  return <>{showLoader || forceLoader ? loader : children || placeholder}</>;
};
