import React, { useContext, useState } from 'react';

import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { Button, mq } from '@clutter/clean';
import { useResolveCheckoutURL } from '@root/components/checkout/utilities/checkout_url';
import { track } from '@root/initializers/wt';
import {
  generateLeadSource,
  WwwOneFunnelEntryPoint,
} from '@root/resources/lead';
import { Action, useClientDataContext } from '@shared/client_data_context';
import { ServerDataContext } from '@shared/server_data_context';
import { ServiceSelection } from '@shared/service_selector/constants';
import { ServiceSelectorModal } from '@shared/service_selector/service_selector_modal';
import { getPageName } from '@utils/get_pagename_utilities';
import { useTrackFunnelEntryPointViewed } from '@utils/hooks/funnel_events/use_track_funnel_entry_point_viewed';

type ButtonCTAProps = {
  container: string;
  label: string;
  offeringType?: string;
  objectType?: string;
  objectName?: string;
  entryPoint: WwwOneFunnelEntryPoint;
  testId?: string;
  service?: ServiceSelection;
  style?: React.CSSProperties;
  className?: string;
  size?: React.ComponentProps<typeof Button>['size'];
  kind?: React.ComponentProps<typeof Button>['kind'];
};

const useButtonCheckoutEntry = ({
  container,
  label,
  offeringType,
  objectType = 'input:button',
  objectName = 'cta',
  entryPoint,
  service,
}: ButtonCTAProps) => {
  const history = useHistory();
  const { dispatch } = useClientDataContext();
  const { isHomepage } = useContext(ServerDataContext);
  const resolveCheckoutURL = useResolveCheckoutURL();
  useTrackFunnelEntryPointViewed({ service: service });

  return async () => {
    track({
      schema: undefined,
      action: 'click',
      label,
      container,
      objectType,
      objectName,
      metadata: { offeringType, service },
    });

    dispatch({
      type: Action.SetupCheckout,
      payload: {
        zipHasSelfStorage: false,
        serviceSelection: service,
        leadSource: generateLeadSource({
          domain: isHomepage ? 'www' : 'landing',
          pageName: getPageName({ prefix: false }),
          entryPoint,
        }),
      },
    });

    const url = await resolveCheckoutURL(service);

    history.push(url);
  };
};

const ResponsiveButton = styled(Button)(
  mq({
    width: ['100%', 'initial'],
  }),
);

export const ButtonCTA: React.FC<ButtonCTAProps> = (props) => {
  const enterCheckout = useButtonCheckoutEntry(props);
  const [showingModal, setShowingModal] = useState(false);

  const { container } = props;
  const showServiceSelectorModal = !props.service;
  return (
    <>
      <ResponsiveButton
        data-test-id={props.testId}
        style={props.style}
        kind={props.kind}
        className={props.className}
        size={props.size ?? 'large'}
        onClick={
          showServiceSelectorModal ? () => setShowingModal(true) : enterCheckout
        }
      >
        {props.label}
      </ResponsiveButton>
      <ServiceSelectorModal
        isOpen={showingModal}
        onClose={() => setShowingModal(false)}
        id="service_selector_modal"
        wtConfig={{ container, label: props.label }}
      />
    </>
  );
};
