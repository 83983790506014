import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, Label, Text } from '@clutter/clean';
import { WWW_ROUTES } from '@root/root/routes';
import { CustomLink } from '@shared/custom_link';
import { FunnelEntryPoint } from '@shared/funnel_entry_point';
import { FunnelEntryPointDropdown } from '@shared/funnel_entry_point_dropdown';
import { FunnelEntryPointForm } from '@shared/funnel_entry_point_form';
import { FunnelEntryPointErrorMessage } from '@shared/funnel_entry_point_message';
import { FunnelEntryPointSubmitButton } from '@shared/funnel_entry_point_submit_button';
import { FunnelEntryPointZipInput } from '@shared/funnel_entry_point_zip_input';
import { useFunnelEntryPointContext } from '@shared/use_funnel_entry_point_context';

import { ServiceSelection } from './constants';

const ErrorMessage = styled.div`
  position: absolute;
  z-index: 10;
  background: ${COLORS.flower};
  padding: 10px;
  font-size: 16px;
  margin: 0 auto;
  color: ${COLORS.toucan};
  border-radius: 2px;
  font-weight: bold;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  bottom: 120%;
  left: 50%;
  width: 100%;
  transform: translate(-50%);

  &::after {
    bottom: -3px;
    content: ${"' '"};
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${COLORS.flower};
    left: 50%;
    transform: rotate(45deg);
    border-radius: 3px;
  }
`;

const LinkText = styled(Text.Callout)`
  font-weight: ${FontWeight.Medium};
  text-decoration: underline;
`;

const Form: React.FC<{ id: string }> = ({ id }) => {
  const { serviceSelection } = useFunnelEntryPointContext();

  return (
    <FunnelEntryPointForm id={id}>
      <Box
        padding={['16px', '24px']}
        background={COLORS.grayBackground}
        borderRadius="4px"
        position="relative"
      >
        <Box textAlign="left">
          <Label>I need help with</Label>
          <Box.Flex gap="16px" flexDirection={['column', null, null, 'row']}>
            <Box.Flex gap="16px" flexGrow={1} flexShrink={1}>
              <Box.FlexItem
                flexGrow={1}
                flexShrink={1}
                minWidth="120px"
                maxWidth="180px"
                flexBasis="100%"
              >
                <FunnelEntryPointDropdown />
              </Box.FlexItem>
              <Box.FlexItem flexGrow={1} flexShrink={1} flexBasis="100%">
                <FunnelEntryPointZipInput />
              </Box.FlexItem>
            </Box.Flex>
            <Box.Flex gap="16px" flexDirection="column" alignItems="center">
              <FunnelEntryPointSubmitButton fullWidth />
              <Text.Callout weight={FontWeight.Medium} color={COLORS.storm}>
                In under 2 minutes!
              </Text.Callout>
            </Box.Flex>
            <FunnelEntryPointErrorMessage>
              <ErrorMessage />
            </FunnelEntryPointErrorMessage>
          </Box.Flex>

          <Box
            margin="auto"
            position={[null, null, null, 'absolute']}
            bottom="24px"
            textAlign={['center', null, null, 'left']}
          >
            <CustomLink
              to={
                serviceSelection === ServiceSelection.Moving
                  ? WWW_ROUTES.SERVICE_MOVING
                  : WWW_ROUTES.SERVICE_WAREHOUSE_STORAGE
              }
            >
              <LinkText>
                {serviceSelection === ServiceSelection.Moving
                  ? 'Learn more about moving'
                  : 'Learn more about storage'}
              </LinkText>
            </CustomLink>
          </Box>
        </Box>
      </Box>
    </FunnelEntryPointForm>
  );
};

export const HeroServiceSelector: React.FC<{
  id: string;
  initialService?: ServiceSelection;
  onServiceChange?(service: ServiceSelection | undefined): void;
}> = ({ id, initialService, onServiceChange }) => {
  return (
    <FunnelEntryPoint
      wtConfig={{ container: 'hero' }}
      onServiceChange={onServiceChange}
      initialService={initialService}
    >
      <Form id={id} />
    </FunnelEntryPoint>
  );
};
