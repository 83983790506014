const DOM_CONTENT_LOADED = 'DOMContentLoaded';

const ready = (fn: EventListenerOrEventListenerObject & (() => void)): void => {
  if (typeof document === 'undefined') {
    return;
  }
  if (document.readyState !== 'loading') {
    fn();
  } else if (document.addEventListener) {
    document.addEventListener(DOM_CONTENT_LOADED, fn);
  }
};

export { ready };
