import React from 'react';

import { Box, COLORS, Text } from '@clutter/clean';
import { Location__Service } from '@graphql/platform';

import { MOVING_PERKS, STORAGE_PERKS } from '../constants';

export const Perks: React.FC<{
  service: Location__Service;
}> = ({ service }) => {
  const perks =
    service === Location__Service.SmartStorage ? STORAGE_PERKS : MOVING_PERKS;

  return (
    <Box color={COLORS.tealDark} textAlign="center">
      <Text.Title size="large" tag="h2">
        How to {service === Location__Service.SmartStorage ? 'store' : 'move'}{' '}
        with Clutter
      </Text.Title>
      <Box.Flex
        flexDirection={['column', null, 'row']}
        margin="60px auto"
        justifyContent="space-evenly"
      >
        {perks.map((perk, index) => (
          <Box key={`perk-${index}`}>
            <img
              loading="lazy"
              {...perk.icon}
              alt=""
              style={{ height: '64px' }}
            />
            <Text.Body>{perk.description}</Text.Body>
          </Box>
        ))}
      </Box.Flex>
    </Box>
  );
};
