import { useEffect, useRef, useState } from 'react';

export const useElementRect = <
  ElementType extends Element = HTMLDivElement,
>() => {
  const containerRef = useRef<ElementType | null>(null);
  const [rect, setRect] = useState<DOMRectReadOnly>();
  useEffect(() => {
    if (typeof ResizeObserver === 'undefined') return;
    const ob = new ResizeObserver((entry) => {
      setRect(entry[0].contentRect);
    });
    ob.observe(containerRef.current!);
    return () => ob.disconnect();
  }, []);

  return [containerRef, rect] as const;
};
