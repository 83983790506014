import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS } from '@clutter/clean';

type Direction = 'left' | 'right';

interface IArrowProps {
  className?: string;
  direction?: Direction;
  fillColor?: string;
}

const Svg = styled.svg<{ direction: Direction }>`
  transform: rotate(${({ direction }) => (direction === 'left' ? 0 : 180)}deg);
`;

export const Arrow: React.FC<IArrowProps> = ({
  className,
  direction = 'left',
  fillColor = COLORS.tealPrimary,
}) => {
  return (
    <Svg
      fill="none"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      direction={direction}
      aria-hidden
    >
      <path
        clipRule="evenodd"
        d="m9.78899 18.9878c-.19955.1908-.51606.1838-.70693-.0157l-8.251342-8.6264c-.184906-.1934-.184906-.49796 0-.69127l8.251342-8.6264c.19087-.199551.50738-.206586.70693-.01571l1.03121.98638c.1996.19088.2066.50738.0157.70693l-5.8165 6.08091h13.6491c.2761 0 .5.22386.5.5v1.42696c0 .2762-.2239.5-.5.5h-13.6491l5.8165 6.081c.1909.1995.1839.516-.0157.7069z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </Svg>
  );
};
