import { useEffect, useState } from 'react';

export const useLastDefined = <T>(value: T) => {
  const [lastValue, setLastValue] = useState(value);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setLastValue(value);
    }
  }, [value]);

  return lastValue;
};
