import { LaborBillingFormatEnum, LaborRateFragment } from '@graphql/platform';

export function extractLaborRateAmount({
  estimatedMovers,
  laborRate,
  laborBillingFormat,
}: {
  estimatedMovers: number;
  laborRate?: LaborRateFragment;
  laborBillingFormat?: LaborBillingFormatEnum;
}) {
  const laborAmount = laborRate?.amount || 0;
  return laborBillingFormat === LaborBillingFormatEnum.PerMoverHour
    ? laborAmount * estimatedMovers
    : laborAmount;
}
