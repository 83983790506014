import React from 'react';

import { Box, COLORS, ZIndex } from '@clutter/clean';

export const Container: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}> = ({ isOpen, children }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Box
      position="fixed"
      borderRadius="4px"
      border={`1px solid ${COLORS.grayBorder}`}
      bottom="88px"
      right="32px"
      width="344px"
      maxHeight="736px"
      background={COLORS.cloud}
      boxShadow="0 4px 19px rgb(0 0 0 / 7%)"
      style={{ zIndex: ZIndex.MODAL }}
    >
      {children}
    </Box>
  );
};
