import React from 'react';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { useDisposalCategoryPricingQuery } from '@graphql/platform';
import { Currency } from '@shared/currency';

export const PricingContent: React.FC = () => {
  const { data } = useDisposalCategoryPricingQuery();
  return (
    <Box.Flex flexDirection="column" gap="20px">
      <Box textAlign="center">
        <Text.Title size="medium" color={COLORS.tealDark}>
          Disposal Pricing
        </Text.Title>
      </Box>
      <Text.Body>
        Our disposal service has a{' '}
        <Text.Body as="span" weight={FontWeight.Medium}>
          $50 flat fee
        </Text.Body>{' '}
        plus a size-based fee for each item. All items are{' '}
        <Text.Body as="span" weight={FontWeight.Medium}>
          $1.50/cubic foot
        </Text.Body>
        , with the following minimum amounts for specific items that require
        special handling.
      </Text.Body>
      <Box.Grid
        gridTemplateColumns="1fr 1fr"
        gridGap=" 12px 16px"
        width="100%"
        maxWidth="424px"
        margin="0 auto"
      >
        {data?.disposalCategoryPricing.map((pricing, key) => (
          <React.Fragment key={key}>
            <Text.Callout>{pricing.category}</Text.Callout>
            <Box textAlign="right">
              <Text.Callout weight={FontWeight.Medium}>
                <Currency amount={pricing.minimum} />
              </Text.Callout>
            </Box>
          </React.Fragment>
        ))}
      </Box.Grid>
    </Box.Flex>
  );
};
