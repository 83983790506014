import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  BREAKPOINTS,
  COLORS,
  FontWeight,
  SERIF_FONT_FAMILY,
  Text,
} from '@clutter/clean';
import { LocationReviewFragment } from '@graphql/platform';

import star from '@images/icons/star.svg';

const Quote = styled.div`
  display: inline-block;
  font-family: ${SERIF_FONT_FAMILY};
  font-size: 32px;
`;

export const Reviews: React.FC<{
  reviews: ReadonlyArray<LocationReviewFragment>;
  locationName?: string;
}> = ({ reviews, locationName }) => {
  return (
    <>
      <Box padding={['0 0 32px', '0 0 64px']} textAlign="center">
        <Text.Title size="large" tag="h2">
          Thousands of satisfied customers
        </Text.Title>
      </Box>
      <Box.Grid
        gridTemplateColumns={['1fr', null, '1fr 1fr', '1fr 1fr 1fr']}
        maxWidth={BREAKPOINTS.LG}
        margin="0 auto"
        gridGap={['48px', null, '72px']}
      >
        {reviews.map((review) => (
          <Box.Flex
            key={review.id}
            flexDirection="column"
            alignItems={['center', null, 'initial', null]}
          >
            <Box.Flex color={COLORS.tealDark} position="relative">
              <Box.FlexItem alignSelf="center">
                <Text.Callout>
                  <Quote style={{ transform: 'translate(-4px, 0px)' }}>“</Quote>
                  {review.message}
                  <Quote style={{ transform: 'translate(4px, 20px)' }}>”</Quote>
                </Text.Callout>
              </Box.FlexItem>
            </Box.Flex>
            <Box margin="16px 0 0 12px">
              {Array.from({ length: review.rating }).map((_, index) => (
                <span key={`${review.id}-${index}`}>
                  <img
                    loading="lazy"
                    src={star}
                    width={22}
                    height={22}
                    alt=""
                  />
                </span>
              ))}
            </Box>
            <Box
              margin="16px 0 0 12px"
              textAlign={['center', null, 'left', null]}
            >
              <Text.Caption color={COLORS.panther} weight={FontWeight.Medium}>
                {review.author}
              </Text.Caption>
              {locationName && (
                <Text.Caption color={COLORS.panther}>
                  {locationName}
                </Text.Caption>
              )}
            </Box>
          </Box.Flex>
        ))}
      </Box.Grid>
    </>
  );
};
