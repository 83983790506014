import React from 'react';

import styled from '@emotion/styled';

import { Box, BREAKPOINTS, Text } from '@clutter/clean';
import {
  ActiveStorageAttachment,
  Location__Service,
  Maybe,
} from '@graphql/platform';
import { ZIP_INPUT_TRIGGER_ID } from '@shared/header/header';
import { PinnedFooterContainer } from '@shared/pinned_footer_container';
import { ServiceSelection } from '@shared/service_selector/constants';
import { HeroServiceSelector } from '@shared/service_selector/hero_service_selector';
import { PinnedServiceSelector } from '@shared/service_selector/pinned_service_selector';
import { useIntersectionObserver } from '@utils/hooks';

import fallbackMovingHero from '@images_responsive/locations/moving_hero.jpg';
import fallbackStorageHero from '@images_responsive/locations/storage_hero.jpg';

const SIZES = [640, 750, 828, 1080, 1200, 1920, 2048];
const ASPECT_RATIO = 0.468;
const MAX_HERO_IMAGE_WIDTH = '752px';

const LOCATION_SERVICE_TO_SERVICE_SELECTION: Record<
  Location__Service,
  ServiceSelection
> = {
  [Location__Service.SmartStorage]: ServiceSelection.Storage,
  [Location__Service.Moving]: ServiceSelection.Moving,
  [Location__Service.SelfStorage]: ServiceSelection.Storage,
};

const Image = styled.img`
  border-radius: 8px;
  max-width: unset;
  width: 100%;
  max-width: 752px;
`;

const srcSet = (baseUrl: string) =>
  SIZES.map((w) => {
    const h = Math.round(w * ASPECT_RATIO);
    return `${baseUrl}?auto=compress,format&w=${w}&h=${h}&fit=min ${w}w`;
  }).join(', ');

export const Hero: React.FC<{
  locationName?: string;
  destinationLocationName?: string;
  service?: Maybe<Location__Service>;
  heroAlt?: Maybe<string>;
  heroImage?: Maybe<Pick<ActiveStorageAttachment, 'id' | 'imgixURL'>>;
  headline?: Maybe<string>;
}> = ({
  locationName,
  heroAlt,
  heroImage,
  service,
  destinationLocationName,
  headline,
}) => {
  const [setNode, entry] = useIntersectionObserver();
  const isIntersecting = entry ? entry.isIntersecting : true;

  const header = !!headline?.trim()
    ? headline.trim()
    : (() => {
        if (locationName && destinationLocationName)
          return `Move from ${locationName} to ${destinationLocationName}`;
        switch (service) {
          case Location__Service.SmartStorage:
          case Location__Service.SelfStorage:
            return `${locationName} Storage`;
          case Location__Service.Moving:
            return `${locationName} Moving`;
          default:
            return (
              <>
                Reliable Storage &<br /> Moving Nationally
              </>
            );
        }
      })();

  const fallbackHero =
    service === Location__Service.Moving
      ? fallbackMovingHero.src
      : fallbackStorageHero.src;

  const initialService = service
    ? LOCATION_SERVICE_TO_SERVICE_SELECTION[service]
    : undefined;

  return (
    <div ref={setNode}>
      <Box.Grid
        gridTemplateColumns={['1fr', null, null, '1fr 1fr']}
        margin="24px 0 0"
      >
        <Box.GridItem
          display={['none', null, null, 'block']}
          margin={['auto 0', null, null, 'auto 54px auto 0']}
        >
          <Box maxWidth={BREAKPOINTS.MD}>
            <Image
              sizes={`(max-width: ${BREAKPOINTS.SM} 100vw, ${MAX_HERO_IMAGE_WIDTH})`}
              src={heroImage?.imgixURL || fallbackHero}
              srcSet={srcSet(heroImage?.imgixURL || fallbackHero)}
              alt={heroAlt ?? ''}
            />
          </Box>
        </Box.GridItem>
        <Box.GridItem
          minHeight="352px"
          textAlign={['center', null, null, 'left']}
        >
          <Text.Display>{header}</Text.Display>
          {service && !destinationLocationName && (
            <Box margin={['16px 0 0', null, null, '24px 0 16px']}>
              <Text.Headline>
                Clutter
                {service === Location__Service.Moving
                  ? ' Moving'
                  : '’s Smart Storage'}{' '}
                is the most affordable, flexible, and reliable{' '}
                {service === Location__Service.Moving ? 'moving' : 'storage'}{' '}
                solution {locationName ? `in the ${locationName}` : 'in your'}{' '}
                area.
              </Text.Headline>
            </Box>
          )}
          <Box
            display={['block', null, null, 'none']}
            margin="24px auto"
            maxWidth={BREAKPOINTS.SM}
          >
            <Image
              src={heroImage?.imgixURL || fallbackHero}
              srcSet={srcSet(heroImage?.imgixURL || fallbackHero)}
              alt={heroAlt ?? ''}
            />
          </Box>

          <Box
            maxWidth="730px"
            margin={['24px auto 0', null, null, '32px 0 0']}
            id={ZIP_INPUT_TRIGGER_ID}
          >
            <HeroServiceSelector
              id="hero_service_selector"
              initialService={initialService}
              onServiceChange={() => null}
            />
          </Box>
        </Box.GridItem>
      </Box.Grid>
      <Box display={['block', 'none']}>
        <PinnedFooterContainer show={!isIntersecting} hideMobile={true}>
          <Box.Flex justifyContent="center">
            <PinnedServiceSelector
              position="footer"
              id="footer_service_selector"
              initialService={initialService}
              container="footer_service_selector"
            />
          </Box.Flex>
        </PinnedFooterContainer>
      </Box>
    </div>
  );
};
