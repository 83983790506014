import React from 'react';

import { useHistory } from 'react-router-dom';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { useTrack } from '@root/initializers/wt';
import { WWW_ROUTES } from '@root/root/routes';
import { CustomLink } from '@shared/custom_link';

import moverWithFridge from '@images/illustrations/mover_with_refrigerator.svg';
import selfStorageUnit from '@images/illustrations/self_storage_unit.svg';

const FunnelEntryCard: React.FC<{
  label: string;
  to: string;
  imgSrc: string;
}> = ({ label, to, imgSrc }) => {
  const track = useTrack();
  const history = useHistory();

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    track({
      action: 'click',
      container: 'cart_preview',
      objectName: 'cart_cta_card',
      objectType: 'link',
      value: to,
    });
    history.push(to);
  };

  return (
    <CustomLink to={to} onClick={onClick}>
      <Box.Flex
        border={`2px solid ${COLORS.grayBorder}`}
        borderRadius="4px"
        height="64px"
        gap="32px"
        padding="12px 24px"
        alignItems="center"
      >
        <img src={imgSrc} alt="" width={42} height={38} />
        <Text.Headline color={COLORS.panther} weight={FontWeight.Medium}>
          {label}
        </Text.Headline>
      </Box.Flex>
    </CustomLink>
  );
};

export const ServiceSelectionContent = () => {
  return (
    <>
      <Text.Body color={COLORS.hippo} weight={FontWeight.Medium}>
        How can we help?
      </Text.Body>
      <Box.Flex gap="8px" flexDirection="column" margin="12px 0 0">
        <FunnelEntryCard
          label="I need storage"
          to={WWW_ROUTES.PRODUCT_PAGE_STORAGE}
          imgSrc={selfStorageUnit}
        />
        <FunnelEntryCard
          label="I need to move"
          to={WWW_ROUTES.PRODUCT_PAGE_MOVING}
          imgSrc={moverWithFridge}
        />
      </Box.Flex>
      <Box textAlign="center" margin="16px 0 0">
        <Text.Caption color={COLORS.hippo}>
          Affordable. Flexible. Reliable.
        </Text.Caption>
      </Box>
    </>
  );
};
