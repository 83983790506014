import * as Sentry from '@sentry/browser';
import { isObject } from 'lodash-es';

type ReportErrorOptions = {
  /** If a service supports searchable tags, each key:value pair represents a
   * tag:value. If a service doesn't support tagging, they will be merged with
   * other metadata */
  tags?: Record<string, string>;
  metadata?: Record<string, JSONSafeValue>;
};

export function reportMessage(
  message: string,
  options: ReportErrorOptions = {},
) {
  const { tags, metadata } = options;

  Sentry.withScope((scope) => {
    if (tags) {
      Object.entries(tags).forEach(([key, value]) => {
        scope.setTag(key, value);
      });
    }
    if (metadata) scope.setExtras(metadata);
    Sentry.captureMessage(message);
  });
}

export function reportError(error: Error, options: ReportErrorOptions = {}) {
  const { tags, metadata } = options;

  /** New Relic */
  const nr = window.newrelic;
  if (nr) {
    // NewRelic only accepts string key:value pairs for metadata.
    const withStringifiedValues = Object.entries({
      ...tags,
      ...metadata,
    }).reduce<Record<string, string>>((acc, [key, value]) => {
      if (value !== null)
        acc[key] = isObject(value) ? JSON.stringify(value) : value.toString();
      return acc;
    }, {});
    nr.noticeError(error, withStringifiedValues);
  }

  /** Sentry */
  Sentry.withScope((scope) => {
    if (tags) {
      Object.entries(tags).forEach(([key, value]) => {
        scope.setTag(key, value);
      });
    }
    if (metadata) scope.setExtras(metadata);
    Sentry.captureException(error);
  });
}

/**
 * Throws only in development/test environments to minimize user impact but
 * maximize developer awareness.
 */
export function considerateThrow(error: Error) {
  if (process.env.NODE_ENV === 'production') {
    reportError(error);
  } else {
    throw error;
  }
}
