import { PackageSetEntryFragment } from '@graphql/platform';

export function formatPackageName(packageSetEntry?: PackageSetEntryFragment) {
  if (!packageSetEntry) return 'move-in';
  if (packageSetEntry.amount === 0) return '';
  const { name } = packageSetEntry.package;
  return name === 'Onboarding service'
    ? 'move-in'
    : `${name.toLocaleLowerCase()} fee`;
}

export const amountForFinalPackageSetEntry = ({
  finalPackageSetEntry,
  onboardingPackageSetEntry,
}: {
  finalPackageSetEntry?: PackageSetEntryFragment;
  onboardingPackageSetEntry?: PackageSetEntryFragment;
}): number => {
  const amount = finalPackageSetEntry?.amount;
  if (amount) {
    if (finalPackageSetEntry?.cappedAtOnboardingAmount) {
      return Math.min(amount, onboardingPackageSetEntry?.amount ?? 0);
    } else {
      return amount;
    }
  }
  return 0;
};
