import { ApolloClient } from '@apollo/client';

import {
  FlowStepTransitionDocument,
  FlowStepTransitionMutation,
} from '@graphql/platform';
import { transformKeysToSnakeCase } from '@utils/transform_keys_to_snake_case';

export interface IStepTransitionParams {
  resourceToken: string;
  resourceType: string;
  name: string;
  actionName: string;
  flowInstanceUuid: string;
  flowName: string;
  flowVersion: string;
  data: any;
  allData: any;
  position: number;
  pageUuid: string;
  metadata?: { [key: string]: any };
}

const transformJSON = (data: any) => {
  if (!data) return data;
  return transformKeysToSnakeCase(data, true);
};

const verifyJSON = (data: any) => {
  // Passing `next` directly to an `onClick` handler results in attempting to
  // serialize the click event which fails due to circularity (and unintended).
  try {
    JSON.stringify(data);
    return data;
  } catch {
    return null;
  }
};

export class StepTransition {
  private client: ApolloClient<unknown>;
  private params: IStepTransitionParams;

  constructor(client: ApolloClient<unknown>, params: IStepTransitionParams) {
    this.client = client;
    this.params = { ...params };
  }

  public async save(): Promise<any> {
    const { allData, data, metadata, ...input } = this.params;

    await this.client.mutate<FlowStepTransitionMutation>({
      mutation: FlowStepTransitionDocument,
      variables: {
        input: {
          ...input,
          data: transformJSON(data),
          allData: transformJSON(allData),
          metadata: transformJSON(verifyJSON(metadata)),
        },
      },
    });
  }
}
