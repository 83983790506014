import { type ApolloClient } from '@apollo/client';

import {
  GeocodeDocument,
  GeocodeFragment,
  GeocodeQuery,
  GeocodeQueryVariables,
  Maybe,
} from '@graphql/platform';

import { plausibleZIP } from './plausible_zip';

export enum GeocodeStatus {
  Valid,
  Invalid,
  OSA,
}

export const geocode = async (
  client: ApolloClient<unknown>,
  zip?: string,
): Promise<{
  status: GeocodeStatus;
  geocode?: GeocodeFragment;
}> => {
  if (!zip || !plausibleZIP(zip)) {
    return { status: GeocodeStatus.Invalid };
  }

  try {
    const { data } = await client.query<GeocodeQuery, GeocodeQueryVariables>({
      query: GeocodeDocument,
      variables: { zip },
    });

    if (!data?.geocode) {
      return { status: GeocodeStatus.Invalid };
    }

    const geocode = data.geocode;

    const status: GeocodeStatus =
      geocode.eligibleForMoving || geocode.eligibleForDoorToDoor
        ? GeocodeStatus.Valid
        : GeocodeStatus.OSA;
    return { status, geocode };
  } catch (_error) {
    return { status: GeocodeStatus.Invalid };
  }
};

export type ParsedGeocodeEligibility = {
  hasMoving: boolean; // Clutter moving service eligible
  hasStorage: boolean; // Clutter storage service eligible
};

const FALLBACK_GEOCODE_ELIGIBILITY: ParsedGeocodeEligibility = {
  hasMoving: false,
  hasStorage: false,
};

export function parseGeocodeEligibility(
  geocode?: Maybe<GeocodeFragment>,
): ParsedGeocodeEligibility {
  if (!geocode) {
    return FALLBACK_GEOCODE_ELIGIBILITY;
  }
  const hasMoving = geocode.eligibleForMoving;
  const hasStorage = geocode.eligibleForDoorToDoor;

  return {
    hasMoving,
    hasStorage,
  };
}
