import React from 'react';

import { Box, COLORS, Text } from '@clutter/clean';

export const TermsOfUseAgreement: React.FC<{ actionLabel: string }> = ({
  actionLabel,
}) => (
  <Box textAlign="center" margin="8px 0 0">
    <Text.Caption color={COLORS.storm}>
      By clicking {actionLabel}, you are agreeing to{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="/legal/tos"
        target="_blank"
      >
        Clutter’s Terms of Use
      </a>{' '}
      and to receive transactional SMS messages related to your appointment or
      account. Message and data rates may apply.
    </Text.Caption>
  </Box>
);
