import React from 'react';

import { Box, Tooltip } from '@clutter/clean';

import { InfoTooltipIcon } from './info_tooltip_icon';

export const InfoTooltip: React.FC<{ contents: string }> = ({ contents }) => (
  <Tooltip arrow text={contents}>
    <Box style={{ transform: 'translateY(2px)' }}>
      <InfoTooltipIcon />
    </Box>
  </Tooltip>
);
