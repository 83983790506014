import React from 'react';

import { Box, Text } from '@clutter/clean';
import {
  Location__City,
  Location__Service,
  Location__State,
} from '@graphql/platform';
import { cityURL } from '@root/components/locations/routes';

import { LocationLinksLayout } from './location_links_layout';

export const CityLinks: React.FC<{
  states: ReadonlyArray<
    Pick<Location__State, 'id' | 'code' | 'name' | 'slug'> & {
      cities: ReadonlyArray<
        Pick<Location__City, 'id' | 'content' | 'name' | 'services' | 'slug'>
      >;
    }
  >;
  service: 'storage' | 'moving';
}> = ({ states, service }) => {
  const locationService =
    service === 'moving'
      ? Location__Service.Moving
      : Location__Service.SmartStorage;

  const title =
    service === 'moving'
      ? `Our Local Moving Locations`
      : `Our Storage Locations`;

  return (
    <Box textAlign="center">
      <Box margin="0 0 72px">
        <Text.Title size="large" tag="h2">
          {title}
        </Text.Title>
      </Box>
      <LocationLinksLayout>
        {states.map((state) => (
          <React.Fragment key={state.id}>
            {state.cities
              .filter((city) => city.services.includes(locationService))
              .map((city) => (
                <LocationLinksLayout.Link
                  key={city.id}
                  to={cityURL(state.slug, city.slug, service)}
                >
                  {city.name}, {state.code}
                </LocationLinksLayout.Link>
              ))}
          </React.Fragment>
        ))}
      </LocationLinksLayout>
    </Box>
  );
};
