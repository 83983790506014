import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, Text } from '@clutter/clean';

import roundCheckmark from '@images/icons/round-checkmark.svg';
import tealDottedUnderline from '@images/icons/teal-dotted-underline.svg';

const ListItem = styled.li`
  display: flex;
  flex-direction: flex-start;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const CheckIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 16px;
`;

const ItemDescription = styled(Text.Body)`
  display: inline-block;
  position: relative;
`;

const DottedUnderline = styled.img`
  max-width: initial;
  position: absolute;
  height: 2px;
  left: 2px;
  margin-top: 2px;
  top: 20px;
`;

const ClickableDescription = styled.a`
  overflow: hidden;

  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

const ClickableItemDescription: React.FC<{
  children: React.ReactNode;
  onClick?(): void;
}> = ({ onClick, children }) => (
  <ClickableDescription onClick={onClick}>
    <ItemDescription>
      {children}
      <DottedUnderline src={tealDottedUnderline} alt="" />
    </ItemDescription>
  </ClickableDescription>
);

export const CheckListItem: React.FC<{
  children: React.ReactNode;
  onClick?(): void;
  className?: string;
}> = ({ className, ...props }) => (
  <ListItem className={className}>
    <CheckIcon src={roundCheckmark} />
    {props.onClick ? (
      <ClickableItemDescription {...props} />
    ) : (
      <ItemDescription {...props} />
    )}
  </ListItem>
);
