import { useEffect } from 'react';
import { useState } from 'react';

import loadjs from 'loadjs';

export const useLoadJS = (name: string) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let aborted = false;

    loadjs.ready(name, () => {
      if (!aborted) {
        setLoaded(true);
      }
    });
    return () => {
      aborted = true;
    };
  }, [name]);

  return loaded;
};
