enum LeadSource {
  WWW_LOCAL_REGION_PAGE = 'www_local_region_page',
  WWW_LOCAL_FACILITY_PAGE = 'www_local_facility_page',
  WWW_LOCAL_NEIGHBORHOOD_PAGE = 'www_local_neighborhood_page',
  WWW_FOOTER = 'www_footer',
  LANDING_FOOTER = 'landing_footer',
  REGIONS_FOOTER = 'regions_footer',
  FACILITIES_FOOTER = 'facilities_footer',
  QUALIFIED_CONTACT_POPUP = 'qualified_contact_popup',
}

export type WwwOneFunnelEntryPoint =
  | 'segmentation_on_demand'
  | 'segmentation_self_storage'
  | 'how_it_works_cta'
  | 'solutions_cta'
  | 'self_storage_cta'
  | 'door_to_door_cta'
  | 'services_cta'
  | 'moving_cta'
  | 'moving_footer_cta'
  | 'moving_header_cta'
  | 'service_page_button'
  | 'instant_dream_home_cta'
  | 'location_page_cta'
  | 'location_footer_cta'
  | 'landing_page_cta'
  | 'homepage_cta'
  | 'value_props';

// TODO: we want to port all lead sources over to generateLeadSource
type LeadSourceComponentDomain = 'landing' | 'www';
export function generateLeadSource(components: {
  domain: LeadSourceComponentDomain;
  pageName: string;
  entryPoint: string;
}) {
  return `${components.domain}:${components.pageName}:${components.entryPoint}` as LeadSource;
}

export { LeadSource };
