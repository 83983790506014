import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import {
  COLORS,
  getPropValue,
  toggleStyleValue,
  transparentize,
} from '@clutter/clean';

export enum CardKind {
  White,
  Yellow,
  LightTeal,
}

const THEME_STYLES: Record<CardKind, SerializedStyles> = {
  [CardKind.White]: css`
    background: ${COLORS.cloud};
    border-color: ${COLORS.grayBorder};
  `,
  [CardKind.Yellow]: css`
    background: ${transparentize(COLORS.dust, 0.4)};
    border-color: ${transparentize(COLORS.tiger, 0.4)};
  `,
  [CardKind.LightTeal]: css`
    background: ${COLORS.tealBackground};
    border-color: ${transparentize(COLORS.dew, 0.25)};
  `,
};

export const Card = styled.div<{ shadow?: boolean; kind?: CardKind }>`
  border: 1.5px solid;
  border-radius: 4px;
  padding: 16px;
  color: ${COLORS.panther};
  ${({ kind = CardKind.White }) => THEME_STYLES[kind]}
  box-shadow: ${toggleStyleValue(
    'shadow',
    '0px 6px 12px rgba(0, 0, 0, 0.1)',
    'none',
  )};
`;

export const SolidCard = styled.div<{
  backgroundColor?: string;
  bordered?: boolean;
  borderColor?: string;
  shadow?: boolean;
  padding?: string;
}>`
  background: ${({ backgroundColor }) =>
    backgroundColor || COLORS.grayBackground};
  border-radius: 4px;
  padding: ${getPropValue('padding', '24px')};
  box-shadow: ${toggleStyleValue(
    'shadow',
    '0px 2px 20px rgba(0, 0, 0, 0.1)',
    'none',
  )};
  border: ${({ borderColor }) =>
    toggleStyleValue(
      'bordered',
      `2px solid ${borderColor || COLORS.grayBorder}`,
      'none',
    )};
`;

export const ErrorCard = styled(Card)`
  background: ${COLORS.flower};
  border-color: ${COLORS.toucan};
  color: ${COLORS.toucan};
  margin-top: 32px;
`;
