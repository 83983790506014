import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, mq, Text } from '@clutter/clean';
import { Location__Service } from '@graphql/platform';
import {
  MOVING_ROUTE_VALUE_PROPS,
  MOVING_VALUE_PROPS,
  STORAGE_VALUE_PROPS,
} from '@root/components/locations/constants';
import { CheckListItem } from '@shared/check_list_item';
import { ResponsiveImage } from '@shared/responsive_image';

import mainMovingImage from '@images_responsive/locations/moving_detail_1.jpg';
import secondaryMovingImage from '@images_responsive/locations/moving_detail_2.jpg';
import mainStorageImage from '@images_responsive/locations/storage_detail_1.jpg';
import secondaryStorageImage from '@images_responsive/locations/storage_detail_2.jpg';
import bbb from '@images/reviews/bbb.svg';
import trustPilot from '@images/reviews/trust_pilot.svg';
import yelp from '@images/reviews/yelp.svg';

const List = styled.ul`
  margin-bottom: 24px;
  text-align: left;

  ${mq({
    columnCount: [1, null, 2],
  })}
`;

const Image = styled(ResponsiveImage)`
  border-radius: 8px;
  display: block;
  overflow: hidden;
`;

const MOBILE_GRID_LAYOUT = `
  "heading"
  "images"
  "values"
`;

const DESKTOP_GRID_LAYOUT = `
  "heading images"
  "values images"
`;

export const ServiceOverview: React.FC<{
  title: React.ReactNode;
  subtitle: React.ReactNode;
  service: Location__Service;
  isRoute?: boolean;
}> = ({ title, subtitle, service, isRoute }) => {
  const [valueProps, mainImage, secondaryImage] =
    service === Location__Service.Moving
      ? isRoute
        ? ([
            MOVING_ROUTE_VALUE_PROPS,
            mainMovingImage,
            secondaryMovingImage,
          ] as const)
        : ([MOVING_VALUE_PROPS, mainMovingImage, secondaryMovingImage] as const)
      : ([
          STORAGE_VALUE_PROPS,
          mainStorageImage,
          secondaryStorageImage,
        ] as const);

  return (
    <Box.Grid
      gridTemplateAreas={[MOBILE_GRID_LAYOUT, null, DESKTOP_GRID_LAYOUT]}
      color={COLORS.tealDark}
      textAlign={['center', null, 'left']}
      gridGap="24px"
    >
      <Box.GridItem gridArea="heading">
        <Box margin="0 0 16px">
          <Text.Title size="large" tag="h2">
            {title}
          </Text.Title>
        </Box>
        <Text.Headline>{subtitle}</Text.Headline>
      </Box.GridItem>
      <Box.GridItem gridArea="values">
        <Box.Flex
          flexDirection="column"
          justifyContent={['center', null, 'initial']}
          alignItems={['center', null, 'initial']}
          width={['initial', '400px', '660px']}
          gap="24px"
          textAlign={['center', null, 'left']}
          margin={['0 auto', null, 'initial']}
        >
          <List>
            {valueProps.map((item, i) => (
              <CheckListItem key={i}>{item}</CheckListItem>
            ))}
          </List>
          <Box.Flex
            gap="24px"
            flexDirection={['column', 'row']}
            alignItems={['center']}
          >
            <Box.Flex gap="24px">
              <img
                loading="lazy"
                src={trustPilot}
                width={104}
                height={40}
                draggable={false}
                alt="trust pilot"
              />
              <img
                loading="lazy"
                src={yelp}
                alt="yelp"
                width={73}
                height={37}
                draggable={false}
              />
              <img
                loading="lazy"
                src={bbb}
                alt="BBB"
                width={72}
                height={36}
                draggable={false}
              />
            </Box.Flex>
          </Box.Flex>
        </Box.Flex>
      </Box.GridItem>
      <Box.GridItem gridArea="images">
        <Box.Flex gap="16px" minWidth={[null, '400px']}>
          <Box.FlexItem width="60%">
            <Image image={mainImage} alt="" />
          </Box.FlexItem>
          <Box.FlexItem width="40%" padding="16% 0 0">
            <Image image={secondaryImage} alt="" />
          </Box.FlexItem>
        </Box.Flex>
      </Box.GridItem>
    </Box.Grid>
  );
};
