import React, { type ComponentProps, type FC } from 'react';

import { Dropdown as DropdownComponent } from '@clutter/clean';
import { SERVICE_SELECTION_OPTIONS } from '@shared/service_selector/constants';

import { useFunnelEntryPointContext } from './use_funnel_entry_point_context';

export const FunnelEntryPointDropdown: FC<
  Partial<ComponentProps<typeof DropdownComponent>>
> = (props) => {
  const { serviceSelection, onServiceChange } = useFunnelEntryPointContext();

  return (
    <DropdownComponent
      options={SERVICE_SELECTION_OPTIONS}
      placeholder="Select service"
      value={serviceSelection ?? ''}
      onChange={onServiceChange as any}
      {...props}
    />
  );
};
