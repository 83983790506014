import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import {
  Box,
  Button,
  COLORS,
  FontWeight,
  Input,
  Label,
  Text,
} from '@clutter/clean';
import {
  GeocodeDocument,
  GeocodeQuery,
  GeocodeQueryVariables,
} from '@graphql/platform';
import { OSACard } from '@root/components/checkout/product_pages/subcomponents/osa_card';
import { MovingCheckoutStepProps } from '@root/components/checkout/types';
import { useZipValidatedFunnelEvent } from '@utils/hooks/funnel_events/use_zip_validated_funnel_event';
import { reportError } from '@utils/monitoring';

const ZIP_REGEX = /^\d{5}$|^[a-z0-9]{6}$/i;

export const Zip: React.FC<MovingCheckoutStepProps> = (props) => {
  const {
    scrollToStep,
    values: { zip },
    onChange,
  } = props;

  const [localZip, setLocalZip] = useState(zip);
  const [showOSAMessage, setShowOSAMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zipError, setZipError] = useState<string | undefined>();
  const client = useApolloClient();
  const trackZipValidated = useZipValidatedFunnelEvent();

  useEffect(() => {
    setLocalZip(zip);
  }, [zip]);

  const handleZipChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLocalZip(value);
    onChange('zipValidated', false);
    if (showOSAMessage) setShowOSAMessage(false);
    if (zipError) setZipError(undefined);
  };

  const handleZipSubmit = async () => {
    if (!localZip || !ZIP_REGEX.test(localZip)) {
      setZipError('Please enter a complete zip code');
      return;
    }
    try {
      setLoading(true);
      const { data } = await client.query<GeocodeQuery, GeocodeQueryVariables>({
        query: GeocodeDocument,
        variables: { zip: localZip },
      });
      const movingEligible = !!data?.geocode?.eligibleForMoving;
      if (movingEligible) {
        onChange('zip', localZip);
        onChange('zipValidated', true);
        onChange(
          'skipAddressEligible',
          !data.geocode.extendedMovingServiceArea,
        );
        trackZipValidated({ zip: localZip, service: 'moving' });
        scrollToStep('next');
      } else if (data && !data.geocode) {
        setZipError('Please enter a valid ZIP code');
      } else {
        setShowOSAMessage(true);
      }
    } catch (e: any) {
      reportError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box margin={['0 0 48px', null, '0 0 72px']}>
      <Box margin="0 0 24px">
        <Text.Title size="small" color={COLORS.tealDark}>
          What’s your starting ZIP code?
        </Text.Title>
      </Box>
      <Label>ZIP Code</Label>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleZipSubmit();
        }}
      >
        <Box.Flex gap="16px">
          <Box.FlexItem>
            <Input
              name="zip"
              value={localZip ?? ''}
              onChange={handleZipChange}
              style={{ width: '100%' }}
            />
          </Box.FlexItem>
          <Box.FlexItem width="130px">
            <Button type="submit" loading={loading}>
              Submit
            </Button>
          </Box.FlexItem>
        </Box.Flex>
      </form>
      <Box minHeight="19px" margin="8px 0 0">
        <Text.Caption color={COLORS.toucan} weight={FontWeight.Medium}>
          {zipError}
        </Text.Caption>
      </Box>
      {showOSAMessage && <OSACard />}
    </Box>
  );
};
