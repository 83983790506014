import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  InfoModal,
  mq,
  Text,
  toggleStyleValue,
  transparentize,
} from '@clutter/clean';

import sparkle from '@images/illustrations/sparkle.svg';

const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

const ModalBanner = styled.div`
  background: ${transparentize(COLORS.dust, 0.6)};
  text-align: center;

  ${mq({
    margin: ['-68px -16px 0', '-80px -24px 0'],
    padding: ['68px 16px 16px', '80px 24px 24px'],
  })}
`;

const Sparkle = styled.img<{ flip?: boolean }>`
  margin: auto 16px;
  width: 24px;
  padding-bottom: 4px;
  align-self: ${toggleStyleValue('flip', 'flex-end', 'flex-start')};
  margin-left: ${toggleStyleValue('flip', '0px', '16px')};
  margin-right: ${toggleStyleValue('flip', '16px', '0px')};
  transform: ${toggleStyleValue('flip', 'scaleX(-1)', 'none')};
`;

const ModalDescriptionSection: React.FC<{ header: string; body: string }> = ({
  header,
  body,
}) => (
  <Box.Flex flexDirection="column">
    <Box margin="0 0 4px">
      <Text.Headline weight={FontWeight.Medium}>{header}</Text.Headline>
    </Box>
    <Text.Body>{body}</Text.Body>
  </Box.Flex>
);

export const FreeStorageContent: React.FC<{ planAdded: boolean }> = ({
  planAdded,
}) => {
  return (
    <>
      <ModalBanner>
        <Box.Flex
          alignItems="center"
          justifyContent="center"
          margin="0 0 10px 0"
        >
          <Sparkle flip src={sparkle} alt="" />
          <Text.Title size="small">1 month of FREE Storage</Text.Title>
          <Sparkle src={sparkle} alt="" />
        </Box.Flex>
      </ModalBanner>
      <ModalTextContainer>
        <ModalDescriptionSection
          header="About Smart Storage"
          body="Clutter stores your items in state-of-the-art warehouses outside of city centers with 24/7 security and cameras. These warehouses have custom racking to safely store a wide variety of items. You can view your digital inventory and order items to be delivered anytime from your online account portal."
        />
        {!planAdded && (
          <ModalDescriptionSection
            header="How do I redeem this offer?"
            body="After you book your move, you will be able to book a storage plan using your online account portal. This can be done at any point before your move date."
          />
        )}
      </ModalTextContainer>
    </>
  );
};

export const MovingFreeStorageModal: React.FC<{
  isOpen: boolean;
  planAdded: boolean;
  handleModalClose(): void;
}> = ({ isOpen, planAdded, handleModalClose }) => {
  return (
    <InfoModal isOpen={isOpen} handleModalClose={handleModalClose}>
      <FreeStorageContent planAdded={planAdded} />
    </InfoModal>
  );
};
