import * as React from 'react';

export const Pluralize: React.FC<{
  count: number;
  singular: string;
  plural: string;
}> = ({ count, singular, plural }) => (
  <>
    {count} {count === 1 ? singular : plural}
  </>
);
