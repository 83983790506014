import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { EventSchema } from '@root/resources/wt/types';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { useOnMount } from '@utils/hooks/mount';

export const useFlowInitiatedFunnelEvents = () => {
  const {
    flowVersion,
    flowState: {
      values: { zipValidated, zip },
    },
  } = useSharedCheckoutContext();
  const trackFunnelEvent = useTrackFunnelEvents();
  useOnMount(() => {
    trackFunnelEvent({
      schema: EventSchema.WWW__FlowInitiated,
      action: 'display',
      metadata: { zip },
    });
    if (zipValidated) {
      trackFunnelEvent({
        schema: EventSchema.WWW__ZipValidated,
        action: 'click',
        metadata: {
          service: flowVersion === 'two_page_storage' ? 'storage' : 'moving',
          zip: zip!,
        },
      });
    }
  });
};
