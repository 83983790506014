import React, { useState } from 'react';

import styled from '@emotion/styled';
import { DateTime, Duration } from 'luxon';

import { Box, COLORS, FontWeight, Text, UnstyledButton } from '@clutter/clean';
import { useMovingCheckoutContext } from '@root/components/checkout/context';
import { MATERIAL_KIT_METADATA } from '@root/components/checkout/helpers/material_kits';
import { isDateScheduledBeforeDepositCutoff } from '@root/components/checkout/helpers/moving';
import { Currency } from '@shared/currency';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { MovingBillingModal } from './moving_billing_modal';

const ModalButton = styled(UnstyledButton)`
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

export const MovingDueToday: React.FC = () => {
  const {
    flowState: {
      values: { movingQuote, dateScheduled, datePreferred, packingMaterials },
    },
  } = useMovingCheckoutContext();
  const { isDesktop } = useBreakpoints();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const depositIntervalInDays =
    movingQuote && Duration.fromISO(movingQuote.depositInterval).as('days');
  const appointmentDateTime = dateScheduled
    ? DateTime.fromISO(dateScheduled.fromTime)
    : // This branch is an unlikely error state so incorrect data _might_ be rendered,
      // but it will be behind a modal conveying the error.
      datePreferred || DateTime.local();
  const depositDueDate = appointmentDateTime
    .minus({ days: depositIntervalInDays })
    .toFormat('MMM d');

  const laborRateAmount = movingQuote?.laborRate?.amount;
  const reservationDepositAmount = movingQuote?.reservationDepositAmount ?? 0;

  const dueTodayCost = isDateScheduledBeforeDepositCutoff(
    depositIntervalInDays,
    dateScheduled,
  )
    ? 0
    : reservationDepositAmount;
  const materialKit = packingMaterials
    ? MATERIAL_KIT_METADATA[packingMaterials.package.name]
    : undefined;

  const isLongDistance = movingQuote?.longDistanceTransportationRate;

  return (
    <>
      <Box background={COLORS.tealBackground} borderRadius="4px" padding="8px">
        {!isDesktop && !isLongDistance && (
          <>
            {!!laborRateAmount && (
              <Box.Flex margin="0 0 8px" justifyContent="space-between">
                <Text.Body>Moving fee</Text.Body>
                <Text.Body>
                  <Currency amount={laborRateAmount} suffix="/hr" />
                </Text.Body>
              </Box.Flex>
            )}
            {!!packingMaterials && materialKit && (
              <Box.Flex margin="0 0 8px" justifyContent="space-between">
                <Text.Body>{materialKit.lineItemName}</Text.Body>
                <Text.Body>
                  <Currency amount={packingMaterials.fixedCost} />
                </Text.Body>
              </Box.Flex>
            )}
          </>
        )}
        <Box.Flex justifyContent="space-between" margin="0 0 8px">
          <Text.Body weight={FontWeight.Medium}>Due today</Text.Body>
          <Text.Body weight={FontWeight.Medium}>
            <Currency amount={dueTodayCost} />
          </Text.Body>
        </Box.Flex>
        {!dueTodayCost && (
          <Box.Flex justifyContent="space-between" margin="0 0 12px">
            <Box>
              <Text.Body weight={FontWeight.Medium}>
                Deposit due {depositDueDate}
              </Text.Body>
              <Text.Caption color={COLORS.storm}>
                10% of min cost of move
              </Text.Caption>
            </Box>
            <Text.Body weight={FontWeight.Medium}>
              <Currency amount={reservationDepositAmount} />
            </Text.Body>
          </Box.Flex>
        )}
        <ModalButton>
          <Text.Callout
            weight={FontWeight.Medium}
            onClick={() => setIsOpen(true)}
          >
            When will I be charged?
          </Text.Callout>
        </ModalButton>
      </Box>
      <MovingBillingModal
        isOpen={isOpen}
        handleModalClose={() => setIsOpen(false)}
      />
    </>
  );
};
