import React from 'react';

import { COLORS } from '@clutter/clean';

import { ISharedIconProps } from './shared_icon_props';

export const XButton: React.FC<ISharedIconProps & { strokeWidth?: number }> = ({
  width = '15',
  height = '15',
  strokeWidth = 2.5,
  className,
  fillColor = COLORS.panther,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
  >
    <path
      vectorEffect="non-scaling-stroke"
      stroke={fillColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      d="M2,2 L28,28"
    />
    <path
      vectorEffect="non-scaling-stroke"
      stroke={fillColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      d="M2,28 L28,2"
    />
  </svg>
);
