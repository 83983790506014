import { useRef, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import {
  ChangeData,
  StaircaseConfig,
  useStaircase,
  UseStaircaseResult,
} from '@clutter/staircase';
import { WTEventParams } from '@clutter/wt';
import { track, WT_PAGE_UUID } from '@root/initializers/wt';
import { IStepTransitionParams } from '@root/resources/step_transition';
import { StepTransition } from '@root/resources/step_transition';
import { useStabilizedFunction } from '@utils/hooks';
import { useOnMount } from '@utils/hooks/mount';
import { uuid } from '@utils/uuid';

type IStepTransitionConfig<Values> = Pick<
  IStepTransitionParams,
  'flowName' | 'flowVersion' | 'resourceType' | 'resourceToken'
> & {
  transform?(data: Values): any;
  metadata?: { [key: string]: any };
};

type IWTConfig = WTEventParams & {
  transformValue?(key: string, value: any): any;
};

export interface AddedFlowHelpers<Values> {
  position: number;
  flowInstanceUuid: string;
  createStepTransition(
    name: string,
    action: string,
    meta?: { [key: string]: any },
    values?: Values,
  ): void;
  createEvent(params: WTEventParams): void;
  completeFlow(action: string, metadata: Record<string, any>): Promise<void>;
}

interface IFlowContainerProps<Values, X = any> {
  // Custom Props
  stepTransitionConfig: IStepTransitionConfig<Values>;
  wtConfig: IWTConfig | false;
  instanceUuid?: string;
  skipStepTransition?: boolean;
  initialMetadata?: Record<string, any>;

  // Modified Staircase Props
  onStepChange?(
    changeData: ChangeData<Values, X>,
    meta?: any,
  ): IStepChangeResult | void;
  onChange?(
    key: any,
    value: any,
    data: ChangeData<Values, X>,
  ): WTEventParams | void;
}

// Omit props that are overwritten/proxied by FlowContainer so we aren't inadvertantly merging types
type FilteredStaircaseProps<Values, AdditionalProps> = Omit<
  StaircaseConfig<Values, AdditionalProps>,
  keyof IFlowContainerProps<Values, AdditionalProps>
>;

interface IStepChangeResult {
  action?: string;
  meta?: { [key: string]: any };
}

export type FlowConfig<Values, AdditionalProps> = IFlowContainerProps<
  Values,
  AdditionalProps
> &
  FilteredStaircaseProps<Values, AdditionalProps>;

export type UseFlowResult<Values, AdditionalProps> = UseStaircaseResult<
  Values,
  AdditionalProps
> &
  AddedFlowHelpers<Values>;

export const useFlow = <Values, AdditionalProps = {}>({
  steps,
  initialValues,
  initialMetadata,
  values,
  initialStepIndex,
  instanceUuid,
  wtConfig,
  stepTransitionConfig,
  skipStepTransition,
  onStepChange: propsOnStepChange,
  onChange: propsOnChange,
}: FlowConfig<Values, AdditionalProps>): UseFlowResult<
  Values,
  AdditionalProps
> => {
  const client = useApolloClient();
  const currentStepDataRef = useRef({} as Values);
  const currentValuesRef = useRef({} as Values);
  const currentIndexRef = useRef(0);
  const previousIndexRef = useRef<number | null>(null);
  const positionRef = useRef(initialStepIndex || 0);
  const flowInstanceUuid = useState(() => instanceUuid || uuid())[0];

  const createEvent = useStabilizedFunction((data: WTEventParams) => {
    if (wtConfig) {
      track({
        container: steps[currentIndexRef.current].name,
        position: positionRef.current,
        metadata: {
          flow_instance_uuid: flowInstanceUuid,
        },
        ...wtConfig,
        ...data,
      });
    }
  });

  const createStepTransition = useStabilizedFunction(
    async (
      name: string,
      actionName: string,
      metadata?: { [key: string]: any },
      values?: Values,
      position = positionRef.current,
    ) => {
      const {
        transform,
        flowName,
        flowVersion,
        resourceToken,
        resourceType,
        metadata: stepTransitionMetadata,
      } = stepTransitionConfig;
      const data = transform
        ? transform(currentStepDataRef.current)
        : currentStepDataRef.current;
      const allData = transform && values ? transform(values) : values;

      const step = new StepTransition(client, {
        flowName,
        flowVersion,
        flowInstanceUuid,
        resourceToken,
        resourceType,
        data,
        allData,
        name,
        actionName,
        position,
        pageUuid: WT_PAGE_UUID,
        metadata: { ...metadata, ...stepTransitionMetadata },
      });

      if (!skipStepTransition) {
        try {
          await step.save();
        } catch (e) {
          // TODO: Error handling
          throw e;
        }
      }
    },
  );

  const onChange = useStabilizedFunction(
    (key: keyof Values, value: any, changeData: ChangeData<Values>) => {
      const changeResult = propsOnChange
        ? propsOnChange(key, value, changeData)
        : {};

      currentValuesRef.current = changeData.values;
      currentStepDataRef.current[key] = value;

      if (wtConfig) {
        const { transformValue } = wtConfig;
        createEvent({
          objectType: 'input:text',
          objectName: steps[changeData.currentStepIndex].name,
          label: key as string,
          action: 'input',
          value: transformValue ? transformValue(key as string, value) : value,
          ...changeResult,
        });
      }
    },
  );

  const onStepChange = useStabilizedFunction(
    (changeData: ChangeData<Values>, meta?: any) => {
      const {
        currentStepIndex: nextIndex,
        lastStepIndex,
        direction,
        values,
      } = changeData;

      const transitionedFrom =
        previousIndexRef.current === null
          ? null
          : steps[previousIndexRef.current].name;
      const transitionedTo = steps[nextIndex].name;
      const currentStep = steps[currentIndexRef.current].name;

      const stepChangeResult = propsOnStepChange?.(changeData, meta) || {};
      const transitionMeta = {
        transitionedFrom,
        transitionedTo,
        ...meta,
        ...stepChangeResult.meta,
      };

      if (currentStep !== transitionedTo) {
        // Allow providing a step name, needed for inline checkout
        const previousName = meta?.previousName ?? steps[lastStepIndex!].name;
        if (meta?.previousName) delete meta.previousName;

        const position = meta?.position ?? positionRef.current;
        if (meta?.position) delete meta.position;

        createStepTransition(
          previousName,
          stepChangeResult.action || direction!,
          transitionMeta,
          values,
          position,
        );

        if (meta && meta.link) {
          createEvent({
            objectType: 'link',
            action: 'click',
            objectName: direction === 'prev' ? 'back' : 'next',
            metadata: { next: steps[nextIndex].name },
          });
        }

        if (direction === 'next') {
          positionRef.current++;
        } else {
          positionRef.current--;
        }
      }

      previousIndexRef.current = currentIndexRef.current;
      currentIndexRef.current = nextIndex;
      currentStepDataRef.current = {} as Values;
    },
  );

  const completeFlow = useStabilizedFunction(
    (action: string, metadata?: Record<string, any>) =>
      createStepTransition(
        'flow_completed',
        action,
        metadata,
        currentValuesRef.current,
      ),
  );

  useOnMount(() => {
    if (steps[0]) {
      createStepTransition(
        'flow_initiated',
        'start',
        {
          transitionedTo: steps[initialStepIndex ?? 0].name,
          ...initialMetadata,
        },
        initialValues,
        0,
      );
    }
  });

  const staircaseState = useStaircase<Values, AdditionalProps>({
    steps,
    initialValues,
    initialStepIndex,
    values,
    onChange,
    onStepChange,
  });

  const flowState = {
    ...staircaseState,
    createStepTransition,
    createEvent,
    completeFlow,
    position: positionRef.current,
    flowInstanceUuid: flowInstanceUuid,
  };

  return flowState;
};
