import React from 'react';

import { Box } from '@clutter/clean';
import { AddressFragment, useGeocodeQuery } from '@graphql/platform';
import { MapProvider } from '@shared/map/context';
import { Pin } from '@shared/map/pin';
import { useServerDataContext } from '@shared/server_data_context';

import { StorageMapValueProps } from './storage_map_value_props';

const MAP_OPTIONS = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
};

export const StorageMap: React.FC<{
  zip?: string;
  address?: Partial<AddressFragment>;
}> = ({ zip, address }) => {
  const { inferredZipCode } = useServerDataContext();
  const geocode = useGeocodeQuery({
    variables: { zip: zip ?? inferredZipCode ?? '90210' },
  });

  const center =
    (address && address.latitude && address.longitude
      ? { lat: address.latitude, lng: address.longitude }
      : undefined) || geocode?.data?.geocode?.center;

  return (
    <>
      {center && (
        <Box height="400px">
          <MapProvider center={center} options={MAP_OPTIONS}>
            {center && <Pin {...center} />}
          </MapProvider>
        </Box>
      )}
      <StorageMapValueProps />
    </>
  );
};
