import { useCallback, useEffect, useState } from 'react';

import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';

import { useLatestRef } from '@clutter/hooks';
import { EventSchema } from '@root/resources/wt/types';
import { useAvailabilitySummary } from '@utils/hooks/availability_summary';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';

export const useAppointmentFunnelEvents = ({
  enabled,
  appointmentDatesShown,
  arrivalWindowCount,
  from,
  till,
  currentDay,
  originZip,
  destinationZip,
  availableDays,
  isUnavailable,
}: {
  enabled: boolean;
  appointmentDatesShown: boolean;
  arrivalWindowCount: number | undefined;
  from: DateTime;
  till: DateTime;
  originZip: string;
  destinationZip?: string;
  availableDays?: Set<string>;
  currentDay: DateTime;
  isUnavailable: (
    value: DateTime,
    availabilitySet: Set<string> | undefined,
  ) => boolean;
}) => {
  const trackFunnelEvent = useTrackFunnelEvents();
  const availabilitySummary = useAvailabilitySummary({
    from,
    till,
    currentDay,
    availableDays,
    isUnavailable,
  });

  const totalDays = availabilitySummary.length;
  const unavailableDays =
    totalDays - availabilitySummary.filter((available) => available).length;
  const inputRef = useLatestRef({
    from: from.toISODate(),
    originZip,
    destinationZip,
    unavailableDays,
    totalDays,
  });

  const [availability, setAvailability] = useState<boolean[]>([]);

  useEffect(() => {
    if (!isEqual(availability, availabilitySummary)) {
      setAvailability(availabilitySummary);
    }
  }, [availability, setAvailability, availabilitySummary]);

  useEffect(() => {
    if (enabled && appointmentDatesShown) {
      trackFunnelEvent({
        schema: EventSchema.WWW__AppointmentDateViewed,
        action: 'display',
        metadata: inputRef.current,
      });
    }
  }, [
    enabled,
    trackFunnelEvent,
    appointmentDatesShown,
    inputRef,
    availability,
  ]);

  useEffect(() => {
    if (enabled && arrivalWindowCount !== undefined) {
      trackFunnelEvent({
        schema: EventSchema.WWW__ArrivalWindowViewed,
        action: 'display',
        metadata: { window_count: arrivalWindowCount },
      });
    }
  }, [enabled, trackFunnelEvent, arrivalWindowCount]);

  const trackUnpackingDateViewed = useCallback(
    (input: {
      packingDate: string;
      packingDays: number;
      availableDays: number;
    }) =>
      trackFunnelEvent({
        schema: EventSchema.WWW__UnpackingDateViewed,
        action: 'display',
        metadata: {
          originZip: originZip ?? '',
          destinationZip: destinationZip ?? '',
          ...input,
        },
      }),
    [originZip, destinationZip, trackFunnelEvent],
  );

  return {
    trackAppointmentDateCompleted(date: string) {
      trackFunnelEvent({
        schema: EventSchema.WWW__AppointmentDateCompleted,
        action: 'click',
        metadata: { date },
      });
    },

    trackArrivalWindowCompleted(arrivalWindow: string) {
      trackFunnelEvent({
        schema: EventSchema.WWW__ArrivalWindowCompleted,
        action: 'click',
        metadata: { arrival_window: arrivalWindow },
      });
    },

    trackUnpackingDateCompleted(date: string) {
      trackFunnelEvent({
        schema: EventSchema.WWW__UnpackingDateCompleted,
        action: 'click',
        metadata: { date },
      });
    },

    trackUnpackingDateViewed,
  };
};
