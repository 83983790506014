import * as React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { COLORS, getPropValue, toggleStyleValue } from '@clutter/clean';

const sharedStyles = css`
  display: block;
  position: absolute;
  width: 100%;
  border-radius: 2px;
  height: 2px;
`;

const Top = styled.span<{ open: boolean }>`
  ${sharedStyles};
  transform: rotate(${toggleStyleValue('open', '45deg', '0deg')});
  bottom: ${toggleStyleValue('open', `50%`, '100%')};
`;

const Middle = styled.span<{ open: boolean }>`
  ${sharedStyles};
  bottom: 50%;
  opacity: ${toggleStyleValue('open', 0, 1)};
`;

const Bottom = styled.span<{ open: boolean }>`
  ${sharedStyles};
  transform: rotate(${toggleStyleValue('open', '-45deg', '0deg')});
  bottom: ${toggleStyleValue('open', `50%`, '0%')};
`;

const Outer = styled.div<{ fillColor: string }>`
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 20px;

  & > span {
    background: ${getPropValue('fillColor')};
  }
`;

export const HamburgerToggle: React.FC<{
  open: boolean;
  fillColor?: string;
  className?: string;
}> = ({ open, className, fillColor = COLORS.tealPrimary }) => (
  <Outer aria-hidden className={className} fillColor={fillColor}>
    <Top open={open} />
    <Middle open={open} />
    <Bottom open={open} />
  </Outer>
);
