import { WWW_ROUTES } from '@root/root/routes';

import { reportError } from './monitoring';

const resolveIsLanding = () => location.hostname.includes('storage');

const HELP_REGEX = /^\/help/;
const SELF_STORAGE_RENTAL_REGEX = /self-storage-rent\/\S+/;
const SEO_CITY_REGEX =
  /^\/locations\/(?<state>[^\/]+)\/(?<city>[^\/]+)\/(?<service>moving|storage)/;
const SEO_NEIGHBORHOOD_REGEX =
  /^\/locations\/(?<state>[^\/]+)\/(?<city>[^\/]+)\/(?<neighborhood>[^\/]+)\/(?<service>moving|storage)/;
const SEO_ROUTE_REGEX =
  /^\/locations\/r\/(?<route>.+)\/(?<service>moving|storage)/;

const LEGACY_PATH_TO_REGEX: Record<string, RegExp> = {
  local_self_storage_detail: /^\/self-storage/,
  local_region: /^\/loc\/(\S+)\/storage$/,
  local_city: /^\/loc\/(\S+)\/storage-units$/,
};

const getLegacyPageName = (pathname: string) => {
  for (const key in LEGACY_PATH_TO_REGEX) {
    if (LEGACY_PATH_TO_REGEX[key].test(pathname)) {
      return key;
    }
  }
};

const getPageNameFromPath = (pathname: string) => {
  let match: RegExpMatchArray | null;
  if (HELP_REGEX.test(pathname)) return 'help';
  if (pathname.startsWith('/p/')) return 'landing_page';
  if (SELF_STORAGE_RENTAL_REGEX.test(pathname)) return '';
  if ((match = pathname.match(SEO_CITY_REGEX))) {
    const service = match.groups!.service;
    return `seo_city_${service}`;
  }
  if ((match = pathname.match(SEO_NEIGHBORHOOD_REGEX))) {
    const service = match.groups!.service;
    return `seo_neighborhood_${service}`;
  }
  if ((match = pathname.match(SEO_ROUTE_REGEX))) {
    const service = match.groups!.service;
    return `seo_route_${service}`;
  }

  switch (pathname) {
    case '':
    case '/':
      return 'home';
    case '/locations':
      return 'locations';
    case '/company':
      return 'company';
    case '/legal':
      return 'legal';
    case '/legal/tos':
      return 'legal';
    case '/legal/privacy_policy':
      return 'legal';
    case '/legal/california_privacy_rights':
      return 'legal';
    case '/legal/limited_security_warranty':
      return 'legal';
    case '/careers':
      return 'careers';
    case '/getstarted':
      return 'checkout';
    case '/guide':
      return 'guide';
    case WWW_ROUTES.SERVICE_WAREHOUSE_STORAGE:
      return 'service_warehouse_storage';
    case WWW_ROUTES.SERVICE_MOVING:
      return 'service_moving';
    case WWW_ROUTES.SERVICE_STORAGE_AND_MOVING:
      return 'service_storage_and_moving';
    case WWW_ROUTES.STORAGE_CART:
      return 'smart_storage_cart';
    case WWW_ROUTES.MOVING_CART:
      return 'moving_cart';
    case WWW_ROUTES.PRODUCT_PAGE_STORAGE:
      return 'smart_storage_detail_page';
    case WWW_ROUTES.PRODUCT_PAGE_MOVING:
      return 'moving_detail_page';
  }
};

const MISSING_PAGENAME_MESSAGE =
  'Unable to generate landing page name: Invalid pathname';

interface IPageNameOptions {
  prefix?: boolean;
}

export const getPageName = ({ prefix = true }: IPageNameOptions = {}) => {
  if (typeof window === 'undefined') {
    return 'unknown';
  }

  const pathname = window.location.pathname.replace(/\/$/, ''); // Ensures no trailing slash
  const isLanding = resolveIsLanding();
  let resolvedPrefix = '';
  if (prefix) {
    resolvedPrefix = isLanding ? 'landing:' : 'www:';
  }
  const resolvedPageName = getPageNameFromPath(pathname);

  if (!resolvedPageName) {
    const legacyPageName = getLegacyPageName(pathname);
    // These page names do not conform to the newer prefixed pattern
    if (legacyPageName) return legacyPageName;
  }

  if (resolvedPageName) {
    return `${resolvedPrefix}${resolvedPageName}`;
  } else if (process.env.NODE_ENV === 'production') {
    reportError(new Error(MISSING_PAGENAME_MESSAGE));
    return `${resolvedPrefix}unknown`;
  } else {
    throw new Error(MISSING_PAGENAME_MESSAGE);
  }
};
