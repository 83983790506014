import * as React from 'react';

import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';

import { COLORS } from '@clutter/clean';
import { filterValidProps } from '@utils/props';

const ABSOLUTE_URL_REGEX = /^https?:\/\//;

const PATHS_WITH_SEPARATE_PACK = ['loc', 'self-storage', 'd', 'locations', 'p'];

const firstSegment = (path: string) =>
  (path[0] === '/' ? path.slice(1) : path).split('/')[0];

const resolvePack = (path: string) => {
  const prefix = firstSegment(path);
  if (PATHS_WITH_SEPARATE_PACK.includes(prefix)) return prefix;
};

const ORIGIN_PATH =
  typeof window !== 'undefined'
    ? firstSegment(window.location.pathname)
    : undefined;

const resolveUseAnchor = (to: string, from: string) => {
  if (ABSOLUTE_URL_REGEX.test(to)) return true;
  if (resolvePack(to) !== resolvePack(ORIGIN_PATH || from)) return true;
  return false;
};

const style = {
  color: COLORS.tealPrimary,

  '&:hover': {
    color: COLORS.__primaryButtonHover,
  },
};

const StyledAnchor = styled.a(style);
const StyledLink = styled(Link)(style);

const CustomLink: React.FC<
  {
    /**
     * If `to` is intended to be an absolute path, it must be prefixed with http:// or https://
     */
    to: string;
    unstyled?: boolean;
    /** Only relevant when `useATag === true` */
    target?: string;
    rel?: string;
  } & React.HTMLAttributes<HTMLAnchorElement>
> = ({ children, target = '_self', to, rel, unstyled, ...props }) => {
  const { pathname } = useLocation();
  const useAnchor = resolveUseAnchor(to, pathname);
  const filteredProps = filterValidProps(props);

  const AnchorTag = unstyled ? 'a' : StyledAnchor;
  const LinkTag = unstyled ? Link : StyledLink;

  return useAnchor ? (
    <AnchorTag
      href={to}
      target={target}
      rel={rel ?? (target === '_blank' ? 'noopener noreferrer' : undefined)}
      {...filteredProps}
    >
      {children}
    </AnchorTag>
  ) : (
    <LinkTag to={to} {...filteredProps}>
      {children}
    </LinkTag>
  );
};

export { CustomLink };
