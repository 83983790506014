import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useTrack } from '@root/initializers/wt';
import { EventSchema } from '@root/resources/wt/types';
import { ServiceSelection } from '@shared/service_selector/constants';

const lastPathByService = {
  [ServiceSelection.Storage]: '',
  [ServiceSelection.Moving]: '',
  none: '',
};

export const useTrackFunnelEntryPointViewed = ({
  service,
  visible = true,
}: {
  service?: ServiceSelection;
  visible?: boolean;
}) => {
  const trackFunnelEvent = useTrack();
  const { pathname } = useLocation();
  useEffect(() => {
    if (!visible) return;
    // We only need to track this event once per page/service combination but
    // this hook can be used in an arbitrary number of components.
    if (lastPathByService[service ?? 'none'] !== pathname) {
      lastPathByService[service ?? 'none'] = pathname;
      trackFunnelEvent({
        schema: EventSchema.WWW__FunnelEntryPointViewed,
        action: 'display',
        category: 'funnel_event',
        metadata: {
          service,
        },
      });
    }
  }, [visible, pathname, trackFunnelEvent, service]);
};
