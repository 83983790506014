export const locationsURL = () => '/locations';
export const cityURL = (state: string, city: string, service = 'storage') =>
  `/locations/${state}/${city}/${service}`;
export const neighborhoodURL = (
  state: string,
  city: string,
  neighborhood: string,
  service = 'storage',
) => `/locations/${state}/${city}/${neighborhood}/${service}`;
export const routeURL = (slug: string, service = 'moving') =>
  `/locations/r/${slug}/${service}`;
