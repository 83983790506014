import styled from '@emotion/styled';

import { COLORS, FontWeight } from '@clutter/clean';

export const BreadcrumbList = styled.nav`
  & > a[href] {
    color: ${COLORS.tealPrimary};
    font-weight: ${FontWeight.Medium};
  }

  & > * + * {
    &:before {
      border-color: ${COLORS.storm};
      margin: 0 12px;
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: '';
      display: inline-block;
      position: relative;
      transform: rotate(45deg);
      vertical-align: middle;
      top: -2px;
      height: 6px;
      width: 6px;
    }
  }
`;
