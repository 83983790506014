import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentRequest, Token } from '@stripe/stripe-js';

import { Box } from '@clutter/clean';
import { track } from '@root/initializers/wt';

const StyledPaymentRequestButtonElement = styled(PaymentRequestButtonElement)`
  padding: 0px;
`;

export enum WalletProviderName {
  ApplePay = 'applePay',
}

const wtProps = {
  applePay: {
    objectType: 'button',
    objectName: 'apple_pay',
    label: 'Pay',
  },
};

export const WalletElement: React.FC<{
  className?: string;
  disabled?: boolean;
  onApplePayToken(stripeToken: Token): void;
  onApplePaySelectPrepaidFunding(): void;
  onApplePayAvailable(value: boolean): void;
}> = ({
  className,
  disabled,
  onApplePayAvailable,
  onApplePaySelectPrepaidFunding,
  onApplePayToken,
}) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<
    PaymentRequest | undefined
  >(undefined);
  const [applePayToken, setApplePayToken] = useState<Token | undefined>();
  const [walletProvider, setWalletProvider] = useState<
    WalletProviderName | undefined
  >(undefined);

  const wtPropsForProvider = () => {
    switch (walletProvider) {
      case WalletProviderName.ApplePay:
        return wtProps.applePay;
    }
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'TODAY',
          amount: 0.0,
        },
      });

      // This is only possible in test
      if (!pr) return;

      pr.canMakePayment().then((result) => {
        if (result?.applePay) {
          setPaymentRequest(pr);
          setWalletProvider(WalletProviderName.ApplePay);
          onApplePayAvailable(true);
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    if (paymentRequest) {
      track({
        ...wtPropsForProvider,
        action: 'display',
      });

      // Customer provided their credit card via apple pay.
      paymentRequest.on('token', async (ev) => {
        if (ev.token.card?.funding === 'prepaid') {
          ev.complete('fail');
          onApplePaySelectPrepaidFunding();
        } else {
          ev.complete('success');
          setApplePayToken(ev.token);
        }
      });
    }
  }, [paymentRequest]);

  // We want to ensure that if a token stays the same, we only call onApplePayToken
  // (i.e submit a checkout) once. It is possible that the 'token' event
  // from Stripe will fire twice (or be handled twice by our listener),
  // but we only want to submit one checkout.
  useEffect(() => {
    if (applePayToken && onApplePayToken) {
      onApplePayToken(applePayToken);
    }
  }, [onApplePayToken, applePayToken]);

  if (!paymentRequest || disabled) {
    return null;
  }

  return (
    <Box className={className}>
      <StyledPaymentRequestButtonElement
        options={{
          paymentRequest,
          style: { paymentRequestButton: { theme: 'light-outline' } },
        }}
        onClick={() =>
          track({
            ...wtPropsForProvider,
            action: 'click',
          })
        }
      />
    </Box>
  );
};
