import isPropValid from '@emotion/is-prop-valid';

// eslint-disable-next-line @typescript-eslint/ban-types
export const filterValidProps = <P extends object>(props: P) => {
  const filteredProps: Partial<P> = {};
  for (const prop in props) {
    if (isPropValid(prop)) filteredProps[prop] = props[prop];
  }
  return filteredProps;
};
