import * as React from 'react';

import { Route } from 'react-router-dom';

import { City } from '@root/components/locations/city';
import { Locations } from '@root/components/locations/locations';
import { Neighborhood } from '@root/components/locations/neighborhood';
import { Route as LocationRoute } from '@root/components/locations/route';
import { ErrorBoundary } from '@shared/error_boundary';
import { SwitchWithSharedRoutes } from '@shared/switch_with_shared_routes';

export const Routes: React.FC = () => {
  return (
    <ErrorBoundary>
      <SwitchWithSharedRoutes>
        <Route path="/locations" exact component={Locations} />
        <Route
          exact
          path="/locations/r/:route+/:service(storage|moving)"
          component={LocationRoute}
        />
        <Route
          exact
          path="/locations/:state/:city/:service(storage|moving)"
          component={City}
        />
        <Route
          exact
          path="/locations/:state/:city/:neighborhood/:service(storage|moving)"
          component={Neighborhood}
        />
      </SwitchWithSharedRoutes>
    </ErrorBoundary>
  );
};
