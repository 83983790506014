import React from 'react';

import { Box, Text, TextButton, UnstyledButton } from '@clutter/clean';
import { DoorToDoor__BundleEnum } from '@graphql/platform';
import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { StorageCheckoutStep } from '@root/components/checkout/types';
import { Currency } from '@shared/currency';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { useGasFeeEligible } from '@utils/hooks/use_gas_fee_eligible';

import { CollapsibleSummaryContainer } from './service_summary/collapsible_summary_container';
import { ExpandableItem } from './service_summary/expandable_item';
import { SummaryItem } from './service_summary/summary_item';
import { SummaryItemLoader } from './service_summary/summary_item_loader';
import { DueToday } from './due_today';
import { StorageSavingsSummary } from './storage_savings_summary';
import { ReservationSummaryProps } from './use_reservation_summary_props';

import cargoVanCrop from '@images/illustrations/cargo_van_crop_small.svg';
import clock from '@images/illustrations/clock_large.svg';
import markerWithRoute from '@images/illustrations/marker_with_route_small.svg';
import moverPackingBoxes from '@images/illustrations/mover_packing_boxes.svg';
import moverWithCouch from '@images/illustrations/mover_with_couch_small.svg';
import selfStorageUnit from '@images/illustrations/self_storage_unit.svg';
import stackedBoxes from '@images/illustrations/stacked_boxes.svg';

const ExpandedPlanContent = ({
  planSizeLabel,
  monthlyTotalAmount,
  protectionPlanAmount,
  protectionPlanLabel,
  monthlyTaxAmount,
  monthlySavingsAmount,
  commitmentLength,
}: {
  commitmentLength: number;
  monthlySavingsAmount: number;
  monthlyTaxAmount: number;
  monthlyTotalAmount: number;
  planSizeLabel: React.ReactNode;
  protectionPlanAmount: number;
  protectionPlanLabel: React.ReactNode;
}) => (
  <>
    <ExpandableItem>
      A temperature-controlled, squeaky clean Smart Storage space with 24/7
      security. Includes an online account to manage your storage inventory.
    </ExpandableItem>
    <ExpandableItem>
      <b>{planSizeLabel} Smart Storage</b>
      <span>
        <Currency amount={monthlyTotalAmount - protectionPlanAmount} />
      </span>
    </ExpandableItem>
    {protectionPlanAmount > 0 && (
      <ExpandableItem>
        <b>{protectionPlanLabel} Protection Plan</b>
        <span>
          <Currency amount={protectionPlanAmount} />
        </span>
      </ExpandableItem>
    )}
    {monthlySavingsAmount > 0 && (
      <ExpandableItem>
        <b>{commitmentLength} month term</b>
        <b>
          <Currency amount={monthlySavingsAmount} suffix="/mo discount" />
        </b>
      </ExpandableItem>
    )}
    {monthlyTaxAmount > 0 && (
      <ExpandableItem>
        <b>Tax</b>
        <Currency amount={monthlyTaxAmount} suffix="/mo" />
      </ExpandableItem>
    )}
  </>
);

const ExpandedPickupContent = ({
  commitmentLength,
  onboardingFeeSavingsAmount,
  onboardingFeeAmount,
}: {
  commitmentLength: number;
  onboardingFeeSavingsAmount: number;
  onboardingFeeAmount: number;
}) => (
  <>
    <ExpandableItem>
      Our team will come pick up your items from your home. This includes a full
      size truck, driver, drive time, loading and unloading.
    </ExpandableItem>
    <ExpandableItem>
      <b>Pickup labor + truck</b>
      <span>
        <Currency amount={onboardingFeeAmount} />
      </span>
    </ExpandableItem>
    {onboardingFeeSavingsAmount > 0 && (
      <ExpandableItem>
        <b>{commitmentLength} month term</b>
        <b>
          <Currency amount={onboardingFeeSavingsAmount} /> discount
        </b>
      </ExpandableItem>
    )}
  </>
);

export const StoragePlanSummaryItem: React.FC<
  ReservationSummaryProps & {
    moverMonthlyAmount?: number;
    commitmentLength?: number;
    monthlySavingsAmount?: number;
  }
> = ({
  monthlyTotalAmount,
  monthlyTaxAmount,
  planSizeLabel,
  protectionPlanAmount,
  protectionPlanLabel,
  moverMonthlyAmount = 0,
  commitmentLength = 0,
  monthlySavingsAmount = 0,
}) => (
  <SummaryItem
    illustration={selfStorageUnit}
    item={<>{planSizeLabel} Unit</>}
    amount={<Currency amount={monthlyTotalAmount} suffix="/mo" />}
    discountedAmount={
      moverMonthlyAmount > monthlyTotalAmount && (
        <Currency
          amount={moverMonthlyAmount + protectionPlanAmount}
          suffix="/mo"
        />
      )
    }
    description={
      <>
        {protectionPlanAmount
          ? `Includes protection plan`
          : `${commitmentLength} month term`}
      </>
    }
    expandableContent={
      <ExpandedPlanContent
        planSizeLabel={planSizeLabel}
        monthlyTotalAmount={monthlyTotalAmount}
        protectionPlanAmount={protectionPlanAmount}
        protectionPlanLabel={protectionPlanLabel}
        monthlyTaxAmount={monthlyTaxAmount}
        monthlySavingsAmount={monthlySavingsAmount}
        commitmentLength={commitmentLength}
      />
    }
  />
);

const Summary: React.FC<{
  reservationSummary: ReservationSummaryProps;
  openDisposalPricingModal: () => void;
  onEdit: (step: StorageCheckoutStep) => void;
}> = ({ reservationSummary, openDisposalPricingModal, onEdit }) => {
  const { isDesktop } = useBreakpoints();
  const {
    pricingSummary,
    flowState: {
      values: { bundleKind, disposalSelected, dateScheduled },
    },
  } = useStorageCheckoutContext();
  const hasGasFee = useGasFeeEligible();

  const {
    fullServiceLaborRateAmount = 0,
    moverMonthlyAmount = 0,
    onboardingFeeAmount = 0,
    commitmentLength,
    onboardingFeeSavingsAmount = 0,
    monthlySavingsAmount,
    formattedFreeDuration,
    bundles,
  } = pricingSummary || {};

  const selectedBundle = bundles?.find((bundle) => bundle.kind === bundleKind);

  return (
    <Box margin="0 0 24px">
      <Box.Flex justifyContent="space-between" margin="0 0 12px">
        <Text.Title size="extraSmall">Smart Storage Summary</Text.Title>
        <TextButton
          size="medium"
          onClick={() => onEdit(StorageCheckoutStep.PlanSize)}
        >
          <Text.Callout>
            <b>Edit</b>
          </Text.Callout>
        </TextButton>
      </Box.Flex>
      <Box.Flex flexDirection="column" style={{ gap: '24px' }}>
        {reservationSummary.summaryLoaded ? (
          <>
            <StoragePlanSummaryItem
              {...reservationSummary}
              moverMonthlyAmount={moverMonthlyAmount}
              commitmentLength={commitmentLength}
              monthlySavingsAmount={monthlySavingsAmount}
            />
            {pricingSummary?.onboardingFeeAmount === 0 ? null : (
              <SummaryItem
                illustration={cargoVanCrop}
                item="Warehouse Fee"
                amount={<Currency amount={onboardingFeeAmount} />}
                description=""
                discountedAmount={
                  onboardingFeeSavingsAmount > 0 && (
                    <Currency
                      amount={onboardingFeeSavingsAmount + onboardingFeeAmount}
                    />
                  )
                }
                expandableContent={
                  <ExpandedPickupContent
                    commitmentLength={commitmentLength ?? 0}
                    onboardingFeeSavingsAmount={onboardingFeeSavingsAmount}
                    onboardingFeeAmount={onboardingFeeAmount}
                  />
                }
              />
            )}
            <SummaryItem
              illustration={stackedBoxes}
              item={selectedBundle ? 'Hourly Rate' : 'Move & Pack Rate'}
              description={
                fullServiceLaborRateAmount === 0
                  ? ''
                  : formattedFreeDuration
                  ? `First ${formattedFreeDuration} totally free!`
                  : 'Total calculated at appointment end'
              }
              amount={
                <>
                  {fullServiceLaborRateAmount > 0 ? (
                    <Currency
                      amount={fullServiceLaborRateAmount}
                      suffix="/hr"
                    />
                  ) : (
                    'Free'
                  )}
                </>
              }
            />
            {selectedBundle?.packageSetEntry &&
              selectedBundle.kind ===
                DoorToDoor__BundleEnum.PickupAndPacking && (
                <SummaryItem
                  illustration={moverPackingBoxes}
                  item={selectedBundle.name + ' Package'}
                  description=""
                  amount={
                    <Currency amount={selectedBundle.packageSetEntry.amount!} />
                  }
                />
              )}
            {disposalSelected && (
              <SummaryItem
                illustration={moverWithCouch}
                item="Disposal Charge"
                description={
                  <UnstyledButton onClick={openDisposalPricingModal}>
                    <Text.Callout textDecoration="underline">
                      See pricing and restrictions
                    </Text.Callout>
                  </UnstyledButton>
                }
                amount={<Currency amount={50} suffix="+" />}
              />
            )}
            {dateScheduled?.feeAmount && dateScheduled.feeAmount.value > 0 && (
              <SummaryItem
                illustration={clock}
                item="Scheduled Arrival Fee"
                description=""
                amount={<Currency amount={dateScheduled.feeAmount.value} />}
              />
            )}
            {hasGasFee && (
              <SummaryItem
                illustration={markerWithRoute}
                item="Gas Fee"
                description=""
                amount={<Currency amount={9} />}
              />
            )}
          </>
        ) : (
          <>
            <SummaryItemLoader />
            <SummaryItemLoader />
          </>
        )}
      </Box.Flex>
      {isDesktop && (
        <Box margin="24px 0 0">
          <DueToday />
        </Box>
      )}
      <Box margin="24px 0 0">
        <StorageSavingsSummary
          monthlySavingsAmount={monthlySavingsAmount ?? 0}
          onboardingFeeSavingsAmount={
            pricingSummary?.onboardingFeeSavingsAmount ?? 0
          }
          commitmentLength={commitmentLength ?? 0}
        />
      </Box>
    </Box>
  );
};

export const StorageServiceSummary: React.FC<{
  reservationSummary: ReservationSummaryProps;
  collapsible?: boolean;
  openDisposalPricingModal: () => void;
  onEdit: (step: StorageCheckoutStep) => void;
}> = ({ collapsible, ...props }) => (
  <>
    {collapsible ? (
      <CollapsibleSummaryContainer>
        {props.reservationSummary.monthlyTotalAmount > 0 && (
          <Summary {...props} />
        )}
      </CollapsibleSummaryContainer>
    ) : (
      <Summary {...props} />
    )}
  </>
);
