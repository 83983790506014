import React from 'react';

import { capitalize } from 'lodash-es';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router';

import { Box, COLORS } from '@clutter/clean';
import { Location__Service, useNeighborhoodQuery } from '@graphql/platform';
import { cityURL, locationsURL } from '@root/components/locations/routes';
import { CityMap } from '@root/components/locations/shared/city_map';
import { Content } from '@root/components/locations/shared/content';
import { NeighborhoodLinks } from '@root/components/locations/shared/neighborhood_links';
import {
  buildBreadcrumbData,
  buildMetaData,
} from '@root/components/locations/shared/structured_data';
import { BreadcrumbItem } from '@shared/breadcrumb_item';
import { BreadcrumbList } from '@shared/breadcrumb_list';
import { ButtonCTA } from '@shared/button_cta';
import { NoWrap } from '@shared/formatters/no_wrap';
import { ServiceSelection } from '@shared/service_selector/constants';

import { Hero } from './shared/hero';
import { Layout } from './shared/layout';
import { Perks } from './shared/perks';
import { Reviews } from './shared/reviews';
import { SERVICE_TO_ENUM } from './shared/service';
import { ServiceOverview } from './shared/service_overview';

type NeighborhoodParams = {
  state: string;
  city: string;
  neighborhood: string;
  service: 'storage' | 'moving';
};

export const Neighborhood = () => {
  const {
    city: cityParam,
    state: stateParam,
    neighborhood: neighborhoodParam,
    service: serviceParam,
  } = useParams<NeighborhoodParams>();

  const { data } = useNeighborhoodQuery({
    variables: {
      city: cityParam,
      state: stateParam,
      neighborhood: neighborhoodParam,
      service: SERVICE_TO_ENUM[serviceParam],
    },
  });

  if (!data?.state?.city?.neighborhood) return null;
  const state = data.state;
  const city = data.state.city;
  const neighborhood = data.state.city.neighborhood;

  const service =
    serviceParam === 'storage'
      ? Location__Service.SmartStorage
      : Location__Service.Moving;

  const reviews = neighborhood.content?.reviews?.length
    ? neighborhood.content.reviews
    : city.content?.reviews;

  const metadata = buildMetaData(data, serviceParam);
  const title = metadata?.title;
  const description = metadata?.description;

  const jsonLD = buildBreadcrumbData(data, serviceParam);

  return (
    <>
      <HelmetProvider context={global.helmetContext}>
        <Helmet>
          <title>{title}</title>
          {description && <meta name="description" content={description} />}
          {jsonLD && <script type="application/ld+json">{jsonLD}</script>}
        </Helmet>
      </HelmetProvider>
      <Layout>
        <Box maxWidth="1600px" margin="0 auto 80px" padding="0 24px">
          <Hero
            headline={neighborhood.content?.headline}
            heroAlt={neighborhood.content?.heroAlt}
            heroImage={neighborhood.content?.heroImage}
            locationName={neighborhood.name}
            service={service}
          />
          <Box margin="32px 0 0">
            <BreadcrumbList>
              <BreadcrumbItem href="/">Clutter</BreadcrumbItem>
              <BreadcrumbItem href={locationsURL()}>Locations</BreadcrumbItem>
              <BreadcrumbItem
                href={cityURL(state.slug, city.slug, serviceParam)}
              >
                {city?.name}, {state?.name}
              </BreadcrumbItem>
              <BreadcrumbItem>
                {capitalize(serviceParam)} in {neighborhood.name}
              </BreadcrumbItem>
            </BreadcrumbList>
          </Box>
        </Box>
        {neighborhood.content && (
          <Layout.Section padding="0 0 80px">
            <Content content={neighborhood.content} />
          </Layout.Section>
        )}
        <Layout.Section
          background={COLORS.grayBackground}
          textAlign="center"
          margin="0 0 80px"
          padding="80px 0"
        >
          <Perks service={service} />
          <ButtonCTA
            container="perks"
            label="Get a Quote"
            entryPoint="location_page_cta"
            service={
              serviceParam === 'moving'
                ? ServiceSelection.Moving
                : ServiceSelection.Storage
            }
          />
        </Layout.Section>
        <Layout.Section margin="0 0 80px">
          <ServiceOverview
            title={
              <>
                {city.name} {serviceParam} you <NoWrap>can trust</NoWrap>
              </>
            }
            subtitle="Get a transparent quote in 2 minutes."
            service={service}
          />
        </Layout.Section>
        {!!reviews?.length && (
          <Layout.Section background={COLORS.grayBackground} padding="80px 0">
            <Reviews reviews={reviews} locationName={neighborhood.name} />
          </Layout.Section>
        )}
        <Layout.Section padding="80px 0">
          <CityMap state={state} city={city} />
          <Box margin="80px 0 0">
            <NeighborhoodLinks
              state={state}
              city={city}
              neighborhoods={city.neighborhoods}
              service={serviceParam}
            />
          </Box>
        </Layout.Section>
      </Layout>
    </>
  );
};
