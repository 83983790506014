import React from 'react';

import { Box, COLORS, FontWeight, Text, TextButton } from '@clutter/clean';
import { LaborBillingFormatEnum } from '@graphql/platform';
import { PricingSummary } from '@utils/hooks/pricing/use_pricing_summary';

import { CardContainer } from '../fees/card_container';
import { Price } from '../fees/price';

import { PerMoverHourDisclaimer } from './per_mover_hour_disclaimer';

import hourlyRateImageMobile from '@images_responsive/www/checkout/storage_hourly_rate_cropped_wide.jpg';
import hourlyRateImageDesktop from '@images_responsive/www/checkout/storage_hourly_rate.jpg';
import lightBulbIllustration from '@images/illustrations/light_bulb_rotated.svg';

export const HourlyRateCard: React.FC<{
  pricingSummary: PricingSummary | undefined;
  showPrice: boolean;
  onModalLinkClick: (e: React.MouseEvent<HTMLElement>) => void;
  planSizeName: string | undefined;
}> = ({
  pricingSummary,
  showPrice,
  onModalLinkClick: onModalLinkClick,
  planSizeName,
}) => {
  const perMoverHour =
    pricingSummary?.laborBillingFormat === LaborBillingFormatEnum.PerMoverHour;

  return (
    <CardContainer
      mobileImage={hourlyRateImageMobile.src}
      desktopImage={hourlyRateImageDesktop.src}
    >
      <Box.Flex
        flexDirection="column"
        gap="8px"
        margin={['16px 0 0', null, null, '0']}
      >
        <Box.Flex justifyContent="space-between">
          <Text.Body weight={FontWeight.Medium}>
            Hourly Move & Pack Rate
          </Text.Body>
          <Box.Flex textAlign="right">
            {pricingSummary && showPrice && (
              <>
                {pricingSummary.maxLaborRateAmount >
                  pricingSummary.fullServiceLaborRateAmount && (
                  <Price
                    amount={pricingSummary.maxLaborRateAmount}
                    suffix={perMoverHour ? '/hr *' : '/hr'}
                    strikeThrough
                  />
                )}
                <Price
                  amount={pricingSummary.fullServiceLaborRateAmount}
                  suffix={perMoverHour ? '/hr *' : '/hr'}
                />
              </>
            )}
          </Box.Flex>
        </Box.Flex>

        {perMoverHour && (
          <PerMoverHourDisclaimer pricingSummary={pricingSummary} />
        )}

        <Text.Callout>
          Our Move & Pack Specialists will come ready to pack boxes, wrap
          furniture, and load your items onto our truck. Unlimited packing
          materials included!{' '}
          <TextButton size="medium" onClick={onModalLinkClick}>
            <Text.Callout weight={FontWeight.Medium}>
              What else is included?
            </Text.Callout>
          </TextButton>
        </Text.Callout>

        {planSizeName && !pricingSummary?.selectedCommitmentHasFreePickup && (
          <Box.Flex>
            <Box.FlexItem alignSelf="start" margin="0 8px 0 0" flexShrink={0}>
              <img
                src={lightBulbIllustration}
                width="16px"
                draggable={false}
                alt=""
              />
            </Box.FlexItem>
            <Text.Caption color={COLORS.hippo}>
              Customers who pack boxes and disassemble items ahead of time save
              25-30% off their final bill.
            </Text.Caption>
          </Box.Flex>
        )}
      </Box.Flex>
    </CardContainer>
  );
};
