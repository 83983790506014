import React, { useEffect, useState } from 'react';

import { useMapContext } from './context';

export const DirectionsRenderer: React.FC<{
  directions?: google.maps.DirectionsResult;
  options?: google.maps.DirectionsRendererOptions;
}> = ({ directions, options }) => {
  const { maps, map } = useMapContext();
  if (!maps || !map) throw new Error('No map found');

  const [directionsRenderer] = useState(() => new maps.DirectionsRenderer());

  useEffect(() => {
    if (!directions || !options) {
      return;
    }
    directionsRenderer.setMap(map);
    directionsRenderer.setDirections(directions);
    directionsRenderer.setOptions(options);
  }, [map, directions, options, directionsRenderer]);

  return null;
};
