import React, { useEffect, useRef } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  Carousel,
  COLORS,
  mq,
  toggleStyleValue,
  UnstyledButton,
  useCarousel,
} from '@clutter/clean';
import { XButton } from '@root/components/icons/x_button';
import { useTrack } from '@root/initializers/wt';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { Arrow } from '@images/svg/arrow';

const NavButton = styled(UnstyledButton)`
  width: 56px;
  height: 56px;
  background: ${COLORS.grayBackground};
  border-radius: 4px;
  margin-top: 16px;
  margin-right: 20px;

  &:hover path {
    fill: ${COLORS.__primaryButtonHover};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledCarousel = styled(Carousel)`
  overflow: visible;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
`;

const Container = styled.div`
  width: 100%;
  max-width: 888px; // 856px + 32px margin
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;

  ${mq({
    height: ['100%', 'unset'],
    padding: ['60px 8px 32px', '40px 8px 32px'],
  })}
`;

const Indicator = styled.div<{ focused: boolean }>`
  border-radius: 6px;
  height: 12px;
  margin: 0 6px;
  width: 12px;

  background: ${toggleStyleValue(
    'focused',
    COLORS.tealBrand,
    COLORS.grayBorder,
  )};

  ${mq({
    display: ['block', 'none'],
  })}
`;

const Indicators = styled.div`
  position: relative;
  justify-content: center;
  height: 0px;
  top: 24px;
  display: flex;
`;

const SlideContainer = styled.div<{ singleSlide?: boolean }>`
  border-radius: 16px;
  color ${COLORS.tealDark};
  position: relative;
  margin: 0 16px;
  background: ${COLORS.cloud};
  max-height: calc(100vh - ${({ singleSlide }) => (singleSlide ? 80 : 260)}px);
  max-height: calc(100dvh - ${({ singleSlide }) => (singleSlide ? 0 : 180)}px);
  overflow: auto;

  ${mq({
    height: ['100%', 'initial'],
  })}
`;

export const FeaturesOverlay: React.FC<{
  isOpen: boolean;
  onClose(): void;
  content: React.ReactNode[];
}> = ({ isOpen, onClose, content }) => {
  const { isMobile } = useBreakpoints();
  const track = useTrack({
    action: 'click',
    container: 'features_overlay',
  });
  const props = useCarousel({
    draggable: !isMobile,
    initialIndex: 0,
    wrapAround: false,
  });
  const { next, prev, idx } = props;

  const focusedIdx = (idx + content.length) % content.length;

  const lastIdxRef = useRef(focusedIdx);
  useEffect(() => {
    if (lastIdxRef.current === focusedIdx) return;
    track({
      objectName: lastIdxRef.current < focusedIdx ? 'next' : 'prev',
      position: lastIdxRef.current,
    });
    lastIdxRef.current = focusedIdx;
  }, [focusedIdx, track]);

  if (!isOpen) {
    return null;
  }

  const singleSlide = content.length === 1;

  const onOverlayClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };

  return (
    <Overlay onClick={onOverlayClick}>
      <Box position="absolute" right="24px" top="24px">
        <UnstyledButton onClick={onClose}>
          <XButton width="24px" height="24px" fillColor={COLORS.cloud} />
        </UnstyledButton>
      </Box>
      <Container onClick={onOverlayClick}>
        {singleSlide ? (
          content.map((feature, index) => (
            <SlideContainer key={index} singleSlide={singleSlide}>
              {feature}
            </SlideContainer>
          ))
        ) : (
          <>
            <StyledCarousel {...props} slidesToShow={1}>
              {content.map((feature, index) => (
                <SlideContainer key={index} singleSlide={singleSlide}>
                  {feature}
                </SlideContainer>
              ))}
            </StyledCarousel>
            <Box.Flex
              alignItems="center"
              flexDirection="column"
              margin={['0', '64px 0 0']}
              onClick={onOverlayClick}
            >
              <div>
                <NavButton
                  onClick={prev}
                  aria-label="previous slide"
                  disabled={focusedIdx === 0}
                >
                  <Arrow />
                </NavButton>
                <NavButton
                  onClick={next}
                  aria-label="next slide"
                  disabled={focusedIdx === content.length - 1}
                >
                  <Arrow direction="right" />
                </NavButton>
              </div>
              <Indicators>
                {content.map((_, i) => (
                  <Indicator key={i} focused={i === focusedIdx} />
                ))}
              </Indicators>
            </Box.Flex>
          </>
        )}
      </Container>
    </Overlay>
  );
};
