import React from 'react';

import styled from '@emotion/styled';

import { Box } from '@clutter/clean';

import pin from '@images/map/pin.svg';

type LabelProps = React.CSSProperties & { text: string };

type CursorOption = 'pointer' | 'default';

const Label: React.FC<{ label: LabelProps }> = ({ label }) => {
  const { text, ...style } = label;
  return (
    <Box position="absolute" top="6px" style={style}>
      {text}
    </Box>
  );
};

const Container = styled.div<{ cursor: CursorOption }>`
  position: absolute;
  transform: translate(-50%, -100%);
  text-align: center;
  cursor: ${({ cursor }) => cursor};
`;

export const Marker: React.FC<{
  children?: React.ReactNode;
  lat: number;
  lng: number;
  icon?: string;
  label?: LabelProps;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
}> = ({ icon = pin, label, onClick, children }) => {
  const cursor = onClick ? 'pointer' : 'default';
  return (
    <Container cursor={cursor}>
      <img
        loading="lazy"
        src={icon}
        width={24}
        height={38}
        onClick={onClick}
        alt=""
      />
      {label && <Label label={label} />}
      {children}
    </Container>
  );
};
