import React from 'react';

import styled from '@emotion/styled';

import { Text } from '@clutter/clean';

const Container = styled.div`
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 12px;
  border-radius: 4px;
`;

export const SkipAddressOverlay: React.FC<{ zip?: string }> = ({ zip }) => (
  <Container>
    <Text.Caption>
      Estimate will be based on a distance of{' '}
      <b>5 miles from {zip ?? 'your zip code'}</b>
    </Text.Caption>
  </Container>
);
