import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, SERIF_FONT_FAMILY } from '@clutter/clean';

const Label: React.FC<{ label: React.ReactNode }> = ({ label }) => {
  return (
    <Box
      position="absolute"
      top="6px"
      style={{
        color: COLORS.panther,
        fontWeight: FontWeight.Bold,
        fontSize: '20px',
        fontFamily: SERIF_FONT_FAMILY,
        top: '6px',
        width: '36px',
      }}
    >
      {label}
    </Box>
  );
};

const Container = styled.div`
  position: absolute;
  transform: translate(-50%, -100%);
  text-align: center;
  cursor: pointer;
`;

export const Pin: React.FC<{
  lat: number;
  lng: number;
  size?: 'small' | 'large';
  color?: string;
  label?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  style?: React.CSSProperties;
}> = ({
  label,
  onClick,
  color = COLORS.tealPrimary,
  size = 'large',
  style,
}) => (
  <Container onClick={onClick} style={style}>
    {size === 'large' ? (
      <svg width="36" height="55" viewBox="0 0 36 55" fill="none">
        <circle cx="17.6" cy="17.6" r="17.6" fill={color} />
        <path
          d="M4.07123 23.9667H31.1288C32.262 23.9667 32.9859 25.1749 32.4515 26.1742L18.9227 51.4671C18.3575 52.5238 16.8425 52.5238 16.2773 51.4671L2.74855 26.1741C2.21409 25.1749 2.93805 23.9667 4.07123 23.9667Z"
          fill={color}
          stroke={color}
        />
        <circle cx="17.6" cy="17.6" r="11.7333" fill="white" />
      </svg>
    ) : (
      <svg width="28" height="44" viewBox="0 0 28 44" fill="none">
        <circle cx="14" cy="14" r="14" fill={color} />
        <path
          d="M3.91368 19.2733H24.0597C25.1909 19.2733 25.9151 20.4779 25.3844 21.477L15.3114 40.4403C14.7474 41.502 13.226 41.502 12.662 40.4403L2.58897 21.477C2.05828 20.4779 2.78241 19.2733 3.91368 19.2733Z"
          fill={color}
          stroke={color}
        />
        <circle cx="14" cy="14" r="7" fill="white" />
      </svg>
    )}
    {label && size === 'large' && <Label label={label} />}
  </Container>
);
